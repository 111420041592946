import React, { useState, useEffect } from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';

import SlidedeckList from "containers/Main/List";
import SlidedeckCard from "./card";
import { getTopicDeleteAPI, SLIDEDECK_LIST_API, getSlidedeckListApi } from 'constants/ApiConstants.js';
import { SLIDEDECK_LIST_TYPE } from './constants.js';
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR } from 'constants/ThemeSetting';
import { onNavStyleChange } from "appRedux/actions/Setting";
import { getCourseId } from 'util/LocationUtils'

import Welcome from 'containers/Main/Welcome'


export const SLIDE_LIST_CONFIG = {
  type: SLIDEDECK_LIST_TYPE,
  perPage: 10,
  API: SLIDEDECK_LIST_API,
  DEL_API_FN: getTopicDeleteAPI,
  initialState: {
    hasMore: true,
    page: 1,
    perPage: 10,
  },
  itemComponent: SlidedeckCard,
  pageTitle: "Slides",
  componentWhenNoItemsFound: Welcome
}

const Slidedecks = (params) => {

  const courseId = getCourseId({ path: params.location.pathname })
  if (courseId != null) {
    const slideListApi = getSlidedeckListApi({ courseId })
    SLIDE_LIST_CONFIG.API = slideListApi
  }

  if (params.navStyle.navStyle != NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    params.dispatch(onNavStyleChange(NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR))
  }

  const slideConfig = { ...SLIDE_LIST_CONFIG, ...params }

  return (
    <div>
      <div className="gx-main-content gx-quiz-details-container gx-pb-sm-4">
        <SlidedeckList key="slidedeck" config={slideConfig} beams={params.beams} firebase={params.firebase} dispatch={params.dispatch} />
      </div>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    themeType: state.settings,
    navStyle: state.settings,
    beams: state.beam.beams,
    beamListenerCreated: state.beam.beamListenerCreated,
    authUser: state.authUser,
    firebase: state.firebase,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(Slidedecks);
