import { getRandomInteger } from "../../util/topicUtils";
import itemConfigSelectable, { ifOneOrMoreOptionsDoNotHaveCorrectAns } from "../itemConfigSelectables";
import promptSelectionArray from "../promptSelectionArrayBuilder";

export const changedTopicItemInfoForFib2 = (item, topicItemInfo) => {
  let activityModeToBeChanged = topicItemInfo.assessment_config.activity_mode;
  let promptSelectables;

  if(ifOneOrMoreOptionsDoNotHaveCorrectAns(item)) {
    activityModeToBeChanged = 'fillInTheBlanks'
  } else if (
    !topicItemInfo.assessment_config.promptSelection &&
    activityModeToBeChanged === "random"
  ) {
    const activityModeTypesArray = itemConfigSelectable("fib2", item, true);
    const randomIndexForArray = getRandomInteger(activityModeTypesArray.length);
    activityModeToBeChanged = activityModeTypesArray[randomIndexForArray].value;
  } 
  
  if (topicItemInfo.assessment_config.promptSelection) {
    promptSelectables = promptSelectionArray("fib2", item);
  }

  const newTopicItemInfo = {
    ...topicItemInfo,
    assessment_config: {
      ...topicItemInfo.assessment_config,
      activity_mode: activityModeToBeChanged
    }
  };

  if (promptSelectables) {
    newTopicItemInfo.assessment_config.allowed_activities_for_beam = promptSelectables;
  }

  return newTopicItemInfo;
};
