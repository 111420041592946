export const getTopicItem = ({topic, itemId})=>{
  if (typeof topic != 'undefined' && typeof topic.items != 'undefined'){
    let matchingTopicItem;
    Object.keys(topic.items).forEach((topicItemKey)=> {
      const  topicItem = topic.items[topicItemKey]
      if (typeof topicItem != 'undefined' && topicItem.uuid == itemId){
        matchingTopicItem = topicItem;
      } 
    });
    return matchingTopicItem;
  }
}

export const getRandomInteger = (maxNumber) => Math.floor(Math.random()*maxNumber);