import produce from 'immer';
import {
  JOIN_BEAM_CHANNEL_ACTION_SUCCESS, JOIN_BEAM_CHANNEL_ACTION_FAILURE,
  RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS, RECEIVE_BEAM_CHANNEL_MESSAGE_FAILURE,
} from "./constants";
import { getInstructorBeamChannelAddress } from './utils';


const initialSettings = {

  joinedBeamChannels: [],
  participants: {},
  attemptedToJoinBeamChannels: [],
};

const beam = (state = initialSettings, action) =>
  produce(state, draft => {
    switch (action.type) {
      case JOIN_BEAM_CHANNEL_ACTION_SUCCESS:
        console.log("in JOIN_BEAM_CHANNEL_ACTION_SUCCESS", state, action);
        draft.joinedBeamChannels.push(action.response.beamChannelAddress);
        draft.attemptedToJoinBeamChannels.push(action.response.beamChannelAddress);
        break;
      case JOIN_BEAM_CHANNEL_ACTION_FAILURE:
        console.log("in JOIN_BEAM_CHANNEL_ACTION_FAILURE", state, action);
        const requestInput = action.requestInput;
        const { currentBeam } = requestInput;
        const beamChannelAddress = getInstructorBeamChannelAddress(currentBeam);
        draft.attemptedToJoinBeamChannels.push(beamChannelAddress);
        break;
      case RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS:
        console.log("in RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS", state, action);
        if (typeof action.response == 'undefined' || typeof action.response.beamChannelMessage == 'undefined') break;
        const beamChannelMessage = action.response.beamChannelMessage
        const message = beamChannelMessage.message;
        
        //don't proceed if beamId or currentBeam is not valid...same with message
        if (typeof action.requestInput.currentBeam == 'undefined') break;
        const beamId = action.requestInput.currentBeam.id;
        if (typeof beamId == 'undefined') break;
        if (typeof message == 'undefined' || typeof message.body == 'undefined') break;
        const msgAction = message.body.action;
        if (msgAction === 'REGISTER' || msgAction === 'PONG') {
          const accountId = message.body.accountId;
          const handlerId = message.body.handlerId;
          if (typeof draft.participants[beamId] == 'undefined') {
            draft.participants[beamId] = {}
          }
          draft.participants[beamId][accountId] = {
            status: "active",
            handlerId: handlerId
          }
        } else if (msgAction === 'UNREGISTER') {
          console.log("in Unregister");
          const handlerId = message.body.handlerId;
          console.log("handlerId", handlerId);
          // const accountIds = draft.participants
          if (typeof draft.participants[beamId] != undefined) {
            const accountId = Object.keys(draft.participants[beamId]).find((key) =>
              (draft.participants[beamId][key]['handlerId'] === handlerId))
            console.log("key found =", accountId);
            if (typeof accountId == 'undefined' || accountId == null) {
              break;
            }
            draft.participants[beamId][accountId]['status'] = 'inactive';
          }
        }
      case RECEIVE_BEAM_CHANNEL_MESSAGE_FAILURE:
        break;
    }
  });

export default beam;
