import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router';
import Settings from "./Settings";
import Auth from "./Auth";
import history from 'util/history';
import Beam from 'containers/Main/Beam/reducer';
import UserBeam from 'containers/Main/StudentBeam/reducer';
import Course from './Course';
import Firebase from "./Firebase";
import FIBResponse from "./FIBResponse";
import BeamResponseStats from "./BeamResponseStats";
import BeamParticipants from "containers/Main/Beam/BeamParticipants/reducer";
import AppBootstrap from "./AppBootstrap";
import RoomUsersStatus from './RoomUsersStatus';

const createRootReducer = (asyncReducers) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  beam: Beam,
  userBeam: UserBeam,
  course: Course,
  firebase: Firebase,
  fibResponse: FIBResponse,
  beamResponseStats: BeamResponseStats,
  beamParticipants: BeamParticipants,
  appBootstrap: AppBootstrap,
  roomUsersStatus: RoomUsersStatus,
  ...asyncReducers,
});

export default createRootReducer;
