import { FETCH_BEAM_SCREENS_ACTION, FETCH_SCREENS_SUCCESS, FETCH_SCREENS_FAILURE,
    UPDATE_CURRENT_BEAM_ACTION, FETCH_BEAMS_ACTION, CREATE_BEAM_ACTION, CREATE_BEAM_SUCCESS, CREATE_BEAM_FAILURE, CREATE_BEAM_LISTENER_ACTION, FETCH_BEAMS_SUCCESS, FETCH_BEAMS_FAILURE,
    UPDATE_BEAM_STATE_ACTION, UPDATE_BEAM_STATE_SUCCESS, UPDATE_BEAM_STATE_FAILURE,
    UPDATE_LIVE_MODE_ACTION, CREATE_NEW_SCREEN_ACTION, UPDATE_CURRENT_SCREEN_ACTION,
    UPDATE_CURRENT_SCREEN_SUCCESS, UPDATE_CURRENT_SCREEN_FAILURE,
    UPDATE_CURRENT_SCREEN_STATE_SUCCESS,
    LISTEN_TO_USER_BEAMS_ACTION, LISTEN_TO_USER_BEAMS_SUCCESS, LISTEN_TO_USER_BEAMS_FAILURE,
    LISTEN_TO_USER_BEAM_SCREENS_FAILURE, LISTEN_TO_USER_BEAM_SCREENS_SUCCESS,
    JOIN_BEAM_CHANNEL_ACTION,
    JOIN_BEAM_CHANNEL_ACTION_FAILURE, JOIN_BEAM_CHANNEL_ACTION_SUCCESS,
    RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS,
    UPDATE_CORRECT_ANSWER_ACTION, UPDATE_CORRECT_ANSWER_SUCCESS, UPDATE_CORRECT_ANSWER_FAILURE,
    USER_BEAM_SCREENS_CHANGED, UPDATE_CURRENT_SCREEN_ANNOTATIONS,UPDATE_CURRENT_SCREEN_ANNOTATIONS_SUCCESS,UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE, UPDATE_CURRENT_BEAM_ANNOTATIONS_DATA,
    UPDATE_CURRENT_SCREEN_WITH_NEW_CONTENT,
    UPDATE_CURRENT_SCREEN_SOLUTION,
    STORE_BEAM_IMAGE_DIMENSIONS,
    UPDATE_BEAM_MAX_POINTS,
    UPDATE_BEAM_MAX_POINTS_SUCCESS,
    UPDATE_BEAM_MAX_POINTS_FAILURE
}
    from "./constants";

/**
 * Load the beams, this action starts the request saga
 *
 * @return {object} An action object with a type of CREATE_BEAM_LISTENER_ACTION
 */
export function actionToCreateBeamListener(requestInput) {
    return {
        type: CREATE_BEAM_LISTENER_ACTION,
        requestInput,
    };
}

export function actionToFetchBeams(requestInput) {
    return {
        type: FETCH_BEAMS_ACTION,
        requestInput,
    };
}


export function actionToUpdateCurrentBeam(requestInput) {
    return {
        type: UPDATE_CURRENT_BEAM_ACTION,
        requestInput,
    };
}

export function actionToUpdateLiveMode(requestInput) {
    return {
        type: UPDATE_LIVE_MODE_ACTION,
        requestInput,
    };
}


export function actionToFetchBeamScreens(requestInput) {
    return {
        type: FETCH_BEAM_SCREENS_ACTION,
        requestInput,
    };
}

export function actionToListenToUserBeams(requestInput) {
    return {
        type: LISTEN_TO_USER_BEAMS_ACTION,
        requestInput,
    };
}

export function listenToUserBeamsSuccess(requestInput, response) {
    return {
        type: LISTEN_TO_USER_BEAMS_SUCCESS,
        requestInput,
        response,
    };
}

export function listenToUserBeamsFailure(requestInput, err) {
    return {
        type: LISTEN_TO_USER_BEAMS_FAILURE,
        requestInput,
        err,
    };
}

export function fetchScreensSuccess(requestInput, response) {
    return {
        type: FETCH_SCREENS_SUCCESS,
        requestInput,
        response,
    };
}

export function fetchScreensFailure(requestInput, err) {
    return {
        type: FETCH_SCREENS_FAILURE,
        requestInput,
        err,
    };
}

export function actionToUpdateBeamState(requestInput) {
    return {
        type: UPDATE_BEAM_STATE_ACTION,
        requestInput,
    };
}

export function updateBeamStateSuccess(requestInput, response) {
    return {
        type: UPDATE_BEAM_STATE_SUCCESS,
        requestInput,
        response,
    };
}

export function updateBeamStateFailure(requestInput, err) {
    return {
        type: UPDATE_BEAM_STATE_FAILURE,
        requestInput,
        err,
    };
}

export function fetchBeamsSuccess(requestInput, response) {
    return {
        type: FETCH_BEAMS_SUCCESS,
        requestInput,
        response,
    };
}

export function fetchBeamsFailure(requestInput, err) {
    return {
        type: FETCH_BEAMS_FAILURE,
        requestInput,
        err,
    };
}

export function actionToCreateBeam(requestInput) {
    return {
        type: CREATE_BEAM_ACTION,
        requestInput,
    };
}

export function createBeamSuccess(requestInput, response) {
    return {
        type: CREATE_BEAM_SUCCESS,
        requestInput,
        response,
    };
}

export function createBeamFailure(requestInput, err) {
    return {
        type: CREATE_BEAM_FAILURE,
        requestInput,
        err,
    };
}

export function actionToCreateNewScreen(requestInput) {
    return {
        type: CREATE_NEW_SCREEN_ACTION,
        requestInput,
    };
}

export function actionToUpdateCurrentScreen(requestInput) {
    return {
        type: UPDATE_CURRENT_SCREEN_ACTION,
        requestInput,
    };
}

export function actionToUpdateCurrentScreenWithNewContent(requestInput) {
    return {
        type: UPDATE_CURRENT_SCREEN_WITH_NEW_CONTENT,
        requestInput,
    };
}

export function updateCurrentScreenSuccess(requestInput, response) {
    return {
        type: UPDATE_CURRENT_SCREEN_SUCCESS,
        requestInput,
        response,
    };
}
export function updateScreenStateSuccess(requestInput, response) {
    return {
        type: UPDATE_CURRENT_SCREEN_STATE_SUCCESS,
        requestInput,
        response,
    };
}

export function updateCurrentScreenFailure(requestInput, err) {
    return {
        type: UPDATE_CURRENT_SCREEN_FAILURE,
        requestInput,
        err,
    };
}

export function listenUserBeamScreenSuccess(requestInput, response) {
    return {
        type: LISTEN_TO_USER_BEAM_SCREENS_SUCCESS,
        requestInput,
        response,
    };
}

export function userBeamScreensChanged(requestInput, response) {
    return {
        type: USER_BEAM_SCREENS_CHANGED,
        requestInput,
        response,
    };
}

export function listenUserBeamScreenFailure(requestInput, err) {
    return {
        type: LISTEN_TO_USER_BEAM_SCREENS_FAILURE,
        requestInput,
        err,
    };
}

export function actionToJoinBeamChannel(requestInput) {
    return {
        type: JOIN_BEAM_CHANNEL_ACTION,
        requestInput,
    };
}

export function joinBeamChannelSuccess(requestInput, response) {
    return {
        type: JOIN_BEAM_CHANNEL_ACTION_SUCCESS,
        requestInput,
        response,
    };
}

export function joinBeamChannelFailure(requestInput, err) {
    return {
        type: JOIN_BEAM_CHANNEL_ACTION_FAILURE,
        requestInput,
        err,
    };
}

export function receiveBeamChannelMessageSuccess(requestInput, response) {
    return {
        type: RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS,
        requestInput,
        response,
    };
}

export function actionToUpdateCorrectAnswer(requestInput){
    return {
        type: UPDATE_CORRECT_ANSWER_ACTION,
        requestInput,
    }
}

export function updateCorrectAnswerSuccess(requestInput, response){
    return {
        type: UPDATE_CORRECT_ANSWER_SUCCESS,
        requestInput,
        response
    }
}

export function updateCorrectAnswerFailure(requestInput, err){
    return {
        type: UPDATE_CORRECT_ANSWER_FAILURE,
        requestInput,
        err
    }
}

export function actionToUpdateCurrentScreenAnnotationsInBeam(requestInput){
    return{
        type:UPDATE_CURRENT_SCREEN_ANNOTATIONS,
        requestInput,
    }
}

export function updateScreenAnnotationSuccess(requestInput,response){
    return {
        type: UPDATE_CURRENT_SCREEN_ANNOTATIONS_SUCCESS,
        requestInput,
        response,
    };
}

export function updateScreenAnnotationFailure(requestInput,err){
    return {
        type: UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE,
        requestInput,
        err,
    };
}

export function actionToUpdateCurrentBeamAnnotationData(requestInput){
    return{
        type:UPDATE_CURRENT_BEAM_ANNOTATIONS_DATA,
        requestInput,
    }
}


export const updateCurrentScreenSolution = payload => ({
  type: UPDATE_CURRENT_SCREEN_SOLUTION,
  payload
});

export function setBeamImageDimensions(requestInput) {
    return {
        type: STORE_BEAM_IMAGE_DIMENSIONS,
        requestInput,
    }
}

export function updateBeamMaxPoints(requestInput) {
    return {
        type: UPDATE_BEAM_MAX_POINTS,
        requestInput
    }
}

export function updateMaxPointsSuccess(requestInput) {
    return {
        type: UPDATE_BEAM_MAX_POINTS_SUCCESS,
        requestInput,
    }
}

export function updateMaxPointsFailure(requestInput) {
    return {
        type: UPDATE_BEAM_MAX_POINTS_FAILURE,
        requestInput,
    }
}