import { selectableForTypeOfFib } from "./fib2";

const itemConfigSelectable = (itemType, data, notIncludeRandom) => {
  let selectableArray = [];
  switch (itemType) {
    case "fib2":
      selectableArray.push({
        value: "fillInTheBlanks",
        title: "Fill In The Blank(s)"
      });
      if (!ifOneOrMoreOptionsDoNotHaveCorrectAns(data)) {
        selectableForTypeOfFib(data, selectableArray, notIncludeRandom);
      }
      break;
    case "matching":
      selectableArray = [
        { value: "right_side", title: "Reorder Right" },
        { value: "left_side", title: "Reorder Left" }
      ];
      break;
    case "highlight":
      selectableArray.push({
        value: "highlight",
        title: "Highlight"
      });
      if (data.content.answers?.length) {
        selectableArray.push({
          value: "fillInTheBlanks",
          title: "Fill In The Blank(s)"
        });
      }
      selectableForTypeOfFib(data, selectableArray, notIncludeRandom);
      break;
    default:
      break;
  }

  return selectableArray;
};

export const ifOneOrMoreOptionsDoNotHaveCorrectAns = data =>
  data.content?.answers?.some(singleAns => singleAns.weight === 0);

export default itemConfigSelectable;
