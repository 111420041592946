/*
 * TopicsList constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const FETCH_ITEMS = 'lrnrApp/authoring/ITEMS';
export const FETCH_ITEMS_SUCCESS = 'lrnrApp/authoring/FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'lrnrApp/authoring/FETCH_ITEMS_FAILURE';
export const ITEMS_HOLDER = "items";
export const CHANGE_ITEM_ORDER = "CHANGE_ITEM_ORDER";
export const DEL_ITEM = 'lrnrApp/authoring/DEL_ITEM';
export const DEL_ITEM_SUCCESS = 'lrnrApp/authoring/DEL_ITEM_SUCCESS';
export const DEL_ITEM_FAILURE = 'lrnrApp/authoring/DEL_ITEM_FAILURE';
export const ADDED_ITEM = 'lrnrApp/authoring/ADDED_ITEM';
export const ADD_ITEM = 'lrnrApp/authoring/ADD_ITEM';
export const ADD_ITEM_SUCCESS = 'lrnrApp/authoring/ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILURE = 'lrnrApp/authoring/ADD_ITEM_FAILURE';

export const UPDATE_LIST_ITEM = 'lrnrApp/authoring/UPDATE_LIST_ITEM';
export const UPDATE_LIST_ITEM_SUCCESS = 'lrnrApp/authoring/UPDATE_LIST_ITEM_SUCCESS';
export const UPDATE_LIST_ITEM_FAILURE = 'lrnrApp/authoring/UPDATE_LIST_ITEM_FAILURE';
export const UPDATE_SAVED_LIST_ITEM = 'lrnrApp/authoring/UPDATE_SAVED_LIST_ITEM';

export const IMPORT_LIST_ITEM_SUCCESS = 'lrnrApp/authoring/IMPORT_LIST_ITEM_SUCCESS';
export const DEL_INNER_ITEM_OF_ITEM_SUCCESS = 'lrnrApp/authoring/DELETE_INNER_ITEM_OF_ITEM_SUCCESS';
export const ADD_INNER_ITEM_OF_ITEM_SUCCESS = 'lrnrApp/authoring/ADD_INNER_ITEM_OF_ITEM_SUCCESS';
export const UPDATE_INNER_ITEM_OF_ITEM_SUCCESS = 'lrnrApp/authoring/UPDATE_INNER_ITEM_OF_ITEM_SUCCESS';

