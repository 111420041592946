import { saveUserResponseSuccess, saveUserResponseFailure,
    joinBeamLiveChannelFailure, joinBeamLiveChannelSuccess,
    sendBeamPresenceMsgSuccess } from "./actions";
import { put } from 'redux-saga/effects';
import fb from 'firebase';
import EventBus from 'vertx3-eventbus-client';
import { EVENT_BUS_SERVICE } from 'constants/ApiConstants'


export function* saveUserResponse(action){
    console.log("saveUserResponse",action);
    const requestInput = action.requestInput;
    const firebase = requestInput.firebase;
    const userScreen = requestInput.userScreen;
    const userBeamsCollection = firebase.userBeamsCollection;
    const userBeamDocument = requestInput.userBeamDocument;
    let err;
    //setting server timestamp to userScreen
    console.log("about to add userScreen document",userScreen)
    
    try{
        let userBeamResultAggregate = computeResultAggregate({userScreen, userBeamDocument});
        console.log("computed resultAggregate", userBeamResultAggregate);
        yield updateUserReponseAndUserBeamEvalResult({firebase, userScreen, userBeamsCollection, userBeamDocument, userBeamResultAggregate})
        yield put(saveUserResponseSuccess(requestInput,{userScreen,userBeamResultAggregate}))
    }catch(error){
        if (error.message === 'ALREADY_ATTEMPTED'){
            err = {
                code:'ALREADY_ATTEMPTED',
                message:'User has already attempted the activity',
                error
            }
        }else{
            err = { 
                code:'ERROR',
                message:error.message,
                error };
        }
        yield put(saveUserResponseFailure(requestInput,err));
    }
}

export const computeUserScreensAggregate = ({userScreens}) => {
    let userBeamResultAggregate =  {
        total_gained_points : 0,
        total_gained_participation_points :  0,
        total_gained_efficacy_points  : 0,
        total_max_points : 0
    };

    if (typeof userScreens != 'undefined' && Object.keys(userScreens).length > 0 ){
        const beamDocRefIds = Object.keys(userScreens);
        console.log("userScreens",userScreens, Object.keys(userScreens));
        //get evalResult on userScreen
        for (let i = 0; i < beamDocRefIds.length; i++) {
            console.log("userScreen in userBeamDocument", userScreens[beamDocRefIds[i]])
            const evalResult = userScreens[beamDocRefIds[i]]['eval_result'];
            if (typeof evalResult != 'undefined' && evalResult != null) {
                userBeamResultAggregate['total_gained_points'] += (typeof evalResult['gained_points'] === 'number') ? evalResult['gained_points'] : 0;
                userBeamResultAggregate['total_gained_participation_points'] += (typeof evalResult['gained_participation_points'] === 'number') ? evalResult['gained_participation_points'] : 0;
                userBeamResultAggregate['total_gained_efficacy_points'] += (typeof evalResult['gained_efficacy_points'] === 'number') ? evalResult['gained_efficacy_points'] : 0;
                userBeamResultAggregate['total_max_points'] += (typeof evalResult['max_points'] === 'number') ? evalResult['max_points'] : 0;
            }
        }
    }

    return userBeamResultAggregate;
}

const computeResultAggregate = ({userScreen, userBeamDocument}) => {

    console.log("in computeResultAggregate", userScreen, userBeamDocument)

    const userScreens = userBeamDocument['user_screens'];
    let userBeamResultAggregate =  computeUserScreensAggregate({userScreens});

    

    const evalResult = userScreen['eval_result'];
    if (typeof evalResult != 'undefined' && evalResult != null){
        userBeamResultAggregate['total_gained_points']+= (typeof evalResult['gained_points'] === 'number')?evalResult['gained_points']:0;
        userBeamResultAggregate['total_gained_participation_points']+= (typeof evalResult['gained_participation_points'] === 'number')?evalResult['gained_participation_points']:0;
        userBeamResultAggregate['total_gained_efficacy_points']+= (typeof evalResult['gained_efficacy_points'] === 'number')?evalResult['gained_efficacy_points']:0;
        userBeamResultAggregate['total_max_points']+= (typeof evalResult['max_points'] === 'number')?evalResult['max_points']:0;
    }

    return userBeamResultAggregate;
}

export function* checkIfAlreadyAttempted ({userBeamsCollection,userBeamDocument, userScreen}){
    console.log("in getUserBeamScreenDoc userBeamDocument", userBeamDocument)
    let snapshot = yield userBeamsCollection.doc(userBeamDocument.id).collection("user_screens")
                    .where('beam_screen_ref_id','==',userScreen.beam_screen_ref_id).get();
    if (!snapshot.empty){
        console.log("User has already submitted a response");
        throw new Error("ALREADY_ATTEMPTED");
    }

}

export function* updateUserReponseAndUserBeamEvalResult ({firebase, userScreen, userBeamsCollection, userBeamDocument, userBeamResultAggregate}){
    
    let batch = firebase.firestore.batch();

    console.log("going to call checkIfAlreadyAttempted");
    checkIfAlreadyAttempted ({userBeamsCollection, userBeamDocument, userScreen})

    console.log("in getUserBeamScreenDoc userBeamDocument", userBeamDocument)
    let snapshot = yield userBeamsCollection.doc(userBeamDocument.id).collection("user_screens")
                    .where('beam_screen_ref_id','==',userScreen.beam_screen_ref_id).get();
    if (!snapshot.empty){
        console.log("Already user has submitted a response");
        throw new Error("ALREADY_ATTEMPTED");
    }


    let newUserBeamScreenRef = userBeamsCollection.doc(userBeamDocument.id).collection("user_screens").doc();

    batch.set(newUserBeamScreenRef, userScreen);
    userScreen['id'] = newUserBeamScreenRef.id;

    let userBeamDocRef = userBeamsCollection.doc(userBeamDocument.id);
    batch.update(userBeamDocRef,{'eval_result_aggregate': userBeamResultAggregate});
    yield batch.commit().then(function(){
        console.log("Successfully saved userScreen and aggreage results to userBeam");
    }).catch(function (error) {
        console.error("Error saving userScreen and aggreage results to userBeam ", error);
        throw error;
    });

}

export function* joinBeamLiveChannel(action){
    console.log("joinBeamLiveChannel action",action);
    const requestInput = action.requestInput;
    const {currentBeam, firebase} = action.requestInput;
    console.log("joining live beamChannel", currentBeam);
    var eb = new EventBus(EVENT_BUS_SERVICE,{"vertxbus_ping_interval": 8000});
    const headers = {} ;
    headers.userSignature=firebase.userSignature;
    try{

        eb.onopen = function () {
            
            
            // set a handler to receive a message
            eb.registerHandler("beams_"+currentBeam.id, headers, function (error, message) {
                console.log('received a message: ' + JSON.stringify(message));
                const action = message.body? message.body.action:null;
                console.log("ACTION",action);
                if (action != null && action == 'PING'){
                    console.log("about to send a message as PONG")
                    eb.send('beams_'+currentBeam.id,headers, {action:'PONG',userSignature: firebase.userSignature});
                }
            });
            eb.unregisterHandler("beams_"+currentBeam.id, headers, function (error, message) {
                console.log('received a message: ' + JSON.stringify(message));
            });
            
            //send success message joined beam live channel
        }
        yield put(joinBeamLiveChannelSuccess(requestInput, true));
        try {
            
            //publish presence by sending a message regularly - as the instructor
            //running this message will get a notification - the regular ping will not
            const beamPresencePingInterval = setInterval(function () {
                try{
                    eb.send('beams_'+currentBeam.id,headers, {action:'PONG',userSignature: firebase.userSignature});
                }catch(error){
                    console.error("Error sending PONGs", error)
                }
            }, 40000);

            //update presencePingInterval to store - when the user exits the beam - this must be cleared
            yield put(sendBeamPresenceMsgSuccess(requestInput, beamPresencePingInterval));
        }catch(err){
            console.error("Could not send presence message",err);
        }

    } catch (err) {
        console.log("error joining beam live channel", err);
        yield put(joinBeamLiveChannelFailure(requestInput, err))   

    }
}

export const checkifAllTheBlanksAreFilledInRichtext = (
  stimulus,
  responseObject
) => {
  let everyBlankIsFilled = true;
  for (let singlePara of stimulus) {
    for (let singleChild of singlePara.children) {
      if (singleChild.type === "blank") {
        if (
          singleChild.id in responseObject === false ||
          responseObject[singleChild.id] === ""
        ) {
          everyBlankIsFilled = false;
          break;
        }
      }
    }
  }
  return everyBlankIsFilled;
};

export const checkifOneOrMoreBlanksAreFilledForRichText = (
  stimulus,
  responseObject
) => {
  let oneOrMoreBlankIsFilled = false;
  for (let singlePara of stimulus) {
    for (let singleChild of singlePara.children) {
      if (singleChild.type === "blank") {
        if (
          singleChild.id in responseObject === true &&
          responseObject[singleChild.id] !== ""
        ) {
          oneOrMoreBlankIsFilled = true;
          break;
        }
      }
    }
  }

  return oneOrMoreBlankIsFilled;
};