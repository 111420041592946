import { isValidHighlight } from "../validators/item/highlight";
import { DEFAULT_HIGHLIGHT_ASSESSMENT_CONFIG } from "../../util/constants";
import {
  changeAsFib,
  changeAsDragDrop,
  changeAsDropDown
} from "./fib2Transformer";

export const changeAsHighlight = itemContent => {
  const itemPara = JSON.parse(itemContent.paragraph);
  let totalNumberOfHighlight = 0;
  for (let singlePara of itemPara) {
    const allChildrenOfPara = singlePara.children;
    for (let singleChild of allChildrenOfPara) {
      if (singleChild.highlight) {
        delete singleChild.highlight;
        delete singleChild.highlightNumber;
        delete singleChild.id;
        totalNumberOfHighlight += 1;
      }
    }
  }

  return {
    stimulus: itemContent.stimulus,
    paragraph: JSON.stringify(itemPara),
    totalNumberOfHighlight,
    maxHighlightsAllowed:itemContent.maxHighlightsAllowed
  };
};

const transFormHighlightParaToFibPara = itemParagraph => {
  const highlightIdAndTextObj = {};
  const highlightIdSet = new Set();
  const newItemPara = [];
  for (let singlePara of itemParagraph) {
    const { children } = singlePara;
    const newSinglePara = { ...singlePara, children: [] };
    for (let singleChild of children) {
      if (singleChild.highlight) {
        if (!highlightIdSet.has(singleChild.id)) {
          const newBlank = {
            type: "blank",
            id: singleChild.id,
            children: [
              {
                text: singleChild.highlightNumber + " - Response",
                textOfBlank: true
              }
            ]
          };
          newSinglePara.children.push(newBlank);
        }
        highlightIdAndTextObj[singleChild.id] = highlightIdAndTextObj[
          singleChild.id
        ]
          ? highlightIdAndTextObj[singleChild.id] + singleChild.text
          : singleChild.text;
        highlightIdSet.add(singleChild.id);
      } else {
        newSinglePara.children.push(singleChild);
      }
    }
    newItemPara.push(newSinglePara);
  }

  return { newItemPara, highlightIdAndTextObj };
};

export const changeAsOtherFibTypes = (itemContent, activity_mode) => {
  const {
    newItemPara,
    highlightIdAndTextObj
  } = transFormHighlightParaToFibPara(JSON.parse(itemContent.paragraph));
  let convertedContent;

  const newAnswersArray = itemContent.answers.map(singleAnsObj => ({
    ...singleAnsObj,
    value: highlightIdAndTextObj[singleAnsObj.challengeId]
  }));
  const newItemContent = {
    ...itemContent,
    paragraph: JSON.stringify(newItemPara),
    answers: newAnswersArray
  };

  if (activity_mode === "fillInTheBlanks") {
    convertedContent = changeAsFib(newItemContent);
  } else if (activity_mode === "dragAndDrop") {
    convertedContent = changeAsDragDrop(newItemContent);
  } else {
    convertedContent = changeAsDropDown(newItemContent);
  }

  convertedContent.paragraph = newItemContent.paragraph;

  return convertedContent;
};

const getHighlighItemContent = (item, topicItemInfo) => {
  let convertedContent;
  const { activity_mode } = topicItemInfo.assessment_config;
  const itemContent = item.content;

  if (activity_mode === "highlight") {
    convertedContent = changeAsHighlight(itemContent);
  } else {
    convertedContent = changeAsOtherFibTypes(itemContent, activity_mode);
  }

  return convertedContent;
};

export const createHighlightAssessmentItem = ({
  item,
  topicItemInfo,
  userSignature
}) => {
  const isValid = isValidHighlight({ item });
  if (!isValid) {
    return null;
  }

  const finalAssessmentItem = {
    _id: item._id,
    type: "assessment",
    subtype: "highlight",
    content: getHighlighItemContent(item, topicItemInfo),
    attachments: item.attachments ? item.attachments : [],
    uuid: item.uuid,
    title: item.title ? item.title : "",
    assessment_config:
      topicItemInfo.assessment_config || DEFAULT_HIGHLIGHT_ASSESSMENT_CONFIG,
    owner_uuid: userSignature.accountId
  };
  return finalAssessmentItem;
};
