/*
 *
 * TopicsList reducer
 *
 */
import produce from 'immer';
import arrayMove from 'array-move';
import { DEL_ITEM_SUCCESS, DEL_ITEM_FAILURE, ADDED_ITEM, ADD_ITEM_SUCCESS, ADD_ITEM_FAILURE, CHANGE_ITEM_ORDER, FETCH_ITEMS_SUCCESS, ITEMS_HOLDER, UPDATE_LIST_ITEM_SUCCESS,IMPORT_LIST_ITEM_SUCCESS,
  DEL_INNER_ITEM_OF_ITEM_SUCCESS,
  ADD_INNER_ITEM_OF_ITEM_SUCCESS,
  UPDATE_INNER_ITEM_OF_ITEM_SUCCESS
} from './constants';
import { DEL_QUIZ_ITEM_SUCCESS, ADD_QUIZ_ITEM_SUCCESS, UPDATE_QUIZ_SUCCESS, QUIZ_LIST_TYPE } from 'containers/Main/Quiz/constants';
import { updateQuizHeader } from 'containers/Main/Quiz/utils';
import { updateSlideDeck,updateImportedListItem } from 'containers/Main/Slidedeck/utils';
export const initialState = {
};

/* eslint-disable default-case, no-param-reassign */
const listReducer = (state = initialState, action) =>
  produce(state, draft => {
    // if (!action.config || !state[action.config.type]){
    //   return;
    // }
    switch (action.type) {

      case FETCH_ITEMS_SUCCESS:
        console.log("in itemList reducer success", state, action);
        if (!draft[action.config.type]) {
          draft[action.config.type] = { ...action.config.initialState, config: action.config };
          draft[action.config.type][ITEMS_HOLDER] = [];

        }
        action.items.forEach(item => {
          draft[action.config.type][ITEMS_HOLDER].push(item);
        })
        if (action.config.indexFunction) {
          //sort the items
          draft[action.config.type][ITEMS_HOLDER].sort((a, b) => { return (action.config.indexFunction(a) - action.config.indexFunction(b)) })
        }
        const maxNoOfItems = draft[action.config.type].perPage * draft[action.config.type].page;
        if (maxNoOfItems > draft[action.config.type][ITEMS_HOLDER].length) {
          //the last call returned less than the noOfItems requested
          draft[action.config.type].hasMore = false;
        }
        draft[action.config.type].page = draft[action.config.type].page + 1
        draft[action.config.type].fetchComplete = true;
        break;
      case CHANGE_ITEM_ORDER:
        console.log("in CHANGE_ITEM_ORDER", state, action);
        draft[action.config.type].items = arrayMove(draft[action.config.type].items, action.oldIndex, action.newIndex);
        break;
      case ADDED_ITEM:
        console.log("in ADDED_ITEM", state, action);
        if (draft[action.config.type] && draft[action.config.type].items) {
          draft[action.config.type].items.splice(action.actionInfo.itemIndex, 0, action.actionInfo.item)
        }
        break;
      case DEL_ITEM_SUCCESS:
        console.log("in DEL_ITEM_SUCCESS", state, action);
        if (draft[action.config.type] && draft[action.config.type].items) {
          draft[action.config.type].items = draft[action.config.type].items.filter((item) => {
            return (item.uuid != action.item.uuid)
          })
        }
        break;
      case DEL_ITEM_FAILURE:
        console.log("in DEL_ITEM_FAILURE", state, action);
        break;
      case ADD_ITEM_SUCCESS:
        console.log("in ADD_ITEM_SUCCESS", state, action);
        draft["quizItems-" + action.topicId].items.splice(action.newIndex, 0, action.item.body);
        break;
      case ADD_ITEM_FAILURE:
        console.log("in ADD_ITEM_FAILURE", state, action);
        break;
      case UPDATE_QUIZ_SUCCESS:
        console.log("in UPDATE_QUIZ_SUCCESS list", state, action);
        const quizItemToUpdateUuid = action.updatePayload.quiz.uuid;
        const quizItemToUpdate = draft[QUIZ_LIST_TYPE].items.find((item) => { return item.uuid === quizItemToUpdateUuid })
        if (typeof quizItemToUpdate != 'undefined') {
          updateQuizHeader(quizItemToUpdate, state, action);
        }
        break;
      case ADD_QUIZ_ITEM_SUCCESS:
        console.log("In ADD_QUIZ_ITEM_SUCCESS", state, action);
        const topicItemInfo = action.addItemPayload.topicItemInfo;
        const newTopicItemUUId = action.addItemPayload.topicItemInfo.topic_item_uuid;
        const quizId = action.quizId;
        //find the quiz in the list
        console.log("draft of quiz", draft['quiz'])
        const quizObj = draft['quiz'].items.filter((item) => { return item.uuid === quizId })[0];
        console.log("quizObj", quizObj)
        if (!quizObj) {
          break;
        }
        if (typeof quizObj.items === 'undefined'){
          quizObj.items={}
        }
        quizObj.items[newTopicItemUUId] = {
          measure: {
            efficacy_factor: topicItemInfo.efficacy_factor,
            grade_points: topicItemInfo.grade_points,
            participation_factor: topicItemInfo.participation_factor,
            timer_in_secs: topicItemInfo.timer_in_secs
          },
          subtype: action.addItemPayload.subtype,
          type: action.addItemPayload.type,
          uuid: action.addItemPayload.item.uuid
        };
        if (typeof quizObj.items_order === 'undefined'){
          quizObj.items_order = [];
        }
        //if newItemIndex is greater than length the new item will be added to the end
        console.log("topicItemInfo.newItemIndex", topicItemInfo.newItemIndex);
        quizObj.items_order.splice(topicItemInfo.newItemIndex, 0, newTopicItemUUId);
        break;
      case DEL_QUIZ_ITEM_SUCCESS:
        console.log("in DEL_QUIZ_ITEM_SUCCESS", state, action);
        const deletedQuizObj = draft['quiz'].items.filter((item) => { return item.uuid === action.topicId })[0];
        deletedQuizObj.items_order = deletedQuizObj.items_order.filter((id) => {
          return (id != action.topicItemUuid)
        })
        delete deletedQuizObj.items[action.topicItemUuid];
        break;
      case ADD_INNER_ITEM_OF_ITEM_SUCCESS:
        const itemObjectToBeAddedIn  = draft[action.payload.itemType]?.items?.find(singleItemObj => singleItemObj.uuid === action.payload.topicId);
        itemObjectToBeAddedIn.items[action.payload.topicItemUuid] = action.payload.item;
        itemObjectToBeAddedIn.items_order.unshift(action.payload.topicItemUuid);
        break;
      case DEL_INNER_ITEM_OF_ITEM_SUCCESS:
        const itemObjectToBeDeletedFrom = draft[action.payload.itemType]?.items?.find(singleItemObj => singleItemObj.uuid === action.payload.topicId);
        itemObjectToBeDeletedFrom.items_order = itemObjectToBeDeletedFrom.items_order.filter(innerItemId => innerItemId !== action.payload.topicItemUuid);
        delete itemObjectToBeDeletedFrom.items[action.payload.topicItemUuid];
        break;
      case UPDATE_INNER_ITEM_OF_ITEM_SUCCESS:
        const itemObjectToBeUpdated = draft[
          action.payload.itemType
        ]?.items?.find(
          singleItemObj => singleItemObj.uuid === action.payload.upperItemId
        );
        if (itemObjectToBeUpdated) {
          itemObjectToBeUpdated.items[action.payload.item.topic_item_uuid] =
            action.payload.item.topicItemInfo;
        }
        break;
      case IMPORT_LIST_ITEM_SUCCESS:
        console.log("in IMPORT_LIST_ITEM_SUCCESS list", state, action);
        const appChannelMessage = action.appChannelMessage;
        if (typeof appChannelMessage == 'undefined' || typeof appChannelMessage.data == 'undefined'
          || appChannelMessage.code == 'undefined'){
            break;
        }
        let jobRefId=appChannelMessage.data.jobId;
        let listItemToUpdate=draft[action.config.type].items.find((item) => { return item.job_refs[0].id === jobRefId })
        if (typeof listItemToUpdate != 'undefined') {
          updateImportedListItem(listItemToUpdate, appChannelMessage);
        }
        break;
    }
  });

export default listReducer;
