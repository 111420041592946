export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

export const DEFAULT_MATCHING_ASSESSMENT_CONFIG = {
  shuffleLeftToRight: false,
  typeOfMatch: "matching",
  challenges_config: {
    max_no_of_challenges: 5,
    same_for_all: true,
    display_order: "fixed"
  },
  options_config: {
    distractors_same_for_all: true,
    display_order: "random",
    max_no_of_distractors: 3
  }
};

export const DEFAULT_SORTING_ASSESSMENT_CONFIG = {
  challenges_config: {
    max_no_of_challenges: 5,
    same_for_all: true,
    display_order: "random"
  }
};

export const DEFAULT_DIAGRAM_ASSESSMENT_CONFIG = {
  activity_mode: "dropPin",
  hide_hotspots: false,
  item_type: "term",
  options_type: ""
};

export const DEFAULT_FIB2_ASSESSMENT_CONFIG = {
  activity_mode: "fillInTheBlanks",
  learningObjective: "",
  chapterSection: "",
  tag: "",
  timer: false,
  promptSelection: false,
  fibConfig: {
    exactSpelling: false
  },
  dndConfig: {
    duplicates: false,
    hideHandles: false
  }
};

export const DEFAULT_MCQ_ASSESSMENT_CONFIG = {
  activity_mode: "mcq",
  item_type: "",
};

export const DEFAULT_MSQ_ASSESSMENT_CONFIG = {
  activity_mode: "msq",
  item_type: "",
};

export const DEFAULT_FIB_ASSESSMENT_CONFIG = {
  activity_mode: "fib",
  item_type: "",
};

export const DEFAULT_HIGHLIGHT_ASSESSMENT_CONFIG = {
  activity_mode: "highlight",
  promptSelection:false
}


export const ITEM_TYPES_ASSESSMENT_CONFIG = {
  fib : DEFAULT_FIB_ASSESSMENT_CONFIG,
  msq : DEFAULT_MSQ_ASSESSMENT_CONFIG,
  mcq : DEFAULT_MCQ_ASSESSMENT_CONFIG,
  fib2 : DEFAULT_FIB2_ASSESSMENT_CONFIG,
  diagram: DEFAULT_DIAGRAM_ASSESSMENT_CONFIG,
  sorting: DEFAULT_SORTING_ASSESSMENT_CONFIG,
  matching: DEFAULT_MATCHING_ASSESSMENT_CONFIG,
  highlight: DEFAULT_HIGHLIGHT_ASSESSMENT_CONFIG
}
