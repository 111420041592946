export function updateData(responseData, data){
	console.log("DATA input qwe", data, responseData);
	var thePatchArray=responseData.body.patchArray;
	if(typeof thePatchArray!=="undefined"){
		console.log('from inside');
		for(var i=0 ;i<thePatchArray.length;i++){
			var thePatch=thePatchArray[i];
			if(thePatch.entity==="content")
			{
				if(thePatch.operation==="update")
				{
					data.content = thePatch.value;
				}
			}
			else if(thePatch.entity==="stimulus"){
				data.content.stimulus=thePatch.value;
			}
			else if(thePatch.entity==="answer"){

				if(typeof data.content.answers==="undefined")
					data.content.answers=[];
				if(thePatch.operation==="swapDistractorAndAnswer"){
					data.content.answers=[];
					data.content.answers.push(thePatch.value.answerProperties);

					var tempIndex=0;
					for(var t=0;t<data.content.distractors.length;t++){
						var tempOption=data.content.distractors[t];
						if(tempOption.id===thePatch.value.distractorId){
							tempIndex=t;
							break;
						}
					}
					data.content.distractors.splice(tempIndex,1);
					data.content.distractors.push(thePatch.value.distractorProperties);

				}
				else if(thePatch.operation==="remove"){
					var tempIndex=0;
					for(var t=0;t<data.content.answers.length;t++){
						var tempOption=data.content.answers[t];
						if(tempOption.id===thePatch.value.id){
							tempIndex=t;
							break;
						}
					}
					data.content.answers.splice(tempIndex,1);
				}
				else if(thePatch.operation==="update"){

					if(thePatch.answerId) {
						data.content.answers = data.content.answers.map(singleAnswer => {
							if(singleAnswer.id === thePatch.answerId)
							{
								return {...singleAnswer,...thePatch.value}
							} else return singleAnswer;
						})
					} else {
						for(var t=0;t<data.content.answers.length;t++){
							var tempOption=data.content.answers[t];
							if(tempOption.id===thePatch.value.id){
								tempIndex=t;
								break;
							}
						}
						data.content.answers[tempIndex]=thePatch.value;
					}
			    }
			    else{
			    	data.content.answers.push(thePatch.value);
			    }
			}
			else if (thePatch.entity === "matching_pair") {
				if (typeof data.content.pairs === "undefined")
				  data.content.pairs = [];
				var tempIndex = 0;
				if (thePatch.operation === "remove") {
				  for (var t = 0; t < data.content.pairs.length; t++) {
					var tempOption = data.content.pairs[t];
					if (tempOption.id === thePatch.value.id) {
					  tempIndex = t;
					  break;
					}
				  }
				  data.content.pairs.splice(tempIndex, 1);
				} else if (thePatch.operation === "update") {
				  for (var t = 0; t < data.content.pairs.length; t++) {
					var tempOption = data.content.pairs[t];
					if (tempOption.id === thePatch.value.id) {
					  tempIndex = t;
					  break;
					}
				  }
				  data.content.pairs[tempIndex] = thePatch.value;
				} else {
				  data.content.pairs.push(thePatch.value);
				}
			  }
			else if(thePatch.entity==="distractor"){


				if(typeof data.content.distractors==="undefined")
					data.content.distractors=[];
				if(thePatch.operation==="remove"){
					var tempIndex=0;
					for(var t=0;t<data.content.distractors.length;t++){
						var tempOption=data.content.distractors[t];
						if(tempOption.id===thePatch.value.id){
							tempIndex=t;
							break;
						}
					}
					data.content.distractors.splice(tempIndex,1);
				}
				else if(thePatch.operation==="update"){

			  		for(var t=0;t<data.content.distractors.length;t++){
						var tempOption=data.content.distractors[t];
						if(tempOption.id===thePatch.value.id){
							tempIndex=t;
							break;
						}
					}
					data.content.distractors[tempIndex]=thePatch.value;;
			    }
			    else{
			    	data.content.distractors.push(thePatch.value);
			    }
			} else if(thePatch.entity === "answerWeight"){
				const answerId = thePatch.answerId;
				const newAnswersArray = data.content.answers.map(ans => {
					if (ans.id !== answerId) {
						return ans;
					} else {
						const newAnsObject = { ...ans,weight: thePatch.value.value };
						return newAnsObject;
					}
				});

				data.content.answers = newAnswersArray;
			} else if(thePatch.entity === 'answerMetadata') {
				const answerId = thePatch.answerId;
				const newAnswersArray = data.content.answers.map(ans => {
					if (ans.id !== answerId) {
						return ans;
					} else {
						const newAnsObject = { ...ans,metadata: thePatch.value };
						return newAnsObject;
					}
				});
				data.content.answers = newAnswersArray;
			}
			else if(thePatch.entity==="acceptableAnswer"){

				if (thePatch.answerId) {
					const answerId = thePatch.answerId;

					const newAnswersArray = data.content.answers.map(ans => {
					  if (ans.id !== answerId) {
						return ans;
					  } else {
						const newAnsObject = { ...ans };
						if (!newAnsObject.acceptable_answers) {
						  newAnsObject.acceptable_answers = [];
						}

						if (thePatch.operation === "remove") {
						  const newAcceptableAnswer = newAnsObject.acceptable_answers.filter(
							accAns => accAns.id !== thePatch.value.id
						  );
						  newAnsObject.acceptable_answers = newAcceptableAnswer;
						} else if (thePatch.operation === "update") {
						  const newAcceptableAnswer = newAnsObject.acceptable_answers.map(
							accAns => {
							  if (accAns.id !== thePatch.value.id) {
								return accAns;
							  } else {
								return thePatch.value;
							  }
							}
						  );
						  newAnsObject.acceptable_answers = newAcceptableAnswer;
						} else {
						  newAnsObject.acceptable_answers.push(thePatch.value);
						}
						return newAnsObject;
					  }
					});
					data.content.answers = newAnswersArray;
				} else {
					if (typeof data.content.answers[0].acceptableAnswers === "undefined")
					data.content.answers[0].acceptableAnswers = [];

				  if (thePatch.operation === "remove") {
					var tempIndex = 0;
					for (var t = 0; t < data.content.answers[0].acceptableAnswers.length; t++) {
					  var tempOption = data.content.answers[0].acceptableAnswers[t];
					  if (tempOption.id === thePatch.value.id) {
						tempIndex = t;
						break;
					  }
					}
					data.content.answers[0].acceptableAnswers.splice(tempIndex, 1);
				  } else if (thePatch.operation === "update") {
					for (var t = 0; t < data.content.answers[0].acceptableAnswers.length; t++) {
					  var tempOption = data.content.answers[0].acceptableAnswers[t];
					  if (tempOption.id === thePatch.value.id) {
						tempIndex = t;
						break;
					  }
					}
					data.content.answers[0].acceptableAnswers[tempIndex] = thePatch.value;
				  } else {
					data.content.answers[0].acceptableAnswers.push(thePatch.value);
				  }
				}
			} else if(thePatch.entity==='answerDistractors'){
				const answerId = thePatch.answerId;

					const newAnswersArray = data.content.answers.map(ans => {
					  if (ans.id !== answerId) {
						return ans;
					  } else {
						const newAnsObject = { ...ans };
						if (!newAnsObject.distractors) {
						  newAnsObject.distractors = [];
						}

						if (thePatch.operation === "remove") {
						  const newDistractors = newAnsObject.distractors.filter(
							dist => dist.id !== thePatch.value.id
						  );
						  newAnsObject.distractors = newDistractors;
						} else if (thePatch.operation === "update") {
						  const newDistractors = newAnsObject.distractors.map(
							dist => {
							  if (dist.id !== thePatch.value.id) {
								return dist;
							  } else {
								return thePatch.value;
							  }
							}
						  );
						  newAnsObject.distractors = newDistractors;
						} else {
						  newAnsObject.distractors.push(thePatch.value);
						}
						return newAnsObject;
					  }
					});
					data.content.answers = newAnswersArray;
			} else if(
				thePatch.entity === "answerCorrectFeedback"||
				thePatch.entity==="answerIncorrectFeedback"
			) {
				const answerId = thePatch.answerId;
				const fieldToUpdate = thePatch.entity === "answerCorrectFeedback"
										?'correct_feedback'
										:'incorrect_feedback';
				const newAnswersArray = data.content.answers.map(ans => {
					if (ans.id !== answerId) {
					  return ans;
					} else {
					  const newAnsObject = { ...ans };
					  newAnsObject[fieldToUpdate] = thePatch.value ;

					  return newAnsObject;
					}
				  });
				  data.content.answers = newAnswersArray;
			}
			else if(thePatch.entity==="feedback"){

				if(typeof data.content.feedbacks==="undefined")
					data.content.feedbacks=[];
				if(thePatch.operation==="remove"){
					var tempIndex=0;
					for(var t=0;t<data.content.feedbacks.length;t++){
						var tempFeedback=data.content.feedbacks[t];
						if(tempFeedback.id===thePatch.value.id){
							tempIndex=t;
							break;
						}
					}
					data.content.feedbacks.splice(tempIndex,1);
				}
				else if(thePatch.operation==="update"){

			  		var tempIndex=0;
					for(var t=0;t<data.content.feedbacks.length;t++){
						var tempFeedback=data.content.feedbacks[t];
						if(tempFeedback.id===thePatch.value.id){
							tempIndex=t;
							break;
						}
					}
					data.content.feedbacks[tempIndex]=thePatch.value;
			    }
			    else{
			    	data.content.feedbacks.push(thePatch.value);
			    }
			}
			else if(thePatch.entity==="options_order"){
				data.content.order=thePatch.value;
			}
			else if(thePatch.entity==="difficulty" || thePatch.entity==="blooms_level" || thePatch.entity==="max_lrnr_points"){
				data.measure[thePatch.entity]=thePatch.value;
			}
			else if(thePatch.entity==="attachmentInfo"){
				var tempIndex=0;
				for(var t=0;t<data.attachments.length;t++){
					var tempAttachment=data.attachments[t];
					if(tempAttachment.id===thePatch.value.attachmentId){
						tempIndex=t;
						break;
					}
				}

				data.attachments[tempIndex].attachmentInfo=thePatch.value.attachmentInfo;
			}
		}
	}
	else{
		console.log("Updaing topicItemInfo in updateData",data);
		var thePatchJson=responseData.body.patchJson;
		if(thePatchJson.entity==="topicItemInfo"){
			console.log("Updating data",data);
			console.log("Updating data patchObj",thePatchJson);
			const { topicItemInfo, topic_item_uuid } = thePatchJson.value
			const { assessment_config, measure, subtype, type, uuid } = topicItemInfo;
			// data[thePatchJson.value["topic_item_uuid"]]=thePatchJson.value.topicItemInfo;
			data.items[topic_item_uuid].assessment_config=assessment_config;
			data.items[topic_item_uuid].measure=measure;
			data.items[topic_item_uuid].subtype=subtype;
			data.items[topic_item_uuid].type=type;
			data.items[topic_item_uuid].uuid=uuid;
			// data=thePatchJson.value.topicItemInfo;
		}
	}
}

export const hasStimulusImage = ({item}) => {

	console.log("in hasStimulusImage", item);

	if (typeof item.attachments === 'undefined'|| item.attachments.length <=0){
		return false;
	}
	const imageJson =  item.attachments.find((attachment)=> {
		return (typeof attachment.attachmentInfo != 'undefined' && attachment.attachmentInfo.entity === "stimulus")
	})
	if (typeof imageJson === 'undefined' || Object.keys(imageJson).length <= 0 ){
		return false;
	}
	return true;
}


export const updateAllignmentofAttachment= (value,type,itemData) => {
	const imageJson =  getStimulusAttachment(itemData);
	var tempInfo=imageJson.attachmentInfo;

	tempInfo[type]=value;

	var patchObj= {
	        "entity": "attachmentInfo",
	        "operation": "update",
	        "value": {
	        	"attachmentId":imageJson.id,
	        	"attachmentInfo":tempInfo
	        }
	}

	return patchObj;
}

export const getStimulusAttachment = (itemData) => {

	const imageJson =  itemData.attachments.find((attachment)=> {
		return (attachment.attachmentInfo.entity === "stimulus")
	})

	return imageJson;

}


export function checkAndUpdatePoints(
  itemPatchJson,
  data,
  id,
  childItemData,
  patchQuizItem,
  patchItem
) {
  var theEfficacy = childItemData.measure.efficacy_factor;
  if (data.subtype !== "diagram") {
    console.log("itemPatchJson", itemPatchJson.body.patchArray);
    if (data.subtype === "matching") {
      const tempPatchObj = itemPatchJson.body.patchArray.find(
        tempPatch =>
          tempPatch.entity === "content" || tempPatch.entity === "matching_pair"
      );
      if (tempPatchObj) {
        if (data.content.pairs && data.content.pairs.length > 0) {
		  theEfficacy = 
		  	calculateEfficacyPointsForMatching(data,childItemData.assessment_config?.shuffleLeftToRight)
          let tempMaxLrnrPoints = data.measure.difficulty / 10;
          if (tempMaxLrnrPoints !== data.measure.max_lrnr_points)
            updateMaxLrnrPoints(tempMaxLrnrPoints);
        } else {
          theEfficacy = 0;
        }
      }
    } else if (data.subtype === "sorting") {
      const tempPatchObj = itemPatchJson.body.patchArray.find(
        tempPatch =>
          tempPatch.entity === "content" ||
          tempPatch.entity === "sorted_elements"
      );
      if (tempPatchObj) {
        if (
          data.content.sortedElements &&
          data.content.sortedElements.length > 0
        ) {
          theEfficacy = 0.5 * data.content.sortedElements.length;
          let tempMaxLrnrPoints = data.measure.difficulty / 10;
          if (tempMaxLrnrPoints !== data.measure.max_lrnr_points)
            updateMaxLrnrPoints(tempMaxLrnrPoints);
        } else {
          theEfficacy = 0;
        }
      }
    } else if(data.subtype === 'fib2') {
			const tempPatchObj = itemPatchJson.body.patchArray.find(
			tempPatch =>
			  tempPatch.entity === "answerWeight" ||
			  tempPatch.entity === "content" ||
			  tempPatch.entity === "answer"
		  );
	    if (tempPatchObj) {
	  		theEfficacy =
	  		  0.5 * data.content?.answers?.filter(singleAns => singleAns.weight === 1).length
				|| 0;
	  		if (theEfficacy) {
	  		  let tempMaxLrnrPoints = data.measure.difficulty / 10;
	  		  if (tempMaxLrnrPoints !== data.measure.max_lrnr_points)
	  			updateMaxLrnrPoints(tempMaxLrnrPoints);
	  		}
	    }
		} else {
	      const tempPatchObj = itemPatchJson.body.patchArray.find(tempPatch => {
	        return tempPatch.entity === "answer";
	      });
	      console.log("tempPatchObj", tempPatchObj);
	      if (typeof tempPatchObj !== "undefined") {
	        // if(tempPatchObj.operation==="add")
	        // 	theEfficacy=theEfficacy+0.5;
	        // else if(tempPatchObj.operation==="remove"){
	        // 	theEfficacy=theEfficacy-0.5;
	        // 	if(theEfficacy<0)
	        // 		theEfficacy=0;
	        // }
	        if (
	          typeof data.content.answers != "undefined" &&
	          data.content.answers.length > 0
	        ) {
	          theEfficacy = 0.5 * data.content.answers.length;
	          let tempMaxLrnrPoints = data.measure.difficulty / 10;
	          if (tempMaxLrnrPoints !== data.measure.max_lrnr_points)
	            updateMaxLrnrPoints(tempMaxLrnrPoints);
	        } else {
	          theEfficacy = 0;
	        }
	      }
    }
    updateTheEfficacy();
  } else {
    if (typeof data.content.scoring != "undefined") {
    	const tempPatchObj = itemPatchJson.body.patchArray.find(
        tempPatch =>
          tempPatch.entity === "content" 
      );
      if (tempPatchObj) {
	      var theScoringLength = Object.keys(data.content.scoring).length;
	      theEfficacy = 0.5 * theScoringLength;
	      let activityItemPoints =
	        data.measure.difficulty == 100
	          ? 2
	          : data.measure.difficulty == 200
	          ? 4
	          : 6;
	      let tempMaxLrnrPoints = theScoringLength * activityItemPoints;
	      if (tempMaxLrnrPoints !== data.measure.max_lrnr_points)
	        updateMaxLrnrPoints(tempMaxLrnrPoints);
	    }
      updateTheEfficacy();
    }
  }
  function updateTheEfficacy() {
    var thePatchObj = {};
    thePatchObj.operation = "replace";
    thePatchObj.entity = "topicItemInfo";
    thePatchObj.value = {};
    thePatchObj.value.topic_item_uuid = id;
    thePatchObj.value.topicItemInfo = childItemData;
    thePatchObj.value.topicItemInfo.measure.efficacy_factor = theEfficacy;
    thePatchObj.value.topicItemInfo.measure.grade_points =
      thePatchObj.value.topicItemInfo.measure.efficacy_factor +
      thePatchObj.value.topicItemInfo.measure.participation_factor;
    patchQuizItem(thePatchObj, childItemData);
  }

  function updateMaxLrnrPoints(tempMaxLrnrPoints) {
    patchItem(
      [
        {
          entity: "max_lrnr_points",
          operation: "update",
          value: tempMaxLrnrPoints
        }
      ],
      data
    );
  }
}

export const calculateNoOfBlanksInStimulus = (stimulus) => {
	let noOfBlanks = 0;
	for(let oneParagraph of stimulus)
    {
        const allChildren = oneParagraph.children;
        for(let oneChild of allChildren)
        {
            if(oneChild.type === 'blank')
            {
                ++noOfBlanks;
            }
        }
    }
	return noOfBlanks;
}

export const calculateNoOfHighlightsInParagraph = (para) => {
	const highlightNumbersSet = new Set();
	for(let oneParagraph of para)
    {
        const allChildren = oneParagraph.children;
        for(let oneChild of allChildren)
        {
           if(oneChild.highlight) {
			   highlightNumbersSet.add(oneChild.id)
		   }
        }
    }
	return highlightNumbersSet.size;
}

export const calculateEfficacyPointsForMatching = (data, sidesShuffled) => {
  let efficacyPoints = 0;
  if (data?.content?.pairs?.length > 0) {
    efficacyPoints = 0.5 * data.content.pairs.length;
    if (sidesShuffled) {
      data.content.pairs.forEach(
        singlePair =>
          (efficacyPoints += 0.5 * (singlePair.correctAnswers?.length || 0))
      );
    }
  } else {
    efficacyPoints = 0;
  }
  return efficacyPoints;
};