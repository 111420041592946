import produce from 'immer';
import { FETCH_BEAMS_SUCCESS, FETCH_BEAMS_FAILURE } from 'containers/Main/Beam/constants';
import { SET_ENV_CONFIG,SUBSCRIBE_TO_APP_CHANNEL_SUCCESS, 
  SUBSCRIBE_TO_APP_CHANNEL_FAILURE, RECEIVE_APP_CHANNEL_MESSAGE,
  MARK_CHANNEL_MESSAGE_AS_READ } from 'constants/ActionTypes'

const initialSettings = {
  beamListenerCreated: false,
  env: null,
  subscribedToAuthoringChannel: -1,
  appChannelMessages:{
    'uid_246b5d8d-c80f-4c5e-911a-de393ff670da_':[
      {
        code: "SLIDEDECK_CONVERSION_STAGE1_FAIL",
        msgTime: 12333,
        message: "Slidedeck processing complete",
        data:{
          jobId:'1',
          jobName:'name1',
          jobJson:{
            uuid:'5e94dc9d-995e-494e-b4e7-7e99e5cbc48e'
          }
        }
      },
      {
        msgTime: 12334,
        code: "SLIDEDECK_CONVERSION_FINAL_STAGE_PASS",
        message: "Error importing slidedeck",
        data:{
          jobId:'2',
          jobName:'name2',
          jobJson:{
            uuid:'5e94dc9d-995e-494e-b4e7-7e99e5cbc48e'
          }
        }
      }
    ]
  }
};

const AppBootstrap = (state = initialSettings, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_BEAMS_SUCCESS:
        console.log("in appbootstrap reducer", state, action)
        draft.beamListenerCreated = true;
        break;
      case FETCH_BEAMS_FAILURE:
        break;
      case SET_ENV_CONFIG:
        break;
      case SUBSCRIBE_TO_APP_CHANNEL_SUCCESS:
        console.log("in SUBSCRIBE_TO_APP_CHANNEL_SUCCESS", state, action);
        draft.subscribedToAuthoringChannel= 1;
        break;
      case SUBSCRIBE_TO_APP_CHANNEL_FAILURE:
        console.log("in SUBSCRIBE_TO_APP_CHANNEL_FAILURE", state, action);
        draft.subscribedToAuthoringChannel= 0;
        break;
      case RECEIVE_APP_CHANNEL_MESSAGE:
        console.log("in RECEIVE_APP_CHANNEL_MESSAGE", state, action);
        const message = action.message;
        if (typeof message == 'undefined' || message == null || typeof message.appChannelMessage == 'undefined' 
          || typeof message.appChannelMessage.message == 'undefined' 
          || typeof message.appChannelMessage.message.body == 'undefined' ){
          break;
        }
        const appChannelAddress = message.appChannelAddress;
        const appChannelMessage = message.appChannelMessage.message.body;
        if ( typeof  draft.appChannelMessages[appChannelAddress] == 'undefined'){
          draft.appChannelMessages[appChannelAddress]=[];
        }
        draft.appChannelMessages[appChannelAddress].push(appChannelMessage);
        break;
      case MARK_CHANNEL_MESSAGE_AS_READ:
          console.log("in MARK_CHANNEL_MESSAGE_AS_READ", state, action);
          //use msgTime and code to find the message
          const msgCode = action.requestInput.message.code;
          const msgTime = action.requestInput.message.msgTime;
          const userChannel = action.requestInput.userChannel;
          const msgToBeMarkedAsRead = draft.appChannelMessages[userChannel].find((msg)=> {
            return (msg["code"] == msgCode && msg["msgTime"] == msgTime)
          })
          msgToBeMarkedAsRead["read"]=true;
          break;
    }
  });

export default AppBootstrap;
