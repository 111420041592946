const localConfig = {
    AUTHORING_SERVICE : "/authoring/api",
    AUTHORING_VERSION : "/7.0",
    LRNR6_SERVICE : "http://qa.lrnr.us/api/0.6.0",
    AUTHORING_AUTH_SERVICE : window.sessionStorage.getItem('beamPortUrl')+"/authoring/api",
    AUTHORING_AUTH_VERSION : "/7.0",
    EVENT_BUS_SERVICE : window.sessionStorage.getItem('livePortUrl')+"/live/api/7.0/eventbus",
    FB_FUNC_SERVICE: "https://us-central1-polling-sb.cloudfunctions.net"
}
const devConfig = {
    AUTHORING_SERVICE : window.sessionStorage.getItem('pollingURL')+"/authoring/api",
    AUTHORING_VERSION : "/7.0",
    LRNR6_SERVICE : "http://qa.lrnr.us/api/0.6.0",
    AUTHORING_AUTH_SERVICE : window.sessionStorage.getItem('beamPortUrl')+"/authoring/api",
    AUTHORING_AUTH_VERSION : "/7.0",
    EVENT_BUS_SERVICE : window.sessionStorage.getItem('livePortUrl')+"/live/api/7.0/eventbus",
    FB_FUNC_SERVICE: "https://us-central1-polling-sb.cloudfunctions.net"
}

const prodConfig = {
    AUTHORING_SERVICE : window.sessionStorage.getItem('pollingURL')+"/authoring/api",
    AUTHORING_VERSION : "/7.0",
    LRNR6_SERVICE : "http://web.lrnr.us/api/0.6.0",
    AUTHORING_AUTH_SERVICE : window.sessionStorage.getItem('beamPortUrl')+"/authoring/api",
    AUTHORING_AUTH_VERSION : "/7.0",
    EVENT_BUS_SERVICE : window.sessionStorage.getItem('livePortUrl')+"/live/api/7.0/eventbus",
    FB_FUNC_SERVICE: "https://us-central1-lrnr-live.cloudfunctions.net",
}

const qaConfig = {
    AUTHORING_SERVICE : "/authoring/api",
    AUTHORING_VERSION : "/7.0",
    LRNR6_SERVICE : "http://qa.lrnr.us/api/0.6.0",
    AUTHORING_AUTH_SERVICE : window.sessionStorage.getItem('beamPortUrl')+"/authoring/api",
    AUTHORING_AUTH_VERSION : "/7.0",
    EVENT_BUS_SERVICE : window.sessionStorage.getItem('livePortUrl')+"/live/api/7.0/eventbus",
    FB_FUNC_SERVICE: "https://us-central1-polling-sb.cloudfunctions.net"
}

const demoConfig = {
    AUTHORING_SERVICE : window.sessionStorage.getItem('pollingURL')+"/authoring/api",
    AUTHORING_VERSION : "/7.0",
    LRNR6_SERVICE : "http://demo.lrnr.us/api/0.6.0",
    AUTHORING_AUTH_SERVICE : window.sessionStorage.getItem('beamPortUrl')+"/authoring/api",
    AUTHORING_AUTH_VERSION : "/7.0",
    EVENT_BUS_SERVICE : window.sessionStorage.getItem('livePortUrl')+"/live/api/7.0/eventbus",
    FB_FUNC_SERVICE: "https://us-central1-polling-demo-9b10c.cloudfunctions.net"
}

const lrnrLiveDevConfig = {
    ...qaConfig,
    TOKEN_API: '/api/iam/v1.0/getSessionToken'
}

export const getEnvConfig = () => {
    const env = getEnv();
    switch(env){
        case "devint":
        case "dev":
            return devConfig;
            break;
        case "local":
            return localConfig;
            break;
        case "prod":
            return prodConfig;
            break;
        case "qa":
            return qaConfig;
            break;
        case "demo":
            return demoConfig;
            break;
        case 'lrnr-live-dev':
            return lrnrLiveDevConfig;
        default:
            return localConfig;
    }
}

export const getEnv = () => {

    const pollingURL =  window.sessionStorage.getItem('pollingURL');

    const locationUrl = window.location.href;

    if (locationUrl.includes('lrnr-live-dev')) {
      return 'lrnr-live-dev';
    }

    if (typeof pollingURL == 'undefined' || pollingURL == null){
      return 'local'
    }else if (pollingURL.includes('qa')){
      return 'qa'
    }else if (pollingURL.includes('demo')){
      return 'demo'
    }else if (pollingURL.includes('web')){
      return 'prod'
    }
    return 'local';
  }