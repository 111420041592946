import { isValid } from "data/validators/valUtils";

const checkIfValidImageAndReturnImage = (attachments, attachedId) => {
  if (!attachments) {
    return false;
  }
  const imageJson = attachments.find(attachment => {
    return (
      typeof attachment.attachmentInfo != "undefined" &&
      attachment.attachmentInfo.entity === "option" &&
      attachment.attachmentInfo.attachedId === attachedId
    );
  });

  if (typeof imageJson === "undefined" || Object.keys(imageJson).length <= 0) {
    return false;
  } else {
    return imageJson;
  }
};

export const isValidSorting = ({ item }) => {
  if (!isValid(item) || !isValid(item.content)) {
    return false;
  }
  const noOfElements = getValidElementsCount({
    options: item.content.sortedElements,
    attachments: item.attachments
  });
  if (noOfElements) {
    if (noOfElements < 2) {
      return false;
    }
    return true;
  } else return false;
};

const getValidElementsCount = ({ options, attachments }) => {
  let noOfValidOptions = 0;
  let allAreValid = true;

  const allOptionValues = new Set();

  if (isValid(options)) {
    allAreValid = options.every(option => {
      if (isValid(option)) {
        if (
          isValid(option.value) ||
          checkIfValidImageAndReturnImage(attachments, option.id)
        ) {
          if (allOptionValues.has(option.value)) {
            return false;
          } else if (option.value !== "") {
            allOptionValues.add(option.value);
          }
          ++noOfValidOptions;
          return true;
        } else return false;
      } else return false;
    });
  }

  if (allAreValid) {
    return noOfValidOptions;
  } else return false;
};
