import React from "react";
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore from "./appRedux/store";
import App from "./containers/App/index";
import history from 'util/history';

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const history = useRouterHistory(createBrowserHistory)();`
const initialState = {};
const store = configureStore(initialState, history);
const NextApp = () => {
  return (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>
  )
}

export default NextApp;
