import {INSTRUCTOR_ROLES, STUDENT_ROLES} from 'constants/AppConstants';

var AppIsInFullScreen=false;

export var AppIsInFullScreen;

export function AppInFullScreen() {
	var elem = document.getElementById("root");
	AppIsInFullScreen=true;

	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) { /* Firefox */
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
		elem.webkitRequestFullscreen();
	} else if (elem.msRequestFullscreen) { /* IE/Edge */
		elem.msRequestFullscreen();
	}


}


export function ElementInFullScreen(ElementId) {
	var elem = document.getElementById(ElementId);

	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) { /* Firefox */
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
		elem.webkitRequestFullscreen();
	} else if (elem.msRequestFullscreen) { /* IE/Edge */
		elem.msRequestFullscreen();
	}

}

export function AppExitFullScreen() {
	//var elem = document.getElementById("root");
	AppIsInFullScreen=false;
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.mozCancelFullScreen) { /* Firefox */
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
		document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) { /* IE/Edge */
		document.msExitFullscreen();
	}
}

export function getCourseIdFromLocation(location) {
	if (location) {
		const pathStrings = location.pathname.split('/');
		const courseIndex = pathStrings.findIndex((path) => { return path == "courses" });
		if (pathStrings.length >= courseIndex) {
			return pathStrings[courseIndex + 1];
		} else {
			return null;
		}

	}
}

export function isUserInstructor(roles) {
	if (roles) {
		return roles.some(r => INSTRUCTOR_ROLES.includes(r))
	}
	return false;
}


export function isUserStudent(roles) {
	if (roles) {
		return roles.some(r => STUDENT_ROLES.includes(r))
	}
	return false;
}

export function getTheTimeElapsed(currentScreen,firebase) {

	// if(currentScreen.state.status!=="open"){
	// 	return -1;
	// }
	// else 

	if(typeof currentScreen.timer_info!=="undefined"){
		console.log("currentScreen.timer_info",currentScreen.timer_info);
		if(currentScreen.state.status==="open"){
			let theDate=new Date();
			let theOffset=theDate.getTimezoneOffset()*60000
			console.log("theOffset",theOffset);
			let theCurrentTimeStamp=theDate.getTime();
			console.log("theCurrentTimeStamp1",theCurrentTimeStamp);
			console.log("clockSkew",firebase.clockSkew);
			// if(firebase.clockSkew)
			// 	theCurrentTimeStamp+=parseInt(firebase.clockSkew);
			let theStartTimeStamp=convertFirebaseTimeStampToTimestamp({"fbTimeStamp":currentScreen.timer_info.start_time});
			console.log("startTime",theStartTimeStamp);
			console.log("theCurrentTimeStamp2",theCurrentTimeStamp);
			console.log("diff1",theCurrentTimeStamp-theStartTimeStamp);
			let diff=(theCurrentTimeStamp-theStartTimeStamp)/1000;
			
			diff=Math.floor(diff);
			console.log("diff",diff);
			if(diff>currentScreen.timer_info.duration)
				diff=currentScreen.timer_info.duration;
			return diff;
		}
		else{
			// let tempdiff=currentScreen.timer_info.duration - currentScreen.timer_info.time_elapsed;
			// console.log("duration",currentScreen.timer_info.duration);
			// console.log("time_elapsed",currentScreen.timer_info.time_elapsed);
			// tempdiff=Math.floor(tempdiff);
			// console.log("diff",tempdiff);
			return currentScreen.timer_info.time_elapsed;
		}	
	}
	console.log("diff","nothing");
	return 0;
}

// export function getTheTimeElapsed(currentScreen) {

// 	if(currentScreen.state.status!=="open"){
// 		return -1;
// 	}
// 	else if(typeof currentScreen.timer_info!=="undefined"){
// 		let theDate=new Date();
// 		let theOffset=theDate.getTimezoneOffset()*60000
// 		console.log("theOffset",theOffset);
// 		let theCurrentTimeStamp=theDate.getTime();
// 		console.log("theCurrentTimeStamp1",theCurrentTimeStamp);
// 		/// theCurrentTimeStamp+=theOffset;
// 		let theStartTimeStamp=convertFirebaseTimeStampToTimestamp({"fbTimeStamp":currentScreen.timer_info.start_time});
// 		console.log("startTime",theStartTimeStamp);
// 		console.log("theCurrentTimeStamp2",theCurrentTimeStamp);
// 		let diff=(theCurrentTimeStamp-theStartTimeStamp)/1000;
// 		console.log("diff",diff);
// 		diff=Math.floor(diff);
// 		return diff;
// 	}

// 	return 0;
// }

export const convertFirebaseTimeStampToTimestamp= ({fbTimeStamp}) =>{


  let theseconds=fbTimeStamp.seconds;
  let theNanoSeconds=fbTimeStamp.nanoseconds;
  let milliSecondsTimeStamp=theseconds*1000;
  milliSecondsTimeStamp+=theNanoSeconds/1000000;

  return milliSecondsTimeStamp;

}
