export const getAuthoringBeamUrlPath = (courseId, beamId) => {
    if (courseId){
        return "/courses/"+courseId+"/author/beams/"+beamId
    }else {
        return "/author/beams/"+beamId
    }
}

export const isABeamUrl = (path) => {
    if (path){
        if(path.includes("/beams/")){
            return true;
        }
    }
    return false;
}

export const getCoursesDashboardUrl = ({location}) => {
    if (location){
        const path = location.pathname;
        const courseId = getCourseId({path});
        if (courseId != null){
            return '/courses/'+courseId+'/home?inst=true';
        }
    }
    return '/';
}

export const getQuizzesUrl = ({location, path}) => {
    if (typeof location != 'undefined'){
        path = location.pathname;
    }
    const courseId = getCourseId({path});
    if (courseId != null){
        return '/courses/'+courseId+'/mybank/quizzes';
    }
    
    return '/mybank/quizzes';
}
export const getSlidesUrl = ({location, path}) => {
    if (typeof location != 'undefined' && location != null){
        path = location.pathname;
    }else if (typeof path == 'undefined' || path == null){
        //read from window
        path = window.location.pathname;
    }
    const courseId = getCourseId({path});
    if (courseId != null){
        return '/courses/'+courseId+'/mybank/slides';
    }
    
    return '/mybank/slides';
}

export const getCourseId = ({path} ) => {

    if (typeof path == 'undefined' || path == null){
        return null
    }
    if (typeof path != 'undefined' && path.includes("/courses")){

        const splitPath = path.split('/');
        const coursesIndex = splitPath.findIndex(term => term === 'courses')
        if (coursesIndex != -1 ){
            return splitPath[coursesIndex+1];
        }
    }

    return null;

}