/* global fetch */
/* global window */
import 'isomorphic-fetch';

export const callApi = (url, options) => {
  const urlWithSignature = getUrlWithSignature(url);
  fetch(urlWithSignature, options)
    .then(
      response => (response.ok
        ? response.json()
        : Promise.reject(response.text())
      ),
      error => Promise.reject(error))
    .then(
      json => ({ json: json }),
      error => ({ error }))
    .catch(error => ({ error }));
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["options"] }] */
/* eslint no-useless-escape: "error" */
export default function request(url, options) {
  console.log('in common requests', url, options);
  let updatedOptions = { ...options };
  delete updatedOptions.ignoreSignature;
  // const accessTokenFromLocalStorage = localStorage.getItem('accessToken');
  // if (typeof accessTokenFromLocalStorage !== 'undefined' && accessTokenFromLocalStorage != null) {
  //   if (updatedOptions == null) {
  //     updatedOptions = { headers: {} };
  //   } else if (updatedOptions.headers == null) {
  //     updatedOptions.headers = {};
  //   }
  //   const accessToken = JSON.parse(accessTokenFromLocalStorage);
  //   updatedOptions.headers.Authorization = `Bearer ${accessToken}`;
  // }
  // console.log('updatedOptions', updatedOptions);
  let urlWithSignature = url;
  if (!options?.ignoreSignature) {
    urlWithSignature = getUrlWithSignature(url);
  }
  return fetch(urlWithSignature, updatedOptions)
    .then(checkStatus)
    .then(parseJSON);
}

const getSignatureQueryParams = () => {
  if (window.Storage && window.sessionStorage) {
    let apiHostName = window.sessionStorage.getItem('contentServerURL');
    let oerHostName = window.sessionStorage.getItem('oerServerURL');
    let tempValue = window.sessionStorage.getItem('userSignature');
    tempValue = JSON.parse(tempValue);
    console.log('temp value', tempValue);
    let roles = tempValue.roles.toString();
    let userSignature = ''
    if (tempValue)
      userSignature = 'signature=' + tempValue.signature + "&signTime=" + tempValue.signTime + "&accountId=" + tempValue.accountId+"&roles="+roles;
    return userSignature;

  } else {
    return null;
  }
}

const getUrlWithSignature = (url) => {
  const signature = getSignatureQueryParams();
  if (!url) {
    return url;
  }
  if (url.includes('?')) {
    return url + "&" + signature;
  } else {
    return url + "?" + signature;
  }
}

/*eslint no-param-reassign: ["error", { "props": false }]*/

export function upload({ xhr, url, options, progressListener, eventListener }) {

  return new Promise((resolve, reject) => {

    console.log("url", url);
    const urlWithSignature = getUrlWithSignature(url);
    const method = options['method'];


    if (typeof xhr == 'undefined') {
      const xhr = new XMLHttpRequest();
    }
    const headers = options.headers;
    Object.keys(headers).forEach((header) => {
      xhr.setRequestHeader(header, headers[header])
    });

    xhr.open(method, urlWithSignature, true);

    xhr.onload = (e) => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        console.warn('request_error');
        reject(xhr.statusText);
      }
    };

    const payload = options.body;

    // upload progress event
    xhr.upload.addEventListener('progress', function (e) {
      let percent_complete = (e.loaded / e.total) * 100;
      if (typeof progressListener == 'function') {
        progressListener({ percent: percent_complete });
      }
    });

    // AJAX request finished event
    xhr.addEventListener('load', function (e) {
      // HTTP status message
      console.log(xhr.status);
      if (typeof eventListener == 'function') {
        eventListener(e);
      }
      // request.response will hold the response from the server
    });

    // send POST request to server side script
    xhr.send(payload);
  });
}