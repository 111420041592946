import { call, put, fork, take, cancelled, takeLatest } from "redux-saga/effects";
import { eventChannel, END } from 'redux-saga';
import { SETUP_ROOM_NODE, DELETE_ROOM_NODE, LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS, EXIT_ROOM_NODE } from 'constants/ActionTypes'
import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { isUserInstructor } from 'util/AppUtil';
import { 
    setupRoomNodeFailure, 
    setupRoomNodeSuccess, 
    updateLiveRoomParticipantsStatus, 
    deleteRoomNodeSuccess,
    deleteRoomNodeFailure
} from "../actions/RoomUsersStatus";

export function* setupRoomNode(action) {
    console.log('setup Room Node saga', action);
    const requestInput = action.requestInput;
    const { firebase, nodeId } = requestInput;
    const {  roomsDatabase, userSignature } = firebase;
    const uid = userSignature.accountId;
    let nodeName = isUserInstructor(firebase.userSignature.roles) ? '/admins/' : '/participants/';
    console.log('details in setuproom node', nodeId, uid, nodeName, firebase.userSignature.roles);
    try {
        const userStatusDatabaseRef = roomsDatabase.ref(nodeId + nodeName + uid);
        console.log('user data ref', userStatusDatabaseRef);
        // We'll create two constants which we will write to 
        // the Realtime database when this device is offline
        // or online.
        const isOfflineForDatabase = {
            state: 'offline',
            last_changed: Firebase.database.ServerValue.TIMESTAMP,
        };

        const isOnlineForDatabase = {
            state: 'online',
            last_changed: Firebase.database.ServerValue.TIMESTAMP,
        };

        yield userStatusDatabaseRef.set(isOnlineForDatabase);

        // Create a reference to the special '.info/connected' path in 
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        roomsDatabase.ref('.info/connected').on('value', function(snapshot) {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() == false) {
                return;
            };

            // If we are currently connected, then use the 'onDisconnect()' 
            // method to add a set which will only trigger once this 
            // client has disconnected by closing the app, 
            // losing internet, or any other means.
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect() 
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/fbHandle.database.OnDisconnect

                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
        console.log('all setup is completed', );
        // listener will start listening once instructor sets up the room and not the participants.
        if (nodeName.includes('admins')) {
            yield call(listenRoomParticipantsStatus, { nodeId, roomsDatabase, })
        }
        console.log('all set up is successfull after listner');

    } catch (e) {
        console.log('error creating the beam node', e);
    }
}

export function setupRoomStatusEventChannelListener({ nodeId, roomsDatabase }) {
    return eventChannel(emmitter => {
        const roomStatusRef = roomsDatabase.ref(nodeId)
        roomStatusRef.on('value', (snapshot) => {
            console.log('snapShot', snapshot)
            const data = snapshot.val();
            console.log('data on listener', data);
            emmitter(data || 'null');
        });

        return () => roomStatusRef;
    })
}

export function* listenRoomParticipantsStatus({ requestInput, nodeId, roomsDatabase }) {
    console.log('listening the room participants status saga', nodeId)
    let response;
    console.log('details in listent room node', nodeId, roomsDatabase);
    const roomNodeChannel = yield call(setupRoomStatusEventChannelListener, {nodeId, roomsDatabase});
    try {
        while (true) {
            const roomNodeData = yield take(roomNodeChannel);
            console.log('room node data', roomNodeData);
            response = { nodeId, listening: true, data: roomNodeData };
            yield put(updateLiveRoomParticipantsStatus({ requestInput, response }))
        }
    } catch (e) {
        console.log('Error while listening to the room status', e)
    } finally {
        console.log("roomNode channel terminated")
        if (yield cancelled()) {
            roomNodeChannel.close();
        }
    }
}

export function* exitRoomNode(action) {
    console.log('exit room node saga', action);
    try {
        const requestInput = action.requestInput;
        const { firebase, nodeId } = requestInput;
        const {  roomsDatabase, userSignature } = firebase;
        const uid = userSignature.accountId;
        let nodeName = isUserInstructor(firebase.userSignature.roles) ? '/admins/' : '/participants/';
        const roomNodeRef = roomsDatabase.ref(nodeId + nodeName + uid);

        const isOfflineForDatabase = {
            state: 'offline',
            last_changed: Firebase.database.ServerValue.TIMESTAMP,
        };
        roomNodeRef.set(isOfflineForDatabase);
    } catch(e) {
        console.log('Error exiting node.');
    }
}

export function*  deleteRoomNode(action) {
    console.log('delete room node saga', action)
    try {
        const requestInput = action.requestInput;
        const { firebase, nodeId } = requestInput;
        const {  roomsDatabase } = firebase;
        const roomNodeRef = roomsDatabase.ref(nodeId);
        roomNodeRef.off();   // detaching all the listeners to this node;

        // cancelling all the onDisconnect calls on this node and it's children node
        // This is important because once you delete the node and refresh the page onDiconnect method will run
        // and create the node even after deleting the parent node
        roomNodeRef.onDisconnect().cancel();

        // deleting the parent room node.
        roomNodeRef.remove()
          .then(() => {
              const response = { nodeId };
              put(deleteRoomNodeSuccess({ requestInput, response }));
        });
    } catch(e) {

    }
}

export default function* watchRoomStatusActions() {
    yield takeLatest(SETUP_ROOM_NODE, setupRoomNode)
    yield takeLatest(DELETE_ROOM_NODE, deleteRoomNode)
    yield takeLatest(LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS,listenRoomParticipantsStatus);
    yield takeLatest(EXIT_ROOM_NODE, exitRoomNode);
  }

