import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { getCoursesDashboardUrl} from 'util/LocationUtils'
import {onNavStyleChange, toggleCollapsedSideNav} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
} from "../../constants/ThemeSetting";


class SidebarLogo extends Component {

  
  render() {
    const {width, themeType, navCollapsed, location} = this.props;
    let {navStyle} = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }
    return (
      <div className="gx-layout-sider-header gx-layout-lrnr-sider-header">

        {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR || NAV_STYLE_NO_HEADER_MINI_SIDEBAR) ? <div className="gx-linebar gx-lrnr-sidebar-layout-btn">

          <i
            className={`gx-icon-btn gx-lrnr-sidebar-layout-btn-icon icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                this.props.toggleCollapsedSideNav(!navCollapsed);
              } else if (navStyle === NAV_STYLE_FIXED) {
                this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                this.props.toggleCollapsedSideNav(!navCollapsed);
              } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
                this.props.toggleCollapsedSideNav(!navCollapsed);
              } else {
                this.props.onNavStyleChange(NAV_STYLE_FIXED)
              }
            }}
          />
        </div> : null}

        <a href={getCoursesDashboardUrl({location})} className="gx-site-logo gx-lrnr-site-logo">
          {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
            <img alt="Lrnr logo" src={require("assets/images/logo.png")}/> :
            themeType === THEME_TYPE_LITE ?
              <img alt="Lrnr logo" src={require("assets/images/logo.png")}/> :
              <img alt="Lrnr logo" src={require("assets/images/logo.png")}/>}

        </a>

      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, width, navCollapsed} = settings;
  return {navStyle, themeType, width, navCollapsed}
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav
})(SidebarLogo);
