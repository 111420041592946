export const createMcqAndMsqAssessmentItem = ({ item }) => {
  console.log("Item content", item);
  let answers=[];
  if(typeof item.content!=="undefined" && typeof item.content.answers!=="undefined" && item.content.answers.length>0){
    item.content.answers.map((tempAnswer)=>{
      let tempNewAnswer={};
      Object.keys(tempAnswer).map((tempKey)=>{
        if(tempKey!=="pointPercentage" && tempKey!=="feedbackvalue"){
          tempNewAnswer[tempKey]=tempAnswer[tempKey];
        }
      })
      answers.push(tempNewAnswer);
    })
  }
  let distractors=[];
  if(typeof item.content!=="undefined" && typeof item.content.distractors!=="undefined" && item.content.distractors.length>0){
    item.content.distractors.map((tempDistractor)=>{
      let tempNewDistractor={};
      Object.keys(tempDistractor).map((tempKey)=>{
        if(tempKey!=="pointPercentage" && tempKey!=="feedbackvalue"){
          tempNewDistractor[tempKey]=tempDistractor[tempKey];
        }
      })
      distractors.push(tempNewDistractor);
    })
  }
  //const answers = item.content?.answers || [];
  //const distractors = item.content?.distractors || [];
  const content = {
    ...item.content,
    noOfCorrectAnswers: answers ? answers.length : 0,
    hasCorrectAnswers: answers.length > 0,
    options: [...answers, ...distractors]
  };
  delete content.distractors;
  delete content.answers;

  const convertedItem = {
    ...item,
    content
  };

  return convertedItem;
};
