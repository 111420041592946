import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";

// import sessionStorageConfig from "../../util/sessionStorageConfig";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import {setInitUrl} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_NO_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
} from "constants/ThemeSetting";

import { isABeamUrl } from 'util/LocationUtils';
import { isUserInstructor} from 'util/AppUtil'
import { createActionToFetchFBAuthToken } from "../../appRedux/actions/Firebase";

const RestrictedRoute = ({component: Component, firebase, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      firebase && (firebase.userSignature || firebase.isAuthenticated)
        ? <Component {...props} />
        : <h1>User Not Authenticated....Please login</h1>}
  />;


class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentWillMount() {
    // sessionStorageConfig();
    if (!(this.props.firebase.userSignature && this.props.firebase.isAuthenticated)) {
      console.log('insdie the dipa', this.props);
      this.props.dispatch(createActionToFetchFBAuthToken({ ignoreSignature: true, firebase: this.props.firebase }));
    }

    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
    }
    if (isABeamUrl(this.props.history.location.pathname) ){
      console.log('layout', this.props.history);
      this.props.onNavStyleChange(NAV_STYLE_NO_HEADER_NO_SIDEBAR);
    } else {
      console.log('layout else', this.props.history);
      this.props.onNavStyleChange(NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR);
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    console.log('test firebase red', this.props);
    const {match, location, layoutType, navStyle, locale, firebase, initURL} = this.props;
    if (location.pathname === '/' || location.pathname === '/mybank') {
      if (firebase == null || (firebase.userSignature !== null && !firebase.isAuthenticated)) {
        return ( <h1>User Not Authenticated....Please login</h1> );

      }else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/mybank') {
        if (firebase.isAuthenticated && isUserInstructor(firebase.userSignature.roles)) {
          //check the last route in localStorage for instructor role
          const lastRoutePathname = window.localStorage.getItem('lastRoute');
          if (typeof lastRoutePathname !== 'undefined') {
            return (<Redirect to={lastRoutePathname} />);
          }
        }else{
          return ( <Redirect to={'/mybank'}/> );
        }
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signup' component={SignUp}/>
            <RestrictedRoute path={`${match.url}`} firebase={firebase}
                             component={MainApp}/>
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange
  };
}

const mapStateToProps = ({settings, auth, firebase}) => {
  const {locale, navStyle, layoutType} = settings;
  const { initURL}  = auth;
  return {locale, navStyle, layoutType, firebase, initURL}
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
