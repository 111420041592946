import React from "react";
import {Select} from "antd";

const Option = Select.Option;

const LrnrDropdownSelect = ({defaultSelect, selectableData, customStyle, onChange}) => {
	function handleChange(value) {
      console.log(`selected ${value}`);
      if(onChange)
      	onChange(value);
    }

    return (
		<Select className="gx-lrnr-general-dropdown-component gx-mb-3 text-secondary-lrnr-small-typo" defaultValue={defaultSelect} style={customStyle} onChange={handleChange}>
			{selectableData.map((theData, index) =>
				<Option key={index} value={theData.value}>{theData.title}</Option>
			)}
		</Select>
	)
}

export default LrnrDropdownSelect;