import fb from "firebase";
import request from "util/ApiUtils";
import { getFirebaseFnEndpoint } from "constants/ApiConstants";
// abstract level -> firebaseEndpoint, payload,
export async function processItemResponse(action) {
  let requestInput = action.requestInput;
  const {
    uuid,
    userBeamDocument,
    beamScreenRefId,
    theResponse,
    item
  } = requestInput;
  let userBeamRefId = userBeamDocument.id;
  let sectionCode = userBeamDocument.section_code;
  let itemSubtype = item.subtype;
  let response = theResponse;
  let bodyData = {
    data: {
      userBeamRefId,
      beamScreenRefId,
      sectionCode,
      response,
      itemSubtype,
    }
  };
  //let url = "https://us-central1-polling-sb.cloudfunctions.net/processItemResponse";
  let pirURl = getFirebaseFnEndpoint({
    functionName: "processItemResponse"
  });
  try {
    let token = await fb.auth().currentUser.getIdToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    console.log(bodyData);
    const pir = await request(pirURl, {
      method: "POST",
      headers,
      body: JSON.stringify(bodyData)
    });
    console.log(pir);
    return pir;
  } catch (error) {
    console.log("Unexpected network error");
    return {
      // same structure as fb.
      status: "ERR_UNEXPECTED",
      message: error
    };
  }
}
