import { 
  SETUP_ROOM_NODE, 
  SETUP_ROOM_NODE_SUCCESS, 
  SETUP_ROOM_NODE_FAILURE,
  DELETE_ROOM_NODE,
  DELETE_ROOM_NODE_SUCCESS,
  DELETE_ROOM_NODE_FAILURE,
  LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS,
  UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS,
} from 'constants/ActionTypes';
import produce from 'immer';


const INIT_STATE = {
  rooms: {},
};


const RoomUsersStatus = (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      // case SETUP_ROOM_NODE_SUCCESS:
      //   console.log("In SETUP_ROOM_NODE_SUCCESS reducer", state, action)
      //   break;
      // case SETUP_ROOM_NODE_FAILURE:
      //   console.log("In SETUP_ROOM_NODE_FAILURE reducer", state, action)
      //   console.log('Error while creating the database beam node.');
      //   break;
      case UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS:
        console.log('In UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS reducer', state, action);
        const updatedNodeId = action.response.nodeId;
        const data = action.response.data;
        console.log('updated node id', updatedNodeId, data);
        draft.rooms[updatedNodeId] = { listening: true, ...data };
        break;
      case DELETE_ROOM_NODE_SUCCESS:
        console.log("In DELETE_ROOM_NODE_SUCCESS reducer", state, action)
        const deletedNodeId = action.response.nodeId;
        delete draft.rooms[deletedNodeId];
        break;
      // case DELETE_ROOM_NODE_FAILURE:
      //   console.log("In DELETE_ROOM_NODE_FAILURE reducer", state, action)
      //   console.log('Error while deleting the database beam node.');
      default:
        return state;
    }
  });

export default RoomUsersStatus;
