export const getSelectionOptionArray = (areas) => {
    let hotspotCount = 0;
    let labelCount = 0;
    const selectionOptionArray = [
		{ activityType: 'dropPin', title: 'Click on Target' },
		{ activityType: 'cot', title: 'Matching' },
		{ activityType: 'labelling', title: 'Fill in the Blank(s)' },
		{ activityType: 'dragAndDrop', title: 'Drag And Drop' },
		{ activityType: 'dropDown', title: 'Drop Down' },
	];
    areas.forEach((area) => {
        if (area.type === 'target') {
            labelCount += 1;
        } else {
            hotspotCount += 1;
        }
    });

    if (hotspotCount > 1) {
        return selectionOptionArray;
    }

    if (hotspotCount === 0) {
        if (labelCount === 1) {
            return [{ activityType: 'labelling', title: 'Fill in the Blank(s)' }]
        } else if(labelCount > 1) {
            return selectionOptionArray.filter((_, index) => index > 1);
        }
    }

    console.log('hpot counts', hotspotCount, labelCount);

    if (hotspotCount === 1) {
        if (labelCount === 0) {
            return [
                { activityType: 'dropPin', title: 'Click on Target' },
                { activityType: 'labelling', title: 'Fill in the Blank(s)' },
            ]
        } else if (labelCount > 0) {
            return selectionOptionArray.filter((_, index) => index !== 1)
        }
    }
    return [{ activityType: 'labelling', title: 'Fill in the Blank(s)' }];
}

export const filterTargetArea = (content) => {
    const { areas, terms, defns, scoring } = content;
    const new_areas = [];
    const target_terms = [];
    const target_defns = [];
    areas.forEach((area) => {
        if (area.type === 'target') {
            const termId = scoring[area.id].answer;
            const defnId = scoring[area.id].defAnswer;
            target_terms.push(termId);
            target_defns.push(defnId);
            return;
        }
        new_areas.push(area);
    });

    const new_terms = terms.filter((term) => !target_terms.includes(term.id));
    const new_defns = defns.filter((defn) => !target_defns.includes(defn.id))

    delete content.scoring;
    return {
        ...content,
        areas: new_areas,
        terms: new_terms,
        defns: new_defns,
    }
}