import React from "react";
import IntlMessages from "util/IntlMessages";

function Welcome({match}) {
  return (
    <div className="gx-main-content gx-lrnr-welcome-container">
    	<div className="gx-lrnr-welcome-inner-container">
    		<div className="h3-lrnr-typo">
    			Hello
    		</div>
    		<div className="h4-lrnr-typo gx-lrnr-welcome-message">
    			Click the Create button on the top left to start building your own content
    		</div>
    	</div>
    	
    </div>
  )
}
export default Welcome;