import request from 'util/ApiUtils';
import { getItemsByTopicAPI } from 'constants/ApiConstants.js';
import React, { useState, useEffect } from "react";


export const useFetchComponentItems = (beamSource) => {

    console.log(" in useFetchComponentItems",beamSource);
    

    const [componentItems, setComponentItems] = React.useState(null);
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        const componentId = beamSource.component.uuid;
        const requestURL = getItemsByTopicAPI(componentId);
        if (beamSource.componentItems) {
            return;
        }
        const fetchData = async () => {
            try {
                const res = await request(requestURL, null);
                console.log("RES", res);
                let json;
                if (res.status == 'success') {
                    json = res.body;
                    setComponentItems(json);
                } else {
                    setError("Could not fetch component items");
                }
            } catch (error) {
                console.log("error ", error);
                setError(error);
            }
        };
        fetchData();
    }, []);
    console.log("response, error", componentItems, error)
    beamSource.componentItems = componentItems;
    return beamSource;
};