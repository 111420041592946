export const LISTEN_TO_BEAM = 'authoring/LISTEN_TO_BEAM';
export const LISTEN_TO_BEAM_SUCCESS = 'authoring/LISTEN_TO_BEAM_SUCCESS';
export const LISTEN_TO_BEAM_FAILURE = 'authoring/LISTEN_TO_BEAM_FAILURE';
export const CREATE_USER_BEAM = 'authoring/CREATE_USER_BEAM';
export const CREATE_USER_BEAM_SUCCESS = 'authoring/CREATE_USER_BEAM_SUCCESS';
export const CREATE_USER_BEAM_FAILURE = 'authoring/CREATE_USER_BEAM_FAILURE';
export const ACTION_SAVE_USER_RESPONSE = 'authoring/ACTION_SAVE_USER_RESPONSE';
export const SAVE_USER_RESPONSE_SUCCESS = 'authoring/SAVE_USER_RESPONSE_SUCCESS';
export const SAVE_USER_RESPONSE_FAILURE = 'authoring/SAVE_USER_RESPONSE_FAILURE';
export const LISTEN_BEAM_SCREENS_SUCCESS = 'authoring/LISTEN_BEAM_SCREENS_SUCCESS';
export const LISTEN_BEAM_SCREENS_FAILURE = 'authoring/LISTEN_BEAM_SCREENS_FAILURE';
export const JOIN_BEAM_LIVE_CHANNEL_ACTION = 'authoring/JOIN_BEAM_LIVE_CHANNEL_ACTION';
export const JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS = 'authoring/JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS';
export const JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE = 'authoring/JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE';
export const LISTEN_TO_USERBEAM_SCREENS_CHANGES = 'authoring/LISTEN_USERBEAM_SCREENS_CHANGES';
export const LISTEN_TO_USERBEAM_SCREENS_SUCCESS = 'authoring/LISTEN_USERBEAM_SCREENS_SUCCESS';
export const LISTEN_TO_USERBEAM_SCREENS_FAILURE = 'authoring/LISTEN_TO_USERBEAM_SCREENS_FAILURE';
export const USER_BEAM_SCREENS_CHANGED = 'authoring/USER_BEAM_SCREENS_CHANGED';
export const SEND_PRESENCE_MSG_SUCCESS = 'authoring/SEND_PRESENCE_MSG_SUCCESS';
export const ACTION_TO_JOIN_BEAM = "authoring/ACTION_TO_JOIN_BEAM";
export const JOIN_BEAM_SUCCESS = "authoring/JOIN_BEAM_SUCCESS";
export const JOIN_BEAM_FAILURE = "authoring/JOIN_BEAM_FAILURE";
export const SET_JOIN_BEAM_STATUS = "authoring/SET_JOIN_BEAM_STATUS";
export const SET_SAVE_USER_RESPONSE_ERROR = "authoring/SET_SAVE_USER_RESPONSE_ERROR";
export const STORE_IMAGE_DIMENSIONS = "authoring/SET_IMAGE_DIMENSIONS";