/* global history */
/* global location */
/* global window */
import React, {useEffect} from "react";
import {Route, Switch, useLocation } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => {

    const routeLocation = useLocation();

    useEffect(() => {
        console.log('Location changed',routeLocation);
        // var thisPageUrl=window.parent.location.href.split("#/")[0];
        // thisPageUrl=thisPageUrl+"#"+routeLocation.pathname;
        // window.parent.history.replaceState(null, null, thisPageUrl);
        const pathname = routeLocation.pathname;
        if (typeof pathname != 'undefined' && !pathname.includes('beams')){
            window.localStorage.setItem('lastRoute',pathname);
        }

    }, [routeLocation]);

    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
                <Route path={`${match.url}mybank`} component={asyncComponent(() => import('./mybank'))}/>
                <Route path={`${match.url}author/beams`} component={asyncComponent(() => import('./beam'))}/>
                <Route path={`${match.url}courses`} component={asyncComponent(() => import('./courses'))}/>
                <Route path={`${match.url}author/courses`} component={asyncComponent(() => import('./courses'))}/>
                <Route path={`${match.url}beams`} component={asyncComponent(() => import('./studentBeam'))}/>
                <Route path={`${match.url}review`} component={asyncComponent(() => import('./review'))}/>

            </Switch>
        </div>
    )
}

export default App;
