import { getScreenByOid } from "./screenUtils";
import { createClickOnTargetAssessmentItem } from "data/transformers/diagramTransformer";
import { isItemBeamable } from "data/validators/quizBeamValidator";
import { ITEM_TYPE } from "constants/ItemConstants.js";
import { createMatchingAssessmentItem } from "data/transformers/matchingTransformer";
import { createSortingAssessmentItem } from "data/transformers/sortingTransformer";
import { createHighlightAssessmentItem } from "data/transformers/highlightTransformer";
import fb from "firebase"
import { createMcqAndMsqAssessmentItem } from "data/transformers/mcqAndMsqTransformer";
import { createFIB2AssessmentItem } from "data/transformers/fib2Transformer";
import matchingSolution from "../../../data/solutionBuilders/matching";
import highlightSolution from "../../../data/solutionBuilders/highlight";

export function getClassSections() {
  return {
    instituionCourseUuid: "",
    classSections: [
      {
        classSectionCode: "CODEA",
        name: "Section A",
        startDate: "",
        endDate: ""
      },
      {
        classSectionCode: "CODEB",
        name: "Section B",
        startDate: "",
        endDate: ""
      }
    ]
  };
}

const changeAuthoringItemToAssessmentItemAndSolutions = ({
  topic,
  item,
  topicItemInfo,
  userSignature
}) => {
  const itemSubtype = item.subtype;
  let assessmentItem;
  if (itemSubtype == "diagram") {
    assessmentItem = createClickOnTargetAssessmentItem({
      topic,
      diagramItem: item,
      topicItemInfo,
      userSignature
    });
    // if (assessmentItem.total_items > assessmentItem.assessment_config.items.length) {
    //   const new_efficacy = (topicItemInfo.measure.efficacy_factor / assessmentItem.total_items) * assessmentItem.assessment_config.items.length;
    //   topicItemInfo.measure = {
    //     ...topicItemInfo.measure,
    //     efficacy_factor: new_efficacy,
    //     grade_points: new_efficacy + topicItemInfo.measure.participation_factor,
    //   }
    // }
  } else if (itemSubtype == "matching") {
    assessmentItem = createMatchingAssessmentItem({
      item,
      topicItemInfo,
      userSignature
    });
  } else if (itemSubtype == "sorting") {
    assessmentItem = createSortingAssessmentItem({
      item,
      topicItemInfo,
      userSignature
    });
  } else if(itemSubtype === 'mcq' || itemSubtype === 'msq') {
    assessmentItem = createMcqAndMsqAssessmentItem({ item });
  } else if (itemSubtype == "fib2") {
    assessmentItem = createFIB2AssessmentItem({
      item,
      topicItemInfo,
      userSignature
    });
  } else if(itemSubtype == "highlight") {
    assessmentItem = createHighlightAssessmentItem({item,topicItemInfo,userSignature});
  } else {
    assessmentItem = item;
  }
  const itemSolution = getSolutionFromItem({ item, topicItemInfo, assessment_config: assessmentItem.assessment_config });
  return { assessmentItem, itemSolution };
};

export function createScreenJson({
  docRefId,
  beam_uuid,
  oid,
  created_by,
  slidedeck
}) {
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const uuid = uuidv4();
  return {
    beam_doc_ref_id: docRefId,
    beam_uuid: beam_uuid,
    oid,
    item: {
      canvas: {
        width: document.body.clientWidth,
        height: document.body.clientHeight
      },
      created_timestamp: Date.now(),
      deleted: false,
      owner_uuid: created_by,
      parent_version: 0,
      relations: {
        topics: slidedeck
      },
      status: "draft",
      subtype: "whiteboard",
      title: "",
      type: "learn",
      uuid,
      version: 1,
      attachments: []
    },
   state: { status: 'init', view_state: 'init' },
   topic_item_info: {
      assessment_config: {},
      measure: {},
      subtype: "whiteboard",
      type: "learn",
      uuid
    }
  };
}

export const addHaltedScreenItems = ({
  item,
  topicItemInfo,
  haltedScreenItems
}) => {
  if (
    !topicItemInfo.assessment_config ||
    (topicItemInfo.assessment_config &&
      !topicItemInfo.assessment_config.promptSelection)
  ) {
    return;
  }
  haltedScreenItems.push({
    item_uuid: topicItemInfo.uuid,
    item_content_object: item.content
  });
};

//beamSource has type, component and componentItems
//component represents topic (quiz, slidedeck, etc)
//componentItems represents items in a topic
//component.items is not same as componentItems
//component.items represent the component specific data held for each item
//like quiz points, which could change for an item between quizzes
//the screens are eventually created as subcollection in firestore
//returns screens and screens_order and answers of these screens
export function getScreensInfo({ beamSource, userSignature }) {
  let counter = 1;
  let screens = [];
  if (!beamSource.componentItems || !beamSource.component) {
    return;
  }
  let screens_order = [];
  let screenAnswers = [];
  const haltedScreenItems = [];
  const itemsOrder = beamSource.component.items_order;
  if (!itemsOrder) {
    return null;
  }
  console.log("check 1");
  itemsOrder.forEach(itemKey => {
    console.log("check 1.1");

    //item uuid (items collection in mongo)
    const itemUuid = beamSource.component.items[itemKey].uuid;
    const componentItem = beamSource.componentItems.filter(
      item => item.uuid == itemUuid
    )[0];
    if (componentItem?.subtype === 'whiteboard') {
      componentItem.canvas = {
        width: document.body.clientWidth,
        height: document.body.clientHeight
      }
    }
    if (
      componentItem?.subtype != "slide" &&
      componentItem?.subtype != "whiteboard" &&
      !isItemBeamable({ topic: beamSource.component, item: componentItem })
    ) {
      //if the item is not beammable return
      return;
    }
    console.log('no return');
    let screen = {};
    screen.oid = counter;
    screens_order.push(counter);
    screen.state = {};
    let tempTopicItemInfo={};
    Object.keys(beamSource.component.items[itemKey]).forEach(key => {
        if(key!=="measure"){
            tempTopicItemInfo[key]=beamSource.component.items[itemKey][key];
        }
        else{
          if(typeof beamSource.component.items[itemKey][key].timer_in_secs!=="undefined" && beamSource.component.items[itemKey][key].timer_in_secs>300){
            tempTopicItemInfo[key]=beamSource.component.items[itemKey][key];
            tempTopicItemInfo[key].timer_in_secs=-1;
          }
        }
    })

    //transform the componentItem to assessmentItem
    const changedItemAndAnswer = changeAuthoringItemToAssessmentItemAndSolutions(
      {
        topic: beamSource.component,
        item: componentItem,
        topicItemInfo: beamSource.component.items[itemKey],
        userSignature
      }
    );
    addHaltedScreenItems({
      item: componentItem,
      topicItemInfo: beamSource.component.items[itemKey],
      haltedScreenItems: haltedScreenItems
    });

    if (
      counter === 1 &&
      (!changedItemAndAnswer.assessmentItem?.assessment_config?.promptSelection ||
        changedItemAndAnswer.assessmentItem?.assessment_config?.
          allowed_activities_for_beam?.length === 1)
    ) {
      screen.state.status = "open";
      screen.state.view_state = "open";
      //screen.timer_info={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':beamSource.component.items[itemKey].measure.timer_in_secs};
    } else {
      screen.state.status = "init";
      screen.state.view_state = "init";
    }

    screen.item = changedItemAndAnswer.assessmentItem;
    console.log('screen item', screen.item);
    screenAnswers.push(changedItemAndAnswer.itemSolution);
    screen.topic_item_info = {
      ...beamSource.component.items[itemKey]
    };
    if (changedItemAndAnswer.assessmentItem.subtype === 'diagram' && changedItemAndAnswer.assessmentItem.total_items > changedItemAndAnswer.assessmentItem.assessment_config.items.length) {
      const new_efficacy = (screen.topic_item_info.measure.efficacy_factor / changedItemAndAnswer.assessmentItem.total_items) * changedItemAndAnswer.assessmentItem.assessment_config.items.length;
      screen.topic_item_info.measure = {
        ...screen.topic_item_info.measure,
        efficacy_factor: new_efficacy,
        grade_points: new_efficacy + screen.topic_item_info.measure.participation_factor,
      }
    }

    console.log('new topic info', screen.topic_item_info);

    if (changedItemAndAnswer.assessmentItem.assessment_config) {
      screen.topic_item_info.assessment_config =
        changedItemAndAnswer.assessmentItem.assessment_config;
    }
    screens.push(screen);
    counter++;
    console.log("check 1.2 ");
  });
  console.log("check 20");

  return { screens, screens_order, screenAnswers, haltedScreenItems };
}

export const getSolutionFromItem = ({ item, topicItemInfo, assessment_config }) => {
  //no solution for ite_type which is of type 'learn'
  if (
    typeof item === "undefined" ||
    item == null ||
    item.type == ITEM_TYPE.LEARN
  ) {
    return {};
  }
  const subtype = item.subtype;
  if (subtype === "diagram") {
    console.log('diagram content', item.content);
    let scoring = { ...item.content.scoring };
    let eval_type;
    if (assessment_config?.allowed_activities_for_beam?.length === 1) {
      eval_type = assessment_config.activity_mode === 'labelling' ? 'string_match':'id_match';
    }
    Object.keys(scoring).forEach((key) => {
      const termObj = item.content.terms.find((data) => data.term.id === scoring[key].answer);
      scoring[key].value = termObj.term.value;
      scoring[key].defAnswer = termObj.defn.id;
      scoring[key].defValue = termObj.defn.value;
      scoring[key].eval_type = eval_type || topicItemInfo.assessment_config.activity_mode === 'labelling' ? 'string_match':'id_match';
    });

    return scoring;
  } else if (subtype === "matching") {
    return matchingSolution(item, topicItemInfo);
  } else if (subtype === "sorting") {
    const itemSolution = {};
    const sortedElements = item.content.sortedElements;
    for (let onePairIndex in sortedElements) {
      itemSolution[sortedElements[onePairIndex].id] = {
        correct_answers: [parseInt(onePairIndex) + 1],
        eval_type: "number_match"
      };
    }
    return itemSolution;
  } else if (subtype === "fib2") {
    const itemSolution = {};
    const answers = item.content.answers;
    for (let singleAns of answers) {
      itemSolution[singleAns.challengeId] = {
        correct_answers: [{ id: singleAns.id, value: singleAns.value }],
        weight: singleAns.weight,
        metaData: singleAns.metadata
      };
      if (singleAns.acceptable_answers?.length > 0)
        itemSolution[singleAns.challengeId].acceptable_answers =
          singleAns.acceptable_answers;
      if (singleAns.distractors?.length > 0)
        itemSolution[singleAns.challengeId].distractors = singleAns.distractors;
      if (singleAns?.correct_feedback?.value?.length > 0)
        itemSolution[singleAns.challengeId].correct_feedback =
          singleAns.correct_feedback.value;
      if (singleAns?.incorrect_feedback?.value?.length > 0)
        itemSolution[singleAns.challengeId].incorrect_feedback =
          singleAns.incorrect_feedback.value;
    }
    return itemSolution;
  } else if(subtype === "highlight") {
    return highlightSolution(item);
  } else {
    if (subtype === "mcq" || subtype === "msq"  || subtype === "fib") {
      let optionsFeedbacks=[];

        if(typeof item.content!=="undefined" && typeof item.content.answers!=="undefined" && item.content.answers.length>0){
          item.content.answers.map((tempAnswer)=>{
            Object.keys(tempAnswer).map((tempKey)=>{
              if(tempKey==="feedbackvalue"){
                let tempFeedback={};
                tempFeedback["value"]=tempAnswer[tempKey];
                tempFeedback["optionId"]=tempAnswer.id;
                tempFeedback.type="optionfeedback";
                optionsFeedbacks.push(tempFeedback);
              }
            })

          })
        }
        let distractors=[];
        if(item.content?.distractors?.length>0){
          item.content.distractors.map((tempDistractor)=>{

            Object.keys(tempDistractor).map((tempKey)=>{
              if(tempKey==="feedbackvalue"){
                let tempFeedback={};
                tempFeedback["value"]=tempDistractor[tempKey];
                tempFeedback["optionId"]=tempDistractor.id;
                tempFeedback.type="optionfeedback";
                optionsFeedbacks.push(tempFeedback);
              }
            })

          })
        }
        let finalFeedback=[];
        if(typeof item.content.feedbacks!=="undefined")
          finalFeedback=[...item.content.feedbacks,...optionsFeedbacks]
        else
          finalFeedback=optionsFeedbacks;
        return { answers:item.content.answers || [], feedbacks:finalFeedback};
    }
  }
  return {};
};

export function checkBeamExists(beams, beamSource, course, section) {
  console.log("in checkBeamExists", beams, beamSource, course, section);
  //Note component is a quiz or slides
  const componentId = beamSource.component.uuid;
  console.log("componentId", componentId);
  const componentKey = beamSource.type === "quiz" ? "quizzes" : "slidedeck";
  const courseSections =
    typeof course.classSections !== "undefined" ? course.classSections : [];

  const sectionArray = [];
  if (Array.isArray(section)) {
    sectionArray = section;
  } else {
    if (section === "all") {
      courseSections.forEach(courseSection => {
        sectionArray.push(courseSection.sectionCode);
      });
    } else {
      sectionArray.push(section);
    }
  }

  //first lets filter the beams which are using the current quiz/slide
  //and are not completed
  const beamsUsingComponent = beams.filter(beam => {
    return ["initialized", "open", "inprogress", "paused"].includes(
      beam.state.status
    ) && beam.components_used[componentKey]
      ? beam.components_used[componentKey].includes(componentId)
      : false;
  });

  console.log("beamsUsingComponent", beamsUsingComponent);

  if (beamsUsingComponent.length == 0) {
    return false;
  }

  console.log("sectionArray, ", sectionArray);
  let foundBeam = false;
  if (course && course.courseId && courseSections.length > 0) {
    console.log(" in course block");

    beamsUsingComponent.forEach(beam => {
      let beamExist = true;
      const beamSectionCodes = beam.section_codes;
      console.log("beamSectionCodes, ", beamSectionCodes);

      sectionArray.forEach(section => {
        if (beamSectionCodes.indexOf(section) >= 0) {
          console.log("section ", section, "found in ", beamSectionCodes);
        } else {
          beamExist = false;
          console.log("section ", section, "not found in ", beamSectionCodes);
        }
      });
      if (beamExist) {
        beamSectionCodes.forEach(beamSectionCode => {
          if (sectionArray.includes(beamSectionCode)) {
            console.log(
              "beamSectionCode ",
              beamSectionCode,
              "found in ",
              sectionArray
            );
          } else {
            console.log(
              "beamSectionCode ",
              beamSectionCode,
              "not found in ",
              sectionArray
            );
            beamExist = false;
          }
        });
      }
      console.log("move to next beam");
      if (beamExist) {
        foundBeam = true;
      }
    });
    console.log("beamFound", foundBeam);
    return foundBeam;
    //if found - would not be reaching here
  } else {
    console.log(" in no-course block");
    return beamsUsingComponent.some(beam => {
      return beam.components_used[componentKey]
        ? beam.components_used[componentKey].includes(componentId)
        : false;
    });
  }
}

export const getCurrentBeamById = (beams, beamId) => {
  const filteredBeams = beams.filter(beam => beam.id === beamId);
  console.log("filteredBeams", filteredBeams);
  if (filteredBeams && filteredBeams.length >= 1) {
    return filteredBeams[0];
  }
  return null;
};

export const getCurrentBeam = (beams, beamSource, course, section) => {
  console.log("in getCurrentBeam", beams, beamSource, course, section);

  //Note component is a quiz or slides
  const componentId = beamSource.component.uuid;
  console.log("componentId", componentId);
  const componentKey = beamSource.type === "quiz" ? "quizzes" : "slidedeck";
  const courseSections =
    typeof course.classSections !== "undefined" ? course.classSections : [];

  const sectionArray = [];
  if (Array.isArray(section)) {
    sectionArray = section;
  } else {
    if (section === "all") {
      courseSections.forEach(courseSection => {
        sectionArray.push(courseSection.sectionCode);
      });
    } else {
      sectionArray.push(section);
    }
  }

  //first lets filter the beams which are using the current quiz/slide
  //and are not completed
  const beamsUsingComponent = beams.filter(beam => {
    return ["initialized", "open", "inprogress", "paused"].includes(
      beam.state.status
    ) && beam.components_used[componentKey]
      ? beam.components_used[componentKey].includes(componentId)
      : false;
  });

  if (beamsUsingComponent.length == 0) {
    //no beam having the component sent
    return null;
  }

  console.log("beamsUsingComponent", beamsUsingComponent);

  let activeBeam = null;
  if (course && course.courseId && courseSections.length > 0) {
    console.log(" in course block");

    beamsUsingComponent.forEach(beam => {
      let beamExist = true;
      const beamSectionCodes = beam.section_codes;
      sectionArray.forEach(section => {
        if (beamSectionCodes.indexOf(section) >= 0) {
          console.log("section ", section, "found in ", beamSectionCodes);
        } else {
          beamExist = false;
          console.log("section ", section, "not found in ", beamSectionCodes);
        }
      });
      if (beamExist) {
        beamSectionCodes.forEach(beamSectionCode => {
          if (sectionArray.includes(beamSectionCode)) {
            console.log(
              "beamSectionCode ",
              beamSectionCode,
              "found in ",
              sectionArray
            );
          } else {
            console.log(
              "beamSectionCode ",
              beamSectionCode,
              "not found in ",
              sectionArray
            );
            beamExist = false;
          }
        });
      }
      console.log("move to next beam");
      if (beamExist) {
        activeBeam = beam;
      }
    });
    return activeBeam;
  } else {
    console.log(" in no-course block");
    // activeBeam =  beams.filter(checkActiveBeam, componentKey, componentId)

    // for (let index in beamsUsingComponent) {
    //     if (typeof beamsUsingComponent[index].section_codes == 'undefined' ||  beamsUsingComponent[index].section_codes.length == 0){
    //         activeBeam = checkActiveBeam(beams[index], componentKey, componentId);
    //         if (activeBeam) {
    //             return beams[index];
    //         }
    //     }else{
    //         return null;
    //     }
    // }
    return beamsUsingComponent[0];
  }

  if (Array.isArray(activeBeam)) {
    return null;
  }
  return activeBeam;
};

const checkActiveBeam = (beam, componentKey, componentId) => {
  return (
    isComponentUsed(beam, componentKey, componentId) &&
    ["initialized", "open", "inprogress", "paused"].includes(beam.state.status)
  );
};

const isComponentUsed = (beam, componentKey, componentId) => {
  if (typeof beam.components_used[componentKey] != "undefined") {
    return beam.components_used[componentKey].includes(componentId);
  }
  return false;
};

export const getInstructorBeamChannelAddress = currentBeam => {
  if (currentBeam) {
    return "beams_" + currentBeam.id + "_i";
  }
  return null;
};

export const getBeamIdFromLocation = location => {
  const url = location.pathname;
  const urlPaths = url.split("?");
  const pathname = urlPaths[0];
  if (pathname) {
    let pathnames = pathname.split("/");
    if (pathnames.length > 1) {
      const beamsPathIndex = pathnames.indexOf("beams");
      if (beamsPathIndex >= 0) {
        return pathnames[beamsPathIndex + 1];
      }
    }
  }
  return null;
};

export const isBeamInLiveMode = location => {
  console.log("location", location);
  if (!location) return false;
  const search = location.search;
  console.log("search", search);

  const searchStrings = search.split("?");
  if (searchStrings.length < 2) {
    return false;
  }
  console.log("searchStrings", searchStrings);
  const queryParams = searchStrings[1];
  console.log("queryParams", queryParams);
  if (queryParams) {
    console.log("queryParams", queryParams);
    if (queryParams == "live") {
      return true;
    }
  }
  return false;
};

export function updateCurrentScreenInReducer(beamObject, action) {
  const response = action.response;
  const screenOid = beamObject.state.current_screen_oid;
  let currentScreen = getScreenByOid(beamObject.screens, screenOid);
  console.log("*************************************************************")
  console.log("currentScreen, response", currentScreen.id, response);
  if(response.entity==="screen")
    currentScreen["state"] = response.data;
  else if(response.entity==="screenTimer"){
    console.log("currentScreen_screentimer, response", currentScreen.id, response);
    console.log("*************************************************************")
    currentScreen=getScreenByOid(beamObject.screens, response.data.screen_oid);
    currentScreen["timer_info"] = response.data.data_value;
  }
  else if(response.entity==="multi_data"){
    if(typeof response.data.screen_oid!=="undefined"){
      currentScreen=getScreenByOid(beamObject.screens, response.data.screen_oid);
    }
    Object.keys(response.data).forEach(data_key => {
      if(data_key!=="screen_oid")
        currentScreen[data_key] = response.data[data_key];
    });
  }
  else
    currentScreen[response.data.data_key] = response.data.data_value;

  console.log("updateCurrentScreenInReducer currentScreen",currentScreen);
}

export function updateCurrentScreenWithNewContent(beamObject, action) {
  const response = action.requestInput;
  const screenOid = beamObject.state.current_screen_oid;
  // let currentScreen = getScreenByOid(beamObject.screens, screenOid);
  beamObject.screens = beamObject.screens.map(screen =>
    screen.oid === screenOid ? {...screen,item:response.item,topic_item_info:response.topic_item_info} : screen
  );

}

export const getActiveParticipantsCount = ({ participants }) => {
  let count = 0;
  Object.keys(participants).forEach(accountId => {
    if (participants[accountId].status === "active") {
      count++;
    }
  });
  return count;
};

export const isActionToFinishBeam = ({ updateObject }) => {
  if (
    typeof updateObject == "undefined" ||
    updateObject == null ||
    typeof updateObject["state.status"] == "undefined"
  ) {
    return false;
  }
  if (updateObject["state.status"] === "completed") {
    return true;
  }
  return false;
};

export const isActionToExitBeam = ({ updateObject }) => {
  if (
    typeof updateObject == "undefined" ||
    updateObject == null ||
    typeof updateObject["state.status"] == "undefined"
  ) {
    return false;
  }
  if (updateObject["state.status"] === "paused") {
    return true;
  }
  return false;
};

export const shuffleArray = array => {
  const newArray = [...array];
  var currentIndex = newArray.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex]
    ];
  }

  return newArray;
};


