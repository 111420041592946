import { isValid } from "data/validators/valUtils";

const checkIfValidImageAndReturnImage = (attachments, attachedId) => {
  if (!attachments) {
    return false;
  }
  const imageJson = attachments.find(attachment => {
    return (
      typeof attachment.attachmentInfo != "undefined" &&
      attachment.attachmentInfo.entity === "option" &&
      attachment.attachmentInfo.attachedId === attachedId
    );
  });

  if (typeof imageJson === "undefined" || Object.keys(imageJson).length <= 0) {
    return false;
  } else {
    return imageJson;
  }
};

export const isValidMatching = ({ item }) => {
  if (!isValid(item) || !isValid(item.content)) {
    return false;
  }
  const validationResult = getValidOptionsCount({
    options: item.content.pairs,
    attachments: item.attachments,
    distractors: item.content.distractors
  });

  if (!validationResult) {
    return false;
  } else {
    const { noOfValidOptions, noOfValidDistractors } = validationResult;
    if (noOfValidOptions + noOfValidDistractors >= 2 && noOfValidOptions) {
      return true;
    } else return false;
  }
};

const getValidOptionsCount = ({ options, attachments, distractors }) => {
  let noOfValidOptions = 0;
  let noOfValidDistractors = 0;
  let allAreValid = true;

  const leftItemsSet = new Set();
  const rightItemsSet = new Set();

  if (isValid(options)) {
    allAreValid = options.every(option => {
      if (isValid(option)) {
        if (isValid(option.lValue) && isValid(option.rValue)) {
          if (
            (isValid(option.lValue.value) ||
              checkIfValidImageAndReturnImage(attachments, option.lValue.id)) &&
            (isValid(option.rValue.value) ||
              checkIfValidImageAndReturnImage(attachments, option.rValue.id))
          ) {
            if (leftItemsSet.has(option.lValue.value)) {
              return false;
            } else if(option.lValue.value !== '') {
              leftItemsSet.add(option.lValue.value);
            }
            if (rightItemsSet.has(option.rValue.value)) {
              return false;
            } else if(option.rValue.value !== '') {
              rightItemsSet.add(option.rValue.value);
            }
            ++noOfValidOptions;
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

  if (allAreValid && isValid(distractors)) {
    allAreValid = distractors.every(distractor => {
      if (isValid(distractor)) {
        if (
          isValid(distractor.value) ||
          checkIfValidImageAndReturnImage(attachments, distractor.id)
        ) {
          if (rightItemsSet.has(distractor.value)) {
            return false;
          } else if(distractor.value !== '') {
            rightItemsSet.add(distractor.value);
          }
          ++noOfValidDistractors;
          return true;
        } else return false;
      } else return false;
    });
  }

  if (allAreValid) {
    return { noOfValidOptions, noOfValidDistractors };
  } else return false;
};
