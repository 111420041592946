export const ITEM_ASSESSMENT_TYPE = 'assessment';
export const ITEM_STUDY_TYPE = 'study';

export const ITEM_SUBTYPE = {
    FIB: 'fib',
    MCQ: 'mcq',
    MSQ: 'msq',
    TERM: 'term',
    DIAGRAM: 'diagram',
    MATCHING: 'matching',
    SORTING: 'sorting',
    FIB2:'fib2',
    WHITEBOARD: 'whiteboard',
    HIGHLIGHTING: 'highlight'
}