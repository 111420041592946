import React, { useState, useEffect } from "react";
import { Upload, Icon, message } from 'antd';
import { Progress } from 'antd';

import request from 'util/ApiUtils'
import { TOPICS_ATTACHMENT_API } from "constants/ApiConstants";

import CloseIcon from "../../../assets/images/lrnr-icons/close_24px.svg";
const Dragger = Upload.Dragger;

export const LrnrDragDropUpload = ({ accept='-', abortUpload,  data, uploadPercent, convertionPercent, uploadRequestFn, uploadStatus, message, closeMe }) => {

	//const [uploadInProgress, setUploadInProgress] = useState(false);
	// console.log("In LrnrDragDropComponent status",uploadStatus);
	// console.log("In LrnrDragDropComponent uploadPercent",uploadPercent)
	// console.log("In LrnrDragDropComponent convertionPercent",convertionPercent)
	const onUpload = (info) => {
		console.log(info);

		//setUploadInProgress(true);
	}

	return (
		<div className="gx-lrnr-general-file-upload-popup">
			<div className={`gx-lrnr-general-file-upload-popup-inner-container`}>
				{(uploadStatus==="start")?(
					<Dragger accept={accept} multiple={false} name='file' showUploadList={true}  customRequest={uploadRequestFn}>
						<p className="ant-upload-drag-icon">
							<Icon type="inbox" />
						</p>
						<React.Fragment>
							<p className="ant-upload-text h5-lrnr-typo-new">Click or drag file to this area to upload</p>
							<p className="ant-upload-hint text4-lrnr-typo-new">PPT, PPTX, PDF files only</p>
						</React.Fragment>
					</Dragger>):(
					(uploadStatus==="uploading")?(
						<div className="gx-lrnr-general-file-upload-popup-message-view">
							<p className="gx-lrnr-general-file-upload-popup-message-text h5-lrnr-typo-new">Uploading Slide Deck...Please Hold</p>
							<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new">Please Donot close the window,</p>
							<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new">Upload will fail</p>
						</div>
						
					):(uploadStatus==="uploadingfailed")?(
							<div className="gx-lrnr-general-file-upload-popup-message-view">
								<p className="gx-lrnr-general-file-upload-popup-message-text h5-lrnr-typo-new error">Uploading Slide Deck...Failed</p>
								<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new error">{message}</p>
								<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new error">Please close the window and try again.</p>
							</div>
						):(
							(uploadStatus==="converting" || uploadStatus==="convertionsuccess")?(
							<div className="gx-lrnr-general-file-upload-popup-message-view">
								<p className="gx-lrnr-general-file-upload-popup-message-text h5-lrnr-typo-new">Converting Slide Deck into Lrnr Slides...Please Hold</p>
								<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new">It will be safe to close the window.</p>
								<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new">You will be able to see the deck once it is ready</p>
							</div>
							):(
								(uploadStatus==="convertionfailed")?(
									<div className="gx-lrnr-general-file-upload-popup-message-view">
										<p className="gx-lrnr-general-file-upload-popup-message-text h5-lrnr-typo-new error">Converting Slide Deck into Lrnr Slides...Failed</p>
										<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new error">Please try after sometime.</p>
										
									</div>
								):(
									(uploadStatus==="completed")?(
										<div className="gx-lrnr-general-file-upload-popup-message-view">
											<p className="gx-lrnr-general-file-upload-popup-message-text h5-lrnr-typo-new">Slide Deck Creation Completed</p>
											<p className="gx-lrnr-general-file-upload-popup-message-hint text4-lrnr-typo-new">Please close the window.</p>
											
										</div>
									):(null)
								)
						)
					)
				)}
				
				{(uploadStatus!=="start")?(
					<div className={`gx-lrnr-general-file-upload-popup-status-view`}>
						<div>Step {(uploadStatus==="uploading")?(1):(2)} of 2</div>
						<div className={`gx-lrnr-general-file-upload-popup-status-progress-bar`}>
							<div className={`gx-lrnr-general-file-upload-popup-status-progress` } style={{width:((uploadStatus==="uploading"|| uploadStatus==="uploadingfailed")?uploadPercent:convertionPercent)+"%"}}>
							</div>
						</div>
					</div>
				):(null)}
				{/*<Progress percent={percent}/>*/}

				<div className={`gx-lrnr-general-file-upload-popup-footer`}>
					<button onClick={abortUpload} type="button" className="ant-btn gx-lrnr-general-file-upload-popup-footer-btn text3-lrnr-typo-new">
						<img className="gx-lrnr-general-img-icon-title-btn-general-icon" src={CloseIcon} alt="close" />
						{(uploadStatus==="start" || uploadStatus==="uploading")?(<span>Cancel Upload</span>):(<span>Close Window</span>)}
						
					</button>
				</div>
			</div>
		</div>
	)
}
export default LrnrDragDropUpload;