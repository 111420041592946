import { isValid } from "data/validators/valUtils";
import { ITEM_SUBTYPE } from "constants/DataModelConstants";
import { isValidMcq } from "./item/mcqValidator";
import { isValidMsq } from "./item/msqValidator";
import { isValidDiagram } from "./item/diagramValidator";
import { isValidMatching } from "./item/matchingValidator";
import { isValidSorting } from "./item/sortingValidator";
import { isValidFib2 } from "./item/fib2Validator";
import { isValidHighlight } from "./item/highlight";

export const isQuizBeammable = ({ topic, items }) => {
  console.log("in isQuizBeammable ", topic, items);
  if (!isValid(topic)) {
    return false;
  }
  if (!Array.isArray(items)) {
    return false;
  }
  let validItemCount = 0;
  items.forEach(item => {
    if (isItemBeamable({ topic, item })) {
      validItemCount++;
    }
  });
  console.log("validItemCount", validItemCount);
  if (validItemCount >= 1) {
    return true;
  }
  return false;
};

export const isItemBeamable = ({ topic, item }) => {
  if (isValid(item)) {
    switch (item.subtype) {
      case ITEM_SUBTYPE.MCQ:
        if (isValidMcq({ topic, item })) {
          return true;
        } else {
          return false;
        }

      case ITEM_SUBTYPE.MSQ:
        if (isValidMsq({ topic, item })) {
          return true;
        } else {
          console.log("invalid msq", item);
          return false;
        }

      case ITEM_SUBTYPE.FIB:
        if (isValid(item.content) && isValid(item.content.stimulus)) {
          return true;
        } else {
          return false;
        }

      case ITEM_SUBTYPE.DIAGRAM:
        if (isValidDiagram({ topic, diagramItem: item })) {
          return true;
        } else {
          return false;
        }
      case ITEM_SUBTYPE.MATCHING:
        return isValidMatching({ item });
      case ITEM_SUBTYPE.SORTING:
        return isValidSorting({ item });
      case ITEM_SUBTYPE.FIB2:
        return isValidFib2({item});
      case ITEM_SUBTYPE.HIGHLIGHTING:
        return isValidHighlight({item});
      case ITEM_SUBTYPE.WHITEBOARD:
        return true;
    }
  } else {
    return false;
  }
};

export const isGradable = beamSource => {
  let gradableDetails = {};
  gradableDetails.isEfficacy = false;
  gradableDetails.isParticipation = false;
  gradableDetails.isTotallyGradable = false;
  if (
    typeof beamSource.componentItems !== "undefined" &&
    beamSource.componentItems !== null &&
    typeof beamSource.component.items !== "undefined"
  ) {
    beamSource.component.items_order.forEach((itemId, index) => {
      console.log("index", index);
      console.log("item", itemId);
      let theItemData = getTheItem(beamSource, itemId);
      if (
        typeof theItemData !== "undefined" &&
        typeof theItemData.miniItem !== "undefined" &&
        typeof theItemData.fullItem !== "undefined"
      ) {
        if (
          isItemBeamable({
            topic: beamSource.component,
            item: theItemData.fullItem
          }) &&
          typeof theItemData.miniItem.measure !== "undefined" &&
          Object.keys(theItemData.miniItem.measure).length > 0
        ) {
          if (theItemData.miniItem.measure.efficacy_factor > 0)
            gradableDetails.isEfficacy = true;
          if (theItemData.miniItem.measure.participation_factor > 0)
            gradableDetails.isParticipation = true;
          if (gradableDetails.isEfficacy && gradableDetails.isParticipation)
            return false;
        }
      }
    });
  }
  if (gradableDetails.isEfficacy || gradableDetails.isParticipation)
    gradableDetails.isTotallyGradable = true;

  return gradableDetails;
};

const getTheItem = (data, theId) => {
  let theMiniItem = data.component.items[theId];
  let theFullItem = data.componentItems.find(tempComponentItem => {
    return tempComponentItem.uuid === theMiniItem.uuid;
  });
  return { miniItem: theMiniItem, fullItem: theFullItem };
};
