import { 
    SETUP_ROOM_NODE, 
    SETUP_ROOM_NODE_SUCCESS, 
    SETUP_ROOM_NODE_FAILURE,
    DELETE_ROOM_NODE,
    DELETE_ROOM_NODE_SUCCESS,
    DELETE_ROOM_NODE_FAILURE,
    LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS,
    UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS,
    EXIT_ROOM_NODE,
} from 'constants/ActionTypes';

export const setupRoomNodeAction = (requestInput) => {
    console.log('action to setup room node', requestInput)
    return {
        type: SETUP_ROOM_NODE,
        requestInput,
    }
}

export const setupRoomNodeSuccess = ({requestInput, response}) => {
    return {
        type: SETUP_ROOM_NODE_SUCCESS,
        requestInput,
        response,
    }
}

export const setupRoomNodeFailure = ({requestInput, err}) => {
    return {
        type: SETUP_ROOM_NODE_FAILURE,
        requestInput,
        err,
    }
}

export const listenLiveRoomParticipantsAction = (requestInput) => {
    console.log('checkinglistener action in room', requestInput);
    return {
        type: LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS,
        requestInput,
    }
}

export const updateLiveRoomParticipantsStatus = ({requestInput, response}) => {
    return {
        type: UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS,
        requestInput,
        response,
    }
}

export const exitRoomNodeAction = (requestInput) => {
    return {
        type: EXIT_ROOM_NODE,
        requestInput,
    }
}

export const deleteRoomNodeAction = (requestInput) => {
    return {
        type: DELETE_ROOM_NODE,
        requestInput,
    }
}

export const deleteRoomNodeSuccess = ({requestInput, response}) => {
    return {
        type: DELETE_ROOM_NODE_SUCCESS,
        requestInput,
        response,
    }
}

export const deleteRoomNodeFailure = ({requestInput, err}) => {
    return {
        type: DELETE_ROOM_NODE_FAILURE,
        requestInput,
        err,
    }
}