export const slidedeckConversionStages  = {
  _SLIDEDECK_CONVERSION_STAGE1_PASS : "SLIDEDECK_CONVERSION_STAGE1_PASS",
  _SLIDEDECK_CONVERSION_STAGE1_FAIL : "SLIDEDECK_CONVERSION_STAGE1_FAIL",
  _SLIDEDECK_CONVERSION_STAGE2_PASS : "SLIDEDECK_CONVERSION_STAGE2_PASS",
  _SLIDEDECK_CONVERSION_STAGE2_FAIL : "SLIDEDECK_CONVERSION_STAGE2_FAIL",
  _SLIDEDECK_CONVERSION_STAGE3_PASS : "SLIDEDECK_CONVERSION_STAGE3_PASS",
  _SLIDEDECK_CONVERSION_STAGE3_FAIL : "SLIDEDECK_CONVERSION_STAGE3_FAIL",
  _SLIDEDECK_CONVERSION_STAGE4_PASS : "SLIDEDECK_CONVERSION_STAGE4_PASS",
  _SLIDEDECK_CONVERSION_STAGE4_FAIL : "SLIDEDECK_CONVERSION_STAGE4_FAIL",
  _SLIDEDECK_CONVERSION_STAGE5_PASS : "SLIDEDECK_CONVERSION_STAGE5_PASS",
  _SLIDEDECK_CONVERSION_STAGE5_FAIL : "SLIDEDECK_CONVERSION_STAGE5_FAIL",
  _SLIDEDECK_CONVERSION_FINAL_STAGE_PASS : "SLIDEDECK_CONVERSION_FINAL_STAGE_PASS",
  _SLIDEDECK_CONVERSION_FINAL_STAGE_FAIL : "SLIDEDECK_CONVERSION_FINAL_STAGE_FAIL"
}