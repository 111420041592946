import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';

import { LrnrDragDropUpload } from 'components/lrnr/LrnrDragDropUpload';
import { TopicAttachmentApi } from 'api/topic/topicAttachmentApi';
import { SLIDE_LIST_CONFIG } from './list';
import { addedItemActionCreator, importListItemSuccess } from 'containers/Main/List/actions';
import { slidedeckConversionStages } from 'constants/NotificationConstants';
import { Progress } from 'antd';


const { requestHandle, createTopicFromSlidedeck } = TopicAttachmentApi();

const SlideDeckUpload = ({ closeMe, dispatch, appBootstrap, firebase, course }) => {

  const [uploadStatus, setUploadStatus] = useState("start");
  const [message, setTheMessage] = useState("");
  const [jobId, setTheJobId] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [convertionPercent, setConvertionPercent] = useState(0);
  console.log("appBootstrap in upload", appBootstrap);

  

  const abortUpload = () => {
    requestHandle.abort();
    closeMe();
  }

  const uploadSlidedeck = (props) => {

    const progressListener = ({ percent }) => {
      setUploadPercent(percent);
    }

    setUploadStatus("uploading")
    // this.setState({ imageBeingLoaded: true, showDiagramControls: false });
    const attachmentInfo = { type: 'ppt', entity: 'slidedeck' };
    const responsePromise = createTopicFromSlidedeck({ slidedeckFile: props.file, attachmentInfo, course,  progressListener })

    console.log("responsePromise ", responsePromise);
    responsePromise.then(
      (json) => {
        console.log("callApiAsync response ", json);
        if (json){
          if(json.status == 'success') {
            setUploadStatus("converting")
            setTheJobId(json.body.job_refs[0].id);
            const actionInfo = {
              item: json.body,
              itemType: 'topic',
              itemIndex: 99999
            }
            dispatch(addedItemActionCreator(actionInfo, SLIDE_LIST_CONFIG))
          }
          else{
            setUploadStatus("uploadingfailed");
            //INVALID_PRENSENTATION_FORMAT
            setTheMessage("Invalid Presentation format, accepted formats are (PPT, PPTX, PDF) only")
          }
        }
      }
    ).catch(error => {
      console.log("error uploading ", error)
    })
  }
  

  let theUserid = "uid_" + firebase.userSignature.accountId + "_i";
  if (jobId !== null && typeof appBootstrap.appChannelMessages !== "undefined" && typeof appBootstrap.appChannelMessages[theUserid] !== "undefined" && appBootstrap.appChannelMessages[theUserid].length > 0) {

    var theFinalJob = appBootstrap.appChannelMessages[theUserid][appBootstrap.appChannelMessages[theUserid].length - 1];
    console.log("FinalJob", appBootstrap.appChannelMessages[theUserid].length, theFinalJob.code);
    if (theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE2_FAIL || theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE3_FAIL || theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_FAIL) {
      console.log(theFinalJob.code)
      if (uploadStatus !== "convertionfailed")
        setUploadStatus("convertionfailed");
    }
    else if (theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_PASS) {
      console.log(slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_PASS)
      if (uploadStatus !== "convertionsuccess")
        setUploadStatus("convertionsuccess");
    }
    else if (theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_FINAL_STAGE_PASS) {
      console.log(slidedeckConversionStages._SLIDEDECK_CONVERSION_FINAL_STAGE_PASS)
      if (uploadStatus !== "completed") {
        setUploadStatus("completed");
        // dispatch(importListItemSuccess(theFinalJob.data, SLIDE_LIST_CONFIG));
        closeMe();
      }
    }
    if(theFinalJob.code ===slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE1_PASS || theFinalJob.code ===slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE2_PASS|| theFinalJob.code ===slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE3_PASS|| theFinalJob.code ===slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_PASS){
      let stage=theFinalJob.code.split("_PASS");
      stage=stage[0].split("SLIDEDECK_CONVERSION_STAGE");
      let thePercent=parseInt(stage[1])*25;
      console.log("convertionPercent to set",thePercent);
      if(thePercent!==convertionPercent)
        setConvertionPercent(thePercent);
    }

  }

  const acceptFileTypes = ".ppt, .pptx, .pdf";

  return (
    <div className="gx-lrnr-general-file-upload-popup">
      <LrnrDragDropUpload accept={acceptFileTypes} abortUpload={abortUpload} uploadPercent={uploadPercent} convertionPercent={convertionPercent} closeMe={closeMe} uploadRequestFn={uploadSlidedeck} uploadStatus={uploadStatus} message={message} />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = (state) => {
  console.log("STATE in SlideDeckUpload", state);
  const { appBootstrap, firebase, course} = state;
  return { appBootstrap, firebase, course }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(SlideDeckUpload);