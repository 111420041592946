import { FB_SIGNIN_SUCCESS, FB_SIGNIN_FAILURE, FETCH_FB_AUTH_TOKEN_SUCCESS, FETCH_FB_AUTH_TOKEN_FAILURE } from "constants/FirebaseConstants";
import produce from 'immer';


const INIT_STATE = {
  userSignature: JSON.parse(window.sessionStorage.getItem('userSignature')),
  fbAuthToken: '',
  // roles: [],
  fbHandle: null,
  isAuthenticated: false,
  auth: null,
  firestore: null,
  beamsCollection: null,
  beamResultsCollection: null,
  firebaseConfig: null,
};


export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_FB_AUTH_TOKEN_SUCCESS:
        console.log("In FETCH_FB_AUTH_TOKEN_SUCCESS reducer", state, action)
        draft.fbAuthToken = action.response.customToken
        break;
      case FETCH_FB_AUTH_TOKEN_FAILURE:
        console.log("In FETCH_FB_AUTH_TOKEN_FAILURE reducer", state, action)
        break;
      case FB_SIGNIN_SUCCESS:
        console.log("In FB_SIGNIN_SUCCESS reducer", state, action)
        console.log("action.signInResponse.firebase", action.signInResponse.firebase);
        draft.isAuthenticated = true;
        // state= { ...state, fbHandle: action.signInResponse.fbHandle};
        // draft.fbHandle = action.signInResponse.fbHandle;
        draft.roomsDatabase = action.signInResponse.roomsDatabase;
        draft.clockSkew = action.signInResponse.clockSkew;
        draft.auth = action.signInResponse.auth;
        // const firestore = action.signInResponse.firebase.firestore()
        draft.firestore = action.signInResponse.firestore;
        draft.beamsCollection = action.signInResponse.firestore.collection("beams");
        draft.userBeamsCollection = action.signInResponse.firestore.collection("user_beams");
        draft.firebaseConfig = action.signInResponse.firebaseConfig;
        break;
      case FB_SIGNIN_FAILURE:
        console.log("In FB_SIGNIN_FAILURE reducer", state, action)
        break;
      default:
        return state;
    }
  });
