import { PATCH_ENTITY_TITLE, PATCH_ENTITY_DESC } from './constants';
import { ITEM_SUBTYPE_SLIDE, ITEM_SUBTYPE_WHITEBOARD, ITEM_SUBTYPE_MATCHING } from 'constants/ItemConstants';

export function validateItems({ items, quiz }) {
    if (typeof items == 'undefined' ) {
        return false;
    }
    return true;
}

export function updateQuizHeader(quiz, state, action) {
    if (typeof action.updatePayload.patchArray != 'undefined') {
        console.log("valid patchArray");
        action.updatePayload.patchArray.forEach((patchObject, index) => {
            console.log("patchObject index", patchObject);

            switch (patchObject.entity) {
                case PATCH_ENTITY_TITLE:
                        console.log("updating draft title");

                    quiz.title = patchObject.value;
                    break;
                case PATCH_ENTITY_DESC:
                    quiz.desc = patchObject.value;
                    break;
            }
        })
    }
}

export const computeQuizSummary = ({ items, itemsOrder }) => {
    let efficacyTotal = 0;
    let participationTotal = 0;
    let totalGradePoints = 0;
    if (itemsOrder) {
        itemsOrder.forEach((quizItemId) => {
            efficacyTotal += items[quizItemId].measure.efficacy_factor;
            participationTotal += items[quizItemId].measure.participation_factor;
            totalGradePoints += items[quizItemId].measure.grade_points;
        }
        )
    }
    return { efficacyTotal, participationTotal, totalGradePoints };
}

export const checkIfAssessmentCongifNeedsToBeChanged = item =>
  item.subtype !== ITEM_SUBTYPE_SLIDE &&
  item.subtype !== ITEM_SUBTYPE_WHITEBOARD &&
  (!item.assessment_config ||
    Object.keys(item.assessment_config).length === 0 ||
    (item.subtype === ITEM_SUBTYPE_MATCHING &&
      !item.assessment_config.typeOfMatch));