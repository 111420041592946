
import { uuidv4, getBeamCode, generateRandomPasscode } from 'util/StringUtils'

export function defaultBeamObject() {
    return {
        uuid: uuidv4(),
        type: 'poll',
        code: getBeamCode(),
        title: 'Title',
        desc: 'Description',
        beam_mode: 'online',
        created_at: Date.now(),
        created_by: '',
        collaborator_uuids: [],
        deleted: false,
        institution_course: "",
        section_codes: [],
        components_used: {
            quizzes: [],
            slidedeck: [],
        },
        config: {
            transition_mode: 'manual',
            items_per_screen: 1,
            default_screen_mode: 'open'
        },
        state: {
            status: 'open',
            current_screen_oid: 1,
            enable_fullscreen: true,
            graded: true,
            use_efficacy: true,
            use_participation: false,
            use_passcode: true,
            passcode: generateRandomPasscode()
        }
    }
}