import { isValid } from '../valUtils';
import { getTopicItem } from 'util/topicUtils';
import { ACTIVITY_MODE } from 'constants/ItemConstants'

export const isValidDiagram = ({ topic, diagramItem }) => {
    if (!isValid(diagramItem)) {
        console.log("diagramItem is undefined or null")
        return false;
    }
    const type = diagramItem.type;
    const subtype = diagramItem.subtype;
    if (isValid(type) && isValid(subtype)) {
        if (subtype != 'diagram') {
            console.log("diagramItem doesn't have a valid subtype")
        }
        //if title is not valid - its not a valid diagram
        if (!isValid(diagramItem.title)) {
            return false;
        }

        const topicItem = getTopicItem({ topic, itemId: diagramItem.uuid })
        console.log('topic item', topic, diagramItem, topicItem);

        if (typeof topicItem == 'undefined' || typeof topicItem.assessment_config == 'undefined'){
            console.log("Invalid topicItem or assessmentConfig in topicItem", topicItem);
            return false;
        }

        const assessmentConfig = topicItem.assessment_config;
        const activityMode = assessmentConfig.activity_mode;

        if (assessmentConfig.promptSelection && diagramItem.content.areas?.length > 0) {
            return true;
        } else if (assessmentConfig.promptSelection && diagramItem.content.areas?.length === 0) {
            return false
        }

        console.log("activityMode", activityMode);
        if (activityMode === ACTIVITY_MODE.MATCHING) {
            console.log("validateMatchingDiagramActivity",validateMatchingDiagramActivity({ diagramItem }));
            return validateMatchingDiagramActivity({ diagramItem });
        }else if (activityMode === ACTIVITY_MODE.CLICK_ON_TARGET) {
            console.log("validateClickOnTargetActivity",validateClickOnTargetActivity({ diagramItem }));

            return validateClickOnTargetActivity({ diagramItem });
        } else if (activityMode === ACTIVITY_MODE.LABELLING || activityMode === ACTIVITY_MODE.DRAG_AND_DROP || activityMode === ACTIVITY_MODE.DROP_DOWN) {
            console.log('validateLabellingActivity', validateLabellingActivity({ diagramItem, count: activityMode === ACTIVITY_MODE.LABELLING ? 1 : 2 }))
            return validateLabellingActivity({ diagramItem, count: activityMode === ACTIVITY_MODE.LABELLING ? 1 : 2 })
        }
    } else {
        console.log("diagramItem doesn't have a valid type or subtype")
        return false
    }
    return true;
}

const validateMatchingDiagramActivity = ({ diagramItem }) => {
    //first check if image exists
    if (Array.isArray(diagramItem.attachments) && diagramItem.attachments.length > 0 && hasAtleastMinHotspotCount({ diagramItem, count: 2 })) {
        //In hotspot matching needs at least 2 terms or 2 defns should be there
        console.log('term and def', hasAtleastMinTermsCount({ diagramItem, count: 1 }), hasAtleastMinDefnsCount({diagramItem, count: 1}))
        if (hasAtleastMinTermsCount({ diagramItem, count: 2 }) || hasAtleastMinDefnsCount({diagramItem, count: 2})) {
            return true;
        } else {
            return false
        }
    }else{
        //at least two terms and two defns must be there
        return false;
    }
}


const validateClickOnTargetActivity = ({diagramItem}) => {

    //for click on target attachment image should be there
    if (Array.isArray(diagramItem.attachments) && diagramItem.attachments.length > 0 && hasAtleastMinHotspotCount({ diagramItem, count: 1 })) {
        //In hotspot matching needs at least 1 term or 1 defn should be there
        if (hasAtleastMinTermsCount({ diagramItem, count: 1 }) || hasAtleastMinDefnsCount({diagramItem, count: 1})) {
            return true;
        }else{
            console.log("atleast 1 term or 1 defn should be present");
            return false;
        }
    }else{
        console.log("An image must be present")
        return false;
    }
}

const validateLabellingActivity = ({diagramItem, count}) => {

    //for click on target attachment image should be there
    if (Array.isArray(diagramItem.attachments) && diagramItem.attachments.length > 0) {
        //In hotspot matching needs at least 1 term or 1 defn should be there
        if (hasAtleastMinTermsCount({ diagramItem, count, dontFilter: true }) || hasAtleastMinDefnsCount({diagramItem, count, dontFilter: true })) {
            return true;
        }else{
            console.log(`atleast ${count} term or ${count} defn should be present`);
            return false;
        }
    }else{
        console.log("An image must be present")
        return false;
    }
}

const hasAtleastMinHotspotCount = ({ diagramItem, count, dontFilterTarget }) => {

    if (Array.isArray(diagramItem.content.areas)) {
        const hotspotAreas = dontFilterTarget ? diagramItem.content.areas : diagramItem.content.areas.filter((area) => area.type !== 'target');
        if (hotspotAreas.length >= count) {
            return true
        } else {
            return false;
        }
    } else {
        console.log("hotspots not found");
        return false;
    }

}

const hasAtleastMinDefnsCount = ({ diagramItem, count, dontFilter }) => {
    if (diagramItem.content.terms_count) {
        const termAndDefns = diagramItem.content.terms;
        if (Array.isArray(termAndDefns)) {
            let countOfValidDefns = 0;

            if (termAndDefns.length >= count) {
                termAndDefns.forEach((termAndDefn) => {
                    const defn = termAndDefn.defn;
                    const areaKey = Object.keys(diagramItem.content.scoring).find((key) => diagramItem.content.scoring[key].defAnswer === defn.id);
                    const area = diagramItem.content.areas.find((area) => area.id === areaKey);
                    const isTarget = !dontFilter && area?.type === 'target'
                    console.log('is target', isTarget);
                    if (!isTarget && area?.type !== 'target' && isValid(defn) && isValid(defn.value)) {
                        countOfValidDefns++;
                    }
                })
                if (countOfValidDefns >= count) {
                    return true;
                } else {
                    console.log("Need atleast two valid defns")
                    return false;
                }
            } else {
                console.log("Need atleast two defns")
                return false;
            }
        } else {
            console.log("term & defn pairs not found");
            return false;
        }
    } else {
        console.log("terms_count is not found")
        return false;
    }

}

const hasAtleastMinTermsCount = ({ diagramItem, count, dontFilter }) => {
    console.log('term in val', diagramItem);
    if (diagramItem.content.terms_count) {
        const termAndDefns = diagramItem.content.terms;
        if (Array.isArray(termAndDefns)) {
            let countOfValidTerms = 0;

            if (termAndDefns.length >= count) {
                termAndDefns.forEach((termAndDefn) => {
                    const term = termAndDefn.term;
                    const areaKey = Object.keys(diagramItem.content.scoring).find((key) => diagramItem.content.scoring[key].answer === term.id);
                    const area = diagramItem.content.areas.find((area) => area.id === areaKey);
                    const isTarget = !dontFilter  &&  area?.type === 'target'
                    console.log('is target', isTarget);
                    if (!isTarget && isValid(term) && isValid(term.value)) {
                        countOfValidTerms++;
                    }
                })
                console.log("count of valid terms", countOfValidTerms);
                if (countOfValidTerms >= count) {
                    return true;
                } else {
                    console.log("Need atleast two valid terms")
                    return false;
                }
            } else {
                console.log("Need atleast two terms")
                return false;
            }
        } else {
            console.log("term & defn pairs not found");
            return false;
        }
    } else {
        console.log("terms_count is not found")
        return false;
    }

}