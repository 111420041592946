import { put, call } from 'redux-saga/effects';
import { updateScreenStateSuccess, updateCurrentScreenFailure, updateBeamStateSuccess, updateScreenAnnotationSuccess, updateScreenAnnotationFailure } from './actions';
import { getPatchedBeamFibItem, getPatchedBeamMcqItem } from './itemPatchUtils.js';
import request from 'util/ApiUtils';
import {getTheTimeElapsed} from 'util/AppUtil';
import { createScreenJson } from './utils';
import { getItemUpdateAPI, API_FAILURE_STATUS } from 'constants/ApiConstants';

import { updateData } from '../Item/utils';
import { ITEM_SUBTYPE_DIAGRAM, ITEM_SUBTYPE_FIB, ITEM_SUBTYPE_MCQ, ITEM_SUBTYPE_MSQ, ITEM_SUBTYPE_SLIDE, ITEM_SUBTYPE_WHITEBOARD } from 'constants/ItemConstants';
import fb from "firebase";
const clone = require('rfdc')();

export function* updateCurrentScreenAnnotations(action){
    const firebase = action.requestInput.firebase;
    let err = null;
    const requestInput = action.requestInput;
    const currentBeam = requestInput.currentBeam;
    const beamsCollection = firebase.beamsCollection;
    const screenOid = currentBeam.state.current_screen_oid;
    const currentScreen = getScreenByOid(currentBeam.screens, screenOid);
    if (!currentScreen) {
        const msg = "currentScreen could not be found in currentBeam with screenOid " + screenOid;
        console.log(msg);
        throw msg;
    };

    try {

        const screenFirestoreId = currentScreen.id;
        const newScreenData={}
        newScreenData["item.annotations"]=action.requestInput.updateObject.annotations;
        beamsCollection.doc(currentBeam.id).collection("screens")
            .doc(screenFirestoreId).update(newScreenData);
        const updatedData = {
            entity: "screen",
            data: {
                annotations: action.requestInput.updateObject.annotations,

            }
        }
        console.log("updatedData", updatedData);
        yield put(updateScreenAnnotationSuccess(requestInput, updatedData))
    } catch (err) {
        console.log("err updating beam state in the beam", err);
        yield put(updateScreenAnnotationFailure(requestInput, err));

    }
}

async function updateBeamAndScreen({id, firebase, screenPayload, updatedObject}) {
    const batch = firebase.firestore.batch();
    const beamsCollection = firebase.beamsCollection;
    let nextBeamRef = beamsCollection.doc(id);
    const screenRef = beamsCollection.doc(id).collection("screens").doc();
    console.log('screnn paaadas', screenPayload);
    screenPayload.id = screenRef.id;
    screenPayload.state = {status: 'open', view_state: 'open'}

    batch.set(screenRef, screenPayload)
    batch.update(nextBeamRef, updatedObject)

    const updatedScreenInfo = {
        screenId: screenRef.id,
        newScreenState: screenPayload.state
    }
    const navUpdate = { updateObject: updatedObject, screenStateUpdatedFlag: true, updatedScreenInfo, newScreenPayload: screenPayload }

    // Commit the batch
    await batch.commit().then(function () {
        console.log("add screen success");

    }).catch(function (error) {
        console.error("Error creating next item: ", error);
        throw error;
    });
    return navUpdate;
}

export function* createNewScreenAction(action) {
    console.log('in create new screen saga', action);
    const { requestInput } = action;
    const { firebase, currentBeam} = action.requestInput;
    const {id, uuid, screens, screens_order, state: {current_screen_oid}, created_by, components_used: {slidedeck} } = currentBeam;
    const oid = screens.length+1;
    const current_screen_index = screens_order.indexOf(current_screen_oid);
    const newScreenOrder = [...screens_order];
    newScreenOrder.splice(current_screen_index+1, 0, oid);
    console.log('individual reasda', id, uuid)
    const screenPayload = createScreenJson({docRefId: id, beam_uuid: uuid, oid, created_by, slidedeck})
    console.log('new screen payload', screenPayload);
    try {
        const updatedObject = {screens_order: newScreenOrder};
        updatedObject['state.current_screen_oid'] = oid;
        let navUpdate;
        yield navUpdate = yield call(updateBeamAndScreen, { id, firebase, screenPayload, updatedObject });
        console.log('after nav update', navUpdate);
        if (navUpdate) {
            navUpdate.isNewScreen = true;
            navUpdate.newScreensOrder = newScreenOrder
            yield put(updateBeamStateSuccess(requestInput, navUpdate))
        }
    } catch(err) {
        console.log('error creating screen', err)
    };
}

export function* updateCurrentScreenAction(action) {
    console.log("in updateCurrentScreenAction saga", action);
    const firebase = action.requestInput.firebase;
    let err = null;

    const requestInput = action.requestInput;
    const currentBeam = requestInput.currentBeam;
    const operation = requestInput.operation;
    const responseAggregate = requestInput.responseAggregate;
    let updatedData, updateObject, currentScreen, opValue, screenStatus, navUpdate;
    try {
        const screenOid = currentBeam.state.current_screen_oid;
        console.log('operation type', operation.type)
        switch (operation.type) {
            case 'state_change':
                const newStatus = operation.value;

                updatedData =  yield call(updateScreenState,{ requestInput, firebase, currentBeam, screenOid, status: newStatus, responseAggregate })
                yield put(updateScreenStateSuccess(requestInput, updatedData))
                break;
            case 'nav_change':
                opValue = operation.value;
                currentScreen = getScreenByOid(currentBeam.screens, screenOid);
                screenStatus = currentScreen.state.status;

                if (opValue == 'next') {
                    //if there is no right answer and currentStatus == review
                    //no need to goto answer state; need to goto the next screen
                    if (screenStatus === 'answer' || (screenStatus == 'review' && !hasCorrectAnswer({ screen: currentScreen })) || currentScreen.item.subtype===ITEM_SUBTYPE_SLIDE || currentScreen.item.subtype===ITEM_SUBTYPE_WHITEBOARD) {
                        //already in the last state...
                        //move the current_screen_oid to the next screen
                        // updateObject = gotoNextItem({ requestInput, firebase, currentBeam, screenOid, operation });
                        // if(typeof currentScreen.timer_info!=="undefined"){
                        //      updatedData = yield call(updateTheTimeElapsedInCurrentScreen,{firebase, requestInput,currentBeam,currentScreen, screenOid});
                        //     console.log("updateData",updatedData);
                        //     yield put(updateScreenStateSuccess(requestInput, updatedData))
                        // }
                        yield navUpdate = yield call(gotoNextItem, {requestInput, firebase, currentBeam, screenOid });
                        // const response = { updateObject }
                        if (navUpdate) {
                            yield put(updateBeamStateSuccess(requestInput, navUpdate))
                        }

                    } else {
                        //move current_screen status to the next one
                        const nextScreenStatus = getNextScreenStatus(currentScreen);
                        console.log("nextScreenStatus", nextScreenStatus);

                        updatedData = yield call(updateScreenState,{ firebase, currentBeam, screenOid, status: nextScreenStatus, responseAggregate })
                        yield put(updateScreenStateSuccess(requestInput, updatedData))
                    }
                } else if (opValue == 'prev') {
                    if (screenStatus === 'open' || currentScreen.item.subtype===ITEM_SUBTYPE_SLIDE || currentScreen.item.subtype===ITEM_SUBTYPE_WHITEBOARD) {
                        //move the current_screen_oid to the previous screen
                        if(typeof currentScreen.timer_info!=="undefined"){
                             updatedData = yield call(updateTheTimeElapsedInCurrentScreen,{firebase, requestInput,currentBeam,currentScreen, screenOid});
                            console.log("updateData",updatedData);
                            yield put(updateScreenStateSuccess(requestInput, updatedData))
                        }
                        yield navUpdate = yield call(gotoPrevItem, {requestInput, firebase, currentBeam, screenOid });
                        // updateObject = navUpdate.updateObject;
                        // const response = { updateObject }
                        console.log("navUpdate", navUpdate);
                        if (navUpdate) {
                            yield put(updateBeamStateSuccess(requestInput, navUpdate))
                        }
                    } else {
                        //move current_screen status to the next one
                        const prevScreenStatus = getPrevScreenStatus(currentScreen);
                        console.log("prevScreenStatus", prevScreenStatus);
                        updatedData = yield call(updateScreenState,{ firebase, currentBeam, screenOid, status: prevScreenStatus, responseAggregate })
                        yield put(updateScreenStateSuccess(requestInput, updatedData))
                    }
                }
                break;
            case 'screen_nav':
                opValue = operation.value;
                currentScreen = getScreenByOid(currentBeam.screens, screenOid);
                if (!currentScreen) {
                    const msg = "currentScreen could not be found in currentBeam with screenOid " + screenOid;
                    console.log(msg);
                    throw msg;
                };
                screenStatus = currentScreen.state.status;
                if(typeof currentScreen.timer_info!=="undefined"){
                     updatedData = yield call(updateTheTimeElapsedInCurrentScreen,{firebase, requestInput,currentBeam,currentScreen, screenOid});
                    console.log("updateData",updatedData);
                    yield put(updateScreenStateSuccess(requestInput, updatedData))
                }


                if (opValue == 'next') {
                    //already in the last state...
                    //move the current_screen_oid to the next screen
                    // updateObject = gotoNextItem({ requestInput, firebase, currentBeam, screenOid });
                    // const response = { updateObject }
                    // yield put(updateBeamStateSuccess(requestInput, response))
                    yield navUpdate = yield call(gotoNextItem, {requestInput, firebase, currentBeam, screenOid });
                    // const response = { updateObject }
                    if (navUpdate) {
                        yield put(updateBeamStateSuccess(requestInput, navUpdate))
                    }
                } else if (opValue == 'prev') {
                    //move the current_screen_oid to the previous screen
                    yield navUpdate = yield call(gotoPrevItem, {requestInput, firebase, currentBeam, screenOid });
                    // const response = { updateObject }
                    if (navUpdate) {
                        yield put(updateBeamStateSuccess(requestInput, navUpdate))
                    }
                } else if (opValue == 'index') {
                    let nextIndex=operation.nextIndex;
                    yield navUpdate = yield call(gotoIndexedItem, {requestInput, firebase, currentBeam, screenOid, nextIndex});
                    if (navUpdate) {
                        yield put(updateBeamStateSuccess(requestInput, navUpdate))
                    }
                }
                break;
            case 'timer_update':
                opValue = operation.value;
                let batch = firebase.firestore.batch();
                const beamsCollection = firebase.beamsCollection;
                currentScreen = getScreenByOid(currentBeam.screens, screenOid);

                console.log(currentScreen);
                if (!currentScreen) {
                    const msg = "currentScreen could not be found in currentBeam with screenOid " + screenOid;
                    console.log(msg);
                    throw msg;
                };

                const screenFirestoreId = currentScreen.id;
                console.log("screenFirestoreId_timer_update", screenFirestoreId);
                let beamRef=beamsCollection.doc(currentBeam.id);
                let screenRef = beamsCollection.doc(currentBeam.id).collection("screens").doc(screenFirestoreId)
                let patchedTimerInfo = clone(currentScreen.timer_info);

                if(typeof patchedTimerInfo.duration!=="undefined"){
                    let theTimeToBeupdated=currentScreen.topic_item_info.measure.timer_in_secs;
                    if(typeof patchedTimerInfo.totalDurationIncreased!=="undefined")
                        theTimeToBeupdated+=patchedTimerInfo.totalDurationIncreased;
                    theTimeToBeupdated+=parseInt(opValue);
                    if(theTimeToBeupdated>300){
                        const msg = "currentScreen reached the max timelimit in currentBeam with screenOid " + screenOid;
                        console.log(msg);
                        throw msg;

                    }
                    if(typeof patchedTimerInfo.time_elapsed!=="undefined"){
                        if(typeof patchedTimerInfo.history==="undefined")
                            patchedTimerInfo.history=[];
                        let tempObj={};
                        Object.keys(currentScreen.timer_info).forEach(key => {
                            if(key!=="history"){
                                tempObj[key]=currentScreen.timer_info[key];
                            }
                        })
                        patchedTimerInfo.history.push(tempObj);
                        patchedTimerInfo.start_time=fb.firestore.FieldValue.serverTimestamp();
                        patchedTimerInfo.duration-= patchedTimerInfo.time_elapsed;
                        delete patchedTimerInfo.time_elapsed;
                    }
                    patchedTimerInfo.duration+=parseInt(opValue);
                    if(typeof patchedTimerInfo.totalDurationIncreased==="undefined")
                        patchedTimerInfo.totalDurationIncreased=parseInt(opValue)
                    else
                        patchedTimerInfo.totalDurationIncreased+=parseInt(opValue);
                }
                else{
                    patchedTimerInfo.duration=opValue;
                }

                console.log(currentScreen.state);


                console.log("patchedTimerInfo", patchedTimerInfo);
                const newScreenState = {};
                if(currentScreen.state.status!=="open"){
                   newScreenState.state={};
                    newScreenState.state.status="open";
                    newScreenState.state.view_state="open";
                }

                newScreenState['timer_info'] = patchedTimerInfo;
                //beamsCollection.doc(currentBeam.id).collection("screens")
                //    .doc(screenFirestoreId).update(newScreenState);
                let newTimerInfo={};
                newTimerInfo.timer_info=patchedTimerInfo;

                screenRef.update(newScreenState)
                batch.update(beamRef, {});

                let updatedDoc = yield call(sendTheData,{batch, beamsCollection, currentBeam, "screenId":currentScreen.id, newTimerInfo});
                console.log("timer_update updatedDoc ",updatedDoc)
                if(updatedDoc){
                    newScreenState['timer_info'] = updatedDoc.data.data_value;
                    let tempUpdatedData = {
                        entity: "multi_data",
                        data: newScreenState
                    }

                   yield put(updateScreenStateSuccess(requestInput, tempUpdatedData));
                }
                else{
                   let tempUpdatedData = {
                        entity: "multi_data",
                        data: newScreenState
                    }

                    yield put(updateScreenStateSuccess(requestInput, tempUpdatedData))
                }
                // let tempUpdatedData = {
                //     entity: "screenTimer",
                //     data: {
                //         data_key:'timer_info',
                //         data_value: patchedTimerInfo,
                //         screen_oid: screenOid
                //     }
                // }


                break;
            default:
                break;
        }
    } catch (err) {
        console.log("err updating beam state in the beam", err);
        yield put(updateCurrentScreenFailure(requestInput, err));

    }
}


function* updateTheTimeElapsedInCurrentScreen({firebase,requestInput,currentBeam, currentScreen, screenOid}){

    const screenFirestoreId = currentScreen.id;
    console.log("screenFirestoreId_timer_update", screenFirestoreId);

    let patchedTimerInfo = clone(currentScreen.timer_info);
    let timeElapsed=getTheTimeElapsed(currentScreen,firebase);

    patchedTimerInfo.time_elapsed=timeElapsed;

    console.log(currentScreen.state);

    const beamsCollection = firebase.beamsCollection;
    console.log("patchedTimerInfo", patchedTimerInfo);
    const newScreenState = {};
    newScreenState['timer_info'] = patchedTimerInfo;
    beamsCollection.doc(currentBeam.id).collection("screens")
        .doc(screenFirestoreId).update(newScreenState);
    let tempUpdatedData = {
        entity: "screenTimer",
        data: {
            data_key:'timer_info',
            data_value: patchedTimerInfo,
            screen_oid: screenOid
        }
    }
    return tempUpdatedData;
}


async function sendTheData ({batch, beamsCollection, currentBeam, screenId, newTimerInfo}){
    let theUpdatedDoc;

    await  batch.commit().then(async function () {
        console.log("goto success");
        if(typeof newTimerInfo!=="undefined"){
           let theScreenRef = beamsCollection.doc(currentBeam.id).collection("screens").doc(screenId);
            await theScreenRef.get().then((doc) => {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    console.log("TheScreen",doc.data());
                    let theDoc=doc.data();
                    //console.log("servertimestamp",theDoc.timer_info.start_time.toDate());
                    // let servertimestamp=convertFirebaseTimeStampToTimestamp({"fbTimeStamp":theDoc.timer_info.start_time});
                    // console.log("milliSecondsTimeStamp_servertimestamp",servertimestamp);


                    newTimerInfo.timer_info.start_time=theDoc.timer_info.start_time;


                    theUpdatedDoc = {
                        entity: "screenTimer",
                        data: {
                            data_key:'timer_info',
                            data_value: newTimerInfo.timer_info,
                            screen_oid: theDoc.oid
                        }
                    }
                    // theUpdatedDoc = {
                    //     entity: "multi_data",
                    //     data: newTimerInfo
                    // }


                    //yield put(updateScreenStateSuccess(requestInput, updatedData))
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }


    }).catch(function (error) {
        console.error("Error going to item: ", error);
    });
    console.log("return statement");
    return theUpdatedDoc;
}



function* gotoNextItem({requestInput, firebase, currentBeam, screenOid }) {

    let batch = firebase.firestore.batch();

    //need to set the Beam object to the next screenOid
    const nextScreenOid = getNextScreenOid(currentBeam.screens_order, screenOid);
    if (nextScreenOid === screenOid) {
        return;
    }
    const beamsCollection = firebase.beamsCollection;
    let updateObject = {};
    updateObject['state.current_screen_oid'] = nextScreenOid;
    let nextBeamRef = beamsCollection.doc(currentBeam.id);


    const nextScreenDoc = currentBeam.screens.find((screen) => screen.oid == nextScreenOid)
    if (typeof nextScreenDoc == 'undefined') {
        return;
    }
    console.log(nextScreenDoc);

    let screenStateUpdatedFlag = false;
    const nextScreenId = nextScreenDoc.id;
    const updatedScreen = {}
    let newScreenState = {};
    let newTimerInfo;

    let nextScreenRef = beamsCollection.doc(currentBeam.id).collection("screens").doc(nextScreenId)
    console.log("nextScreenRef", nextScreenRef);

    if (nextScreenDoc.state.status == 'init') {
        console.log("updating next screen state to open");
        if(
            !nextScreenDoc.topic_item_info?.assessment_config?.promptSelection ||
            nextScreenDoc.topic_item_info?.assessment_config?.allowed_activities_for_beam?.length === 1
        ) {
            newScreenState['state.status'] = 'open';
            newScreenState['state.view_state'] = 'open';
            // console.log("timeStamp",fb.firestore.Timestamp.now());
            // console.log("serverTimestamp",fb.firestore.FieldValue.serverTimestamp());
            // console.log("milliSecondsTimeStamp_timestamp_now",convertFirebaseTimeStampToTimestamp({"fbTimeStamp":fb.firestore.Timestamp.now()}));


            //console.log("milliSecondsTimeStamp_servertimestamp",convertFirebaseTimeStampToTimestamp({"fbTimeStamp":fb.firestore.FieldValue.serverTimestamp()}));


            if(
                typeof nextScreenDoc.topic_item_info.measure.timer_in_secs!=="undefined" &&
                nextScreenDoc.topic_item_info.measure.timer_in_secs>0
            ){
                newTimerInfo={};
                newTimerInfo['timer_info']=
                    {
                        'start_time':fb.firestore.FieldValue.serverTimestamp(),
                        'duration':nextScreenDoc.topic_item_info.measure.timer_in_secs
                    };
                nextScreenRef.update(newTimerInfo)
            }

            //console.log(fb.database.ServerValue.TIMESTAMP);
        }
        batch.update(nextScreenRef, newScreenState)

        screenStateUpdatedFlag = true;
        if(currentBeam.state.graded){
            console.log('current beam after next screen', currentBeam);
            let maxBeamPoint=(typeof currentBeam.state.max_points!=="undefined")?currentBeam.state.max_points:0;
            let isUpdated=false;
            if(typeof nextScreenDoc.topic_item_info!=="undefined" && typeof nextScreenDoc.topic_item_info.measure!=="undefined" && typeof nextScreenDoc.topic_item_info.measure.grade_points!=="undefined"){
                if(currentBeam.state.use_efficacy){
                    isUpdated=true;
                    maxBeamPoint = maxBeamPoint+nextScreenDoc.topic_item_info.measure.efficacy_factor;
                }
                if(currentBeam.state.use_participation){
                    isUpdated=true;
                    maxBeamPoint = maxBeamPoint+nextScreenDoc.topic_item_info.measure.participation_factor;
                }
            }
            if(isUpdated)
                updateObject['state.max_points'] = maxBeamPoint;
        }
    }
    else if(nextScreenDoc.state.status === 'open'){
        if(typeof nextScreenDoc.timer_info!=="undefined"){
            screenStateUpdatedFlag = true;
            newTimerInfo={};
            newTimerInfo.timer_info=clone(nextScreenDoc.timer_info)
            if(typeof nextScreenDoc.timer_info.time_elapsed!=="undefined"){
               if(typeof newTimerInfo.timer_info.history==="undefined")
                newTimerInfo.timer_info.history=[];

                let tempObj={};
                Object.keys(nextScreenDoc.timer_info).forEach(key => {
                    if(key!=="history"){
                        tempObj[key]=nextScreenDoc.timer_info[key];
                    }
                })
                newTimerInfo.timer_info.history.push(tempObj);
            }

            newTimerInfo.timer_info.start_time=fb.firestore.FieldValue.serverTimestamp();
            if(typeof nextScreenDoc.timer_info.time_elapsed!=="undefined"){
                newTimerInfo.timer_info.duration=nextScreenDoc.timer_info.duration-nextScreenDoc.timer_info.time_elapsed
                delete newTimerInfo.timer_info.time_elapsed;
            }
            nextScreenRef.update(newTimerInfo)
        }
    }

    batch.update(nextBeamRef, updateObject);
    // Commit the batch
    let updatedDoc = yield call(sendTheData,{batch, beamsCollection, currentBeam, "screenId":nextScreenId, newTimerInfo});
    console.log("goto next item updatedDoc ",updatedDoc)
    if(updatedDoc)
        yield put(updateScreenStateSuccess(requestInput, updatedDoc));

    updatedScreen.screenStateUpdatedFlag = screenStateUpdatedFlag
    let updatedScreenInfo = {
        screenId: nextScreenId,
        newScreenState
    }
    if(typeof newTimerInfo!=="undefined"){
        updatedScreenInfo.newTimerInfo=newTimerInfo;
    }
    return { updateObject, screenStateUpdatedFlag, updatedScreenInfo };

}

function* gotoPrevItem({requestInput, firebase, currentBeam, screenOid }) {

    let batch = firebase.firestore.batch();

    //need to set the Beam object to the prev screenOid
    const prevScreenOid = getPrevScreenOid(currentBeam.screens_order, screenOid);
    if (prevScreenOid === screenOid) {
        return null;
    }
    const beamsCollection = firebase.beamsCollection;
    let updateObject = {};
    updateObject['state.current_screen_oid'] = prevScreenOid;
    let currentBeamRef = beamsCollection.doc(currentBeam.id);


    const prevScreenDoc = currentBeam.screens.find((screen) => screen.oid == prevScreenOid)
    if (typeof prevScreenDoc == 'undefined') {
        return;
    }
    let screenStateUpdatedFlag = false;
    const prevScreenId = prevScreenDoc.id;
    const updatedScreen = {}
    let newScreenState = {};
    let newTimerInfo;

    console.log("prevScreenId", prevScreenId);
    let prevScreenRef = beamsCollection.doc(currentBeam.id).collection("screens").doc(prevScreenId)
    console.log("prevScreenRef", prevScreenRef);

    if (prevScreenDoc.state.status == 'init') {
        console.log("updating prev screen state to open")
        if(
            !prevScreenDoc.topic_item_info?.assessment_config?.promptSelection ||
            prevScreenDoc.topic_item_info?.assessment_config?.allowed_activities_for_beam?.length === 1
        ) {
            newScreenState['state.status'] = 'open';
            newScreenState['state.view_state'] = 'open';
        };

        if(typeof prevScreenDoc.topic_item_info.measure.timer_in_secs!=="undefined" && prevScreenDoc.topic_item_info.measure.timer_in_secs>0){
            newTimerInfo={};
            newTimerInfo['timer_info']={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':prevScreenDoc.topic_item_info.measure.timer_in_secs};
            prevScreenRef.update(newTimerInfo)
        };

        batch.update(prevScreenRef, newScreenState)
        screenStateUpdatedFlag = true;

        if(currentBeam.state.graded){
            let maxBeamPoint=(typeof currentBeam.state.max_points!=="undefined")?currentBeam.state.max_points:0;
            let isUpdated=false;
            if(typeof prevScreenDoc.topic_item_info!=="undefined" && typeof prevScreenDoc.topic_item_info.measure!=="undefined" && typeof prevScreenDoc.topic_item_info.measure.grade_points!=="undefined"){
                if(currentBeam.state.use_efficacy){
                    isUpdated=true;
                    maxBeamPoint = maxBeamPoint+prevScreenDoc.topic_item_info.measure.efficacy_factor;
                }
                if(currentBeam.state.use_participation){
                    isUpdated=true;
                    maxBeamPoint = maxBeamPoint+prevScreenDoc.topic_item_info.measure.participation_factor;
                }
            }
            if(isUpdated)
                updateObject['state.max_points'] = maxBeamPoint;
        }

    }
    else if(prevScreenDoc.state.status === 'open'){
        if(typeof prevScreenDoc.timer_info!=="undefined"){
            screenStateUpdatedFlag = true;
            newTimerInfo={};
            newTimerInfo.timer_info=clone(prevScreenDoc.timer_info)
            if(typeof newTimerInfo.timer_info.history==="undefined")
                newTimerInfo.timer_info.history=[];
            let tempObj={};
            Object.keys(prevScreenDoc.timer_info).forEach(key => {
                if(key!=="history"){
                    tempObj[key]=prevScreenDoc.timer_info[key];
                }
            })
            newTimerInfo.timer_info.history.push(tempObj);
            newTimerInfo.timer_info.start_time=fb.firestore.FieldValue.serverTimestamp();
            if(typeof prevScreenDoc.timer_info.time_elapsed!=="undefined"){
                newTimerInfo.timer_info.duration=prevScreenDoc.timer_info.duration-prevScreenDoc.timer_info.time_elapsed
                delete newTimerInfo.timer_info.time_elapsed;
            }
            prevScreenRef.update(newTimerInfo)
            //newScreenState['timer_info']=newTimerInfo;
        }
    }

    batch.update(currentBeamRef, updateObject);
    // Commit the batch
    let updatedDoc = yield call(sendTheData,{batch, beamsCollection, currentBeam, "screenId":prevScreenId , newTimerInfo});
     console.log("goto prev item updatedDoc ",updatedDoc)
    if(updatedDoc)
        yield put(updateScreenStateSuccess(requestInput, updatedDoc));

    updatedScreen.screenStateUpdatedFlag = screenStateUpdatedFlag
    let updatedScreenInfo = {
        screenId: prevScreenId,
        newScreenState
    }
    if(typeof newTimerInfo!=="undefined"){
        updatedScreenInfo.newTimerInfo=newTimerInfo;
    }
    return { updateObject, screenStateUpdatedFlag, updatedScreenInfo };


}

function* gotoIndexedItem({requestInput, firebase, currentBeam, screenOid, nextIndex }) {
    let batch = firebase.firestore.batch();

    //need to set the Beam object to the prev screenOid
    const indexedScreenOid = getIndexedScreenOid(currentBeam.screens_order, nextIndex);
    if (indexedScreenOid === screenOid) {
        return null;
    }

    const beamsCollection = firebase.beamsCollection;
    let updateObject = {};
    updateObject['state.current_screen_oid'] = indexedScreenOid;
    let currentBeamRef = beamsCollection.doc(currentBeam.id);
    batch.update(currentBeamRef, updateObject);

    const indexedScreenDoc = currentBeam.screens.find((screen) => screen.oid == indexedScreenOid)
    if (typeof indexedScreenDoc == 'undefined') {
        return;
    }

    let screenStateUpdatedFlag = false;
    const indexedScreenId = indexedScreenDoc.id;
    const updatedScreen = {}
    let newScreenState = {};
    let newTimerInfo;

    console.log("indexedScreenId", indexedScreenId);
    let indexedScreenRef = beamsCollection.doc(currentBeam.id).collection("screens").doc(indexedScreenId)
    console.log("indexedScreenRef", indexedScreenRef);

    if (indexedScreenDoc.state.status == 'init') {
        console.log("updating indexed screen state to open")
        newScreenState['state.status'] = 'open';
        newScreenState['state.view_state'] = 'open';
        if(typeof indexedScreenDoc.topic_item_info.measure.timer_in_secs!=="undefined" && indexedScreenDoc.topic_item_info.measure.timer_in_secs>0){
            newTimerInfo={};
            newTimerInfo['timer_info']={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':indexedScreenDoc.topic_item_info.measure.timer_in_secs};
            indexedScreenRef.update(newTimerInfo);
        }
        batch.update(indexedScreenRef, newScreenState)
        screenStateUpdatedFlag = true;

        if(currentBeam.state.graded){
            let maxBeamPoint=(typeof currentBeam.state.max_points!=="undefined")?currentBeam.state.max_points:0;
            let isUpdated=false;
            if(typeof indexedScreenDoc.topic_item_info!=="undefined" && typeof indexedScreenDoc.topic_item_info.measure!=="undefined" && typeof indexedScreenDoc.topic_item_info.measure.grade_points!=="undefined"){
                if(currentBeam.state.use_efficacy){
                    isUpdated=true;
                    maxBeamPoint = maxBeamPoint+indexedScreenDoc.topic_item_info.measure.efficacy_factor;
                }
                if(currentBeam.state.use_participation){
                    isUpdated=true;
                    maxBeamPoint = maxBeamPoint+indexedScreenDoc.topic_item_info.measure.participation_factor;
                }
            }
            if(isUpdated)
                updateObject['state.max_points'] = maxBeamPoint;
        }

    }
    else if(indexedScreenDoc.state.status == 'open'){

        if(typeof indexedScreenDoc.timer_info!=="undefined"){
            screenStateUpdatedFlag = true;
            newTimerInfo={};
            newTimerInfo.timer_info=clone(indexedScreenDoc.timer_info)
            if(typeof newTimerInfo.timer_info.history==="undefined")
                newTimerInfo.timer_info.history=[];
            let tempObj={};
            Object.keys(indexedScreenDoc.timer_info).forEach(key => {
                if(key!=="history"){
                    tempObj[key]=indexedScreenDoc.timer_info[key];
                }
            })
            newTimerInfo.timer_info.history.push(tempObj);
            newTimerInfo.timer_info.start_time=fb.firestore.FieldValue.serverTimestamp();
            if(typeof indexedScreenDoc.timer_info.time_elapsed!=="undefined"){
                newTimerInfo.timer_info.duration=indexedScreenDoc.timer_info.duration-indexedScreenDoc.timer_info.time_elapsed
                delete newTimerInfo.timer_info.time_elapsed;
            }
            indexedScreenRef.update(newTimerInfo);
            //newScreenState['timer_info']=newTimerInfo;
        }
    }
    // Commit the batch

    let updatedDoc = yield call(sendTheData,{batch, beamsCollection, currentBeam, screenId:indexedScreenId, newTimerInfo});
    console.log("goto indexed item updatedDoc ",updatedDoc)
    if(updatedDoc)
        yield put(updateScreenStateSuccess(requestInput, updatedDoc));

    updatedScreen.screenStateUpdatedFlag = screenStateUpdatedFlag
    let updatedScreenInfo = {
        screenId: indexedScreenId,
        newScreenState
    }
    if(typeof newTimerInfo!=="undefined"){
        updatedScreenInfo.newTimerInfo=newTimerInfo;
    }
    return { updateObject, screenStateUpdatedFlag, updatedScreenInfo };
}

async function updateScreenState({ requestInput, firebase, currentBeam, screenOid, status, responseAggregate }) {
    const beamsCollection = firebase.beamsCollection;
    const currentScreen = getScreenByOid(currentBeam.screens, screenOid);
    if (!currentScreen) {
        const msg = "currentScreen could not be found in currentBeam with screenOid " + screenOid;
        console.log(msg);
        throw msg;
    };
    console.log("currentScreen, status", currentScreen, status);

    const screenFirestoreId = currentScreen.id;
    console.log("screenFirestoreId_updateScreenState", screenFirestoreId);

    const newScreenState = {};
    newScreenState.state={};
    newScreenState.state.status=status;
    newScreenState.state.view_state=status;

    // newScreenState['state.status'] = status;
    // newScreenState['state.view_state'] = status;
    const currentScreenStatus = currentScreen.state.status;

    console.log("responseAggregate", responseAggregate);
    if (typeof responseAggregate != 'undefined' && responseAggregate != null && currentScreenStatus === 'open') {
        console.log("going to update responseAggregate")
        newScreenState['responseAggregate'] = responseAggregate;

        // beamsCollection.doc(currentBeam.id).update({responseAggregate})
    }
    if(typeof currentScreen.timer_info!=="undefined"){
        if(currentScreenStatus==="open"){
            let timeElapsed=getTheTimeElapsed(currentScreen,firebase);
            console.log("timeElapsed",timeElapsed);
            let tempTimerInfo=clone(currentScreen.timer_info)
            tempTimerInfo.time_elapsed=timeElapsed;
            newScreenState.timer_info=tempTimerInfo;
        }
        else if(status==="open"){
            let tempTimerInfo=clone(currentScreen.timer_info)
            if(typeof tempTimerInfo.history==="undefined")
                tempTimerInfo.history=[];
            let tempObj={};
            Object.keys(currentScreen.timer_info).forEach(key => {
                if(key!=="history"){
                    tempObj[key]=currentScreen.timer_info[key];
                }
            })
            tempTimerInfo.history.push(tempObj);
            tempTimerInfo.start_time=fb.firestore.FieldValue.serverTimestamp();
            if(typeof currentScreen.timer_info.time_elapsed!=="undefined"){
                tempTimerInfo.duration=currentScreen.timer_info.duration-currentScreen.timer_info.time_elapsed
                if(tempTimerInfo.duration===0){
                    tempTimerInfo.noTimer=true;
                }
                delete tempTimerInfo.time_elapsed;
            }

            newScreenState['timer_info']=tempTimerInfo;
        }
    }
    else if(typeof currentScreen.topic_item_info.measure.timer_in_secs!=="undefined" && currentScreen.topic_item_info.measure.timer_in_secs>0){
        let newTimerInfo={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':currentScreen.topic_item_info.measure.timer_in_secs};
        newScreenState.timer_info=newTimerInfo;
    }

    console.log("newScreenState", newScreenState);
    let updatedData;
    await beamsCollection.doc(currentBeam.id).collection("screens")
        .doc(screenFirestoreId).update(newScreenState)
        .then(async function () {
            await beamsCollection.doc(currentBeam.id).collection("screens")
        .doc(screenFirestoreId).get().then((doc) => {
                if (doc.exists) {
                     let theDoc=doc.data();
                     newScreenState['timer_info']=clone(theDoc.timer_info);;
                }
                console.log("####################****MUlti dAta****#######################");
                updatedData = {
                    entity: "multi_data",
                    data: newScreenState
                }
            })
            .catch((error)=>{
                console.error("Error fetching document: ", error);
            });

        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
        });

    console.log("updatedData", updatedData);
    return updatedData;
}



export function getScreenByOid(screens, oid) {
    const filterScreens = screens.find(x => x.oid === oid);
    if (filterScreens) {
        return filterScreens;
    }
    return null;
}

export function getNextScreenStatus(screen) {
    const screenStatus = screen.state.status;
    console.log("screenStatus", screenStatus);

    if (screenStatus === 'open') {
        return 'closed'
    } else if (screenStatus === 'closed') {
        return 'review'
    } else if (screenStatus === 'review') {
        return 'answer'
    }
}

export function getPrevScreenStatus(screen) {
    const screenStatus = screen.state.status;
    console.log("screenStatus", screenStatus);

    if (screenStatus === 'closed') {
        return 'open'
    } else if (screenStatus === 'review') {
        return 'closed'
    } else if (screenStatus === 'answer') {
        return 'review'
    }
}

export const getNextScreenOid = (screensOrder, screenOid) => {
    const currentIndex = screensOrder.indexOf(screenOid);
    if (currentIndex + 1 < screensOrder.length) {
        return screensOrder[currentIndex + 1];
    } else {
        return screenOid;
    }
}

export const getPrevScreenOid = (screensOrder, screenOid) => {
    const currentIndex = screensOrder.indexOf(screenOid);
    if (currentIndex > 0) {
        return screensOrder[currentIndex - 1];
    } else {
        return screenOid;
    }
}
export const getIndexedScreenOid = (screensOrder, nextIndex) => {
    return screensOrder[nextIndex];
}


export function* updateCorrectAnswers(action) {

    const requestInput = action.requestInput;
    console.log("requestInput", requestInput)

    const { patchArray, patchType, item, firebase, currentBeam } = requestInput;

    try {
        //update the beam item
        yield updateBeamItemCorrectAnswers({ patchArray, patchType, item, firebase, currentBeam });
        //update the item in authoring db - mongo
        patchItem(patchArray, item)
    } catch (err) {
        console.error("Error in updateBeamItemCorrectAnswers", err);
    }

}

function updateBeamItemCorrectAnswers({ patchArray, patchType, item, firebase, currentBeam }) {
    const beamsCollection = firebase.beamsCollection;
    const screenOid = currentBeam.state.current_screen_oid;
    const currentScreen = getScreenByOid(currentBeam.screens, screenOid);
    if (!currentScreen) {
        const msg = "currentScreen could not be found in currentBeam with screenOid " + screenOid;
        console.log(msg);
        throw msg;
    };

    const screenFirestoreId = currentScreen.id;
    console.log("screenFirestoreId_updateBeamItemCorrectAnswers", screenFirestoreId);


    // newScreenState['state.status'] = status;
    // newScreenState['state.view_state'] = status;
    // console.log("responseAggregate",responseAggregate);
    // if (typeof responseAggregate != 'undefined' && responseAggregate != null && status === 'closed'){
    //     console.log("going to update responseAggregate")
    //     newScreenState['responseAggregate']=responseAggregate;
    //     // beamsCollection.doc(currentBeam.id).update({responseAggregate})
    // }

    const patchedItem = getPatchedBeamItem({ patchArray, patchType, item })
    console.log("patchedItem", patchedItem);
    const newScreenState = {};
    newScreenState['item'] = patchedItem;
    beamsCollection.doc(currentBeam.id).collection("screens")
        .doc(screenFirestoreId).update(newScreenState);

}

function getPatchedBeamItem({ patchArray, patchType, item, }) {
    switch (patchType) {
        case 'fib_addAcceptableAnswer':
            item = getPatchedBeamFibItem({ patchArray, patchType, item })
            break;
        case 'mcq_addCorrectAnswer':
            item = getPatchedBeamMcqItem({ patchArray, patchType, item })
            break;
            defatul:
            return item;
            break;
    }
    return item;
}

const patchItem = (patchArray, item) => {
    const json = request(getItemUpdateAPI(item.uuid), { method: 'PATCH', body: JSON.stringify(patchArray) });
    console.log(json);
    json.then(
        (json) => {
            console.log("json", json)
            //check the status to see if its success
            if (json === undefined || (json.status === API_FAILURE_STATUS)) {
                console.log("in updateItemFailed", json);
            } else {
                console.log("in updateItemSuccess", json);
                updateData(json, item);
                //update the item in the reducer (list)
                // console.log("item after update", item);
            }
        }
    )
}

const hasCorrectAnswer = ({ screen }) => {

    if (typeof screen != 'undefined' && typeof screen.item != 'undefined' && typeof screen.item.content != 'undefined' ) {
        const subtype = screen.item.subtype;
        switch(subtype){
            case 'mcq':
            case 'msq':
            case 'fib2':
                if (screen.item.content.hasCorrectAnswers) {
                    return true;
                }
                break;
            case 'fib':
                if (Array.isArray(screen.item.content.answers)) {
                    if (screen.item.content.answers.length > 0) {
                        return true;
                    }
                }
                break;
            case 'diagram':
                if (Array.isArray(screen.item.content.terms)) {
                    if (screen.item.content.terms.length > 0) {
                        return true;
                    }
                }
                break;
            case 'sorting':
                if (Array.isArray(screen.item.content.challenges)) {
                    if (screen.item.content.challenges.length > 0) {
                        return true;
                    }
                }
                break;
            case 'matching':
                if (Array.isArray(screen.item.content.lItems)) {
                    if (screen.item.content.lItems.length > 0) {
                        return true;
                    }
                }
                break;
        }
    }
    return false
}