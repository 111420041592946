export const hasBeamStarted = (status) => {
    if (status === 'inprogress'){
        return true;
    }
    return false;
}

export const isBeamOpen = (status) => {
    if (status === 'open'){
        return true;
    }
    return false;
}

export const isBeamPaused = (status) => {
    if (status === 'paused'){
        return true;
    }
    return false;
}


export const isBeamCompleted = (status) => {
    if (status === 'completed'){
        return true;
    }
    return false;
}