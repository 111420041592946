export const selectableForTypeOfFib = (data, finalArray,notIncludeRandom) => {
  if (
    ifOneOrMoreAnsContainDistractorOrThereAreMoreThanTwoAns(
      data.content?.answers
    )
  ) {
    finalArray.push({ value: "dragAndDrop", title: "Drag and Drop" });
  }
  if (ifOneOrMoreAnsContainDistractor(data.content?.answers)) {
    finalArray.push({ value: "select", title: "Drop Down" });
  }

  if (finalArray.length > 1 && !notIncludeRandom) {
    finalArray.push({ value: "random", title: "Random" });
  }
};

const ifOneOrMoreAnsContainDistractor = answerArray =>
  answerArray?.every(ans => ans.distractors && ans.distractors.length > 0);

const ifOneOrMoreAnsContainDistractorOrThereAreMoreThanTwoAns = answerArray =>
  answerArray?.length > 1 ||
  answerArray?.some(ans => ans.distractors && ans.distractors.length > 0);
