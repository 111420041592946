import { returnJsonOrStringItself } from "../../../util/StringUtils";

export const isValidFib2 = ({ item }) => {
  let isItemBeamable = false;
  let isBlankPresent = false;
  let isTextPresent = false;

  const itemStimulus = returnJsonOrStringItself(item.content.stimulus);
  if (typeof itemStimulus === "string") {
    return isItemBeamable;
  }

  for (let singlePara of itemStimulus) {
    const paraChildren = singlePara.children;
    for (let singleElement of paraChildren) {
      if (singleElement.type === "blank") {
        isBlankPresent = true;
      } else if (singleElement.text.trim().length > 0) {
        isTextPresent = true;
      }
      if (isBlankPresent && isTextPresent) {
        isItemBeamable = true;
        break;
      }
    }
    if (isItemBeamable) {
      break;
    }
  }

  return isItemBeamable;
};
