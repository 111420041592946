export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  
export const generateRandomPasscode = () => {
  return Math.floor(100000 + Math.random() * 900000);
}


export function getBeamCode(){
	//this is a hack...it takes the last first 6 chars for the last 8 chars in 
	//current time in millis
	//the chances of getting the same number in a matter of 10 days is slim
	//note that this number need not be unique across all beams
	return (Date.now().toString()).slice(-8).slice(0,6);
}

export const returnJsonOrStringItself = (string) => {
  try{
    return JSON.parse(string);
  }catch(e) {
    return string;
  }
}

export const makeStimulusBackwardCompatible = (stimulus) => {
  const jsonOrString = returnJsonOrStringItself(stimulus);
  const changedStimulus = jsonOrString?((typeof jsonOrString === "string")? [{ type: "paragraph", children: [{text:jsonOrString}] }]: jsonOrString): [{ type: "paragraph", children: [{text:""}] }]

  return changedStimulus;
}