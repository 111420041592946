import { takeLatest, take, cancelled, call, put } from "redux-saga/effects";
import { eventChannel } from 'redux-saga'

import { SET_ENV_CONFIG_ACTION, SUBSCRIBE_TO_APP_CHANNEL_ACTION } from 'constants/ActionTypes'
import { getEnv } from 'constants/EnvConfig';
import { EVENT_BUS_SERVICE } from 'constants/ApiConstants';
import EventBus from 'vertx3-eventbus-client';
import { receiveAppChannelMessage} from 'appRedux/actions/AppBootstrap';
import { slidedeckConversionStages } from 'constants/NotificationConstants';
import { importListItemSuccess,importListItemFailed } from 'containers/Main/List/actions';
import { SLIDE_LIST_CONFIG } from 'containers/Main/Slidedeck/list';


function* setEnvConfig(action) {
  console.log("in setEnvConfig", action)
  const location = action.requestInput.location;
  const env = getEnv();
}


function* subscribeToAuthoringChannel(action) {

  const requestInput = action.requestInput
  console.log("In subscribeToAuthoringChannel ", requestInput)
  const firebase = requestInput.firebase;
  const accountId = requestInput.firebase.userSignature.accountId;

  const appChannelAddress = "uid_" + accountId + "_i";
  const appChannel = yield call(setupAppChannelMessageListener, { firebase, channelAddress: appChannelAddress });

  try {
    while (true) {
      const appChannelMessage = yield take(appChannel);
      yield put(receiveAppChannelMessage({ appChannelAddress, appChannelMessage }));
      const channelMessageCode = appChannelMessage.message.body.code;
      if (channelMessageCode == slidedeckConversionStages._SLIDEDECK_CONVERSION_FINAL_STAGE_PASS || channelMessageCode === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE2_FAIL || channelMessageCode === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE3_FAIL || channelMessageCode === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_FAIL){
        yield put(importListItemSuccess(appChannelMessage.message.body, SLIDE_LIST_CONFIG))
      }
    }
  } catch (err) {
    console.log("ERROR in receiving appChannel Message", err);

  } finally {
    console.log("appChannel terminated")
    if (yield cancelled()) {
      appChannel.close();
    }
  }


}

// if (theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE2_FAIL || theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE3_FAIL || theFinalJob.code === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_FAIL) {
//       console.log(theFinalJob.code)

const setupAppChannelMessageListener = ({ firebase, channelAddress }) => {
  return eventChannel(emmitter => {

    let eb = new EventBus(EVENT_BUS_SERVICE, { "vertxbus_ping_interval": 8000 });
    eb.onopen = function () {

      const headers = {};
      headers.userSignature = firebase.userSignature;
      // set a handler to receive a message
      eb.registerHandler(channelAddress, headers, function (error, message) {
        if (typeof message != 'undefined') {
          emmitter({ channelAddress, message });
        }
      });
      // eb.unregisterHandler(beamChannelAddress, headers, function (error, message) {
      //     console.log('received a message: ' + JSON.stringify(message));
      // });
    }

    return () => eb;
  })
}

export default function* watchAppBootstrapActions() {
  yield takeLatest(SET_ENV_CONFIG_ACTION, setEnvConfig);
  yield takeLatest(SUBSCRIBE_TO_APP_CHANNEL_ACTION, subscribeToAuthoringChannel);
}

