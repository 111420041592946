import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import watchCourseActions from "./Course"
import watchBeamActions from "containers/Main/Beam/saga";
import watchAuthTokenActions from './Firebase'
import watchStudentBeamActions from "containers/Main/StudentBeam/saga";
import watchAppBootstrapActions from "./AppBootstrap";
import watchRoomStatusActions from "./RoomUsersStatus";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    watchCourseActions(),
    watchBeamActions(),
    watchStudentBeamActions(),
    watchAuthTokenActions(),
    watchAppBootstrapActions(),
    watchRoomStatusActions(),
  ]);
}
