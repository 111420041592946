
import produce from 'immer';
import {
  LISTEN_TO_USER_BEAMS_SUCCESS, LISTEN_TO_USER_BEAMS_FAILURE,
  USER_BEAM_SCREENS_CHANGED
} from "containers/Main/Beam/constants";


const initialSettings = {
  userBeams: {},
  beamUserBeams: {},
  listeningToUserBeams: {},
};

const BeamResponseStats = (state = initialSettings, action) =>
  produce(state, draft => {
    let beamId;
    switch (action.type) {
      case LISTEN_TO_USER_BEAMS_SUCCESS:
        console.log("in BeamResponseStats LISTEN_TO_USER_BEAMS_SUCCESS", state, action);
        beamId = action.requestInput.currentBeam.id;
        const userBeams = action.response.userBeams;
        if (userBeams) {
          userBeams.forEach((userBeam) => {
            const ubId = userBeam.id;
            draft.userBeams[ubId] = userBeam;
            if (typeof draft.beamUserBeams[userBeam.beam_doc_ref_id] == 'undefined') {
              draft.beamUserBeams[userBeam.beam_doc_ref_id] = {}
            }
            draft.beamUserBeams[userBeam.beam_doc_ref_id][ubId] = userBeam;
          })
        }
        draft.listeningToUserBeams[beamId] = true;
        break;
      case LISTEN_TO_USER_BEAMS_FAILURE:
        console.log("in BeamResponseStats LISTEN_TO_USER_BEAMS_FAILURE", state, action);
        beamId = action.requestInput.currentBeam.id;
        draft.listeningToUserBeams[beamId] = false;
        break;
      case USER_BEAM_SCREENS_CHANGED:
        console.log("in BeamResponseStats USER_BEAM_SCREENS_CHANGED", state, action);
        const uBeam = action.response.userScreens.userBeam;
        const userBeamScreens = action.response.userScreens.userBeamScreens;
        if (typeof draft.userBeams[uBeam.id] == 'undefined' || draft.userBeams[uBeam.id] == null) {
          break;
        }
        if (!draft.userBeams[uBeam.id]['user_screens']) {
          draft.userBeams[uBeam.id]['user_screens'] = {};
        }
        if (!userBeamScreens) {
          console.log("empty userBeamScreens");
          break;
        }
        userBeamScreens.forEach((userBeamScreen) => {
          draft.userBeams[uBeam.id]['user_screens'][userBeamScreen.beam_screen_ref_id] = userBeamScreen;
          draft.beamUserBeams[uBeam.beam_doc_ref_id][uBeam.id]=draft.userBeams[uBeam.id]
        })
        break;
      default:
        return state;
    }
  });
export default BeamResponseStats;