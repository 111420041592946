export const getInstructorBeamChannelAddress = (currentBeam) => {
  if (currentBeam) {
      return "beams_" + currentBeam.id + "_i";
  }
  return null;
}


export const getActiveParticipantsCount = ({ participants }) => {

  let count = 0;
  if (typeof participants == 'undefined') return count;
  Object.keys(participants).forEach((accountId) => {
    if (participants[accountId].status === 'active') {
      count++;
    }
  })
  return count;
}