import {SET_ENV_CONFIG_ACTION,SUBSCRIBE_TO_APP_CHANNEL_ACTION} from 'constants/ActionTypes'
import { SUBSCRIBE_TO_APP_CHANNEL_SUCCESS, SUBSCRIBE_TO_APP_CHANNEL_FAILURE,
  RECEIVE_APP_CHANNEL_MESSAGE, MARK_CHANNEL_MESSAGE_AS_READ } from '../../constants/ActionTypes';

export const actionToSetEnvConfig = (requestInput) => {
    return {
      type: SET_ENV_CONFIG_ACTION,
      requestInput
    };
};

export const actionToSubscribeToAuthoringChannel = (requestInput) => {
  return {
    type: SUBSCRIBE_TO_APP_CHANNEL_ACTION,
    requestInput
  };
};

export const subscribeToAuthoringChannelSuccess = (requestInput, response) => {
  return {
    type: SUBSCRIBE_TO_APP_CHANNEL_SUCCESS,
    requestInput,
    response
  };
};

export const subscribeToAuthoringChannelFailure = (requestInput, err) => {
  return {
    type: SUBSCRIBE_TO_APP_CHANNEL_FAILURE,
    requestInput,
    err
  };
};

export const receiveAppChannelMessage = (message) => {
  return {
    type: RECEIVE_APP_CHANNEL_MESSAGE,
    message
  };
};

export const markMessageAsRead = (requestInput) => {
  return {
    type: MARK_CHANNEL_MESSAGE_AS_READ,
    requestInput
  };
};