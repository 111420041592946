const highlightSolution = item => {
  const itemSolution = {
    correctSolutionText: item.content.paragraph
  };
  const itemAnswers = item.content.answers;
  const itemParagraph = JSON.parse(item.content.paragraph);
  let previousHighlightChildInfo;
  let wasPreviousChildHighlight = false;
  let stringifiedtext = "";
  let highlightText = "";
  for (let singlePara of itemParagraph) {
    const allChildrenOfPara = singlePara.children;
    for (let singleChild of allChildrenOfPara) {
      const previousHighlightId = previousHighlightChildInfo?.id;
      if (singleChild.highlight) {
        if (
          wasPreviousChildHighlight &&
          previousHighlightId !== singleChild.id
        ) {
          findStringOccurenceAndAddToObject(
            highlightText,
            stringifiedtext,
            previousHighlightChildInfo,
            itemSolution,
            itemAnswers
          );
        }
        if (
          !wasPreviousChildHighlight ||
          previousHighlightId !== singleChild.id
        ) {
          highlightText = "";
        }
        highlightText += singleChild.text;
        previousHighlightChildInfo = singleChild;
        wasPreviousChildHighlight = true;
      } else {
        if (wasPreviousChildHighlight) {
          findStringOccurenceAndAddToObject(
            highlightText,
            stringifiedtext,
            previousHighlightChildInfo,
            itemSolution,
            itemAnswers
          );
        }
        wasPreviousChildHighlight = false;
      }

      stringifiedtext += singleChild.text;
    }
  }

  if (wasPreviousChildHighlight) {
    findStringOccurenceAndAddToObject(
      highlightText,
      stringifiedtext,
      previousHighlightChildInfo,
      itemSolution,
      itemAnswers
    );
  }

  return itemSolution;
};

const findStringOccurenceAndAddToObject = (
  highlightText,
  stringifiedtext,
  previousHighlightChildInfo,
  itemSolution,
  itemAnswers
) => {
  const regex = new RegExp(highlightText, "g");
  const noOfPreviousOccurence = (stringifiedtext.match(regex) || []).length;
  const singleAns = itemAnswers.find(
    ans => ans.challengeId === previousHighlightChildInfo.id
  );
  itemSolution[previousHighlightChildInfo.id] = {
    correct_answers: [
      {
        id: singleAns.id,
        value: highlightText,
        occurrence: noOfPreviousOccurence
      }
    ],
    weight: 1
  };
  
  if (singleAns?.acceptable_answers?.length > 0)
    itemSolution[singleAns.challengeId].acceptable_answers =
      singleAns.acceptable_answers;
  if (singleAns?.distractors?.length > 0)
    itemSolution[singleAns.challengeId].distractors = singleAns.distractors;
  if (singleAns?.correct_feedback?.value?.length > 0)
    itemSolution[singleAns.challengeId].correct_feedback =
      singleAns.correct_feedback.value;
  if (singleAns?.incorrect_feedback?.value?.length > 0)
    itemSolution[singleAns.challengeId].incorrect_feedback =
      singleAns.incorrect_feedback.value;
};

export default highlightSolution;
