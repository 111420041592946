import { getEnv, getEnvConfig } from "./EnvConfig";
// let apiHostName='';
// let oerHostName=''
// if(typeof(window) !== "undefined"){
//     import mojs from 'mo-js';
// }
// if(window.StorageFB_AUTH_TOKEN_API && window.sessionStorage){
// 	apiHostName=window.sessionStorage.getItem('contentServerURL');
// 	oerHostName=window.sessionStorage.getItem('oerServerURL');
// }
// export const OER_HOSTNAME=oerHostName;
// const API_HOSTNAME = apiHostName;

let envConfig = getEnvConfig();

const AUTHORING_SERVICE = 'https://dev-live-api.brightkids.in/authoring/api' || envConfig.AUTHORING_SERVICE;
const AUTHORING_VERSION = envConfig.AUTHORING_VERSION;

const LRNR6_SERVICE = envConfig.LRNR6_SERVICE;

const AUTHORING_AUTH_SERVICE = envConfig.AUTHORING_AUTH_SERVICE;
const AUTHORING_AUTH_VERSION = envConfig.AUTHORING_AUTH_VERSION;

export const EVENT_BUS_SERVICE = envConfig.EVENT_BUS_SERVICE;

const constructUrl = url => `${AUTHORING_SERVICE}${AUTHORING_VERSION}${url}`;
const constructAuthUrl = url =>
  `${AUTHORING_AUTH_SERVICE}${AUTHORING_AUTH_VERSION}${url}`;

const constructLrnr6Url = url => `${LRNR6_SERVICE}${url}`;

export const TOPICS_API = constructUrl("/topics?type=topic");
export const TOPIC_DETAIL_API = constructUrl("/topics");
export const QUIZLIST_API = constructUrl("/topics?type=quiz");
export const QUIZCREATE_API = constructUrl("/topics?type=quiz");
export const QUIZUPDATE_API = constructUrl("/topics");
export const SLIDEDECK_LIST_API = constructUrl("/topics?type=slidedeck");
export const SLIDEDECK_CREATE_API = constructUrl("/topics?type=slidedeck");
export const SLIDEDECK_UPDATE_API = constructUrl("/topics");
export const ITEMS_API = constructUrl("/items?");
export const FB_AUTH_TOKEN_API = getFbAuthTokenApi();
export const TOPICS_ATTACHMENT_API = constructUrl("/topics/attachments");

function getFbAuthTokenApi() {
  const env = getEnv();
  switch(env) {
    case 'lrnr-live-dev':
      return envConfig.TOKEN_API;
    default:
      return constructAuthUrl("/fb/auth/token");
  }
}

export function getTopicsByCourseAPI(topicId) {
  return constructUrl("/topics/" + topicId);
}
export function getQuizListApi({ courseId = null }) {
  if (courseId != null) {
    return constructUrl(
      "/topics/?type=quiz&institution_course_uuid=" + courseId
    );
  } else {
    return constructUrl("/topics?type=quiz");
  }
}
export function getSlidedeckListApi({ courseId = null }) {
  if (courseId != null) {
    return constructUrl(
      "/topics/?type=slidedeck&institution_course_uuid=" + courseId
    );
  } else {
    return constructUrl("/topics?type=slidedeck");
  }
}
export function getTopicDeleteAPI(topicId) {
  return constructUrl("/topics/" + topicId);
}
export function getItemUpdateAPI(itemId) {
  return constructUrl("/items/" + itemId);
}
export function getItemsByTopicAPI(topicId) {
  return constructUrl("/items?topicIds=" + topicId);
}
export function getTopicItemUpdateAPI(topicId) {
  return constructUrl("/topics/" + topicId + "/items");
}
export function getTopicItemDeleteAPI(topicId, topicItemUuid) {
  return constructUrl("/topics/" + topicId + "/items/" + topicItemUuid);
}
export function getItemAttachementsAPI(itemId) {
  return constructUrl("/items/" + itemId + "/attachments");
}

// let tempValue;
// if(window.Storage && window.sessionStorage){
// 		tempValue=window.sessionStorage.getItem('userSignature');
//    tempValue=JSON.parse(tempValue);
// }
// //export const USER_SIGNATURE='';
// export const USER_SIGNATURE = 'signature='+tempValue.signature+"&signTime="+tempValue.signTime+"&accountId="+tempValue.accountId;

export function getClassSectionsAPI(institutionCourseId) {
  const path = "/institutioncourses/" + institutionCourseId + "/sections";
  return constructLrnr6Url(path);
}

//infinite scroll
export const PER_PAGE_PARAM = "per_page";
export const PAGE_PARAM = "page";
export const PAGE_OFFSET_PARAM = "offset";

export const DEFAULT_PER_PAGE = 50;
export const DEFAULT_PAGE_OFFSET = 0;
export const DEFAULT_PAGE_NUMBER = 1;

export const TOPICS_PER_PAGE = `${DEFAULT_PER_PAGE}`;
export const TOPICS_PAGE_OFFSET = `${DEFAULT_PAGE_OFFSET}`;
export const TOPICS_PAGE_NUMBER = `${DEFAULT_PAGE_NUMBER}`;

export const ITEMS_PER_PAGE = `${DEFAULT_PER_PAGE}`;
export const ITEMS_PAGE_OFFSET = `${DEFAULT_PAGE_OFFSET}`;
export const ITEMS_PAGE_NUMBER = `${DEFAULT_PAGE_NUMBER}`;

export const API_FAILURE_STATUS = "failure";

export const getFirebaseFnEndpoint = ({ functionName }) => {
  return envConfig.FB_FUNC_SERVICE + "/" + functionName;
};
