import { getEnvConfig } from "../constants/EnvConfig";

export const getUserSessionData = (data) => {
    const { userData, signature, roleNames } = data;
    const courses = userData.enrollmentsInfo;

    const sessionData = {
        sections: [],
        userSignature: {
            accountId: userData.id,
            roles: roleNames,
            signTime: Date.now(),
            signature
        },
    }

    const activeSections = [];

    if (courses) {
        Object.values(courses).forEach((course) => {
            const currentCourseEnrollment = Object.values(course).find((enr) => enr.status === 1);

            if (currentCourseEnrollment) {
                activeSections.push(currentCourseEnrollment.sCode);
            }
        });
    }

    if (userData.sectionsInfo && Array.isArray(userData.sectionsInfo)) {
        for (const sec of userData.sectionsInfo) {
            if (activeSections.includes(sec.sCode)) {
                const roles = mapRolesToLivePollingRoles(sec.roles)
                sessionData.sections.push({ ...sec, roles, sectionCode: sec.sCode, startDate: Date.now(), endDate: Date.now() + 10 * 24*60*60*1000 });
            }
        }
    }

    updateSessionStorage(sessionData);
    return sessionData
}

const mapRolesToLivePollingRoles = (roles) => roles.map((role) => getLivePollingRole(role));

const getLivePollingRole = (role) => {
    const roleLowerCase = role.toLowerCase();
    if (roleLowerCase === 'i') {
        return 'instructor';
    } else if (roleLowerCase === 's') {
        return 'student';
    }

    return role;
}

export const updateSessionStorage = (sessionData) => {
    if (sessionData.sections) {
        sessionStorage.setItem('sectionCodes', JSON.stringify(sessionData.sections))
    }

    if (sessionData.userSignature) {
        sessionStorage.setItem('userSignature', JSON.stringify(sessionData.userSignature));
    }

    sessionStorage.setItem('oerServerURL', 'https://qa.lrnr.us');
    sessionStorage.setItem('contentServerURL', 'https://qa-api.lrnr.us');
    sessionStorage.setItem('pollingServerURL', 'https://qa.lrnr.us/mybank');
    sessionStorage.setItem('pollingURL', 'https://qa.lrnr.us');
    sessionStorage.setItem('beamPortUrl', 'https://qa.lrnr.us');
    sessionStorage.setItem('livePortUrl', 'https://pollingdev.lrnrglobal.com:9092');
}