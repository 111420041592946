import {
  LISTEN_TO_BEAM,
  CREATE_USER_BEAM,
  ACTION_SAVE_USER_RESPONSE,
  JOIN_BEAM_LIVE_CHANNEL_ACTION,
  LISTEN_TO_USERBEAM_SCREENS_CHANGES,
  ACTION_TO_JOIN_BEAM
} from "./constants";
import { call, put, fork, take, cancelled, takeLatest } from 'redux-saga/effects';
import {
  listenToBeamSuccess,
  listenToBeamFailure,
  createUserBeamSuccess,
  createUserBeamFailure,
  listenBeamScreensSuccess,
  listenBeamScreensFailure,
  listenToUserBeamScreensSuccess,
  actionToJoinBeam,
  joinBeamFailure,
  joinBeamSuccess,
  listenToUserBeamScreensFailure,
  saveUserResponseSuccess,
  saveUserResponseFailure
} from "./actions";
import { joinBeam } from "./joinUtil";
import { processItemResponse } from "./processItemResponseUtil";
import { eventChannel, END } from 'redux-saga'
import { joinBeamLiveChannel } from './utils';
import fb from 'firebase';
import { watchUserBeamScreen } from 'containers/Main/Beam/userBeamUtils';


export function* createSingleBeamListener(action) {
    console.log("in createSingleBeamListener saga", action);
    const requestInput = action.requestInput;
    const beamId = requestInput.beamId;
    let beam, userBeamDocument;
    let beamScreens = [], userBeamScreens = [];

    const getBeamPromise = ({ beamsCollection, firebase }) => {

        return new Promise((resolve, reject) => {
            let resolveOnce = (doc) => {
                resolveOnce = () => { };
                resolve(doc);
            };
            let beamWatcher = beamsCollection.doc(beamId).onSnapshot(function (beamDoc) {
                console.log(`${beamDoc.id} => ${beamDoc.data()}`);
                beam = beamDoc.data();
                beam['id'] = beamDoc.id;
                resolveOnce(beamDoc);
            }, reject)
        });
    }

    const setupBeamListener = ({ beamsCollection, firebase }) => {

        return eventChannel(emmitter => {

            let beamWatcher = beamsCollection.doc(beamId).onSnapshot(function (beamDoc) {
                if (beamDoc.exists) {
                    console.log(`${beamDoc.id} => ${beamDoc.data()}`);
                    beam = beamDoc.data();
                    beam['id'] = beamDoc.id;
                    emmitter(beam);
                } else {
                    console.log("No such beam with id", beamId);
                    emmitter("");
                }
            })

            return () => beamWatcher;
        })
    }

    const getUserBeamPromise = ({ userBeamsCollection, firebase }) => {

        console.log("getUserBeamPromise ",{userBeamsCollection, firebase })
        return new Promise((resolve, reject) => {
            let resolveOnce = (doc) => {
                resolveOnce = () => { };
                resolve(doc);
            };
            let userBeamWatcher = userBeamsCollection.
                where("beam_doc_ref_id", "==", beamId).
                where("created_by", "==", firebase.userSignature.accountId).
                get().then(function (querySnapshot) {
                    console.log("in userBeam querySnapshot", querySnapshot);
                    querySnapshot.forEach(function (beamDoc) {
                        console.log("userBeam in querySnapshot", `${beamDoc.id} => ${beamDoc.data()}`);
                        if (!beamDoc.data()) {
                            return;
                        }
                        userBeamDocument = beamDoc.data();
                        userBeamDocument['id'] = beamDoc.id;
                    })
                    // resolveOnce(userBeamDocument);
                    resolve();
                }).catch(function (error) {
                    console.log("Error in getting userBeam", error);
                })
        });
    }

    const getBeamScreensPromise = ({ beamsCollection, firebase }) => {
        return new Promise((resolve, reject) => {
            let resolveOnce = (beamScreens) => {
                resolveOnce = () => { };
                resolve(beamScreens);
            };
            let beamScreensWatcher = beamsCollection.doc(beamId).collection("screens")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        console.log(`${doc.id} => ${doc.data()}`);
                        let beamScreen = doc.data();
                        if (!beamScreen) {
                            return;
                        }
                        beamScreen['id'] = doc.id;
                        beamScreens.push(beamScreen);
                        console.log(doc);
                    });
                    // resolveOnce(beamScreens);
                    resolve();
                })
                .catch(function (error) {
                    console.log("Error getting screens: ", error);
                });
        });
    }

    const getUserBeamScreensPromise = ({ userBeamsCollection, firebase, userBeamDocument }) => {
        console.log("in getUserBeamScreensPromise");

        return new Promise((resolve, reject) => {
            let resolveOnce = (beamScreens) => {
                resolveOnce = () => { };
                resolve(userBeamScreens);
            };
            let userBeamScreensWatcher = userBeamsCollection.doc(userBeamDocument.id).collection("user_screens")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        console.log(`${doc.id} => ${doc.data()}`);
                        let userBeamScreen = doc.data();
                        userBeamScreen['id'] = doc.id;
                        userBeamScreens.push(userBeamScreen);
                        console.log(doc);
                    });
                    // resolveOnce(userBeamScreens);
                    resolve();
                })
                .catch(function (error) {
                    console.error("Error getting user screens: ", error);
                });
        });
    }

    try {

        //   firebase.initializeApp(firebaseConfig);
        const firebase = requestInput.firebase;
        const beamsCollection = firebase.beamsCollection;
        const userBeamsCollection = firebase.userBeamsCollection;


        yield Promise.all([
            //  getBeamPromise({ beamsCollection, firebase }),
            // setupBeamListener({ beamsCollection, firebase }),
            // getBeamScreensPromise({ beamsCollection, firebase }),
            getUserBeamPromise({ userBeamsCollection, firebase })
            
        ]).then(function (values) {
            console.log("values", values);
            
        });
        if (userBeamDocument){
            yield getUserBeamScreensPromise({ userBeamsCollection, firebase, userBeamDocument })
        }else{
            //handle exception
        }

        const channel = yield call(setupBeamListener, { beamsCollection, firebase });
        try {
            while (true) {
                const beam = yield take(channel);
                console.log ("BEAM",beam);
                if ( typeof beam == "undefined" || beam == "" || !beam) {
                    yield put(listenToBeamFailure(requestInput, "Beam not found"));
                } else {
                    yield put(listenToBeamSuccess(requestInput, { beam, userBeamDocument, beamScreens, userBeamScreens }));
                    yield fork(watchBeamScreen, { beam, userBeamDocument, firebase, beamsCollection, requestInput });
                }
            }
        } finally {
            if (yield cancelled()) {
                channel.close();
            }
        }

        // yield put(listenToBeamSuccess(requestInput, { beam, userBeamDocument, beamScreens, userBeamScreens }))
    } catch (err) {
        console.log("ERROR: ", err);
        yield put(listenToBeamFailure(requestInput, err));
    }
}


function* watchBeamScreen({ beam, userBeamDocument, firebase, beamsCollection, requestInput }) {
    console.log("in watchBeamScreen");
    const screenChannel = yield call(setupBeamScreenEventChannelListener, { beam, userBeamDocument, firebase, beamsCollection, requestInput });
    try {
        while (true) {
            const beamScreens = yield take(screenChannel);
            console.log("beamScreens", beamScreens)
            yield put(listenBeamScreensSuccess(requestInput, { beamScreens }));
        }
    } catch (err) {
        console.log("ERROR in userBeamUtils", err);
        yield put(listenBeamScreensFailure(requestInput, err));

    } finally {
        console.log("screenChannel terminated")
        if (yield cancelled()) {
            screenChannel.close();
        }
    }
    // )
}

export function setupBeamScreenEventChannelListener({ beam, userBeamDocument, firebase, beamsCollection, requestInput }) {

    return eventChannel(emmitter => {


        const beamId = beam.id;
        let beamScreensWatcher = beamsCollection.doc(beamId).collection("screens")
            // .get()
            .onSnapshot(function (querySnapshot) {
                const beamScreens = [];
                querySnapshot.forEach(function (doc) {
                    console.log(` setupBeamScreenEventChannelListener ${doc.id} => ${doc.data()}`);
                    let beamScreen = doc.data();
                    if (!beamScreen) {
                        return;
                    }
                    beamScreen['id'] = doc.id;
                    beamScreens.push(beamScreen);
                });
                emmitter(beamScreens);
            })
            // .catch(function (error) {
            //     console.log("Error getting screens: ", error);
            // });
        return () => beamScreensWatcher;
    })
}

export function* listenUserBeamScreensChanges(action) {
    const requestInput = action.requestInput
    const { userBeamDocument, currentBeam, firebase } = requestInput
    //listen to userBeam screens
    const userBeams = [];
    try {
        userBeams.push(userBeamDocument);
        const userBeamsCollection = firebase.userBeamsCollection;
        console.log("calling fork watchUserBeamScreen");

        yield fork(watchUserBeamScreen, { userBeams, currentBeam, firebase, userBeamsCollection, requestInput });
        yield put(listenToUserBeamScreensSuccess(requestInput, true))
    } catch (err) {
        console.error("Error in listening to userBeam screens", err);
        yield put(listenToUserBeamScreensFailure(requestInput, err))   
    }
}
function* joinThisBeam(action) {
  let response = yield joinBeam(action);
  console.log(response);
  const requestInput = action.requestInput;
  if (response.result.status == "success") {
    const { userBeamDoc } = response.result.message;
    console.log(userBeamDoc);
    yield put(
      joinBeamSuccess(requestInput, { userBeamDoc, status: response.code })
    );
  } else if (response.result.status === "failure" || response.result.status === 'Failure') {
    yield put(joinBeamFailure(requestInput, response.result));
  }
}
function* saveUserResponse(action) {
  let response = yield processItemResponse(action);
  console.log(response);
  const requestInput = action.requestInput;
  const { beamScreenRefId, theResponse } = requestInput;
  // handle try catch exception
    if (response.status == "ERR_UNEXPECTED") {
    let err = response;
    yield put(saveUserResponseFailure(requestInput, err));
  } else if (response.result.status == "success") {
    let individualResult = response.result.data.marksSheetDetails.individualResult;
    let eval_result = response.result.data.marksSheetDetails.eval_result;
    if(individualResult)
    {
        eval_result["individualResult"] = individualResult;
    }
    // let eval_result = {
    //   gained_efficacy_points:
    //     response.result.data.marksSheetDetails.eval_result
    //       .gained_efficacy_points,
    //   gained_participation_points:
    //     response.result.message.eval_result.gained_participation_points,
    //   gained_points: response.result.message.eval_result.gained_points,
    //   individualResult,
    //   result: response.result.message.eval_result.result
    // };
    console.log(beamScreenRefId, theResponse, eval_result, response.result.message.createdAt);
    const userScreen = {
      beam_screen_ref_id: beamScreenRefId,
      response: theResponse,
      eval_result,
      created_at: response.result.data.marksSheetDetails.created_at,
      ...response.result.data.marksSheetDetails
    };
    const userBeamResultAggregate = response.result.data.eval_result_aggregate;
    yield put(
      saveUserResponseSuccess(requestInput, {
        userScreen,
        userBeamResultAggregate
      })
    );
  } else if (response.result.status == "failure") {
    let err = response.result;
    yield put(saveUserResponseFailure(requestInput, err));
  }
}


function* createUserBeam(action) {

    console.log("in createSingleBeamListener saga", action);
    const requestInput = action.requestInput;
    const {firebase, currentBeam, userBeamDocument} = requestInput
    // const userBeamDocument = requestInput.userBeamDocument;
    const userBeamsCollection = firebase.userBeamsCollection;
    let err;

    //first check if the userBeam already exists
    const beamId = userBeamDocument.beam_doc_ref_id
    const accountId = userBeamDocument.created_by;
    console.log("BeamId, accountId",beamId,accountId);
    const snapshot = yield userBeamsCollection.where('beam_doc_ref_id', '==', beamId).where('created_by','==',accountId).get();
    if (!snapshot.empty) {
        
        yield put(createUserBeamFailure(requestInput, {code:'ALREADY_JOINED', err: new Error("Already joined the beam; try rejoining")}));
        return;
    } 
    console.log("about to add userBeamDoc", userBeamDocument)
    yield userBeamsCollection.add(userBeamDocument).then(function (userBeamRef) {
        console.log("userBeamDocument created", userBeamRef.id)
        userBeamDocument['id'] = userBeamRef.id;
    }).catch(function (error) {
        console.error("error adding userBeamDoc ", error)
        err = error;
    })
    console.log("userBeamDocument", userBeamDocument)
    if (userBeamDocument.id) {
        yield put(createUserBeamSuccess(requestInput, { userBeamDocument }));
    //     //listen to userBeam screens
    //     const userBeams = [];
    //     userBeams.push(userBeamDocument);
        
    //     yield fork(watchUserBeamScreen, {userBeams, currentBeam, firebase, userBeamsCollection, requestInput });
    } else {
        yield put(createUserBeamFailure(requestInput, err));
    }
}
export default function* watchStudentBeamActions() {

    yield takeLatest(LISTEN_TO_BEAM, createSingleBeamListener);
    yield takeLatest(CREATE_USER_BEAM, createUserBeam);
    yield takeLatest(ACTION_SAVE_USER_RESPONSE, saveUserResponse);
    yield takeLatest(JOIN_BEAM_LIVE_CHANNEL_ACTION, joinBeamLiveChannel);
    yield takeLatest(LISTEN_TO_USERBEAM_SCREENS_CHANGES, listenUserBeamScreensChanges);
    yield takeLatest(ACTION_TO_JOIN_BEAM, joinThisBeam);
}