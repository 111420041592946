import { isValidFib2 } from "../validators/item/fib2Validator";
import { shuffleArray } from "../../containers/Main/Beam/utils";
import { changedTopicItemInfoForFib2 } from "../topicItemInfoBuilders/fib2";
import { ifOneOrMoreOptionsDoNotHaveCorrectAns } from "../itemConfigSelectables";
import { DEFAULT_FIB2_ASSESSMENT_CONFIG } from "../../util/constants";
const clone = require("rfdc")();

const checkIfContainsCorrectAns = itemContent =>
  itemContent.answers.some(singleAns => singleAns.weight > 0);

const formInputTypesObject = itemContent => {
  const finalObject = {};
  itemContent.answers.forEach(
    singleAnswer =>
      (finalObject[singleAnswer.challengeId] =
        singleAnswer?.metadata?.dataType || "text")
  );

  return finalObject;
};

export const changeAsFib = itemContent => ({
  stimulus: itemContent.stimulus,
  hasCorrectAnswers: checkIfContainsCorrectAns(itemContent),
  inputTypeObject: formInputTypesObject(itemContent)
});

export const changeAsDragDrop = itemContent => {
  const allTheOptions = [];
  itemContent.answers.forEach(ans => {
    allTheOptions.push({ id: ans.id, value: ans.value });
    if (ans.distractors) {
      ans.distractors.forEach(distractor => allTheOptions.push(distractor));
    }
  });
  const shuffledOptions = shuffleArray(allTheOptions);
  return {
    stimulus: itemContent.stimulus,
    options: shuffledOptions,
    hasCorrectAnswers: checkIfContainsCorrectAns(itemContent)
  };
};

export const changeAsDropDown = itemContent => {
  const mappedOptions = [];
  itemContent.answers.forEach(ans => {
    const allTheOptionsForOneOption = [{ id: ans.id, value: ans.value }];
    if (ans.distractors) {
      ans.distractors.forEach(distractor =>
        allTheOptionsForOneOption.push(distractor)
      );
    }
    const allShuffledOptions = shuffleArray(allTheOptionsForOneOption);
    mappedOptions.push({
      challengeId: ans.challengeId,
      options: allShuffledOptions
    });
  });
  return {
    stimulus: itemContent.stimulus,
    options: mappedOptions,
    hasCorrectAnswers: checkIfContainsCorrectAns(itemContent)
  };
};

const getFib2ItemContent = (item, topicItemInfo) => {
  let convertedContent;
  if (
    topicItemInfo.assessment_config.promptSelection ||
    typeof topicItemInfo.assessment_config.activity_mode === "undefined" ||
    topicItemInfo.assessment_config.activity_mode === "fillInTheBlanks"
  ) {
    convertedContent = changeAsFib(item.content);
  } else if (topicItemInfo.assessment_config.activity_mode === "dragAndDrop") {
    convertedContent = changeAsDragDrop(item.content);
  } else {
    convertedContent = changeAsDropDown(item.content);
  }

  return convertedContent;
};

export const createFIB2AssessmentItem = ({
  item,
  topicItemInfo,
  userSignature
}) => {
  const isValid = isValidFib2({ item });
  if (!isValid) {
    return null;
  }

  const finalTopicItemInfo =
    topicItemInfo.assessment_config.activity_mode === "random" ||
    topicItemInfo.assessment_config.promptSelection ||
    ifOneOrMoreOptionsDoNotHaveCorrectAns(item)
      ? changedTopicItemInfoForFib2(item, topicItemInfo)
      : topicItemInfo;

  const finalAssessmentItem = {
    _id: item._id,
    type: "assessment",
    subtype: "fib2",
    content: getFib2ItemContent(item, finalTopicItemInfo),
    attachments: item.attachments ? item.attachments : [],
    uuid: item.uuid,
    title: item.title ? item.title : "",
    assessment_config: clone(finalTopicItemInfo.assessment_config) || {
      ...DEFAULT_FIB2_ASSESSMENT_CONFIG
    },
    owner_uuid: userSignature.accountId
  };
  return finalAssessmentItem;
};
