import {
  LISTEN_TO_BEAM,
  LISTEN_TO_BEAM_FAILURE,
  LISTEN_TO_BEAM_SUCCESS,
  CREATE_USER_BEAM,
  CREATE_USER_BEAM_FAILURE,
  CREATE_USER_BEAM_SUCCESS,
  ACTION_SAVE_USER_RESPONSE,
  SAVE_USER_RESPONSE_SUCCESS,
  SAVE_USER_RESPONSE_FAILURE,
  LISTEN_BEAM_SCREENS_SUCCESS,
  LISTEN_BEAM_SCREENS_FAILURE,
  JOIN_BEAM_LIVE_CHANNEL_ACTION,
  JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS,
  JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE,
  LISTEN_TO_USERBEAM_SCREENS_CHANGES,
  LISTEN_TO_USERBEAM_SCREENS_FAILURE,
  LISTEN_TO_USERBEAM_SCREENS_SUCCESS,
  SEND_PRESENCE_MSG_SUCCESS,
  ACTION_TO_JOIN_BEAM,
  JOIN_BEAM_FAILURE,
  JOIN_BEAM_SUCCESS,
  ACTION_TO_LOADING,
  SET_JOIN_BEAM_STATUS,
  SET_SAVE_USER_RESPONSE_ERROR,
  STORE_IMAGE_DIMENSIONS
} from "./constants";

export function actionToListenBeam(requestInput) {
    return {
        type: LISTEN_TO_BEAM,
        requestInput,
    };
}

export function listenToBeamSuccess(requestInput, response) {
    return {
        type: LISTEN_TO_BEAM_SUCCESS,
        requestInput,
        response,
    };
}

export function listenToBeamFailure(requestInput, err) {
    return {
        type: LISTEN_TO_BEAM_FAILURE,
        requestInput,
        err,
    };
}

export function actionToCreateUserBeam(requestInput) {
    return {
        type: CREATE_USER_BEAM,
        requestInput,
    };
}

export function createUserBeamSuccess(requestInput, response) {
    return {
        type: CREATE_USER_BEAM_SUCCESS,
        requestInput,
        response,
    };
}

export function createUserBeamFailure(requestInput, err) {
    return {
        type: CREATE_USER_BEAM_FAILURE,
        requestInput,
        err,
    };
}

export function actionToSaveUserResponse(requestInput) {
    return {
        type: ACTION_SAVE_USER_RESPONSE,
        requestInput,
    };
}

export function saveUserResponseSuccess(requestInput, response) {
    return {
        type: SAVE_USER_RESPONSE_SUCCESS,
        requestInput,
        response,
    };
}

export function saveUserResponseFailure(requestInput, err) {
    return {
        type: SAVE_USER_RESPONSE_FAILURE,
        requestInput,
        err,
    };
}


export function listenBeamScreensSuccess(requestInput, response) {
    return {
        type: LISTEN_BEAM_SCREENS_SUCCESS,
        requestInput,
        response,
    };
}

export function listenBeamScreensFailure(requestInput, err) {
    return {
        type: LISTEN_BEAM_SCREENS_FAILURE,
        requestInput,
        err,
    };
}

export function actionToJoinBeamLiveChannel(requestInput) {
    return {
        type: JOIN_BEAM_LIVE_CHANNEL_ACTION,
        requestInput,
    };
}

export function joinBeamLiveChannelSuccess(requestInput, response) {
    return {
        type: JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS,
        requestInput,
        response,
    };
}

export function joinBeamLiveChannelFailure(requestInput, err) {
    return {
        type: JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE,
        requestInput,
        err,
    };
}


export function listenToUserBeamScreensChanges(requestInput) {
    return {
        type: LISTEN_TO_USERBEAM_SCREENS_CHANGES,
        requestInput,
        requestInput,
    };
}


export function listenToUserBeamScreensSuccess(requestInput, response) {
    return {
        type: LISTEN_TO_USERBEAM_SCREENS_SUCCESS,
        requestInput,
        response,
    };
}

export function listenToUserBeamScreensFailure(requestInput, err) {
    return {
        type: LISTEN_TO_USERBEAM_SCREENS_FAILURE,
        requestInput,
        err,
    };
}

export function actionToJoinBeam(requestInput) {
  return {
    type: ACTION_TO_JOIN_BEAM,
    requestInput
  };
}

export function joinBeamSuccess(requestInput, response) {
  return {
    type: JOIN_BEAM_SUCCESS,
    requestInput,
    response
  };
}

export function joinBeamFailure(requestInput, err) {
  return {
    type: JOIN_BEAM_FAILURE,
    requestInput,
    err
  };
}
export function setJoinBeamStatus(requestInput, err) {
  return {
    type: SET_JOIN_BEAM_STATUS,
    requestInput,
    err
  };
}

export function setSaveUserResponseError(requestInput, err) {
  return {
    type: SET_SAVE_USER_RESPONSE_ERROR,
    requestInput,
    err
  };
}

export function sendBeamPresenceMsgSuccess(requestInput, response) {
    return {
        type: SEND_PRESENCE_MSG_SUCCESS,
        requestInput,
        response,
    };
}

export function setImageDimensions(requestInput) {
    return {
        type: STORE_IMAGE_DIMENSIONS,
        requestInput,
    }
}