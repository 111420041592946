/**
 * Gets the tests of the course from database
 */

import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { DEL_ITEM, FETCH_ITEMS, ADD_ITEM, UPDATE_LIST_ITEM,UPDATE_SAVED_LIST_ITEM } from './constants';
import { addItemSuccessActionCreator, fetchItemsSuccess, fetchItemsFailure, addItemFailureActionCreator } from './actions';
import { ITEM_SUBTYPE_DIAGRAM, ITEM_SUBTYPE_WHITEBOARD } from 'constants/ItemConstants';
import { delItemSuccess, delItemFailure } from './actions';
import {updateListItemSuccess} from "./actions";
import request from 'util/ApiUtils';
import { uuidv4 } from 'util/StringUtils'

/**
 * Server request/response handler
 */
export function* fetchItems(action) {

  console.log("fetchTopics action = ",action);
  const requestURL = action.config.API+'&per_page='+action.config.perPage+'&page='+action.page;

  try {
    // Call our request helper (see 'util/request')
    if (action.config.page > 10){
      return;
    }
    const topics = yield call(request, requestURL);
    yield put(fetchItemsSuccess(topics.body, action.config));
  } catch (err) {
    console.log('Failed to fetch items', err);
    yield put(fetchItemsFailure(err,action.config));
  }
}

/**
 * add Item
 */
function* addItemHandler(action) {

  console.log("addItem action = ",action);
  // const requestURL = action.config.API;

  try {

   const emptyItem = {
      topicItemInfo: {
        item_references_order:3,
        efficacy_factor:0,
        participation_factor:(action.actionInfo.itemSubtype===ITEM_SUBTYPE_WHITEBOARD)?0:.5,
        grade_points:(action.actionInfo.itemSubtype===ITEM_SUBTYPE_WHITEBOARD)?0:.5,
        timer_in_secs: -1
      },
      item: {
        type: action.actionInfo.itemType,
        subtype: action.actionInfo.itemSubtype,
        content: {
          id: uuidv4(),
          stimulus: ''
        },
        uuid: uuidv4(),
        relations: {
          topics: [
            action.actionInfo.topicId
          ]
        },
        owner_uuid: null,
        draftTopic: true,
        created_timestamp: 1576045595622,
        last_modified_timestamp: 1576045595622,
        status: 'draft',
        max_attempts: 1,
        version: 1.0,
        parent_version: 0.0,
        title: action.actionInfo.itemSubtype,
        measure: {
          difficulty: 100,
          blooms_level: 1,
          max_lrnr_points: 10
        },
        deleted: false,
        valid: false
      }
    }
    //let item = emptyItem;
    const requestURL = action.config.ADD_ITEM_API;

    let item = yield call(request, requestURL, { method: 'POST', body: JSON.stringify(emptyItem) });

    console.log("going to call success action creator");
   yield put(addItemSuccessActionCreator( action.actionInfo.topicId, action.actionInfo.itemIndex, item));
  } catch (err) {
    console.log("failed in addItemHandler", err)
    yield put(addItemFailureActionCreator(err,action.actionInfo.topicId));
  }
}

function* delItemHandler(action) {
  console.log("delItemHandler action = ",action);
  const requestURL = action.config.DEL_API_FN(action.item.uuid);
  console.log("DEL API", requestURL);
  try {

    const result = yield call(request, requestURL, { method: 'DELETE', body:'' });
    yield put(delItemSuccess(action.item, action.config));
  } catch (err) {
    yield put(delItemFailure(err,action.item, action.config));
  }
}

function* updateSavedItemHandler(action) {
  yield put(updateListItemSuccess(action.item, action.config));
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchTopicsListActions() {
  // Watches for FETCH_TOPICS action and calls getTopics when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(FETCH_ITEMS, fetchItems);
  yield takeLatest (ADD_ITEM, addItemHandler)
  yield takeLatest (DEL_ITEM, delItemHandler)
  yield takeLatest (UPDATE_SAVED_LIST_ITEM, updateSavedItemHandler)

}
