import React, { useState, useEffect, useReducer } from "react";
import LrnrMaterialSelect from "components/lrnr/LrnrMaterialSelect";
import LrnrDropdownSelect from "components/lrnr/LrnrDropdownSelect";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Spin } from 'antd';

import CheckedBox from "assets/images/lrnr-icons/selected_indicator_checkbox.svg";
import UncheckedBox from "assets/images/lrnr-icons/unchecked-box.svg";

import { actionToCreateBeam } from './actions';
import { defaultBeamObject } from './defaultBeamObject';
import { getScreensInfo, checkBeamExists, getCurrentBeam } from './utils';
import { actionToFetchBeams } from 'containers/Main/Beam/actions';
import { isUserInstructor} from 'util/AppUtil'
import { useFetchComponentItems } from './useFetchComponentItems';
import { generateRandomPasscode } from 'util/StringUtils'
import { updateBeamState } from './beamUtils';
import { isGradable } from 'data/validators/quizBeamValidator';
import { isQuizBeammable } from 'data/validators/quizBeamValidator';


function BeamPopup({ dispatch, beamSource, styleName, data, patchItem, beam, firebase, course, location, quizSummaryStore }) {

	const [isFormLoaded, setIsFormLoaded] = useState(false);
	const [beamBeingCreated, setBeamBeingCreated] = useState(false);
	const [sectionData,setSectionData]=useState();

	//in case componentItems is not set
	//if(beamSource.componentItems===null){
		console.log("beamSource null",beamSource.componentItems);
		beamSource=useFetchComponentItems(beamSource);
	//}


	let gradableDetails=isGradable(beamSource);
	console.log("gradableDetails",gradableDetails);
	console.log("beamSource",beamSource);
	// theNewState={use_passcode: false,passcode:0, isGraded:gradableDetails.isTotallyGradable, efficacyEnabled: gradableDetails.isEfficacy,
	// 	participationEnabled:gradableDetails.isParticipation, beamMode:'class', sectionSelected:'no'};
	const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      use_passcode: false,
      passcode: 0,
      isGraded: gradableDetails.isTotallyGradable,
      efficacyEnabled: gradableDetails.isEfficacy,
      participationEnabled: gradableDetails.isParticipation,
	  canChangeGrading:gradableDetails.isTotallyGradable,
      beamMode: "class",
      sectionSelected: "no"
    }
  );

	//const [state, setState] =useState(theNewState)

	// console.log("Beams ", beam);
	// console.log("beamSource ", beamSource);
	const beamListenerCreated = beam.beamListenerCreated;
	const beams = beam.beams;
	const history = useHistory();
	let currentBeam = null;
	//console.log("BeamBeingcreate", beamBeingCreated);
	const beamCreationSuccessful = beam.beamCreationSuccessful;

	const validBeamSource = () => {
		if (beamSource && beamSource.type && beamSource.component && beamSource.componentItems){
			return true;
		}else{
			return false;
		}
	}



	let theSectionCodes=JSON.parse(window.sessionStorage.getItem('sectionCodes'));

	useEffect(() => {
		console.log("getting form dependencies...classCodes")
		if (!course.courseId && beamListenerCreated ) {
			setIsFormLoaded(true);
		} else if (course.areClassSectionsFetched && beamListenerCreated ) {
			setIsFormLoaded(true);
		}

		if (theSectionCodes){
			var date = new Date();
			var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
		 	date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
			var currentGmtDate=new Date(now_utc);
		 	var currentGmtTime= currentGmtDate.getTime();
			let sectionsData=[];
			let tempNoSection={};
			tempNoSection.value="no";
			tempNoSection.title="Select Class Section";
			sectionsData.push(tempNoSection);
			// let tempAllSectionData={};
			// tempAllSectionData.value="all";
			// tempAllSectionData.title="All sections";
			// sectionsData.push(tempAllSectionData);
			//tempAllSectionData.sectionsList=[];
			for(let i=0;i<theSectionCodes.length;i++){
				let theSection=theSectionCodes[i];
				if(theSection.endDate>currentGmtTime){
					let tempSectionData={};
					tempSectionData.value=theSection.sectionCode;
					tempSectionData.title=theSection.name;
					//tempAllSectionData.sectionsList.push(theSection.sectionCode);
					sectionsData.push(tempSectionData);
				}

			}
			console.log("set classCodes", sectionData)
			setSectionData(sectionsData);

		}

		if (! beamListenerCreated){
			//fetch beams
			console.log("in popup useEffect ")
			let isInstructor = false;
			const courseId = course.courseId;
			if (firebase.isAuthenticated && isUserInstructor(firebase.userSignature.roles) && !beam.beamListenerCreated) {
				console.log("fetching beams ");
				dispatch(actionToFetchBeams({ firebase, courseId,location }));
			}else{
				console.log("firebase  authenticateD?",firebase.isAuthenticated);
				console.log("isUserInstructor?",isUserInstructor(firebase.userSignature.roles));

			}
		}

	}, [beamListenerCreated]);

	useEffect(() => {
		console.log("useeffect beamSource components")
		gradableDetails=isGradable(beamSource);
		setState({
				isGraded:gradableDetails.isTotallyGradable, efficacyEnabled:gradableDetails.isEfficacy,
				participationEnabled:gradableDetails.isParticipation,canChangeGrading:gradableDetails.isTotallyGradable});
	},[beamSource.componentItems]);

	useEffect ( ()=> {
		console.log("state.section",state.sectionSelected);
		if (checkBeamExists(beams, beamSource, course, state.sectionSelected )) {
			console.log("checkBeamExists is true")
			currentBeam = getCurrentBeam(beams, beamSource, course, state.sectionSelected)
			console.log("in checkBeamExists", currentBeam);
			setState({use_passcode: currentBeam.state.use_passcode, passcode:currentBeam.state.passcode,
				isGraded:currentBeam.state.graded, efficacyEnabled: currentBeam.state.use_efficacy,
				participationEnabled:currentBeam.state.use_participation, beamMode:currentBeam.beam_mode});
		}
		else{
			setState({
				isGraded:gradableDetails.isTotallyGradable, efficacyEnabled:gradableDetails.isEfficacy,
				participationEnabled:gradableDetails.isParticipation});
		}
	},[state.sectionSelected] )

	if (currentBeam == null){
		console.log("currentBeam is null");

		currentBeam = getCurrentBeam(beams, beamSource, course, state.sectionSelected)

	}

	const handleGradedUpdate = (event) => {
		console.log("isGraded",event.target.value, currentBeam);
		const theSelectedtype= event.target.getAttribute("data-type");
		const isGradedSelection  = (theSelectedtype === "yes") ? true : false;
		if (currentBeam){
			const updateObject = { 'state.graded': isGradedSelection }
			updateBeamState({firebase, currentBeam, updateObject});
		}
		if(isGradedSelection){
			let participationEnabled=(gradableDetails.isParticipation)?true:false;
			let efficacyEnabled=(gradableDetails.isEfficacy)?true:false;
			setState({isGraded: isGradedSelection,efficacyEnabled:efficacyEnabled,participationEnabled:participationEnabled,canChangeGrading:true})
		}
		else
			setState({isGraded: isGradedSelection,efficacyEnabled:false,participationEnabled:false,canChangeGrading:false})
	}

	const handleEfficacyConfig = (event) => {
		var theEfficacyholder = event.target.closest(".gx-popup-beam-efficacy-holder");
		var theCurrentStatus = theEfficacyholder.getAttribute("data-efficacy");
		console.log("theEfficacyholder",theEfficacyholder)
		console.log("theCurrentStatus",theCurrentStatus)
		theCurrentStatus = (theCurrentStatus == "true") ? false : true;
		if (currentBeam){
			const updateObject = { 'state.use_efficacy': theCurrentStatus }
			updateBeamState({firebase, currentBeam, updateObject});
		}
		if(!theCurrentStatus && !state.participationEnabled)
			setState({efficacyEnabled:theCurrentStatus,isGraded:false});
		else
			setState({efficacyEnabled:theCurrentStatus});
	}

	const handleParticipationConfig = (event) => {
		var theParticipationholder = event.target.closest(".gx-popup-beam-participation-holder");
		var theCurrentStatus = theParticipationholder.getAttribute("data-participation");
		theCurrentStatus = (theCurrentStatus == "true") ? false : true;
		if (currentBeam){
			const updateObject = { 'state.use_participation': theCurrentStatus }
			updateBeamState({firebase, currentBeam, updateObject});
		}

		if(!theCurrentStatus && !state.efficacyEnabled)
			setState({participationEnabled:theCurrentStatus,isGraded:false});
		else
			setState({participationEnabled:theCurrentStatus});
	}

	const handleRequirePasscode = (event) => {
		var thePasswordRequiredholder = event.target.closest(".gx-popup-beam-require-password-holder");
		var theCurrentStatus = thePasswordRequiredholder.getAttribute("data-passcoderequired");
		theCurrentStatus = (theCurrentStatus == "true") ? false : true;
		setState({use_passcode:theCurrentStatus});
		console.log("in reuqire pc handler", state)
		if (state.passcode == 0 ){
			const randomPasscode = generateRandomPasscode();
			console.log("generating new random passcode", randomPasscode);
			setState({passcode: randomPasscode});
		}
		if (currentBeam){
			const updateObject = { 'state.use_passcode': theCurrentStatus, 'state.passcode': state.passcode}
			updateBeamState({firebase, currentBeam, updateObject});
		}
	}

	const handleBeamModeChange = (value) => {
		if (currentBeam){
			const updateObject = { 'beam_mode': value}
			updateBeamState({firebase, currentBeam, updateObject});
		}
		setState({beamMode:value});
	}

	const handleSectionsSelection = (value) => {
		console.log(value);

		setState({sectionSelected:value});
	}

	const getTheSectionData = () =>{
		var theTempSections;
		if(state.sectionSelected==="all"){

			var tempdata=sectionData.find(tempSection => {
				return tempSection.value === "all";
			})
			theTempSections=tempdata.sectionsList;
		}
		else{
			theTempSections=[];
			theTempSections.push(state.sectionSelected);
		}

		return theTempSections;
	}

	const getBeamPage = ({beam}) => {
		if (course.courseId){
			return '/courses/'+course.courseId+'/author/beams/'+ beam.id
		}else{
			return '/author/beams/'+ beam.id
		}
	}
	const handleStartBeam = (event) => {
		if(!beamBeingCreated){
			//fire beam creation
			setBeamBeingCreated(true);
			if (checkBeamExists(beams, beamSource, course, state.sectionSelected)) {
				console.log("going to push beam")
				currentBeam = getCurrentBeam(beams, beamSource, course, state.sectionSelected)
				console.log("currentBeam", currentBeam);
				history.push({pathname: getBeamPage({beam:currentBeam}), state:state});
				return;
			}
			const newBeamObject = defaultBeamObject();
			console.log("New beam", newBeamObject);
			newBeamObject.collaborator_uuids.push(firebase.userSignature.accountId);
			newBeamObject.created_by=firebase.userSignature.accountId;
			newBeamObject.title = beamSource.component.title;
			newBeamObject.desc = beamSource.component.desc;
			if (course.courseId) newBeamObject.institution_course = course.courseId;
			if (beamSource.type === 'quiz') newBeamObject.components_used.quizzes.push(beamSource.component.uuid);
			if (beamSource.type === 'slidedeck') newBeamObject.components_used.slidedeck.push(beamSource.component.uuid);
			newBeamObject.state.use_passcode = state.use_passcode;
			if (state.use_passcode) {
				newBeamObject.state.passcode = state.passcode;
			}
			newBeamObject.state.graded = state.isGraded;
			if (state.isGraded) {
				newBeamObject.state.use_efficacy = state.efficacyEnabled;
				newBeamObject.state.use_participation = state.participationEnabled;
			}
			newBeamObject.beam_mode = state.beamMode;


			newBeamObject.section_codes=getTheSectionData();
			const sectionInfo = {};
			getTheSectionData().forEach( (sectionCode)=> {
				const sectionCodeInfo = theSectionCodes.find((sectionInfo)=> {
					return sectionInfo.sectionCode === sectionCode
				})
				//TODO - if null throw error
				sectionInfo[sectionCode]=sectionCodeInfo?sectionCodeInfo:{}
			})
			newBeamObject.section_codes_info=sectionInfo;
			const userSignature = firebase.userSignature;
			console.log("beamSource",beamSource);
			const screensInfo = getScreensInfo({beamSource, userSignature});
			console.log("screensInfo", screensInfo);
			if (!screensInfo || !Array.isArray(screensInfo.screens) || !screensInfo.screens.length > 0 ){
				console.log("Invalid screensInfo");
				return;
			}
			const screenAnswers = screensInfo.screenAnswers
			newBeamObject.screens_order = screensInfo.screens_order;
			const screens = screensInfo.screens;
			const haltedScreenItems = screensInfo.haltedScreenItems;
			console.log("Beam object 2b created", newBeamObject);
			
			dispatch(actionToCreateBeam({ newBeamObject, firebase, beamSource, screens, course , screenAnswers, haltedScreenItems }))
		}

	}

	const ResumeOrCreateButton = () => {
		console.log("ResumeOrCreate");
		return (
			<button type="button" className="ant-btn gx-lrnr-general-icon-title-btn gx-lrnr-beam-start-btn h6-lrnr-typo-new" onClick={handleStartBeam}>
				{(checkBeamExists(beams, beamSource, course, state.sectionSelected) ? "Resume" : "Start")}
			</button>
		)
	}

	return (
		<div className="gx-popup-beam-start" >
			{(gradableDetails.isTotallyGradable)?(
				<div className="gx-popup-beam-config">
					<div className="gx-popup-beam-graded-holder" data-graded={state.isGraded}>
						<div className={`gx-popup-beam-config-name h6-lrnr-typo-new`}>
							Graded:
						</div>
						<div className={`gx-popup-beam-graded-switch-area h6-lrnr-typo-new`}>
							<button type="button" className={`ant-btn gx-lrnr-beam-graded-switch-btn ${(state.isGraded)?"active":""}  h6-lrnr-typo-new`} data-type="yes" onClick={(state.isGraded)?null:handleGradedUpdate}>
								Yes
							</button>
							<button type="button" className={`ant-btn gx-lrnr-general-img-icon-btn gx-lrnr-beam-graded-switch-btn ${(!state.isGraded)?"active":""}  h6-lrnr-typo-new`} data-type="no" onClick={(!state.isGraded)?null:handleGradedUpdate}>
								No
							</button>
						</div>
					</div>

					<div className="gx-popup-beam-grading-config">
						<div className="gx-popup-beam-efficacy-holder" data-efficacy={state.efficacyEnabled}>
							<button disabled={!state.canChangeGrading} type="button" className={`ant-btn gx-lrnr-general-icon-title-btn gx-lrnr-option-status-icon-btn ${(!gradableDetails.isEfficacy)?"disabled":""}`} onClick={(gradableDetails.isEfficacy)?handleEfficacyConfig:null}>
								<img className="gx-card-item-correct-answer-indicator" src={(gradableDetails.isEfficacy && state.efficacyEnabled) ? CheckedBox : UncheckedBox} alt="option status image" />
							</button>
							<span className="gx-popup-beam-config-name text-secondary-lrnr-small-typo gx-text-left">Efficacy</span>
						</div>
						<div className="gx-popup-beam-participation-holder" data-participation={state.participationEnabled}>
							<button disabled={!state.canChangeGrading} type="button" className={`ant-btn gx-lrnr-general-icon-title-btn gx-lrnr-option-status-icon-btn ${(!gradableDetails.isParticipation)?"disabled":""}`} onClick={(gradableDetails.isParticipation)?handleParticipationConfig:null}>
								<img className="gx-card-item-correct-answer-indicator" src={(gradableDetails.isParticipation && state.participationEnabled) ? CheckedBox : UncheckedBox} alt="option status image" />
							</button>
							<span className="gx-popup-beam-config-name text-secondary-lrnr-small-typo gx-text-left">Participation</span>
						</div>
					</div>
				</div>
			):(null)}
				<div className="gx-popup-beam-config">

				<div className="gx-popup-beam-selectors-holder">
					{(typeof sectionData!=="undefined" && sectionData.length>0)?(
						<LrnrDropdownSelect
							defaultSelect={state.sectionSelected}
							selectableData={sectionData}
							onChange={handleSectionsSelection}
							customStyle={{ width: '90%' }}
						/>
					):(null)}

				</div>
			</div>
			<div className="gx-popup-beam-config">
				<div className="gx-popup-beam-require-password-holder" data-passcoderequired={state.use_passcode}>
					<button type="button" className="ant-btn gx-lrnr-general-icon-title-btn gx-lrnr-option-status-icon-btn" onClick={handleRequirePasscode} >
						<img className="gx-card-item-correct-answer-indicator" src={(state.use_passcode) ? CheckedBox : UncheckedBox} alt="passcode required status image" />
					</button>
					<span className="gx-popup-beam-config-name h3-lrnr-typo-new gx-text-left">Require password</span>
				</div>
				{(state.use_passcode) ? (
					<div className="gx-popup-beam-password-holder h3-lrnr-typo-new">
						{state.passcode}
					</div>
				) : (null)

				}

			</div>
			{(state.sectionSelected!=="no")?(<div className="gx-popup-beam-action-holder">
				{isFormLoaded ? (<ResumeOrCreateButton />
				) : <Spin size='large' />}
				<h1>{beam.beamCreationFailed}</h1>
				<div>{beam.beamCreationFailed ? (<h1>{getBeamCreationErrMessage(beam)} </h1>) : ''}</div>
			</div>):(<div className="gx-popup-beam-action-holder"></div>)}
		</div>
	)
}

const getBeamCreationErrMessage = (beam) => {
	const genericMessage = "Error creating beam!!!";
	if (typeof beam.beamCreationError != 'undefined'){
		if (typeof beam.beamCreationError.message != 'undefined' && beam.beamCreationError.message != null ){
			return beam.beamCreationError.message;
		}
	}
	return genericMessage;
}
function mapStateToProps(state, params) {
	console.log("STATE in beam", state);
	const { beam, course, firebase, router, quizSummaryStore } = state;
	const location = router.location;
	return {
		beam,
		course,
		firebase,
		location,
		quizSummaryStore
	}
}

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
	withConnect,
)(BeamPopup);