import {
  FETCH_BEAM_SCREENS_ACTION,
  FETCH_BEAMS_ACTION,
  CREATE_BEAM_LISTENER_ACTION,
  CREATE_BEAM_ACTION,
  UPDATE_BEAM_STATE_ACTION,
  CREATE_NEW_SCREEN_ACTION,
  UPDATE_CURRENT_SCREEN_ACTION,
  LISTEN_TO_USER_BEAMS_ACTION,
  JOIN_BEAM_CHANNEL_ACTION,
  UPDATE_CORRECT_ANSWER_ACTION,
  UPDATE_CURRENT_SCREEN_ANNOTATIONS,
  UPDATE_BEAM_MAX_POINTS
} from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  createBeamSuccess,
  createBeamFailure,
  fetchScreensSuccess,
  fetchScreensFailure,
  fetchBeamsSuccess,
  fetchBeamsFailure,
  updateBeamStateSuccess,
  updateBeamStateFailure,
  updateCurrentScreenSuccess,
  updateCurrentScreenFailure,
  updateScreenStateSuccess,
  updateMaxPointsSuccess,
  updateMaxPointsFailure
} from "./actions";
import { defaultBeamObject } from "./defaultBeamObject";
import fb from "firebase";
import history from "util/history";
import { getBeamIdFromLocation } from "containers/Main/Beam/utils";
import {
  createNewScreenAction,
  updateCurrentScreenAction,
  updateCorrectAnswers,
  updateCurrentScreenAnnotations
} from "./screenUtils";
import { setupUserBeamsListener, joinBeamChannel } from "./userBeamUtils";
import { getAuthoringBeamUrlPath } from "util/LocationUtils";
import { getSolutionFromItem, isActionToFinishBeam, isActionToExitBeam } from "./utils";
import { getCurrentTimeInMilli } from "util/DateUtils";
// require("firebase/functions");
import request from "util/ApiUtils";
import {getTheTimeElapsed} from 'util/AppUtil';
import { getEnv } from "constants/EnvConfig";
import { getProcessBeamFinishFnUrl } from "firebase/config";

const clone = require('rfdc')();

export function* createBeamListener(action) {
  console.log("in creating beams listener saga", action);
  const functions = fb.functions();

  const requestInput = action.requestInput;
  try {
    const beamsCollection = requestInput.firebase.beamsCollection;
    console.log("beamsCollection", beamsCollection);

    beamsCollection
      .where("status", "==", "published")
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(`${doc.id} => ${doc.data()}`);
          const payload = doc.data();
          payload["key"] = doc.id;
          const beams = [];
          beams.push(doc);
          put(fetchBeamsSuccess(action.requestInput, { beams }));
        });
      });
    const beams = [defaultBeamObject];
    //yield put(fetchBeamsSuccess(action.requestInput, beams));
  } catch (err) {
    yield put(fetchBeamsFailure(action.requestInput, err));
  }
}

export function* fetchBeams(action) {
  console.log("in fetchBeams saga", action);
  const requestInput = action.requestInput;
  try {
    const courseId = requestInput.courseId;
    const firebase = requestInput.firebase;
    const beamsCollection = firebase.beamsCollection;
    const userSignature = firebase.userSignature;
    console.log("UserSign in fetchBeams", userSignature);
    let query = beamsCollection
      .where("state.status", "in", [
        "initialized",
        "published",
        "open",
        "inprogress",
        "paused"
      ])
      .where("collaborator_uuids", "array-contains", userSignature.accountId);
    if (courseId && courseId != "") {
      console.log("adding course condition ", courseId);
      query = query.where("institution_course", "==", courseId);
    }
    const beams = [];
    yield query
      .get()
      .then(function(querySnapshot) {
        if (querySnapshot.empty) {
          console.log("Query to Firestore returned no Beams");
        }
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          const beam = doc.data();
          beam.id = doc.id;
          beams.push(beam);
        });
      })
      .catch(err => {
        console.log("Error getting beams", err);
        throw err;
      });
    console.log("calling fetchBeamsSuccess ", beams);
    const location = action.requestInput.location;
    const beamId = getBeamIdFromLocation(location);
    let currentBeamObject = null;
    if (beamId) {
      const filteredBeams = beams.filter(beam => beam.id === beamId);
      currentBeamObject = filteredBeams[0];
    }
    console.log("currentBeamObject", currentBeamObject);
    yield put(
      fetchBeamsSuccess(action.requestInput, { beams, currentBeamObject })
    );
  } catch (err) {
    yield put(fetchBeamsFailure(action.requestInput, err));
  }
}

export function* createBeam(action) {
  console.log("in createBeam", action);
  const haltedScreenItems = action.requestInput.haltedScreenItems;
  const firebase = action.requestInput.firebase;
  const beamsCollection = firebase.beamsCollection;
  const newBeamObject = action.requestInput.newBeamObject;
  const course = action.requestInput.course;
  let err = null;

  const screens = action.requestInput.screens;
  const screenAnswers = action.requestInput.screenAnswers;
  try {
    let batch = firebase.firestore.batch();
    let newBeamDocRef = beamsCollection.doc();
    let newBeamDocUuid = newBeamObject.uuid;
    const userSignature = firebase.userSignature;

    batch.set(newBeamDocRef, newBeamObject);

    //create a doc for beam_solutions
    const beamSolutionsCollection = firebase.firestore.collection(
      "beam_solutions"
    );
    const beamSolutions = {};
    beamSolutions["beam_ref_id"] = newBeamDocRef;
    beamSolutions["created_by"] = userSignature.accountId;
    const currentTimeInMilli = getCurrentTimeInMilli();
    beamSolutions["created_at"] = currentTimeInMilli;
    console.log('beamSolution time log ',beamSolutions["created_at"])
    newBeamObject.created_at = currentTimeInMilli;
    newBeamObject.updated_at = currentTimeInMilli;
    const screenSolutions = {};
    let beamSolutionsCollectionRef = beamSolutionsCollection.doc();

    //loop  each haltedScreens and create a doc as subcollection
      const validHaltedScreens = [];
      for (let i = 0; i < haltedScreenItems.length; i++) {
          let haltedScreensRef = newBeamDocRef.collection("halted_screens").doc();
          let haltedScreen = haltedScreenItems[i];
          batch.set(haltedScreensRef, haltedScreen);
          validHaltedScreens.push(haltedScreen);
      }

    //loop each screens and create a document as part of subcollection

    const validScreens = [];

    for (let i = 0; i < screens.length; i++) {
      const item = screens[i].item;
      if (item != null) {
        let screensRef = newBeamDocRef.collection("screens").doc();
        let screen = screens[i];
        screen.beam_uuid = newBeamDocUuid;
        screen.beam_doc_ref_id = newBeamDocRef.id;
        screen.id = screensRef.id;
        screenSolutions[screen.item.uuid] = {
          screen_ref_id: screensRef,
          item_uuid: screen.item.uuid,
          item_subtype: screen.item.subtype,
          screen_oid: screen.oid,
          solutions: screenAnswers[i].answers || screenAnswers[i],
          measure: screen.topic_item_info.measure,
          feedbacks: screenAnswers[i].feedbacks || null
        };


        batch.set(screensRef, screen);
        validScreens.push(screen);
      }
    }

    if (!validScreens.length > 0) {
      throw new Error("No valid items found to create beam");
    }
    beamSolutions["solutions"] = screenSolutions;
    console.log("beamSoltions", beamSolutions);
    batch.set(beamSolutionsCollectionRef, beamSolutions);

    // Commit the batch


      // let theUpdatedDoc=yield call(sendTheScreenData,{batch, beamsCollection, newBeamDocRef, newBeamObject, validScreens, err});
      // console.log("theUpdatedDoc",theUpdatedDoc);
      // yield put(updateScreenStateSuccess(action.requestInput, theUpdatedDoc));
    yield batch
      .commit()
      .then(function() {
        console.log("Beam created written with ID: ", newBeamDocRef.id);
        newBeamObject.id = newBeamDocRef.id;
        newBeamObject.screens = validScreens;
        newBeamObject.halted_screens = validHaltedScreens;
        newBeamObject.beamSoln = { id: beamSolutionsCollectionRef.id, ...beamSolutions }
        newBeamObject.solutions = screenSolutions;
      })
      .catch(function(error) {
        console.error("Error creating beam: ", error);
        err = error;
      });
    // yield beamsCollection.add(newBeamObject)
    //     .then(function (docRef) {
    //         console.log("Beam created written with ID: ", docRef.id);
    //         newBeamObject.id=docRef.id;
    //     })
    //     .catch(function (error) {
    //         console.error("Error creating beam: ", error);
    //         err=error;
    //     })
  } catch (error) {
    err = error;
    console.log(err);
  }

  if (err) {
    yield put(createBeamFailure(action.requestInput, err));
  } else {
    yield put(createBeamSuccess(action.requestInput, { newBeamObject }));
    //re route to beam page
    console.log("about to re-route to beam start page");
    history.push(getAuthoringBeamUrlPath(course.courseId, newBeamObject.id));
  }
}

async function getBeamSolution(firestore, beamRefId) {
  try {
    const currentBeamSolSnap = await firestore.collection('beam_solutions').where('beam_ref_id', '==', beamRefId).get();
    console.log('current beam snap shot', beamRefId, currentBeamSolSnap);
    const currentBeamSolution = currentBeamSolSnap.docs[0].data().solutions;
    console.log('current beam solutions', currentBeamSolution)
    return { beamSoln: { id: currentBeamSolSnap.docs[0].id, ...currentBeamSolSnap.docs[0].data()}, currentBeamSolution};
  } catch(err) {
    console.log('Error while getting the beam solution', err);
    return {};
  }
}

function* fetchBeamScreens(action) {
  console.log("in fetchBeamScreens", action);
  const requestInput = action.requestInput;

  try {
    const firebase = requestInput.firebase;
    const beamsCollection = firebase.beamsCollection;
    const userSignature = firebase.userSignature;
    const currentBeam = requestInput.currentBeam;

    console.log("beamsCollection", beamsCollection);
    console.log("UserSign in fetchBeams", userSignature);
    console.log("currentBeam", currentBeam);
    const screens = [];
    yield beamsCollection
      .doc(currentBeam.id)
      .collection("screens")
      .get()
      .then(function(querySnapshot) {
        if (querySnapshot.empty) {
          console.log("Get call to Firestore returned no screens for the beam");
        }
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          const screen = doc.data();
          screen.id = doc.id;
          screens.push(screen);
        });
      });
    console.log("screens", screens);

    const beamRefId = beamsCollection.doc(currentBeam.id);
    const { beamSoln, currentBeamSolution } = yield call(() => getBeamSolution(firebase.firestore, beamRefId))

    yield put(fetchScreensSuccess(requestInput, { screens, beamSoln, currentBeamSolution }));
  } catch (err) {
    console.log("error", err);
    yield put(fetchScreensFailure(requestInput, err));
  }
}

function* updateBeamState(action) {
  console.log("#############################################");
  console.log("updateBeamState", action);
  const requestInput = action.requestInput;

  try {
    const firebase = requestInput.firebase;
    const beamsCollection = firebase.beamsCollection;
    const userSignature = firebase.userSignature;
    const currentBeam = requestInput.currentBeam;
    // const field = requestInput.field;
    // const value = requestInput.value;
    const updateObject = requestInput.updateObject;
    console.log("beamsCollection", beamsCollection);
    console.log("UserSign in fetchBeams", userSignature);
    console.log("currentBeam", currentBeam);
    console.log("updating beam state with ", updateObject);
    // let updateObject = {};
    // updateObject[`state.${field}`]=value;
    // updateObject['state.graded']=false;
    updateObject.updated_at = getCurrentTimeInMilli();
    const beamsRef = beamsCollection.doc(currentBeam.id);
    //check if the action is to finish the beam
    if (isActionToFinishBeam({ updateObject })) {
      //trigger the beam sync to Lrnr
      const screens = [];
      yield beamsRef
        .collection("screens")
        .get()
        .then(function(querySnapshot) {
          if (querySnapshot.empty) {
            console.log(
              "Get call to Firestore returned no screens for the beam"
            );
          }
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log("fetchBeamsSuccess", doc.id, " => ", doc.data());

            let screen = doc.data();

            screen.id = doc.id;
            screens.push(screen);
          });
        });




      console.log("Screens", screens);
      const beamedPoints = getBeamedItemPoints({ screens });
      updateObject["beamed_max_grade_points"] = beamedPoints;
      const processBeamFinishUrl = getProcessBeamFinishFnUrl();
      console.log("getProcessBeamFinishFnUrl", getProcessBeamFinishFnUrl);

      const processBeamFunctionPayload = {
        data: {
          beam_id: currentBeam.id,
          config: {
            env: getEnv()
          }
        }
      };
      // const processBeamFinishFn = fb.functions.httpsCallable('processBeamFinish');
      // console.log("about to call cloud function",processBeamFinishFn)
      // processBeamFinishFn({data:{beam_id:currentBeam.id}}).then(function(result) {
      //     console.log("processBeamFinishFn result",result)
      // }).catch(function(error){
      //     console.log("processBeamFinishFn error",error)
      // })

      fb.auth()
        .currentUser.getIdToken()
        .then(function(token) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          };
          const processBeamFinishFnResponse = request(processBeamFinishUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(processBeamFunctionPayload)
          });
          console.log(
            "processBeamFinishFnResponse",
            processBeamFinishFnResponse
          );
        });
    }
    else if (isActionToExitBeam({ updateObject })){
      let currentScreen;
      let currentScreenId;
      yield beamsRef
        .collection("screens")
        .get()
        .then(function(querySnapshot) {
          if (querySnapshot.empty) {
            console.log(
              "Get call to Firestore returned no screens for the beam"
            );
          }
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log("fetchBeamsSuccess", doc.id, " => ", doc.data());

            let screen = doc.data();
            if(screen.oid===currentBeam.state.current_screen_oid){

              currentScreenId=doc.id;
              console.log("GOT current screen id",currentScreenId);
              currentScreen=screen;
              return;
            }


          });
        });

        if(typeof currentScreenId!=="undefined" && currentScreen.state.status==="open"){
          const batch = firebase.firestore.batch();
          let theCurrentScreenRef = beamsCollection.doc(currentBeam.id).collection("screens").doc(currentScreenId)
          console.log("theCurrentScreenRef", theCurrentScreenRef);

          if(currentScreen.timer_info!==undefined){
            let timeElapsed=getTheTimeElapsed(currentScreen,firebase);
            let tempTimerInfo=clone(currentScreen.timer_info)
            tempTimerInfo.time_elapsed=timeElapsed;
            // let theStateData={};
            // theStateData.status="closed";
            // theStateData.view_state="closed";
            let newCurrentScreenData={};
            newCurrentScreenData.timer_info=tempTimerInfo;
            //newCurrentScreenData.state=theStateData;
            // let newTimerInfo={};
            // newTimerInfo['timer_info']={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':theFirstScreen.topic_item_info.measure.timer_in_secs};
            batch.update(theCurrentScreenRef,newCurrentScreenData)
          }


          let exitBeamUpdatedDoc = yield call(sendTheScreenData,{batch, theCurrentScreenRef, action});
          console.log("updatedDoc",exitBeamUpdatedDoc);
          yield put(updateScreenStateSuccess(requestInput, exitBeamUpdatedDoc));

        }
    }
    else{

      let theCurrentScreenId;
      let theCurrentScreen;

      //screen.timer_info={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':beamSource.component.items[itemKey].measure.timer_in_secs};
      yield beamsRef
        .collection("screens")
        .get()
        .then(function(querySnapshot) {
          if (querySnapshot.empty) {
            console.log(
              "Get call to Firestore returned no screens for the beam"
            );
          }
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log("fetchBeamsSuccess", doc.id, " => ", doc.data());

            let screen = doc.data();
            if(screen.oid===currentBeam.state.current_screen_oid){

              theCurrentScreenId=doc.id;
              console.log("GOT current screen id",theCurrentScreenId);
              theCurrentScreen=screen;
              return;
            }
          });
        });

        console.log("currentScreen",theCurrentScreen);
        console.log("currentScreenId",theCurrentScreenId);
        if(typeof theCurrentScreenId!=="undefined" && typeof theCurrentScreen.topic_item_info.measure.timer_in_secs!=="undefined" && theCurrentScreen.topic_item_info.measure.timer_in_secs>0){
          const batch = firebase.firestore.batch();
          let tempBeamsref=beamsCollection.doc(currentBeam.id);
          console.log("beamsRef",tempBeamsref.id);
          let tempScreenRef = tempBeamsref.collection("screens").doc(theCurrentScreenId)
          console.log("theCurrentScreenRef", tempScreenRef);
          let newTimerInfo;
          if(typeof theCurrentScreen.timer_info=="undefined"){

            newTimerInfo={};
            newTimerInfo['timer_info']={'start_time':fb.firestore.FieldValue.serverTimestamp(),'duration':theCurrentScreen.topic_item_info.measure.timer_in_secs};
          }
          else{
            newTimerInfo={};
            newTimerInfo.timer_info=clone(theCurrentScreen.timer_info)
            if(typeof newTimerInfo['timer_info'].history==="undefined")
                newTimerInfo['timer_info'].history=[];
            let tempObj={};
            Object.keys(theCurrentScreen.timer_info).forEach(key => {
                if(key!=="history"){
                    tempObj[key]=theCurrentScreen.timer_info[key];
                }
            })
            newTimerInfo.timer_info.history.push(tempObj);
            newTimerInfo.timer_info.start_time=fb.firestore.FieldValue.serverTimestamp();
            if(typeof theCurrentScreen.timer_info.time_elapsed!=="undefined"){
                newTimerInfo.timer_info.duration=theCurrentScreen.timer_info.duration-theCurrentScreen.timer_info.time_elapsed
                delete newTimerInfo.timer_info.time_elapsed;
            }
          }
          batch.update(tempScreenRef,newTimerInfo)
          let startOrResumeBeamupdatedDoc = yield call(sendTheScreenData,{batch, "theCurrentScreenRef":tempScreenRef, action});
          console.log("updatedDoc",startOrResumeBeamupdatedDoc);
          yield put(updateScreenStateSuccess(requestInput, startOrResumeBeamupdatedDoc));
        }
    }
    yield beamsRef.update(updateObject);
    //call function to sync
    yield put(updateBeamStateSuccess(requestInput, { updateObject }));
  } catch (err) {
    console.log("err updating beam state in the beam", err);
    yield put(updateBeamStateFailure(requestInput, err));
  }
}


async function sendTheScreenData ({batch, theCurrentScreenRef, action, theStateData}){
  let theUpdatedDoc;
  await batch.commit().then(async function () {
    console.log("first screen update success");
    await theCurrentScreenRef.get().then((doc) => {
      if (doc.exists) {
        console.log("theCurrentScreenRef Document data:", doc.data());
        let theDoc=doc.data();

        let newTimerInfo= clone(theDoc.timer_info);
        let newScreenData={};
        newScreenData.timer_info=newTimerInfo;
        if(typeof theStateData!=="undefined"){
          newScreenData.state=theStateData;
        }

        console.log("####################****MUlti dAta****#######################")
        theUpdatedDoc = {
            entity: "multi_data",
            data: newScreenData
        }



        //yield put(updateScreenStateSuccess(action.requestInput, theUpdatedDoc))
      }else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }

    }).catch(function (error) {
        console.error("Error getting document: ", error);
        throw error;
    });

  }).catch(function (error) {
      console.error("Error creating next item: ", error);
      throw error;
  });
  console.log("return statement");
  return theUpdatedDoc;
}

const getBeamedItemPoints = ({ screens }) => {
  let beamedItemPoints = 0;
  for (let i = 0; i < screens.length; i++) {
    if (screens[i].state.status != "init") {
      if (typeof screens[i].topic_item_info.measure.grade_points === "number") {
        beamedItemPoints =
          beamedItemPoints + screens[i].topic_item_info.measure.grade_points;
      }
    }
  }
  return beamedItemPoints;
};

function* updateBeamMaxPointsSaga ({ requestInput }) {
  const { beamRef, beamId, new_max_points } = requestInput;
  console.log('update max points saga', requestInput, beamRef, new_max_points);
  try {
    yield beamRef.update({
      'state.max_points': new_max_points
    })
    yield put(updateMaxPointsSuccess({ new_max_points }))
  } catch(e) {
    yield put(updateMaxPointsFailure({ new_max_points, error: { message: e.message } }));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchBeamActions() {
  // Watches for FETCH_TOPICS action and calls getTopics when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(CREATE_BEAM_LISTENER_ACTION, createBeamListener);
  yield takeLatest(FETCH_BEAMS_ACTION, fetchBeams);
  yield takeLatest(CREATE_BEAM_ACTION, createBeam);
  yield takeLatest(FETCH_BEAM_SCREENS_ACTION, fetchBeamScreens);
  yield takeLatest(UPDATE_BEAM_STATE_ACTION, updateBeamState);
  yield takeLatest(CREATE_NEW_SCREEN_ACTION, createNewScreenAction);
  yield takeLatest(UPDATE_CURRENT_SCREEN_ACTION, updateCurrentScreenAction);
  yield takeLatest(LISTEN_TO_USER_BEAMS_ACTION, setupUserBeamsListener);
  yield takeLatest(JOIN_BEAM_CHANNEL_ACTION, joinBeamChannel);
  yield takeLatest(UPDATE_CORRECT_ANSWER_ACTION, updateCorrectAnswers);
  yield takeLatest(UPDATE_BEAM_MAX_POINTS, updateBeamMaxPointsSaga);
  yield takeLatest(
    UPDATE_CURRENT_SCREEN_ANNOTATIONS,
    updateCurrentScreenAnnotations
  );
}
