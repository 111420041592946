const matchingSolution = (item, topicItemInfo) => {
  const itemSolution = {};
  for (let onePair of item.content.pairs) {
    if (!topicItemInfo.assessment_config.shuffleLeftToRight) {
      let multiplePresent = false;
      const allCorrectAnswers = [onePair.rValue.id];

      if (
        topicItemInfo.assessment_config.typeOfMatch === "grouping" &&
        onePair.correctAnswers &&
        onePair.correctAnswers.length > 0
      ) {
        multiplePresent = true;
        onePair.correctAnswers.forEach(singleAns =>
          allCorrectAnswers.push(singleAns.id)
        );
      }
      itemSolution[onePair.lValue.id] = {
        correct_answers: allCorrectAnswers,
        eval_type: multiplePresent ? "array_match" : "exact_match"
      };
    } else {
      itemSolution[onePair.rValue.id] = {
        correct_answers: [onePair.lValue.id],
        eval_type: "exact_match"
      };
      if (onePair.correctAnswers && onePair.correctAnswers.length > 0) {
        onePair.correctAnswers.forEach(
          singleAns =>
            (itemSolution[singleAns.id] = {
              correct_answers: [onePair.lValue.id],
              eval_type: "exact_match"
            })
        );
      }
    }
  }

  return itemSolution;
};

export default matchingSolution;
