/*
 * TopicsList constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SLIDEDECK_LIST_TYPE = 'slides';
export const FETCH_SLIDEDECK = 'lrnrApp/authoring/FETCH_SLIDEDECK';
export const FETCH_SLIDEDECK_SUCCESS = 'lrnrApp/authoring/FETCH_SLIDEDECK_SUCCESS';
export const FETCH_SLIDEDECK_FAILURE = 'lrnrApp/authoring/FETCH_SLIDEDECK_FAILURE';
export const ADD_SLIDEDECK = 'lrnrApp/authoring/ADD_SLIDEDECK';
export const UPDATE_SLIDEDECK = 'lrnrApp/authoring/UPDATE_SLIDEDECK';
export const UPDATE_SLIDEDECK_SUCCESS = 'lrnrApp/authoring/UPDATE_SLIDEDECK_SUCCESS';
export const UPDATE_SLIDEDECK_FAILURE = 'lrnrApp/authoring/UPDATE_SLIDEDECK_FAILURE';
export const FETCH_SLIDEDECK_ITEMS = 'lrnrApp/authoring/FETCH_SLIDEDECK_ITEMS';
export const FETCH_SLIDEDECK_ITEMS_SUCCESS = 'lrnrApp/authoring/FETCH_SLIDEDECK_ITEMS_SUCCESS';
export const FETCH_SLIDEDECK_ITEMS_FAILURE = 'lrnrApp/authoring/FETCH_SLIDEDECK_ITEMS_FAILURE';
export const SLIDEDECK_ITEMS_HOLDER = "SLIDEDECK_ITEMS_HOLDER";
export const CHANGE_SLIDEDECK_ITEM_ORDER = "CHANGE_SLIDEDECK_ITEM_ORDER";
export const ADD_SLIDEDECK_ITEM = 'lrnrApp/authoring/ADD_SLIDEDECK_ITEM';
export const DUPLICATE_SLIDEDECK_ITEM = 'lrnrApp/authoring/DUPLICATE_SLIDEDECK_ITEM';
export const ADD_SLIDEDECK_ITEM_SUCCESS = 'lrnrApp/authoring/ADD_SLIDEDECK_ITEM_SUCCESS';
export const ADD_SLIDEDECK_ITEM_FAILURE = 'lrnrApp/authoring/ADD_SLIDEDECK_ITEM_FAILURE';
export const REORDER_SLIDEDECK_ITEMS = 'lrnrApp/authoring/REORDER_SLIDEDECK_ITEMS';
export const REORDER_SLIDEDECK_ITEMS_SUCCESS = 'lrnrApp/authoring/REORDER_SLIDEDECK_ITEMS_SUCCESS';
export const REORDER_SLIDEDECK_ITEMS_FAILURE = 'lrnrApp/authoring/REORDER_SLIDEDECK_ITEMS_FAILURE';
export const UPDATE_SLIDEDECK_SUMMARY = 'lrnrApp/authoring/UPDATE_SLIDEDECK_SUMMARY';
export const UPDATING_SLIDEDECK_ITEM = 'lrnrApp/authoring/UPDATING_SLIDEDECK_ITEM';

export const PATCH_ENTITY_TITLE="title";
export const PATCH_ENTITY_DESC="desc";

export const DEL_SLIDEDECK_ITEM = 'lrnrApp/authoring/DEL_SLIDEDECK_ITEM';
export const DEL_SLIDEDECK_ITEM_SUCCESS = 'lrnrApp/authoring/DEL_SLIDEDECK_ITEM_SUCCESS';
export const DEL_SLIDEDECK_ITEM_FAILURE = 'lrnrApp/authoring/DEL_SLIDEDECK_ITEM_FAILURE';