import { promptSelectableForFib2 } from "./fib";
import { promptSelectableForMatching } from "./matching";

const promptSelectionArray = (itemType, data,topicItemInfo) => {
  let selectableArray = [];
  switch (itemType) {
    case "fib2":
      promptSelectableForFib2(data, selectableArray);
      break;
    case "matching":
      promptSelectableForMatching(data,selectableArray,topicItemInfo);
      break;
    default:
      break;
  }

  return selectableArray;
};

export default promptSelectionArray;

