import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_CLASS_SECTIONS } from "constants/CourseConstants";
import { getClassSectionsAPI } from "constants/ApiConstants";
import request from 'util/ApiUtils';
import { testClassSections } from 'data/test/classSections';

import { fetchClassSectionsSuccess, fetchClassSectionsFailure } from "../actions/Course";

export function* fetchClassSections(action) {
  console.log("in fetchClassSections", action)
  const courseId = action.requestInput.courseId;

  //if the courseId is not there - assuming there are no sections
  if (typeof courseId == 'undefined' || !courseId) {
    yield put(fetchClassSectionsSuccess(action.requestInput, []));

  } else {
    //first get sections from sessionStorage

    const sectionCodes = JSON.parse(window.sessionStorage.getItem('sectionCodes'));
    if (typeof sectionCodes == 'undefined' || !sectionCodes) {
      yield put(fetchClassSectionsFailure(action.requestInput, "No sections found"));
      return;
    }
    
    yield put(fetchClassSectionsSuccess(action.requestInput, { classSections:sectionCodes, courseId }));


    //make to call to lrnr6 to get active class sections
    // // const classSectionUrl = getClassSectionsAPI(action.requestInput.courseId) + "?onlyActive=true";
    // // console.log("classSectionurl", classSectionUrl)
    // try {
    //   //const response = yield call(request, classSectionUrl);
    //   const classSections = testClassSections;
    //   const courseId = action.requestInput.courseId;
    //   yield put(fetchClassSectionsSuccess(action.requestInput, {classSections, courseId}));
    // } catch (err) {
    //   yield put(fetchClassSectionsFailure(action.requestInput, err));
    // }
  }

}
export default function* watchCourseActions() {
  yield takeLatest(FETCH_CLASS_SECTIONS, fetchClassSections);
}
