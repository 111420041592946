import produce from 'immer';
import { FETCH_CLASS_SECTIONS_SUCCESS, FETCH_CLASS_SECTIONS_FAILURE } from "constants/CourseConstants";


const initialSettings = {
  areClassSectionsFetched: false,
  classSections:[],
  courseId:null
};

const course = (state = initialSettings, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_CLASS_SECTIONS_SUCCESS:
        console.log("in FETCH_CLASS_SECTIONS_SUCCESS ", action)
        draft.classSections=action.response.classSections;
        draft.courseId=action.response.courseId;
        draft.areClassSectionsFetched=true;
        break;
      case FETCH_CLASS_SECTIONS_FAILURE:
        console.log("in FETCH_CLASS_SECTIONS_FAILURE ", action)
        draft.areClassSectionsFetched=false;
        break;
    }
  });

export default course;
