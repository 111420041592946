
export const ITEM_SUBTYPE_FIB = 'fib';
export const ITEM_SUBTYPE_MCQ = 'mcq';
export const ITEM_SUBTYPE_DIAGRAM = 'diagram';
export const ITEM_SUBTYPE_MSQ = 'msq';
export const ITEM_SUBTYPE_SLIDE = 'slide';
export const ITEM_SUBTYPE_WHITEBOARD = 'whiteboard';
export const ITEM_SUBTYPE_MATCHING = "matching";
export const ITEM_SUBTYPE_SORTING = "sorting";
export const ITEM_SUBTYPE_FIB2 = "fib2";
export const ITEM_SUBTYPE_HIGHLIGHT = "highlight";

export const ITEM_TYPE = {
  ASSESSMENT: 'assessment',
  LEARN : 'learn'

}

export const ACTIVITY_MODE = {
  CLICK_ON_TARGET: "dropPin",
  MATCHING: "cot",
  LABELLING: 'labelling',
  DRAG_AND_DROP: 'dragAndDrop',
  DROP_DOWN: 'dropDown'
}