import produce from 'immer';
import {
  LISTEN_TO_BEAM_SUCCESS,
  LISTEN_TO_BEAM_FAILURE,
  CREATE_USER_BEAM_SUCCESS,
  CREATE_USER_BEAM_FAILURE,
  SAVE_USER_RESPONSE_SUCCESS,
  SAVE_USER_RESPONSE_FAILURE,
  LISTEN_BEAM_SCREENS_SUCCESS,
  LISTEN_BEAM_SCREENS_FAILURE,
  JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE,
  JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS,
  LISTEN_TO_USERBEAM_SCREENS_FAILURE,
  LISTEN_TO_USERBEAM_SCREENS_SUCCESS,
  USER_BEAM_SCREENS_CHANGED,
  SEND_PRESENCE_MSG_SUCCESS,
  JOIN_BEAM_FAILURE,
  JOIN_BEAM_SUCCESS,
    SET_JOIN_BEAM_STATUS,
    SET_SAVE_USER_RESPONSE_ERROR,
    STORE_IMAGE_DIMENSIONS

} from "./constants";
import { computeUserScreensAggregate } from './utils';
import { isBeamCompleted } from 'containers/Main/Beam/beamStatusUtils';

const initialSettings = {
    //property to check if beam is being listened to
    beamListenerCreated: false,
    userBeamRetrieved: false,
    beamScreensPulled: false,
    userBeams: [],
    noBeamFound: {},
    joinedBeamChannel: false,
    listeningToUserBeamScreens: false,
    joinBeamStatus: {},
    saveUserResponseError: {},
    error:{},
    beamPresencePingIntervals:{},
    imageStates: {
    }
};

const userBeam = (state = initialSettings, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LISTEN_TO_BEAM_SUCCESS:
                console.log("in LISTEN_TO_BEAM_SUCCESS reducer", state, action)
                draft.currentBeam = action.response.beam;
                draft.currentBeam.screens = action.response.beamScreens;
                const userBeamDocument = action.response.userBeamDocument;
                if (typeof userBeamDocument != 'undefined') {
                    draft.userBeamDocument = action.response.userBeamDocument;
                    if (typeof action.response.userBeamScreens != 'undefined'){
                        if (typeof draft.userBeamDocument.user_screens == 'undefined'){
                            draft.userBeamDocument.user_screens={};
                        }
                        action.response.userBeamScreens.forEach((userBeamScreen)=>{
                            draft.userBeamDocument.user_screens[userBeamScreen.beam_screen_ref_id] = userBeamScreen;
                        })
                    }
                }
                if (typeof action.requestInput.beamId != 'undefined'){
                    draft.noBeamFound[action.requestInput.beamId] = false;
                }
                draft.beamListenerCreated = true;
                draft.userBeamRetrieved = true;
                draft.beamScreensPulled = true;
                draft.error=null;
                //stop presence pings if beam is completed
                console.log("checking presence")
                if (isBeamCompleted(action.response.beam.state.status)){
                    console.log("Stoppping presence pings",draft.beamPresencePingIntervals)
                    if (typeof draft.beamPresencePingIntervals[action.response.beam.id] != 'undefined'){
                        console.log("clearing beam presence")
                        clearInterval(draft.beamPresencePingIntervals[action.response.beam.id]);
                    }
                }
                break;
            case LISTEN_TO_BEAM_FAILURE:
                console.log("in LISTEN_TO_BEAM_FAILURE reducer", state, action);
                draft.beamListenerCreated = false;
                draft.userBeamRetrieved = false;
                if (typeof action.requestInput.beamId != 'undefined'){
                    draft.noBeamFound[action.requestInput.beamId] = true;
                }
                draft.error=action.err;
                break;
            case CREATE_USER_BEAM_SUCCESS:
                console.log("in CREATE_USER_BEAM_SUCCESS", state, action);
                draft['userBeamDocument'] = action.response.userBeamDocument;
                // draft.userBeams.push(action.response.userBeamDocument);
                draft.error=null;
                break;
            case CREATE_USER_BEAM_FAILURE:
                console.log("in CREATE_USER_BEAM_FAILURE", state, action);
                draft.error=action.err;
                break;
            case JOIN_BEAM_SUCCESS:
                console.log("JOIN_BEAM_SUCCESS", state, action);
                draft["userBeamDocument"] = action.response.userBeamDoc;
                const joinBeamId = action.requestInput.currentBeam.id;
                draft.joinBeamStatus[joinBeamId] = action.response.status;
                draft.error = null;
                break;
            case JOIN_BEAM_FAILURE:
                console.log("JOIN BEAM FAILURE", state, action);
                 let BeamId = action.requestInput.currentBeam.id;
                draft.joinBeamStatus[`${BeamId}`] = action.err;
                draft.error = action.err;
                console.log(draft.joinBeamStatus, BeamId);
                break;
            case SET_JOIN_BEAM_STATUS:
                console.log("SET JOIN BEAM STATUS", state, action);
                let idBeam = action.requestInput.currentBeam.id;
                draft.joinBeamStatus[idBeam] = null;
                console.log(draft.joinBeamStatus);
                break;
            case SAVE_USER_RESPONSE_SUCCESS:
                console.log("in SAVE_USER_RESPONSE_SUCCESS ", state, action);
                if (typeof draft.userBeamDocument.user_screens == 'undefined'){
                    draft.userBeamDocument.user_screens={};
                }
                console.log(
                  "In save UserResponse Success , UserScreen is",
                  action.response.userScreen,
                  "userBeamResultAggregate is",
                  action.response.userBeamResultAggregate
                );
                const userScreen=action.response.userScreen;
                draft.userBeamDocument.user_screens[userScreen.beam_screen_ref_id] = userScreen;
                draft.userBeamDocument['eval_result_aggregate']=action.response.userBeamResultAggregate;
                draft.error=null;
                break;
            case SAVE_USER_RESPONSE_FAILURE:
                console.log("in SAVE_USER_RESPONSE_FAILURE ", state, action);
                const { beamScreenRefId } = action.requestInput;
                draft.saveUserResponseError[beamScreenRefId] = action.err;
                draft.error = action.err;
                break;
            case SET_SAVE_USER_RESPONSE_ERROR:
                console.log("SET Save UserResponseError", state, action);
                console.log("IN REDUCER SET_SAVE_USER_RESPONSE_ERROR", action.requestInput);
                let screenId = action.requestInput.currentScreen.id;
                draft.saveUserResponseError[screenId] = null;
                console.log(draft.saveUserResponseError);
                break;
            case LISTEN_BEAM_SCREENS_SUCCESS:
                console.log("in LISTEN_BEAM_SCREENS_SUCCESS ", state, action);
                if (draft.currentBeam){
                    draft.currentBeam.screens=action.response.beamScreens;
                }
                draft.error=null;
                break;
            case LISTEN_BEAM_SCREENS_FAILURE:
                console.log("in LISTEN_BEAM_SCREENS_FAILURE ", state, action);
                draft.error=action.err;
                break;
            case LISTEN_TO_USERBEAM_SCREENS_SUCCESS:
                console.log(" student beam in LISTEN_TO_USERBEAM_SCREENS_SUCCESS", state, action);
                draft.listeningToUserBeamScreens=true;
                break;
            case LISTEN_TO_USERBEAM_SCREENS_FAILURE:
                console.log("in student beam LISTEN_TO_USERBEAM_SCREENS_FAILURE", state, action);
                draft.listeningToUserBeamScreens=false;
                break;
            case JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS:
                console.log(" student beam in JOIN_BEAM_LIVE_CHANNEL_ACTION_SUCCESS", state, action);
                draft.joinedBeamChannel=true;
                break;
            case JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE:
                console.log("in student beam JOIN_BEAM_LIVE_CHANNEL_ACTION_FAILURE", state, action);
                draft.joinedBeamChannel=false;
                break;
            case SEND_PRESENCE_MSG_SUCCESS:
                console.log("in student beam SEND_PRESENCE_MSG_SUCCESS", state, action);
                const presencePingInterval = action.response;
                const beamId = action.requestInput.currentBeam.id;

                //stop pings - before adding another
                if (typeof draft.beamPresencePingIntervals[beamId] != 'undefined'){
                    clearInterval(draft.beamPresencePingIntervals[beamId]);
                }
                draft.beamPresencePingIntervals[beamId]= presencePingInterval;
                break;
            case USER_BEAM_SCREENS_CHANGED:
                console.log(" student beam in USER_BEAM_SCREENS_CHANGED", state, action);
                const uBeam = action.response.userScreens.userBeam;
                const userBeamScreens = action.response.userScreens.userBeamScreens;
                if (typeof draft.userBeamDocument == 'undefined'){
                    //the state doesn't have userBeam itself
                    break;
                }
                console.log(1);
                if (!draft.userBeamDocument['user_screens']) {
                    draft.userBeamDocument['user_screens'] = {};
                }
                console.log(2);
                if (!userBeamScreens || userBeamScreens == null) {
                    console.log("empty userBeamScreens");
                    break;
                }
                console.log(3);
                //don't use forEach as the order in which the items are pulled is not as per the index
                //note that when there is a change to one user-screen doc - firebase sends both docs
                //the last one in the array has the latest one
                for (let i=0; i<userBeamScreens.length;i++){
                    draft.userBeamDocument['user_screens'][userBeamScreens[i].beam_screen_ref_id] = userBeamScreens[i];
                }
                // userBeamScreens.forEach((userBeamScreen) => {
                // })
                const userScreens = draft.userBeamDocument['user_screens'];
                draft.userBeamDocument['eval_result_aggregate'] = computeUserScreensAggregate({userScreens});
                //update the aggregate
                break;
            case STORE_IMAGE_DIMENSIONS:
                console.log('store image dimensions', action);
                const { dimensions, theAreas, screenId: scrId } = action.requestInput;
                draft.imageStates[scrId] = {
                    dimensions,
                    theAreas,
                }
              break;
        }
    })

export default userBeam;
