import { call } from 'redux-saga/effects';
import { upload } from 'util/ApiUtils'
import { TOPICS_ATTACHMENT_API } from 'constants/ApiConstants';

export const TopicAttachmentApi = () => {

    console.log("in TopicAttachmentApi constructor");
    const requestHandle = new XMLHttpRequest();
    requestHandle.onabort = () => {
        console.log("ABORTING", requestHandle.readyState)
    }
    
    const createTopicFromSlidedeck = ({ slidedeckFile, attachmentInfo, course, progressListener }) => {

        const form = new FormData();
        form.append('file', slidedeckFile);
        form.append('operation', 'import');
        form.append('entity', "slidedeck")
        form.append('value', 'import')

        if (typeof course != 'undefined' && typeof course.courseId != null ){
            form.append("institution_course_uuid", course.courseId)
        }

        if (typeof attachmentInfo != 'undefined') {
            form.append('attachmentInfo', JSON.stringify(attachmentInfo));
        }

        return callTopicAttachmentsApi({ form, progressListener });

    }

    const callTopicAttachmentsApi = ({ form, progressListener }) => {

        const url = TOPICS_ATTACHMENT_API;

        const options = {
            headers: {
            }, method: 'POST', body: form
        }

        return upload({
            xhr: requestHandle,
            url, options,
            progressListener: progressListener
        });

    }

    return {
        requestHandle, createTopicFromSlidedeck
    }

}