import React from "react";

const LrnrDropdownSelect = ({title, defaultSelect, selectableData, customStyle, onChange, fontTypo,placeholder, customSelectStyle,disabled}) => {
	return(
		<div className={"gx-lrnr-material-select-group "+fontTypo} style={customStyle} disabled={(typeof disabled!=="undefined")?disabled:false}>
			<select className={"gx-lrnr-material-select-text "+fontTypo} defaultValue={defaultSelect?defaultSelect:placeholder?"":undefined} onChange={onChange} style={customSelectStyle}required>
				{(typeof placeholder!=="undefined")?(<option value="" disabled>{placeholder}</option>):(null)}
				{selectableData.map((theData, index) =>
					<option key={index} value={theData.value}>{theData.title}</option>
				)}
			</select>
			<span className="gx-lrnr-material-select-highlight"></span>
			<span className="gx-lrnr-material-select-bar"></span>
			<label className="gx-lrnr-material-select-label">{title}</label>
		</div>
	)
}

export default LrnrDropdownSelect;