import { FETCH_CLASS_SECTIONS, FETCH_CLASS_SECTIONS_SUCCESS, FETCH_CLASS_SECTIONS_FAILURE } from "constants/CourseConstants";

/**
 * Load the ClassSections, this action starts the request saga
 *
 * @return {object} An action object with a type of FETCH_CLASS_SECTIONS
 */
export function fetchClassSectionsActionCreator(requestInput) {
    return {
        type: FETCH_CLASS_SECTIONS,
        requestInput,
    };
}

export function fetchClassSectionsSuccess(requestInput, response) {
    return {
        type: FETCH_CLASS_SECTIONS_SUCCESS,
        requestInput,
        response,
    };
}

export function fetchClassSectionsFailure(requestInput, err) {
    return {
        type: FETCH_CLASS_SECTIONS_FAILURE,
        requestInput,
        err,
    };
}