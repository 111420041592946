import produce from 'immer';
import {
  UPDATE_CURRENT_BEAM_ACTION, FETCH_SCREENS_SUCCESS,
  FETCH_SCREENS_FAILURE,
  CREATE_BEAM_SUCCESS, CREATE_BEAM_FAILURE, FETCH_BEAMS_SUCCESS, FETCH_BEAMS_FAILURE,
  UPDATE_BEAM_STATE_SUCCESS, UPDATE_BEAM_STATE_FAILURE, UPDATE_LIVE_MODE_ACTION,
  UPDATE_CURRENT_SCREEN_SUCCESS, UPDATE_CURRENT_SCREEN_FAILURE, UPDATE_CURRENT_SCREEN_STATE_SUCCESS,
  JOIN_BEAM_CHANNEL_ACTION_SUCCESS, JOIN_BEAM_CHANNEL_ACTION_FAILURE,
  RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS, RECEIVE_BEAM_CHANNEL_MESSAGE_FAILURE,
  USER_BEAM_SCREENS_CHANGED, UPDATE_CURRENT_SCREEN_ANNOTATIONS, UPDATE_CURRENT_SCREEN_ANNOTATIONS_SUCCESS, UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE,
  UPDATE_CURRENT_SCREEN_WITH_NEW_CONTENT,
  UPDATE_CURRENT_SCREEN_SOLUTION,
  STORE_BEAM_IMAGE_DIMENSIONS,
  UPDATE_BEAM_MAX_POINTS_SUCCESS,
  UPDATE_BEAM_MAX_POINTS_FAILURE
} from "./constants";
import { getCurrentBeamById, updateCurrentScreenInReducer, getInstructorBeamChannelAddress, updateCurrentScreenWithNewContent } from './utils';
import {
  LISTEN_TO_USER_BEAMS_FAILURE, LISTEN_TO_USER_BEAMS_SUCCESS,
  LISTEN_TO_USER_BEAM_SCREENS_FAILURE, LISTEN_TO_USER_BEAM_SCREENS_SUCCESS, UPDATE_CURRENT_BEAM_ANNOTATIONS_DATA
} from './constants';


const initialSettings = {
  //property to check if beam is being listened to
  beamListenerCreated: false,
  beams: [],
  currentBeamId: null,
  currentBeamObject: null,
  beamCreationSuccessful: false,
  beamCreationFailed: false,
  beamCreationError: null,
  inLiveMode: false,
  userBeams: {},
  listeningToUserBeams: false,
  listeningToUserBeamScreens: false,
  beamNotFound:false,
  joinedBeamChannels:[],
  participants:{},
  attemptedToJoinBeamChannels:[],
  beamUserBeams:{},
  errorUpdatingBeam:{},
  imageStates: {},
};

const beam = (state = initialSettings, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_BEAMS_SUCCESS:
        console.log("in beam reducer", state, action)
        draft.beamListenerCreated = true;
        draft.beams = action.response.beams;
        if (action.response.currentBeamObject) {
          draft.currentBeamObject = action.response.currentBeamObject;
          draft.currentBeamId = action.response.currentBeamObject.id;
        }
        draft.beamNotFound=false;
        break;
      case FETCH_BEAMS_FAILURE:
        console.log("in FETCH_BEAMS_FAILURE reducer", state, action)
        break;
      case CREATE_BEAM_SUCCESS:
        console.log("in CREATE_BEAM_SUCCESS reducer", state, action)
        draft.beams.push(action.response.newBeamObject);
        draft.currentBeamObject = action.response.newBeamObject;
        draft.currentBeamId = action.response.newBeamObject.id;
        draft.beamCreationFailed = false;
        draft.beamCreationError = null;
        draft.beamCreationSuccessful = true;
        break;
      case CREATE_BEAM_FAILURE:
        console.log("in CREATE_BEAM_FAILURE reducer", state, action)
        draft.beamCreationFailed = true;
        draft.beamCreationError = action.err;
        draft.beamCreationSuccessful = false;
        break;
      case UPDATE_CURRENT_BEAM_ACTION:
        console.log("in UPDATE_CURRENT_BEAM_ACTION", state, action);
        const currentBeamObject = getCurrentBeamById(state.beams, action.requestInput.beamId)
        draft.currentBeamObject = currentBeamObject;
        if (currentBeamObject) {
          draft.currentBeamId = currentBeamObject.id;
        }else{
          draft.beamNotFound = true;
        }
        break;
      case FETCH_SCREENS_SUCCESS:
        console.log("in FETCH_SCREENS_SUCCESS", state, action);
        const screens = action.screens;
        draft.currentBeamObject["screens"] = action.response.screens;
        draft.currentBeamObject['beamSoln'] = action.response.beamSoln;
        draft.currentBeamObject['solutions'] = action.response.currentBeamSolution;
        break;
      case FETCH_SCREENS_FAILURE:
        console.log("in FETCH_SCREENS_FAILURE", state, action);
        break;
      case UPDATE_BEAM_STATE_SUCCESS:
        console.log("in UPDATE_BEAM_STATE_SUCCESS", state, action)
        const updateObject = action.response.updateObject;
        const inputBeamId = action.requestInput.currentBeam.id;
        const isNewScreen = action.response.isNewScreen;
        const newScreen = action.response.newScreenPayload;
        const newScreensOrder = action.response.newScreensOrder;

        if (draft.currentBeamObject != null && typeof action.requestInput.currentBeam != 'undefined'){
          //get the currentBeamObject beamId in store
          const beamIdInStore = draft.currentBeamObject.id;
          //check if the beam updated is same as the one in the currentBeamObject
          if (inputBeamId === beamIdInStore){
            //update the currentBeamObject in store
            for (let key of Object.keys(updateObject)) {
              //split key to remove the string "state."
              const value = updateObject[key];
              if (key.includes('state')){
                draft.currentBeamObject.state[key.replace('state.', '')] = value;
              }else{
                draft.currentBeamObject[key]=value;
              }
            }
          }
        }
        let beamBeingUpdated;
        if (draft.currentBeamObject == null){
          beamBeingUpdated = action.requestInput.currentBeam;
        }else{
          beamBeingUpdated = draft.currentBeamObject;
        }
        for(let b=0;b<draft.beams.length;b++){
          if(draft.beams[b].id === inputBeamId){
            for (let key of Object.keys(updateObject)) {
              //split key to remove the string "state."
              const value = updateObject[key];
              if (key.includes('state')){
                draft.beams[b].state[key.replace('state.', '')] = value;
              }else{
                draft.beams[b][key]=value;
              }            }
            //Hack to break the loop
            b=draft.beams.length;
          }
        }

        const screenStateUpdatedFlag = action.response.screenStateUpdatedFlag;
        if (screenStateUpdatedFlag && draft.currentBeamObject != null) {
          const { updatedScreenInfo } = action.response;
          const { screenId, newScreenState, newTimerInfo } = updatedScreenInfo;
          if (isNewScreen) {
            draft.currentBeamObject.screens.push(newScreen)
            draft.currentBeamObject.screens_order = newScreensOrder
          }else {
            const screenToUpdate = draft.currentBeamObject.screens.find((screen) => screen.id == screenId);
            console.log("sscreenToUpdate",screenToUpdate);
            console.log("updatedScreenInfo",updatedScreenInfo);
            for (let key of Object.keys(newScreenState)) {
              //split key to remove the string "state."
              const value = newScreenState[key];
              screenToUpdate.state[key.replace('state.', '')] = value;
            }
          }

          if(typeof newTimerInfo!=="undefined"){
            const screenToUpdate = draft.currentBeamObject.screens.find((screen) => screen.id == screenId);
            console.log("screenToUpdate",screenToUpdate);
            console.log("updatedScreenInfo",updatedScreenInfo);
            screenToUpdate.timer_info=newTimerInfo.timer_info;
          }
        };
        break;
      case UPDATE_BEAM_STATE_FAILURE:
        console.log("in UPDATE_BEAM_STATE_FAILURE", state, action);
        if (typeof action.requestInput.currentBeam != 'undefined'){
          draft.errorUpdatingBeam[action.requestInput.currentBeam.id]={
            status:'failure',
            error: action.err
          }
        }
        break;
      case UPDATE_LIVE_MODE_ACTION:
        console.log("in UPDATE_LIVE_MODE_ACTION", state, action);
        draft.inLiveMode = true;
        break;
      case UPDATE_CURRENT_SCREEN_SUCCESS:
        console.log("in UPDATE_CURRENT_SCREEN_SUCCESS", state, action);
        console.log("draft.currentBeamObject", draft.currentBeamObject);
        updateCurrentScreenInReducer(draft.currentBeamObject, action)
        break;
      case UPDATE_CURRENT_SCREEN_FAILURE:
        console.log(" in UPDATE_CURRENT_SCREEN_FAILURE", state, action);
        break;
      case UPDATE_CURRENT_SCREEN_STATE_SUCCESS:
        console.log(" in UPDATE_CURRENT_SCREEN_STATE_SUCCESS", state, action);
        updateCurrentScreenInReducer(draft.currentBeamObject, action)
        break;
      // case LISTEN_TO_USER_BEAMS_SUCCESS:
      //   console.log("in LISTEN_TO_USER_BEAMS_SUCCESS", state, action);
      //   const userBeams = action.response.userBeams;
      //   if (userBeams) {
      //     userBeams.forEach((userBeam) => {
      //       const ubId = userBeam.id;
      //       draft.userBeams[ubId] = userBeam;
      //       if (typeof draft.beamUserBeams[userBeam.beam_doc_ref_id] == 'undefined'){
      //         draft.beamUserBeams[userBeam.beam_doc_ref_id]={}
      //       }
      //       draft.beamUserBeams[userBeam.beam_doc_ref_id][ubId]=userBeam;
      //     })
      //   }
      //   draft.listeningToUserBeams = true;
      //   break;
      // case LISTEN_TO_USER_BEAMS_FAILURE:
      //   console.log("in LISTEN_TO_USER_BEAMS_FAILURE", state, action);
      //   break;
      // case LISTEN_TO_USER_BEAM_SCREENS_SUCCESS:
      //   console.log(" in LISTEN_TO_USER_BEAM_SCREENS_SUCCESS", state, action);
      //   draft.listeningToUserBeamScreens=true;
      //   break;
      // case LISTEN_TO_USER_BEAM_SCREENS_FAILURE:
      //   console.log("in LISTEN_TO_USER_BEAM_SCREENS_FAILURE", state, action);
      //   draft.listeningToUserBeamScreens=false;
      //   break;
      case USER_BEAM_SCREENS_CHANGED:
        console.log("in USER_BEAM_SCREENS_CHANGED", state, action);
        const uBeam = action.response.userScreens.userBeam;
        const userBeamScreens = action.response.userScreens.userBeamScreens;
        if (typeof draft.userBeams[uBeam.id] == 'undefined' || draft.userBeams[uBeam.id] == null){
          break;
        }
        if (!draft.userBeams[uBeam.id]['user_screens']) {
          draft.userBeams[uBeam.id]['user_screens'] = {};
        }
        if (!userBeamScreens) {
          console.log("empty userBeamScreens");
          break;
        }
        userBeamScreens.forEach((userBeamScreen) => {
          draft.userBeams[uBeam.id]['user_screens'][userBeamScreen.beam_screen_ref_id] = userBeamScreen;
        })
        break;
      case JOIN_BEAM_CHANNEL_ACTION_SUCCESS:
        console.log("in JOIN_BEAM_CHANNEL_ACTION_SUCCESS", state, action);
        draft.joinedBeamChannels.push(action.response.beamChannelAddress);
        draft.attemptedToJoinBeamChannels.push(action.response.beamChannelAddress);
        break;
      case JOIN_BEAM_CHANNEL_ACTION_FAILURE:
        console.log("in JOIN_BEAM_CHANNEL_ACTION_FAILURE", state, action);
        const requestInput = action.requestInput;
        const {currentBeam} = requestInput;
        const beamChannelAddress = getInstructorBeamChannelAddress(currentBeam);
        draft.attemptedToJoinBeamChannels.push(beamChannelAddress);
        break;
      // case UPDATE_CURRENT_SCREEN_ANNOTATIONS_:
      //     console.log(action.requestInput);
      //    if (draft.currentBeamObject != null){

      //     const screenToUpdate = draft.currentBeamObject.screens.find((screen) => screen.id == action.requestInput.updateObject.screenData.id);
      //     screenToUpdate.item.annotations=action.requestInput.updateObject.annotations;
      //     console.log("screenToUpdate",screenToUpdate);
      //    }
      //    break;
      case UPDATE_CURRENT_SCREEN_ANNOTATIONS_SUCCESS:
        if (draft.currentBeamObject != null){
          const screenToUpdate = draft.currentBeamObject.screens.find((screen) => screen.id == action.requestInput.updateObject.screenData.id);
          screenToUpdate.item.annotations=action.requestInput.updateObject.annotations;
          console.log("screenToUpdate",screenToUpdate);
        }
        break;
      case UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE:
        console.log("in UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE");
        break;
      case UPDATE_CURRENT_BEAM_ANNOTATIONS_DATA:
        if (draft.currentBeamObject != null){


          draft.currentBeamObject.annotationSettings=action.requestInput.updateObject;
        }
        break;
      case UPDATE_CURRENT_SCREEN_WITH_NEW_CONTENT :
        updateCurrentScreenWithNewContent(draft.currentBeamObject, action)
        break;
      // case RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS:
      //   console.log("in RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS", state, action);
      //   if (typeof action.response == 'undefined' || typeof action.response.beamChannelMessage == 'undefined') break;
      //   const beamChannelMessage = action.response.beamChannelMessage
      //   const message = beamChannelMessage.message;
      //   if (typeof message == 'undefined' || typeof message.body == 'undefined') break;
      //   const msgAction = message.body.action;
      //   if (msgAction === 'REGISTER' || msgAction === 'PONG'){
      //     const accountId = message.body.accountId;
      //     const handlerId = message.body.handlerId;
      //     draft.participants[accountId]={
      //       status:"active",
      //       handlerId:handlerId
      //     }
      //   } else if (msgAction === 'UNREGISTER'){
      //     console.log("in Unregister");
      //     const handlerId = message.body.handlerId;
      //     console.log("handlerId",handlerId);
      //     // const accountIds = draft.participants
      //     const accountId = Object.keys(draft.participants).find((key) =>
      //       (draft.participants[key]['handlerId'] === handlerId))
      //     console.log("key found =",accountId);
      //     if (typeof accountId == 'undefined' || accountId == null){
      //       break;
      //     }
      //     draft.participants[accountId]['status']='inactive';
      //   }
      //   break;
      // case RECEIVE_BEAM_CHANNEL_MESSAGE_FAILURE:
      //   break;
      case UPDATE_CURRENT_SCREEN_SOLUTION :
        if(draft.currentBeamObject?.solutions?.[action.payload.screenUUID]) {
          draft.currentBeamObject.solutions[action.payload.screenUUID] =
            action.payload.newScreenSolution;
        }
        break;
      case STORE_BEAM_IMAGE_DIMENSIONS:
        console.log('store image dimensions', action);
        const { dimensions, theAreas, screenId: scrId } = action.requestInput;
          draft.imageStates[scrId] = {
              dimensions,
              theAreas,
        }
        break;

      case UPDATE_BEAM_MAX_POINTS_SUCCESS:
        const { beamId, new_max_points } = action.requestInput;
        draft.currentBeamObject.state.max_points = new_max_points;
        break;
      case UPDATE_BEAM_MAX_POINTS_FAILURE:
        const { error } = action.requestInput;
        console.log('failed to update the beam max points', error)
        break;
    }
  });

export default beam;
