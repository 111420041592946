
import produce from 'immer';
import { FIB_RESPONSE_CORRECTION_SUCCESS, FIB_RESPONSE_CORRECTION_FAILURE } from "containers/Main/BeamItems/constants";


const initialSettings = {
  operationsByScreen: {}
};

const FIBResponse = (state = initialSettings, action) => 
  produce(state, draft => {
    switch (action.type) {
      case FIB_RESPONSE_CORRECTION_SUCCESS:
        console.log("in FIB_RESPONSE_CORRECTION_SUCCESS", action, state)
        break;
      case FIB_RESPONSE_CORRECTION_FAILURE:
        console.log("in FIB_RESPONSE_CORRECTION_FAILURE", action, state)
        break;
      default:
        return state;
    }
  });
export default FIBResponse;