import { createSelector } from 'reselect';

/**
 * Direct selector to the items state
 * slideItems represents the json object present in the slide
 */

export const getSlideItemsOrder = slide => slide.items_order || [];
export const getSlideItems = slide => slide.items || {};


export const totalChildrenCount = createSelector(
    [getSlideItems,getSlideItemsOrder], (slideItems,slideItemsOrder) => {
        // let slidesCount = 0;
        // (slideItemsOrder).forEach( (slideItemId) =>
        // {
        //     if(slideItems[slideItemId].subtype==="slide")
        //         slidesCount++;
        // }
        // )
        return slideItemsOrder.length;
    }
);

export const slidesCount = createSelector(
    [getSlideItems,getSlideItemsOrder], (slideItems,slideItemsOrder) => {
        let slidesCount = 0;
        (slideItemsOrder).forEach( (slideItemId) =>
        {
            if(slideItems[slideItemId].subtype==="slide")
                slidesCount++;
        }
        )
        return slidesCount;
    }
);

export const itemCount = createSelector(
    [getSlideItems,getSlideItemsOrder], (slideItems,slideItemsOrder) => {
        let itemsCount = 0;
        (slideItemsOrder).forEach( (slideItemId) =>
            {
                if(slideItems[slideItemId].subtype !== "slide")
                    itemsCount++;
            }
        )
        return itemsCount;
    }
);

export const slideSummary = createSelector(
    [getSlideItems, getSlideItemsOrder], (slideItems, slideItemsOrder) => {
        let efficacyTotal = 0;
        let participationTotal = 0;
        let totalGradePoints = 0;
        (slideItemsOrder).forEach( (slideItemId) =>
        {
            if(typeof slideItems[slideItemId].measure != "undefined" && Object.keys(slideItems[slideItemId].measure).length > 0){
                efficacyTotal+=slideItems[slideItemId].measure.efficacy_factor;
                participationTotal+=slideItems[slideItemId].measure.participation_factor;
                totalGradePoints+=slideItems[slideItemId].measure.grade_points;
            }
        }
        )
        return { efficacyTotal, participationTotal, totalGradePoints};
    }
);
