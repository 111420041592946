export const CREATE_BEAM_LISTENER_ACTION = 'authoring/CREATE_BEAM_LISTENER_ACTION';
export const FETCH_BEAMS_ACTION = 'authoring/FETCH_BEAMS_ACTION';
export const FETCH_BEAMS_SUCCESS = 'authoring/FETCH_BEAMS_SUCCESS';
export const FETCH_BEAMS_FAILURE = 'authoring/FETCH_BEAMS_FAILURE';
export const CREATE_BEAM_ACTION = 'authoring/CREATE_BEAM_ACTION';
export const CREATE_BEAM_SUCCESS = 'authoring/CREATE_BEAM_SUCCESS';
export const CREATE_BEAM_FAILURE = 'authoring/CREATE_BEAM_FAILURE';
export const UPDATE_CURRENT_BEAM_ACTION = 'authoring/UPDATE_CURRENT_BEAM_ACTION';
export const FETCH_BEAM_SCREENS_ACTION = 'authoring/FETCH_BEAM_SCREENS_ACTION';
export const FETCH_SCREENS_SUCCESS = 'authoring/FETCH_SCREENS_SUCCESS';
export const FETCH_SCREENS_FAILURE = 'authoring/FETCH_SCREENS_FAILURE';
export const UPDATE_BEAM_STATE_ACTION = 'authoring/UPDATE_BEAM_STATE_ACTION';
export const UPDATE_BEAM_STATE_SUCCESS = 'authoring/UPDATE_BEAM_STATE_SUCCESS';
export const UPDATE_BEAM_STATE_FAILURE = 'authoring/UPDATE_BEAM_STATE_FAILURE';
export const UPDATE_LIVE_MODE_ACTION = 'authoring/UPDATE_LIVE_MODE_ACTION';
export const CREATE_NEW_SCREEN_ACTION = 'authoring/CREATE_NEW_SCREEN_ACTION';
export const UPDATE_CURRENT_SCREEN_ACTION = 'authoring/UPDATE_CURRENT_SCREEN_ACTION';
export const UPDATE_CURRENT_SCREEN_SUCCESS = 'authoring/UPDATE_CURRENT_SCREEN_SUCCESS';
export const UPDATE_CURRENT_SCREEN_FAILURE = 'authoring/UPDATE_CURRENT_SCREEN_FAILURE';
export const UPDATE_CURRENT_SCREEN_STATE_SUCCESS = 'authoring/UPDATE_CURRENT_SCREEN_STATE_SUCCESS';
export const LISTEN_TO_USER_BEAMS_ACTION = 'authoring/LISTEN_TO_USER_BEAMS_ACTION';
export const LISTEN_TO_USER_BEAMS_SUCCESS = 'authoring/LISTEN_TO_USER_BEAMS_SUCCESS';
export const LISTEN_TO_USER_BEAMS_FAILURE = 'authoring/LISTEN_TO_USER_BEAMS_FAILURE';
export const LISTEN_TO_USER_BEAM_SCREENS_SUCCESS = 'authoring/LISTEN_TO_USER_BEAM_SCREENS_SUCCESS';
export const LISTEN_TO_USER_BEAM_SCREENS_FAILURE = 'authoring/LISTEN_TO_USER_BEAM_SCREENS_FAILURE';
export const USER_BEAM_SCREENS_CHANGED = 'authoring/USER_BEAM_SCREENS_CHANGED';
export const JOIN_BEAM_CHANNEL_ACTION = 'authoring/JOIN_BEAM_CHANNEL_ACTION';
export const JOIN_BEAM_CHANNEL_ACTION_SUCCESS = 'authoring/JOIN_BEAM_CHANNEL_ACTION_SUCCESS';
export const JOIN_BEAM_CHANNEL_ACTION_FAILURE = 'authoring/JOIN_BEAM_CHANNEL_ACTION_FAILURE';
export const RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS = 'authoring/RECEIVE_BEAM_CHANNEL_MESSAGE_SUCCESS';
export const RECEIVE_BEAM_CHANNEL_MESSAGE_FAILURE = 'authoring/RECEIVE_BEAM_CHANNEL_MESSAGE_FAILURE';
export const UPDATE_CORRECT_ANSWER_ACTION = 'authoring/UPDATE_CORRECT_ANSWER_ACTION';
export const UPDATE_CORRECT_ANSWER_SUCCESS = 'authoring/UPDATE_CORRECT_ANSWER_SUCCESS';
export const UPDATE_CORRECT_ANSWER_FAILURE = 'authoring/UPDATE_CORRECT_ANSWER_FAILURE';
export const BEAM_OPERATION_TYPES = ['arrow','tab'];
export const UPDATE_CURRENT_SCREEN_WITH_NEW_CONTENT = "authoring/UPDATE_CURRENT_SCREEN_DATA";

export const UPDATE_CURRENT_SCREEN_ANNOTATIONS='authoring/UPDATE_CURRENT_SCREEN_ANNOTATIONS';
export const UPDATE_CURRENT_SCREEN_ANNOTATIONS_SUCCESS='authoring/UPDATE_CURRENT_SCREEN_ANNOTATIONS_SUCCESS';
export const UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE='authoring/UPDATE_CURRENT_SCREEN_ANNOTATIONS_FAILURE';

export const UPDATE_CURRENT_BEAM_ANNOTATIONS_DATA='authoring/UPDATE_CURRENT_BEAM_ANNOTATIONS_DATA';
export const UPDATE_CURRENT_SCREEN_SOLUTION = 'beam/UPDATE_CURRENT_SCREEN_SOLUTION';

export const STORE_BEAM_IMAGE_DIMENSIONS = 'beam/STORE_BEAM_IMAGE_DIMENSIONS';

export const UPDATE_BEAM_MAX_POINTS = 'beam/UPDATE_BEAM_MAX_POINTS';
export const UPDATE_BEAM_MAX_POINTS_SUCCESS = 'beam/UPDATE_BEAM_MAX_POINTS_SUCCESS';
export const UPDATE_BEAM_MAX_POINTS_FAILURE = 'beam/UPDATE_BEAM_MAX_POINTS_FAILURE';
