import fb from "firebase";
import request from "util/ApiUtils";
import { getFirebaseFnEndpoint } from "constants/ApiConstants";

export async function joinBeam(action) {
  const requestInput = action.requestInput;
  const { currentBeam, passcode, studentSectionInfo } = requestInput;
  let beamId = currentBeam.id;
  let passCode = parseInt(passcode);
  let sectionCode = studentSectionInfo.sectionCode;
  let bodyData = {
    data: {
      beamId,
      passcode: passCode,
      sectionCode
    }
  };
  let joinBeamUrl = getFirebaseFnEndpoint({ functionName: "joinBeam" });
  // "https://us-central1-polling-sb.cloudfunctions.net/joinBeam";
  console.log(
    currentBeam.id,
    passcode,
    studentSectionInfo.sectionCode,
    joinBeamUrl
  );
  try {
    let token = await fb.auth().currentUser.getIdToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    };
    const joinBeam = await request(joinBeamUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(bodyData)
    });
    console.log(joinBeam);
    return joinBeam;
  } catch (error) {
    console.log("Unexpected network error");
    return {
      status: "ERR_UNEXPECTED",
      message: error
    };
  }
}
