import React, { Component } from "react";
import { Layout } from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import Customizer from "containers/Customizer";
import { connect } from "react-redux";
import { compose } from 'redux';
import AppBootstrap from './AppBootstrap';

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  NAV_STYLE_NO_HEADER_NO_SIDEBAR
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";

import { fetchClassSectionsActionCreator } from 'appRedux/actions/Course';
import { createActionToFetchFBAuthToken } from 'appRedux/actions/Firebase';
import { getCourseIdFromLocation } from 'util/AppUtil';
import AppChannelMessages from './Notifications/AppChannelMessage';

const { Content, Footer } = Layout;

export class MainApp extends Component {

  initClassSections = (props, courseId) => {
    if (!props.course.areClassSectionsFetched) {
      console.log("fetching course class sections");
      props.dispatch(fetchClassSectionsActionCreator({ courseId }))
    }
  }

  initFirebase = (props, firebase, courseId) => {
    console.log('firebase', firebase, firebase.auth)
    // if (firebase.fbAuthToken === "" && !firebase.isAuthenticated) {
    //   console.log('insdie the fapa', this.props);
    //   props.dispatch(createActionToFetchFBAuthToken({ firebase, courseId }));
    // }
  }


  constructor(props) {
    super(props);

    const firebase = props.firebase;

    //bootstrap the app
    console.log("in constructor MainApp props=", props)
    const courseId = getCourseIdFromLocation(props.location);
    this.initClassSections(props, courseId);
    this.initFirebase(props, firebase, courseId)

  }

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_NO_SIDEBAR:
        return null;
      default:
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_NO_SIDEBAR:
        return null;
      default:
        return null;
    }
  };

  render() {
    console.log("In MainApp")
    const { match, width, navStyle } = this.props;



    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${this.getContainerClass(navStyle)} `}>
            <App match={match} />
            <Footer>
              <div className="gx-layout-footer-content">

              </div>
            </Footer>
          </Content>
        </Layout>
        <AppBootstrap key="2" />
        <AppChannelMessages />
        <Customizer />
      </Layout>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = (state) => {
  console.log("STATE in MainApp", state);
  const { settings, course, router,  firebase } = state;
  const location = router.location;
  const { width, navStyle } = settings;
  return { width, navStyle, course, location,  firebase }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default compose(
  withConnect,
)(MainApp);

