import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import { getCourseIdFromLocation } from 'util/AppUtil';
import { isUserInstructor } from 'util/AppUtil'
import { actionToFetchBeams } from 'containers/Main/Beam/actions';
import { actionToSetEnvConfig, actionToSubscribeToAuthoringChannel } from 'appRedux/actions/AppBootstrap';


export class AppBootstrap extends Component {

  componentDidMount() {
    const firebase = this.props.firebase;
    const subscribedToAuthoringChannel = this.props.appBootstrap.subscribedToAuthoringChannel;
    console.log("subscribedToAuthoringChannel", subscribedToAuthoringChannel);
    if (subscribedToAuthoringChannel == -1 && isUserInstructor(firebase.userSignature.roles)) {
      this.props.dispatch(actionToSubscribeToAuthoringChannel({ firebase }));
    }
  }

  componentDidUpdate() {
    console.log("in AppBootstrap componentDidUpdate", this.props)
    const firebase = this.props.firebase;
    const location = this.props.location;
    const courseId = getCourseIdFromLocation(this.props.location);
    let isInstructor = false;
    if (firebase.isAuthenticated && isUserInstructor(firebase.userSignature.roles) && !this.props.appBootstrap.beamListenerCreated) {
      if (location.pathname.includes('/review') === false){
        console.log("fetching beams ");
        const firebase = this.props.firebase;
        this.props.dispatch(actionToFetchBeams({ firebase, courseId, location }));
      }
    }
    const env = this.props.appBootstrap.env;
    if (env == null) {
      this.props.dispatch(actionToSetEnvConfig({ location }));
    }

  }

  render() {
    console.log("rerendering appbootstrap");
    return (
      <div></div>
    )
  }


}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = (state) => {
  console.log("STATE in AppBootstrap", state);
  const { course, router, appBootstrap, firebase } = state;
  const location = router.location;
  return { course, location, appBootstrap, firebase }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default compose(
  withConnect,
)(AppBootstrap);

