import { getEnv } from 'constants/EnvConfig';

// Initialize Firebase
const firebaseSandboxConfig = {
    apiKey: "AIzaSyAOxR643t9z0QfbSFeFN9Xq1In_10vZ8nI",
    authDomain: "polling-sb.firebaseapp.com",
    databaseURL: "https://polling-sb.firebaseio.com",
    projectId: "polling-sb",
    storageBucket: "polling-sb.appspot.com",
    messagingSenderId: "797073912281",
    appId: "1:797073912281:web:5404afee3b81b253f0a9c8"
};

const firebaseDemoConfig = {
    apiKey: "AIzaSyBTewZYbt2JStuLtnmMwRyDgtdXi5v8Y88",
    authDomain: "polling-demo-9b10c.firebaseapp.com",
    databaseURL: "https://polling-demo-9b10c.firebaseio.com",
    projectId: "polling-demo-9b10c",
    storageBucket: "polling-demo-9b10c.appspot.com",
    messagingSenderId: "1172771381",
    appId: "1:1172771381:web:73bfb6684a13c8526ed77c",
    measurementId: "G-9JX0XLPCV3"
}


const firebaseProdConfig = {
    apiKey: "AIzaSyCLR0zytF3Ivah9Yp7aQGT_sbXblq4aqNw",
    authDomain: "lrnr-live.firebaseapp.com",
    databaseURL: "https://lrnr-live.firebaseio.com",
    projectId: "lrnr-live",
    storageBucket: "lrnr-live.appspot.com",
    messagingSenderId: "68425155387",
    appId: "1:68425155387:web:4974025bedb12b224a2d1e",
    measurementId: "G-HW67BMXCCY"
}

const firebaseLrnrLiveDevConfig = {
    apiKey: "AIzaSyDnp3Xw6sQoJGDYwnFzApAfpRcsrQ3eRNk",
    authDomain: "moksha-dev-89b2e.firebaseapp.com",
    projectId: "moksha-dev-89b2e",
    storageBucket: "gs://usergeneratedcontent",
    messagingSenderId: "581013006116",
    appId: "1:581013006116:web:ce4ef24ec2ee2e965a35b1",
    measurementId: "G-9WBED55B09"
};

const qaRoomDatabaseUrl = "https://room-users-status.firebaseio.com/";
const demoRoomDatabaseUrl = "https://room-users-status-demo.firebaseio.com/";
const prodRoomDatabaseUrl = "https://room-users-status-prod.firebaseio.com/";
const lrnrLiveDevRoomDbUrl = 'https://moksha-room-users-status.firebaseio.com/'

export const getRoomDatabaseUrl = () => {
    const env = getEnv();
    switch (env) {
        case "devint":
        case "dev":
        case "qa":
        case "local":
            return qaRoomDatabaseUrl
        case "demo":
            return demoRoomDatabaseUrl
        case "prod":
            return prodRoomDatabaseUrl
        case 'lrnr-live-dev':
            return lrnrLiveDevRoomDbUrl;
        default:
            return qaRoomDatabaseUrl
    }
}

export const getFirebaseConfig = () => {
    const env = getEnv();
    switch (env) {
        case "devint":
        case "dev":
            return firebaseSandboxConfig;
            break;
        case "local":
            return firebaseSandboxConfig;
            break;
        case "prod":
            return firebaseProdConfig;
            break;
        case "qa":
            return firebaseSandboxConfig;
            break;
        case "demo":
            return firebaseDemoConfig;
            break;
        case 'lrnr-live-dev':
            return firebaseLrnrLiveDevConfig;
        default:
            return firebaseSandboxConfig;
    }
}

export const getProcessBeamFinishFnUrl = () => {
    const env = getEnv();
    switch (env) {
        case "devint":
        case "dev":
        case "local":
        case "qa":
            return "https://us-central1-polling-sb.cloudfunctions.net/processBeamFinish";
            break;
        case "prod":
            return "https://us-central1-lrnr-live.cloudfunctions.net/processBeamFinish";
            break;
        case "demo":
            return "https://us-central1-polling-demo-9b10c.cloudfunctions.net/processBeamFinish";
            break;
        default:
            return "https://us-central1-polling-sb.cloudfunctions.net/processBeamFinish";
    }
}