import { isValidDiagram } from 'data/validators/item/diagramValidator';
import { shuffleArray } from '../../containers/Main/Beam/utils';
import { getSelectionOptionArray } from '../utils/diagram';
import { DEFAULT_DIAGRAM_ASSESSMENT_CONFIG } from '../../util/constants';
const clone = require('rfdc')();

export const createClickOnTargetAssessmentItem = ({ topic, diagramItem, topicItemInfo, userSignature }) => {
    console.log("createClickOnTargetAssessmentItem diagramItem", diagramItem, topicItemInfo)

    //click on target (cot)
    const isValid = isValidDiagram({ topic, diagramItem })
    if (!isValid) {
        console.log("transformToClickOnTarget failed as input sent is not valid")
        return null;
    }

    const assessmentConfig = clone(topicItemInfo.assessment_config) || { ...DEFAULT_DIAGRAM_ASSESSMENT_CONFIG };
    const itemType = assessmentConfig.item_type;
    const optionsType = assessmentConfig.options_type;
    const checkActivityMode = assessmentConfig.activity_mode;
    const promptSelection = assessmentConfig.promptSelection;

    const diagramItems = getItems({ diagramItem, theItemType:itemType });
    assessmentConfig['items'] = ((checkActivityMode === 'dragAndDrop' || checkActivityMode === 'dropDown') && !promptSelection) ? shuffleArray(diagramItems) : diagramItems;
    assessmentConfig['options'] = getOptions({ diagramItem, optionsType })

    const { targetTerms, targetDefns, cotContent } = getCotAssessmentItemContent({ diagramItem, activityMode: assessmentConfig.activity_mode, promptSelection })
    const selectionOptionArray = getSelectionOptionArray(cotContent.areas);

    const total_items = assessmentConfig.items.length;
    if (promptSelection) {
        console.log('items check', targetTerms, targetDefns);
        assessmentConfig.items = assessmentConfig.items.map((item) => {
            if (targetTerms.includes(item.uuid) || targetDefns.includes(item.uuid)) {
                item.isTarget = true;
            }
            return item;
        });
        let finalSelectionArray = [ ...selectionOptionArray ];
        if (itemType !== 'term') {
            finalSelectionArray = selectionOptionArray.filter((option) => option.activityType !== 'labelling');
        }
        assessmentConfig.allowed_activities_for_beam = [ ...finalSelectionArray ];
    } else {
        assessmentConfig.items = assessmentConfig.items.filter((item) => !(targetTerms.includes(item.uuid) || targetDefns.includes(item.uuid)))
    }

    if (promptSelection && selectionOptionArray.length === 1) {
        assessmentConfig.activity_mode = 'labelling';
        delete cotContent.scoring;
    }
    //assessmentConfig['activity_mode']='cot';
    const cotItem = {
        _id: diagramItem._id,
        type: 'assessment',
        subtype: 'diagram',
        content: cotContent,
        assessment_config: assessmentConfig,
        total_items,
        uuid: diagramItem.uuid,
        owner_uuid: userSignature.accountId,
        title: diagramItem.title ? diagramItem.title : '',
        attachments: diagramItem.attachments ? diagramItem.attachments : []
    }

    return cotItem;
}

const getCotAssessmentItemContent = ({ diagramItem, activityMode, promptSelection }) => {
    console.log("getCotAssessmentItemContent diagramItem", diagramItem)
    const cotContent = {};
    cotContent.id = diagramItem.content.id;
    cotContent.stimulus = diagramItem.content.stimulus;
    const terms = []
    const defns = []
    const areas = [];
    const targetTerms = [];
    const targetDefns = []

    const labellingOrDragdrop = activityMode === 'labelling' || activityMode === 'dragAndDrop' || activityMode === 'dropDown';
    if (diagramItem.content.areas) {
        diagramItem.content.areas.forEach((areaElement) => {
            if ((!labellingOrDragdrop || promptSelection) && areaElement.type === 'target') {
                targetTerms.push(diagramItem.content.scoring[areaElement.id].answer);
                targetTerms.push(diagramItem.content.scoring[areaElement.id].defAnswer);
                if (!promptSelection) return;
            }
            areas.push({
                id: areaElement.id,
                type: areaElement.type,
                coords: areaElement.coords,
                color: areaElement.color,
                areaColor: areaElement.areaColor
            })
        })
    }

    if (diagramItem.content.terms) {
        diagramItem.content.terms.forEach((termElement) => {
            if (!promptSelection && targetTerms.length > 0 && targetTerms.includes(termElement.term.id)) {
                return
            }
            terms.push(termElement.term)
            defns.push(termElement.defn)
        })
    }

    cotContent.terms = terms;
    cotContent.defns = defns;
    cotContent.areas = areas;
    if (promptSelection) {
        cotContent.scoring = diagramItem.content.scoring;
    }
    // cotContent.scoring = diagramItem.content.scoring ? diagramItem.content.scoring : {};
    cotContent.terms_count = diagramItem.content.terms_count ? diagramItem.content.terms_count : 0;
    return {targetTerms, targetDefns, cotContent};
}

const getItems = ({ diagramItem, theItemType }) => {
    if(theItemType==="random"){
        let tempItemTypesArray=["term","defn"];
        theItemType = tempItemTypesArray[Math.floor(Math.random() * tempItemTypesArray.length)];
    }
    const itemsCount = getNoOfItems({ diagramItem });
    if (!Array.isArray(diagramItem.content.terms) || diagramItem.content.terms.length <= 0) {
        return [];
    }
    const items = [];
    if (theItemType == 'term') {
        for (let i = 0; i < itemsCount; i++) {
            items.push({
                item_id: diagramItem.content.terms[i].term_item_id,
                type: theItemType,
                uuid: diagramItem.content.terms[i].term.id
            })
        }
    } else if (theItemType == 'defn') {
        for (let i = 0; i < itemsCount; i++) {
            items.push({
                item_id: diagramItem.content.terms[i].term_item_id,
                type: theItemType,
                uuid: diagramItem.content.terms[i].defn.id
            })
        }
    }
    return items;
}

const getNoOfItems = ({ diagramItem }) => {


    //check the number of terms in the content
    let availableNoOfItems = diagramItem.content.terms.length;
    if (diagramItem.assessment_config && diagramItem.assessment_config.items_count) {
        const itemsCount = diagramItem.assessment_config.items_count
        if (itemsCount > availableNoOfItems) {
            return availableNoOfItems
        }else{
            return itemsCount;
        }
    }
    return availableNoOfItems;

}

const getOptions = ({ diagramItem, itemType: optionsType }) => {
    const itemsCount = getNoOfOptions({ diagramItem });
    const options = [];
    if (optionsType == 'image') {
        return options;
    }
    if (optionsType == 'term') {
        for (let i = 0; i < itemsCount; i++) {
            options.push({
                type: optionsType,
                uuid: diagramItem.content.terms[i].term.id
            })
        }
    } else if (optionsType == 'defn') {
        for (let i = 0; i < itemsCount; i++) {
            options.push({
                type: optionsType,
                uuid: diagramItem.content.terms[i].defn.id
            })
        }
    }
    return options;
}

const getNoOfOptions = ({ diagramItem }) => {
    //check the number of terms in the content
    let availableNoOfOptions = diagramItem.content.terms.length;

    //TODO - need to check if the defn value is valid
    if (diagramItem.assessment_config && diagramItem.assessment_config.options_count) {
        const optionsCount = diagramItem.assessment_config.options_count
        if (optionsCount > availableNoOfOptions) {
            return availableNoOfOptions
        }else{
            return optionsCount;
        }
    }
    return availableNoOfOptions;
}