import { ifOneOrMoreOptionsDoNotHaveCorrectAns } from "../itemConfigSelectables";

export const promptSelectableForFib2 = (data, selectableArray) => {
  selectableArray.push({
    activityType: "fillInTheBlanks",
    title: "Fill In The Blank"
  });

  if (ifOneOrMoreOptionsDoNotHaveCorrectAns(data)) {
    return;
  }

  if (
    ifOneOrMoreAnsContainDistractorOrThereAreMoreThanTwoAns(
      data.content?.answers
    )
  ) {
    selectableArray.push({
      activityType: "dragAndDrop",
      title: "Drag and Drop"
    });
  }
  if (ifAllAnswersContainDistractor(data.content?.answers)) {
    selectableArray.push({
      activityType: "select",
      title: "Dropdown"
    });
  }
};

const ifAllAnswersContainDistractor = answerArray =>
  answerArray?.every(ans => ans.distractors && ans.distractors.length > 0);

const ifOneOrMoreAnsContainDistractorOrThereAreMoreThanTwoAns = answerArray =>
  answerArray?.length > 1 ||
  answerArray?.some(ans => ans.distractors && ans.distractors.length > 0);
