/*
 * TopicsList constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const QUIZ_LIST_TYPE = 'quiz';
export const FETCH_QUIZ = 'lrnrApp/authoring/FETCH_QUIZ';
export const FETCH_QUIZ_SUCCESS = 'lrnrApp/authoring/FETCH_QUIZ_SUCCESS';
export const FETCH_QUIZ_FAILURE = 'lrnrApp/authoring/FETCH_QUIZ_FAILURE';
export const ADD_QUIZ = 'lrnrApp/authoring/ADD_QUIZ';
export const UPDATE_QUIZ = 'lrnrApp/authoring/UPDATE_QUIZ';
export const UPDATE_QUIZ_SUCCESS = 'lrnrApp/authoring/UPDATE_QUIZ_SUCCESS';
export const UPDATE_QUIZ_FAILURE = 'lrnrApp/authoring/UPDATE_QUIZ_FAILURE';
export const FETCH_QUIZ_ITEMS = 'lrnrApp/authoring/FETCH_QUIZ_ITEMS';
export const FETCH_QUIZ_ITEMS_SUCCESS = 'lrnrApp/authoring/FETCH_QUIZ_ITEMS_SUCCESS';
export const FETCH_QUIZ_ITEMS_FAILURE = 'lrnrApp/authoring/FETCH_QUIZ_ITEMS_FAILURE';
export const QUIZ_ITEMS_HOLDER = "QUIZ_ITEMS_HOLDER";
export const CHANGE_QUIZ_ITEM_ORDER = "CHANGE_QUIZ_ITEM_ORDER";
export const ADD_QUIZ_ITEM = 'lrnrApp/authoring/ADD_QUIZ_ITEM';
export const DUPLICATE_QUIZ_ITEM = 'lrnrApp/authoring/DUPLICATE_QUIZ_ITEM';
export const ADD_QUIZ_ITEM_SUCCESS = 'lrnrApp/authoring/ADD_QUIZ_ITEM_SUCCESS';
export const ADD_QUIZ_ITEM_FAILURE = 'lrnrApp/authoring/ADD_QUIZ_ITEM_FAILURE';
export const REORDER_QUIZ_ITEMS = 'lrnrApp/authoring/REORDER_QUIZ_ITEMS';
export const REORDER_QUIZ_ITEMS_SUCCESS = 'lrnrApp/authoring/REORDER_QUIZ_ITEMS_SUCCESS';
export const REORDER_QUIZ_ITEMS_FAILURE = 'lrnrApp/authoring/REORDER_QUIZ_ITEMS_FAILURE';
export const UPDATE_QUIZ_SUMMARY = 'lrnrApp/authoring/UPDATE_QUIZ_SUMMARY';
export const UPDATING_QUIZ_ITEM = 'lrnrApp/authoring/UPDATING_QUIZ_ITEM'

export const PATCH_ENTITY_TITLE="title";
export const PATCH_ENTITY_DESC="desc";

export const DEL_QUIZ_ITEM = 'lrnrApp/authoring/DEL_QUIZ_ITEM';
export const DEL_QUIZ_ITEM_SUCCESS = 'lrnrApp/authoring/DEL_QUIZ_ITEM_SUCCESS';
export const DEL_QUIZ_ITEM_FAILURE = 'lrnrApp/authoring/DEL_QUIZ_ITEM_FAILURE';