import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { FETCH_FB_AUTH_TOKEN } from "constants/FirebaseConstants";
import request from 'util/ApiUtils';
import { FB_AUTH_TOKEN_API } from 'constants/ApiConstants.js';
import { fbSigninSuccess, fbSigninFailure, fetchFBAuthTokenSuccess, fetchFBAuthTokenFailure } from 'appRedux/actions/Firebase';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { getFirebaseConfig, getRoomDatabaseUrl } from "firebase/config";
import { getUserSessionData } from "../../util/UserSessionData";

async function authentication({firebase, customToken}) {
  const auth = await firebase.auth().signInWithCustomToken(customToken);
  return auth
}

/*
 Making the (checkIfLoggedIn) onAuthStateChanged call a promise and awaiting it to get the user.
 We will use this user in fetchFbAuthToken generator function to check if authentication call should be made.
*/
async function checkIfLoggedIn() {
  const userExists = () => new Promise((res, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log('current logged in user', user);
      console.log('current logged in user time', user);
      res(user)
    })
  })

  const user = await userExists();
  return user;
}

const firebaseConfig = getFirebaseConfig();
const firebaseApp = firebase.initializeApp(firebaseConfig);

export function* fetchFbAuthToken(action) {
  console.log("in fetchFbAuthToken", action);
  const requestInput = action.requestInput;
  const accountId = requestInput.firebase.userSignature?.accountId;
  const requestURL = FB_AUTH_TOKEN_API;
  console.log("request to fetch fbAuthToken", requestURL);
  //const fbHandle = requestInput.firebase.fbHandle;
  try {
    // const firebaseConfig = getFirebaseConfig();
    console.log('firebase config', firebaseConfig);
    let auth = null;
    let clockSkew = null;
    //log into firebase
    // const firebaseApp = firebase.initializeApp(firebaseConfig);

    // Making a call to check i fthe user already exists. I case if user already exists we will not make authentication call.
    const user = yield call(checkIfLoggedIn);
    console.log('checking if user logged in', user);

    // If user doesn't exists then we will make auth call.
    if (!user || (accountId && accountId !== user.uid) ) {  // fix it later by replacing true to some condition
      const response = yield call(request, requestURL, { ignoreSignature: requestInput.ignoreSignature });
      console.log("fetchFbAuthToken, response", response);
      yield put(fetchFBAuthTokenSuccess({ requestInput, response }));
      const { customToken } = response;

      if (response.data) {
        getUserSessionData(response.data);
      }

      const localDateB = new Date();

      auth = yield call(authentication, { firebase, customToken })

      const localDateA = new Date();
      const firebaseDate = new Date(firebase.auth().currentUser.metadata.lastSignInTime);
      const localSignInTime = localDateA.getTime() - ((localDateA.getTime() - localDateB.getTime())/2);
      clockSkew = firebaseDate.getTime() - localSignInTime;
      localStorage.setItem('clockSkew', clockSkew);

      console.log('new local data b4 auth', localDateB.getTime());
      console.log('new local data after auth', localDateA.getTime());
      console.log('firebase signin time', firebaseDate.getTime());
      console.log('times in local and', localSignInTime, clockSkew);
    } else {
      clockSkew = localStorage.getItem('clockSkew');
    }

    const firestore = firebase.firestore();
    const roomsDatabase = firebaseApp.database(getRoomDatabaseUrl())

    const signInResponse = {auth,firebase, roomsDatabase, clockSkew, firestore,firebaseConfig};
    yield put(fbSigninSuccess({ requestInput,signInResponse}));

  } catch (err) {
    console.log('Failed to fetch token and signin', err);
    yield put(fetchFBAuthTokenFailure({ requestInput, err }));
  }
}

export default function* watchAuthTokenActions() {
  yield takeLatest(FETCH_FB_AUTH_TOKEN, fetchFbAuthToken);
}