import React from "react";
import { Link } from "react-router-dom";
import Beam from "../../../assets/images/lrnr-icons/headlight_outline.svg"
import Beamhover from "../../../assets/images/lrnr-icons/headlight_outline_blue.svg"
import { Popconfirm, Popover } from "antd";
import { slidesCount,itemCount, slideSummary, totalChildrenCount } from './selectors';
import BeamPopup from "containers/Main/Beam/popup";
import { Trash } from 'components/lrnr/IconButtons/Trash';
import { Spin } from 'antd'

import FinishBeamIcon from "../../../assets/images/lrnr-icons/BeamLive/finish_beam.svg"
import FinishBeamHoverIcon from "../../../assets/images/lrnr-icons/BeamLive/finish_beam_hover.svg"
import LiveIndicatorIcon from "../../../assets/images/lrnr-icons/live_indicator.svg"

import SectionIcon from "../../../assets/images/lrnr-icons/section_icon.svg"
import {  actionToUpdateBeamState } from 'containers/Main/Beam/actions';
import { BEAM_STATE } from 'constants/BeamConstants';

function SlidedeckCard({ styleName, data, onDelete, beams, firebase, dispatch }) {
  const { avatar, title, description, item_refs, uuid, items } = data;
  function confirm(e) {
    console.log("onDelete", e, data);
    if (onDelete) {
      onDelete(data);
    } else {
      console.log("onDelete fn is not passed")
    }
  }

  function cancel(e) {
    console.log(e);
  }

  const beamKey = 'slideList'+data.uuid;

  const beamContent = () => {

    return totalChildrenCount(data) > 0 ?
      <BeamPopup key={beamKey} beamSource={{ type: 'slidedeck', component: data, componentItems: null }} />
      :"No items to beam"
  }

  function finishconfirm(theBeam){
     console.log("onFinish",theBeam);
      if (finishTheBeam) {
        finishTheBeam(theBeam);
      } else {
        console.log("onFinish fn is not passed")
      }
  }



  const finishTheBeam = (theTempBeam) => {
    const updateObject = { 'state.status': BEAM_STATE._COMPLETED }
    // dispatchActionToUpdateBeamState(updateObject);
    // event.preventDefault();

    let theBeam=beams.find(theBeam =>{
      return theBeam.id === theTempBeam.beamId;
    })
    dispatch(actionToUpdateBeamState({firebase, updateObject, currentBeam:theBeam}))
  }

  const canDelete = ({beams,slideId}) => {
    const slideBeams= getSlideBeams({beams,slideId});
    if (Array.isArray(slideBeams) && slideBeams.length > 0){
      return false;
    }
    return true;
  }

  const getSlideBeams = ({beams,slideId}) => {
    return beams.filter((beam)=> {
      if (beam != null && beam.state.status != BEAM_STATE._COMPLETED &&
          Array.isArray(beam.components_used.slidedeck) && typeof beam.components_used.slidedeck[0] != 'undefined'){
        return beam.components_used.slidedeck[0] == slideId
      }
    })
  }

  const slideSummaryObj = slideSummary(data);

  const SlideBeams = ({beams, slideId}) => {
    if (typeof beams == 'undefined' || beams.length <= 0){
      return null;
    }
    const beamList = getSlideBeams({beams,slideId});

    if(beamList.length>0){
      let theSectionCodes=JSON.parse(window.sessionStorage.getItem('sectionCodes'));
      let finalBeamsList=[];
      for(let b=0;b<beamList.length;b++){
        var theTempBeam=beamList[b];

        if(theTempBeam.state.status==="open" || theTempBeam.state.status==="inprogress" || theTempBeam.state.status==="paused"){
          let tempObj={}
          tempObj.beamId=theTempBeam.id;
          tempObj.sectionNames="";
          for(let s=0;s<theTempBeam.section_codes.length;s++){
            if (typeof theSectionCodes == 'undefined'){
              throw Error("Enrollment is not found")
            }
            var tempSection=theSectionCodes.find(theSection => {
              return theSection.sectionCode === theTempBeam.section_codes[s];
            })
            if(typeof tempSection!=="undefined"){
              tempObj.sectionNames=tempObj.sectionNames+tempSection.name
              if(s<theTempBeam.section_codes.length-1){
                tempObj.sectionNames=tempObj.sectionNames+", ";
              }
            }
          }
          finalBeamsList.push(tempObj);
        }
      }

      return (
        <div className="gx-flex-row gx-lrnr-slide-beams-holder">
          {finalBeamsList.map((theBeam, index) => (
            <div className="gx-lrnr-slide-beam-list-item" id={theBeam.beamId} key={theBeam.beamId}>
              <div className="gx-lrnr-slide-beam-placeholder h7-lrnr-typo-new">
                <img className="gx-lrnr-slide-beam-placeholder-icon" src={LiveIndicatorIcon} alt="live" />
                <span>LIVE</span>
              </div>
              <div className="gx-lrnr-slide-beam-details">
                <div className="gx-w-50 gx-lrnr-slide-beam-title-holder">
                  <div className="gx-lrnr-slide-beam-section-indicator h6-lrnr-typo-new">Class Sections: </div>
                  {/*<img className="gx-lrnr-slide-beam-section-icon" src={SectionIcon} alt="Section" />*/}
                  <div className="gx-lrnr-slide-beam-title h6-lrnr-typo-new">{theBeam.sectionNames}</div>
                </div>
                <div className="gx-w-50 gx-lrnr-slide-beam-actions">
                  <Link to={{ pathname: `../author/beams/${theBeam.beamId}`, beam: theBeam }}>
                    <button type="button" className="ant-btn gx-lrnr-general-img-icon-title-btn gx-lrnr-slide-beam-section-action-btn text4-lrnr-typo-new" >
                      <img className="gx-lrnr-general-img-icon-title-btn-general-icon" src={Beam} alt="beam" />
                      <img className="gx-lrnr-general-img-icon-title-btn-hover-icon" src={Beamhover} alt="beam" />
                      <span>Resume</span>
                    </button>
                  </Link>
                  <Popconfirm title="Submit to finalize grades?" onConfirm={() => finishconfirm(theBeam)} onCancel={cancel} okText="Yes"
              cancelText="No">
                    <button type="button" className="ant-btn gx-lrnr-general-img-icon-title-btn gx-lrnr-slide-beam-section-action-btn text4-lrnr-typo-new" >
                        <img className="gx-lrnr-general-img-icon-title-btn-general-icon" src={FinishBeamIcon} alt="finish icon" />
                        <img className="gx-lrnr-general-img-icon-title-btn-hover-icon" src={FinishBeamHoverIcon} alt="finish icon" />
                        <span>Finish</span>
                    </button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
    else
      return null;

  }

  let theThumbnail;
  if(typeof items!=="undefined" && Object.keys(items).length > 0){
    if(typeof items[Object.keys(items)[0]].item_attachment_url!=="undefined")
      theThumbnail=items[Object.keys(items)[0]].item_attachment_url;
  }



  return (
    <div className={`gx-user-list gx-lrnr-slide-card-list-item ${styleName}`} id={uuid}>
      {/*
        (typeof avatar !== "undefined") ? (
          <span className="ant-avatar ant-avatar-circle ant-avatar-image gx-mr-2 gx-lrnr-slide-avatar-img">
            <img src={avatar} />
          </span>
        ) : (
            <span className="ant-avatar ant-avatar-circle gx-mr-2 gx-lrnr-slide-avatar">
              <span className="ant-avatar-string gx-lrnr-slide-avatar-string">{title!=null?title.charAt(0):''}</span>
            </span>
          )
      */}

      <div className={`gx-description gx-lrnr-slide-card-description ${(itemCount(data)==0)?"noItems":""}`}>
        <div className="gx-flex-row gx-lrnr-slide-title-action-holder">
          <div className="gx-flex-row gx-lrnr-slide-title-thumbnail-holder">
            {(data.import_status==="complete")?((typeof theThumbnail!=="undefined")?(<div className="gx-lrnr-slide-title-thumbnail text-secondary-lrnr-small-typo"><img src={theThumbnail}/></div>):(<div className="gx-lrnr-slide-title-thumbnail-noimg text-secondary-lrnr-small-typo">No Image</div>)):((data.import_status==="failed")?(<div className="gx-lrnr-slide-title-thumbnail-noimg text-secondary-lrnr-small-typo">Upload Failed</div>):(<div className="gx-lrnr-slide-title-thumbnail-spinner text-secondary-lrnr-small-typo"><Spin size='large' /></div>))}
            {/*<img src={imageJson.url}/>*/}
          </div>
          <div className="gx-flex-row gx-lrnr-slide-title-data-holder">
            <div className="gx-w-100 gx-lrnr-slide-title h2-lrnr-typo-new">{title}</div>
            <div className="gx-w-100 h6-lrnr-typo-new">{slidesCount(data)} Slides</div>
          </div>
        </div>
        <p className="gx-text-grey gx-mb-2 gx-lrnr-slide-description-body">{description}</p>
        {/*<div className="gx-lrnr-slide-description-extra">
          <div className="gx-lrnr-slide-learning-obj">
            <h3>Learning Objectives</h3>
          </div>
          <div className="gx-lrnr-slide-chapter">
            <h3>Chapter</h3>
          </div>
        </div>*/}
      </div>
      {(itemCount(data)>0)?(
          <div className="gx-card-list-footer gx-lrnr-slide-card-footer h6-lrnr-typo-new">
            <div className="gx-flex-row gx-embed-responsive gx-mb-1">
              <span className=" gx-lrnr-slide-footer-item-name gx-text-right">Items:</span>
              <span className="gx-lrnr-slide-footer-item-value gx-text-right">{itemCount(data)}</span>
            </div>
            <div className="gx-flex-row gx-embed-responsive gx-mb-1">
              <span className="gx-lrnr-slide-footer-item-name gx-text-right">Efficacy:</span>
              <span className="gx-lrnr-slide-footer-item-value gx-text-right">{slideSummaryObj.efficacyTotal}</span>
            </div>
            <div className="gx-flex-row gx-embed-responsive gx-mb-1">
              <span className="gx-lrnr-slide-footer-item-name gx-text-right">Participation:</span>
              <span className="gx-lrnr-slide-footer-item-value gx-text-right">{slideSummaryObj.participationTotal}</span>
            </div>
            <div className="gx-flex-row gx-embed-responsive gx-mt-1" style={{borderTop: "1px dotted #cccccc", paddingTop: "0.25rem"}}>
              <span className="gx-lrnr-slide-footer-item-name gx-text-right">Total Points:</span>
              <span className="gx-lrnr-slide-footer-item-value gx-text-right">{slideSummaryObj.totalGradePoints}</span>
            </div>
          </div>
        ):(null)
      }

      <SlideBeams slideId={data.uuid} beams={beams} />
      {(data.import_status==="complete" || data.import_status==="failed")?(
        <div  className="gx-flex-row gx-lrnr-slide-actions-holder">
          {(data.import_status==="complete")?(<Link to={{ pathname: `./slides/${uuid}`, slide: data }}>
              <button type="button" className="ant-btn gx-lrnr-general-icon-btn gx-lrnr-slide-beam-action-btn text4-lrnr-typo-new">
                <i className="icon icon-edit"></i>
                <span>Edit</span>
              </button>
            </Link>):(null)}

            {canDelete({ beams, slideId: data.uuid}) ?
              <Popconfirm title="Are you sure you want to delete?" onConfirm={confirm} onCancel={cancel} okText="Yes"
                cancelText="No">
                <Trash displayName={"Delete"} extraClass="gx-lrnr-slide-beam-action-btn text4-lrnr-typo-new"/>
              </Popconfirm> : null
            }

            {/*<button type="button" className="ant-btn gx-lrnr-general-icon-btn gx-lrnr-slide-beam-action-btn text4-lrnr-typo-new">
              <i className="icon icon-copy"></i>
              <span>Duplicate</span>
            </button>
            <button type="button" className="ant-btn gx-lrnr-general-icon-btn gx-lrnr-slide-beam-action-btn text4-lrnr-typo-new">
              <i className="icon icon-add-circle"></i>
              <span>Assign</span>
            </button>*/}
            {(data.import_status==="complete")?(
              <Popover placement="bottomRight" title="" content={beamContent()} trigger="click">
                <button type="button" className={`ant-btn gx-lrnr-general-img-icon-btn gx-lrnr-slide-beam-action-btn text4-lrnr-typo-new`}>
                  <img className="gx-lrnr-general-icon-btn-general-icon" src={Beam} alt="beam" />
                  <img className="gx-lrnr-general-icon-btn-hover-icon" src={Beamhover} alt="beam" />
                  <span>Beam</span>
                </button>
              </Popover>):(null)}

        </div>
      ):(null)}



    </div>
  );

}



export default SlidedeckCard;
