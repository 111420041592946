import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
import { notification, Button, Icon } from 'antd';
import { slidedeckConversionStages } from 'constants/NotificationConstants';
import { markMessageAsRead } from 'appRedux/actions/AppBootstrap';
import { useHistory } from "react-router-dom";
import { getSlidesUrl } from 'util/LocationUtils';

import SlidedeckIcon from "assets/images/lrnr-icons/slidedeck_icon.svg";
import SlidedeckEnabledIcon from "assets/images/lrnr-icons/slidedeck_enabled_icon.svg";

const AppChannelMessage = ({ dispatch, appBootstrap, firebase }) => {

  const history = useHistory();
  const messageFilter = [
    slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE1_FAIL,
    slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE2_FAIL,
    slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE3_FAIL,
    slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_FAIL,
    slidedeckConversionStages._SLIDEDECK_CONVERSION_FINAL_STAGE_FAIL,
    slidedeckConversionStages._SLIDEDECK_CONVERSION_FINAL_STAGE_PASS
  ];



  const openNotification = ({ placement, userChannelMessage }) => {
    console.log("userChannelMessage",userChannelMessage);
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Confirm
      </Button>
    );
    const close = () => {
      console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
      );
      dispatch(markMessageAsRead({ message: userChannelMessage, userChannel }))
    };

    const NoficiationIcon = () => {
      console.log("Includes fail", userChannelMessage.code);
      return (
        userChannelMessage.code.includes('FAIL') ?
          <Icon type="frown" style={{ color: 'red' }} /> :
          <Icon type="smile" style={{ color: '#108ee9' }} />
      )
    }

    const gotoSlidedeckEdit = () => {
      //console.log("userChannelMessage.data.jobJson.uuid",userChannelMessage);
      const slidesPath = getSlidesUrl({})
      notification.close(userChannelMessage.data.jobJson.uuid)
      history.push(slidesPath+'/'+userChannelMessage.data.jobJson.input.topic_uuid)
    }

    const NotificationDescriptionUI = () =>{
      if(userChannelMessage.code==="SLIDEDECK_CONVERSION_FINAL_STAGE_PASS"){
        return(
          <div>
            <div className="gx-lrnr-notification-description">{userChannelMessage.data.jobName}</div>
             <div  className="gx-lrnr-notification-action-holder">
                <a className={`ant-btn gx-lrnr-general-img-icon-btn gx-lrnr-notification-action-btn text4-lrnr-typo-new`} onClick={(event) =>gotoSlidedeckEdit(event)}>
                    <img className="gx-lrnr-general-icon-btn-general-icon" src={SlidedeckIcon} alt="slidedeck" />
                    <img className="gx-lrnr-general-icon-btn-hover-icon" src={SlidedeckEnabledIcon} alt="slidedeck" />
                    <span>Open Deck</span>
                </a>
             </div>
          </div>
        )
      }
      else{
        return(
          <div>Slidedeck Processing: {userChannelMessage.data.jobName}</div>
        )
      }
    }

    notification.info({
      message: `${userChannelMessage.message}`,
      description:<NotificationDescriptionUI/>,
      placement,
      key:`${userChannelMessage.data.jobJson.uuid}`,
      duration:null,
      onClose: close,
      icon: <NoficiationIcon />,

    });
  };

  if (typeof firebase == "undefined" || typeof firebase.userSignature == "undefined") {
    return '';
  }

  const accountId = firebase.userSignature.accountId;
  const userChannel = "uid_" + accountId + "_i";

  const channelMessages = appBootstrap.appChannelMessages;
  const userChannelMessages = channelMessages[userChannel];

  if (Array.isArray(userChannelMessages)) {
    userChannelMessages.filter((userChannelMessage) => {
      return (typeof userChannelMessage["read"] === "undefined" && messageFilter.includes(userChannelMessage.code))
    }).forEach((userChannelMessage) => {
      openNotification({ placement: 'bottomLeft', userChannelMessage });
      dispatch(markMessageAsRead({ message: userChannelMessage, userChannel }))

    })
  }

  return (
    <div></div>
  )

}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = (state) => {
  console.log("STATE in appChannelMessage", state);
  const { appBootstrap, firebase } = state;
  return { appBootstrap, firebase }
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(AppChannelMessage);