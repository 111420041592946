import { PATCH_ENTITY_TITLE, PATCH_ENTITY_DESC } from './constants';
import { slidedeckConversionStages } from 'constants/NotificationConstants';


export function validateItems({ items, slidedeck }) {
    if (typeof items == 'undefined' ) {
        return false;
    }
    return true;
}

export function updateSlidedeckHeader(slidedeck, state, action) {
    if (typeof action.updatePayload.patchArray != 'undefined') {
        action.updatePayload.patchArray.forEach((patchObject, index) => {

            switch (patchObject.entity) {
                case PATCH_ENTITY_TITLE:

                    slidedeck.title = patchObject.value;
                    break;
                case PATCH_ENTITY_DESC:
                    slidedeck.desc = patchObject.value;
                    break;
            }
        })
    }
}

export function updateImportedListItem(slidedeck, appChannelMessage){

    const msgData = appChannelMessage.data;
    // if (typeof msgData == 'undefined'|| typeof msgData.topicInfo == 'undefined'){
    //   return;
    // }
    const channelMessageCode = appChannelMessage.code;
    
    if (channelMessageCode == slidedeckConversionStages._SLIDEDECK_CONVERSION_FINAL_STAGE_PASS){
        if (typeof msgData == 'undefined'|| typeof msgData.topicInfo == 'undefined'){
          return;
        }
        const topicInfo = msgData.topicInfo;
        slidedeck.items_order=topicInfo.itemsOrder;
        slidedeck.items=topicInfo.topicItems;
        slidedeck.import_status="complete";
    }
    else if (channelMessageCode === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE2_FAIL || channelMessageCode === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE3_FAIL || channelMessageCode === slidedeckConversionStages._SLIDEDECK_CONVERSION_STAGE4_FAIL) {
        slidedeck.import_status="failed";
    }
     
}

export const computeSlideSummary = ({ slidedeck }) => {
    let efficacyTotal = 0;
    let participationTotal = 0;
    let totalGradePoints = 0;
    const itemsOrder = slidedeck.items_order;
    const items = slidedeck.items;
    if (itemsOrder) {
        itemsOrder.forEach((itemId) => {
            if (items[itemId] && typeof items[itemId].measure != 'undefined' && Object.keys(items[itemId].measure).length > 0){
                efficacyTotal += items[itemId].measure.efficacy_factor;
                participationTotal += items[itemId].measure.participation_factor;
                totalGradePoints += items[itemId].measure.grade_points;
            }
        })
    }
    return { efficacyTotal, participationTotal, totalGradePoints };
  }
