export const isValidHighlight = ({ item }) => {
  const { stimulus, paragraph } = item.content;
  if (
    !stimulus?.length > 0 ||
    !paragraph ||
    paragraph === '[{"type":"paragraph","children":[{"text":""}]}]'
  ) {
    return false;
  }
  return true;
};
