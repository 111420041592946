import { eventChannel, END } from 'redux-saga'
import { call, put, fork, take, takeLatest, cancelled } from 'redux-saga/effects';
import { listenToUserBeamsSuccess, listenUserBeamScreenSuccess, listenToUserBeamsFailure, 
    joinBeamChannelSuccess, joinBeamChannelFailure,
    receiveBeamChannelMessageSuccess, 
    userBeamScreensChanged} from './actions';
import { isUserStudent, isUserInstructor } from 'util/AppUtil'
import { EVENT_BUS_SERVICE} from 'constants/ApiConstants';
import { getInstructorBeamChannelAddress } from './utils'
import EventBus from 'vertx3-eventbus-client';


export function* setupUserBeamsListener(action) {

    console.log("setupUserBeamsListener", action)
    const requestInput = action.requestInput;
    const currentBeam = requestInput.currentBeam;
    const firebase = requestInput.firebase;
    const userBeamsCollection = firebase.userBeamsCollection;

    const setupEventListener = ({ userBeamsCollection, currentBeam }) => {

        return eventChannel(emmitter => {

            const beamId = currentBeam.id;
            let beamWatcher = userBeamsCollection.where("beam_doc_ref_id", "==", beamId)
                .onSnapshot(function (querySnapshot) {
                    console.log("new change to userBeamsCollection", querySnapshot);
                    const userBeams = [];
                    querySnapshot.forEach(userBeamDoc => {
                        console.log(` setupEventListener ${userBeamDoc.id} => ${userBeamDoc.data()}`);
                        const userBeam = userBeamDoc.data();
                        userBeam['id'] = userBeamDoc.id;
                        userBeams.push(userBeam);
                    });
                    console.log("b4 emitter")
                    emmitter(userBeams);
                    console.log("after emitter", userBeams)
                })

            return () => beamWatcher;
        })
    }

    // const channel = yield call(setupEventListener, { userBeamsCollection, currentBeam });
    const channel = setupEventListener({ userBeamsCollection, currentBeam });
    try {
        while (true) {
            const userBeams = yield take(channel);
            console.log("userBeams in saga", userBeams)
            yield put(listenToUserBeamsSuccess(requestInput, { userBeams }));
            yield fork(watchUserBeamScreen, { userBeams, currentBeam, firebase, userBeamsCollection, requestInput });
        }
    } catch (err) {
        console.log("ERROR in userBeamUtils", err);
    } finally {

        if (yield cancelled()) {
            console.log("userBeamChannel terminated")
            channel.close();
        }
    }
}

export function* watchUserBeamScreen({ userBeams, currentBeam, firebase, userBeamsCollection, requestInput }) {
    if (userBeams && userBeams.length > 0) {
        // setupUserBeamsResponseListener({ currentBeam, firebase, userBeamsCollection, userBeams })
        // yield Promise.all(
        const screenChannels = [];
        // for (let i = 0; i < userBeams.length; i++) {
        //     const userBeam = userBeams[i];
        //     console.log("going to listen to user screens", userBeam)
        //     // const userScreens = setupUserBeamScreenListener({ userBeam, currentBeam, firebase, userBeamsCollection });

        const screenChannel = yield call(setupScreenEventChannelListener, { userBeams, userBeam: "", currentBeam, firebase, userBeamsCollection });
        //     screenChannels.push({userBeam, screenChannel})
        // }
        console.log("screenChannels", screenChannels);

        try {
            while (true) {
                // for (let sc = 0; sc < screenChannels.length; sc++) {
                // console.log("screenChannel", screenChannels[sc].screenChannel);
                // console.log("userBeam in screens", screenChannels[sc].userBeam);
                const userScreens = yield take(screenChannel);
                console.log("userScreens", userScreens)
                if (typeof userScreens != 'undefined' && userScreens != null 
                        && userScreens.userBeamScreens.length > 0 ){
                    yield put(userBeamScreensChanged(requestInput, { userScreens }));
                }
            }
        } catch (err) {
            console.log("ERROR in userBeamUtils", err);
        } finally {
            console.log("screenChannel terminated")
            if (yield cancelled()) {
                screenChannel.close();
            }
        }
        // )
    }
}

export function setupScreenEventChannelListener({ userBeams, userBeam, currentBeam, firebase, userBeamsCollection }) {

    return eventChannel(emmitter => {

        let userBeamWatcher;

        userBeams.forEach(userBeam => {
            const userBeamScreens = [];
            const userBeamId = userBeam.id;
            userBeamWatcher = userBeamsCollection.doc(userBeamId).collection("user_screens")
                .onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(userBeamScreenDoc => {
                        console.log(`${userBeamScreenDoc.id} => ${userBeamScreenDoc.data()}`);
                        const userBeamScreen = userBeamScreenDoc.data();
                        userBeamScreen['id'] = userBeamScreenDoc.id;
                        userBeamScreens.push(userBeamScreen);
                    });
                    emmitter({ userBeamScreens, userBeam });

                })
        })
        return () => userBeamWatcher;
    })
}

export function setupUserBeamScreenListener({ userBeam, currentBeam, firebase, userBeamsCollection }) {
    return new Promise((resolve, reject) => {
        let userBeamScreens = [];
        let resolveOnce = (doc) => {
            resolveOnce = () => { };
            resolve(doc);
        };
        let beamWatcher = userBeamsCollection.doc(userBeam.id).collection("user_screens")
            .onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(userBeamScreenDoc => {
                    console.log(`${userBeamScreenDoc.id} => ${userBeamScreenDoc.data()}`);
                    const userBeamScreen = userBeamScreenDoc.data();
                    userBeamScreen['id'] = userBeamScreenDoc.id;
                    userBeamScreens.push(userBeamScreen);
                });
                resolveOnce(userBeamScreens);
            }, reject)
    });
}

export function* joinBeamChannel(action) {
    console.log("action",action);
    const requestInput = action.requestInput;
    const {currentBeam, firebase} = requestInput;
    console.log("joining beamChannel", currentBeam);
    try {

        const beamChannelAddress = getInstructorBeamChannelAddress(currentBeam);
        const beamChannel = yield call(setupBeamChannelMessageListener, { firebase, beamChannelAddress });

        console.log("beamChannel", beamChannel,beamChannelAddress );
        yield put(joinBeamChannelSuccess(requestInput, { beamChannelAddress }));

        try {
            while (true) {
                const beamChannelMessage = yield take(beamChannel);
                console.log("beamChannelMessage received", beamChannelMessage)
                yield put(receiveBeamChannelMessageSuccess(requestInput, { beamChannelAddress, beamChannelMessage }));
            }
        } catch (err) {
            console.log("ERROR in receiving beamChannel Message", err);

        } finally {
            console.log("beamChannel terminated")
            if (yield cancelled()) {
                beamChannel.close();
            }
        }

 
    }catch(err){
        console.error("could not join beamChannel",err);
        yield put(joinBeamChannelFailure(requestInput, err));

    }
}

export function setupBeamChannelMessageListener ({beamChannelAddress,firebase}) {
    return eventChannel(emmitter => {

        let eb = new EventBus(EVENT_BUS_SERVICE,{"vertxbus_ping_interval": 8000});
        eb.onopen = function () {
            
            const headers = {} ;
            headers.userSignature=firebase.userSignature;
            // set a handler to receive a message
            eb.registerHandler(beamChannelAddress, headers, function (error, message) {
                console.log('received a message: ' + JSON.stringify(message));
                if (typeof message != 'undefined'){
                    emmitter({ beamChannelAddress, message });
                }
            });
            // eb.unregisterHandler(beamChannelAddress, headers, function (error, message) {
                //     console.log('received a message: ' + JSON.stringify(message));
                // });
        }

        return () => eb;
    })
}