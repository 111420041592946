import { DEFAULT_MATCHING_ASSESSMENT_CONFIG } from "../../util/constants";
import { isValidMatching } from "../validators/item/matchingValidator";
import { changedTopicItemInfoForMatching } from "../topicItemInfoBuilders/matching";
const clone = require("rfdc")();

export const getMatchingItemContent = ({
  isTriggeredFromPromptSelection,
  itemContent,
  toShuffleSides,
  matchingType,
  promptSelectionEnabled
}) => {
  if (promptSelectionEnabled && !isTriggeredFromPromptSelection) {
    return { stimulus: itemContent.stimulus };
  }

  const convertedContent = {};
  convertedContent.id = itemContent.id;
  convertedContent.stimulus = itemContent.stimulus;
  convertedContent.containsMultipleAns = false;

  const lItems = itemContent.pairs.map(pair => {
    const newLitem = {
      ...pair.lValue
    };
    if (!toShuffleSides) {
      newLitem.pointPercentage = pair.pointPercentage;
      newLitem.weight = pair.weight;
    }
    return newLitem;
  });

  let rItems = [];
  itemContent.pairs.forEach(pair => {
    const singleFirstAns = { ...pair.rValue };
    if (toShuffleSides) {
      singleFirstAns.pointPercentage = pair.pointPercentage;
      singleFirstAns.weight = pair.weight;
    }
    rItems.push(singleFirstAns);
    if (
      (matchingType === "grouping" || toShuffleSides) &&
      pair.correctAnswers &&
      pair.correctAnswers.length > 0
    ) {
      let newPointPercentage=pair.pointPercentage/(pair.correctAnswers.length+1);
      singleFirstAns.pointPercentage =newPointPercentage
      if (!convertedContent.containsMultipleAns)
        convertedContent.containsMultipleAns = true;
      pair.correctAnswers.forEach(corrAns => {
        const newCorrAns = { ...corrAns };
        if (toShuffleSides) {
          newCorrAns.pointPercentage = newPointPercentage;
          newCorrAns.weight = pair.weight;
        }
        rItems.push(newCorrAns);
      });
    }
  });

  if (!toShuffleSides && matchingType === "matching") {
    if (itemContent.distractors && itemContent.distractors.length > 0) {
      const distractorsWithoutPointPercentage = itemContent.distractors.map(
        distractor => ({ value: distractor.value, id: distractor.id })
      );
      rItems = rItems.concat(distractorsWithoutPointPercentage);
    }
  }

  convertedContent.lItems = toShuffleSides ? rItems : lItems;
  convertedContent.rItems = toShuffleSides ? lItems : rItems;

  return convertedContent;
};

export const createMatchingAssessmentItem = ({
  item,
  topicItemInfo,
  userSignature
}) => {
  const isValid = isValidMatching({ item });
  if (!isValid) {
    return null;
  }

  const finalTopicItemInfo =
    (!topicItemInfo.assessment_config.challenges_config.display_order &&
      !topicItemInfo.assessment_config.options_config.display_order) ||
    topicItemInfo.assessment_config.promptSelection
      ? changedTopicItemInfoForMatching(item, topicItemInfo)
      : topicItemInfo;

  const finalAssessmentItem = {
    _id: item._id,
    type: "assessment",
    subtype: "matching",
    content: getMatchingItemContent({
      itemContent: item.content,
      toShuffleSides: finalTopicItemInfo.assessment_config.shuffleLeftToRight,
      matchingType: finalTopicItemInfo.assessment_config.typeOfMatch,
      promptSelectionEnabled:
        finalTopicItemInfo.assessment_config.promptSelection &&
        finalTopicItemInfo.assessment_config.allowed_activities_for_beam
          .length > 1
    }),
    attachments: item.attachments ? item.attachments : [],
    uuid: item.uuid,
    title: item.title ? item.title : "",
    assessment_config: clone(topicItemInfo.assessment_config) || { ...DEFAULT_MATCHING_ASSESSMENT_CONFIG },
    owner_uuid: userSignature.accountId
  };

  return finalAssessmentItem;
};
