import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Button, Dropdown, Icon, Typography } from "antd";
import { Link } from "react-router-dom";

import { isMobile } from 'react-device-detect';

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import MyBankIcon from "../../assets/images/lrnr-icons/my_bank.svg";
import QuizzesIcon from "../../assets/images/lrnr-icons/quizzes_icon.svg";
import SlidedeckIcon from "../../assets/images/lrnr-icons/slidedeck_icon.svg";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { SubMenu } from "rc-menu";
import { getQuizzesUrl, getSlidesUrl } from 'util/LocationUtils'

import SlideDeckUpload from 'containers/Main/Slidedeck/upload';
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  constructor(props) {
    super(props)
    this.state = { isSlideClicked: false }
    this.toogleSlidedeckCreateState.bind(this);
  }

  toogleSlidedeckCreateState = () => {
    console.log("this.state", this.state);
    this.setState( {...this.state, isSlideClicked:!this.state.isSlideClicked})
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup text-lrnr-font-size-small";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = "mybank";
    //strip the courses from the pathname if exists
    const pathArry = pathname.split('/');
    if ( pathname.includes('courses')){
      if (pathArry.length >= 4 && pathArry[0] === ""){
        selectedKeys = pathArry[4];
      }
    }else{
      if (pathArry.length >= 2 && pathArry[0] === ""){
      selectedKeys = pathArry[2];
      }
    }

    const creatMenu = (
      <Menu theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}>
        <Menu.Item key="3" className={`gx-lrnr-sidebar-create-submenu-item button2-lrnr-typo ${(isMobile) ? "mobileTypo" : ""}`}>
          <Link to={getQuizzesUrl({ location: this.props.location }) + '/create'}>
            <img className="icon icon-card" src={QuizzesIcon} />
            <IntlMessages id="sidebar.quizzes" />
          </Link>
        </Menu.Item>
        <Menu.Item key="2" onClick={this.toogleSlidedeckCreateState} className={`gx-lrnr-sidebar-create-submenu-item button2-lrnr-typo ${(isMobile) ? "mobileTypo" : ""}`}>
          {/* <Link to={getSlidesUrl({ location: this.props.location }) + '/create'}> */}
         
            <img className="icon icon-card" src={SlidedeckIcon} />
            <IntlMessages id="sidebar.uploadslides" />
          
          {/* </Link> */}

        </Menu.Item>
      </Menu>
    );


    return (<Auxiliary>

      <SidebarLogo location={this.props.location} />

      <div className="gx-sidebar-content">
        {/*<div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>

          <Typography.Title level={4} ellipsis={false}>Anatomy & Physiology</Typography.Title>
        </div>*/}

        <CustomScrollbars className="gx-layout-sider-scrollbar gx-layout-lrnr-sider-scrollbar">
          <div className="gx-lrnr-sidebar-create-btn-holder">
            <Dropdown overlay={creatMenu}>
              <Button type="outline" icon="plus" size='large' className={`gx-lrnr-create-btn button2-lrnr-typo ${(isMobile) ? "mobileTypo" : ""}`} >
                <span>CREATE</span>
              </Button>
            </Dropdown>
          </div>

          <Menu
            defaultOpenKeys={['mybank']}
            selectedKeys={[selectedKeys]}
            isOpen={true}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            <MenuItemGroup key="main" className="gx-menu-group" >
              <SubMenu key="mybank" className={this.getNavStyleSubMenuClass(navStyle)}
                isOpen={true} title={<span> <img className="icon icon-dasbhoard" src={MyBankIcon} />
                  <IntlMessages id="sidebar.mybank" className={`button-lrnr-typo ${(isMobile) ? "mobileTypo" : ""}`} /></span>}  >

                <Menu.Item key="quizzes" className={`gx-lrnr-sidebar-submenu-item button2-lrnr-typo ${(isMobile) ? "mobileTypo" : ""}`}>
                  <Link to={getQuizzesUrl({ location: this.props.location })}>
                    <div className={`gx-lrnr-general-menu-item-name `}>
                      <img className="icon icon-card" src={QuizzesIcon} />
                      <IntlMessages id="sidebar.quizzes" />
                    </div>
                    {/*<div className="gx-lrnr-general-menu-item-count gx-text-right">
                          0
                        </div>*/}
                  </Link>
                </Menu.Item>

                <Menu.Item key="slides" className={`gx-lrnr-sidebar-submenu-item button2-lrnr-typo ${(isMobile) ? "mobileTypo" : ""}`}>
                  <Link to={getSlidesUrl({ location: this.props.location })}>
                    <div className={`gx-lrnr-general-menu-item-name `}>
                      <img className="icon icon-card" src={SlidedeckIcon} />
                      <IntlMessages id="sidebar.slides" />
                    </div>
                    {/*<div className="gx-lrnr-general-menu-item-count gx-text-right">
                          0
                        </div>*/}
                  </Link>
                </Menu.Item>
              </SubMenu>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
      {this.state.isSlideClicked?<SlideDeckUpload closeMe={this.toogleSlidedeckCreateState} /> : ''}
    </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = (state) => {
  console.log("STATE in SidebarContent", state);
  const { router, settings } = state;
  const { navStyle, themeType, locale } = settings;
  const { pathname } = router.location;
  const { location } = router;
  return { navStyle, themeType, locale, pathname, location }
};
export default connect(mapStateToProps)(SidebarContent);

