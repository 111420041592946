/**
 *
 * TopicList
 *
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Col, Row, Spin } from "antd";
import ContainerHeader from "components/ContainerHeader";

import { useInjectSaga } from 'util/injectSaga';
import { useInjectReducer } from 'util/injectReducer';
import reducer from './reducer';

import saga from './saga';
import { deleteItemActionCreator, fetchItems } from './actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ITEMS_HOLDER } from './constants';

//import { Draggable } from 'react-beautiful-dnd';
import { selectTopicsList } from './selectors';


const reducerName = "listReducer";

export function List(params) {

  console.log("params", params)
  let dispatch = params.dispatch
  let config = params.config
  let listReducer = params[reducerName]
  let componentState;
  if (!listReducer || typeof listReducer[config.type] == 'undefined') {
    componentState = params.config.initialState
  } else {
    componentState = listReducer[config.type];
  }

  let key = reducerName;
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  let hasMore = componentState.hasMore;
  let page = componentState.page;

  let items = [];
  if (componentState && componentState[ITEMS_HOLDER] && componentState[ITEMS_HOLDER].length > 0) {
    items = componentState[ITEMS_HOLDER];
  }

  useEffect(() => {
    if (items.length === 0) {
      dispatchFetchItems(page, config);
    }
  }, []);

  const getItemIndex = (item, key) => {
    if (params.sortIndexFunction) {
      return params.sortIndexFunction(item.uuid)
    } else {
      return key;
    }
  }

  const onDelete = (item) => {
    dispatch(deleteItemActionCreator(item, config))
  }

  const ItemComponent = config.itemComponent;

  const ItemListMemo = React.memo(({ items }) => {
    return (items || []).map((item, key) => (
            <div  key={item.uuid}>
              <ItemComponent beams={params.beams}
                id={item.uuid} index={getItemIndex(item, key)} key={item.uuid} data={item} styleName="gx-card-list" 
                onDelete={onDelete} firebase={params.firebase} dispatch={params.dispatch} />
            </div>
          )
    );
  });
  
  // const SortableItemListMemo = React.memo(({ items }) => {
  //   return (items || []).map((item, key) => (
  //     <Draggable key={item.uuid} draggableId={item.uuid} index={getItemIndex(item, key)} >
  //       {
  //         provided => (
  //           <div  key={item.uuid} {...provided.draggableProps} {...provided.dragHandleProps}
  //             ref={provided.innerRef}>
  //             <ItemComponent onDelete={onDelete}
  //               id={item.uuid} index={getItemIndex(item, key)} key={item.uuid} data={item} styleName="gx-card-list" />
  //           </div>
  //         )
  //       }
  //     </Draggable>
  //   ));
  // });

  const match = {};

  const dispatchFetchItems = () => {
    console.log("calling dispatchFetchItems")
    config.indexFunction = getItemIndex;
    dispatch(fetchItems(page, config));
  }

  const EmptyListComponent = config.componentWhenNoItemsFound;

  const isFetchDone = (typeof componentState.fetchComplete !== 'undefined')? componentState.fetchComplete:false; 

  const renderItemList = ({items}) => {
    if (items.length >0 ){
      return renderInfiniteItems({items});
    }else if (isFetchDone) {
      return <EmptyListComponent />
    }

  }
  const renderInfiniteItems = () => {
    return (

      <div>
        {/*<ContainerHeader title={config.pageTitle} match={match} />*/}
        <div className="gx-quiz-details-topbar">
        </div>
        <div className="gx-quiz-details-content" id="scrollableDiv">
          <Row>
            <Col span={24}>
              <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={dispatchFetchItems}
                hasMore={hasMore}
                loader={<Spin size='large' />}
                scrollThreshold={.75}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b></b>
                  </p>
                }
                scrollableTarget="scrollableDiv"
              // below props only if you need pull down functionality
              // refreshFunction={this.refresh}
              // pullDownToRefresh
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
              // }
              >
                {<ItemListMemo items={items} />}

              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div >

    )
  }

  // const renderSortableList = () => {
  //   return (
  //     <Row>
  //       <Col span={24}>
  //         <ItemListMemo items={items} />
  //       </Col>
  //     </Row>
  //   )
  // }

  // const onDragEnd = (result) => {
  //   console.log("Result ", result);
  //   onSortEnd({oldIndex: result.source.index, newIndex: result.destination.index})
  // }

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   console.log({ oldIndex, newIndex, params })
  //   //change the order of the items in reducer
  //   if (oldIndex !== newIndex) {
  //     dispatch({ type: "CHANGE_ITEM_ORDER", config, oldIndex, newIndex })
  //     params.reOrderFunction({ oldIndex, newIndex });
  //   }
  // }
  // const helperFunction = ({ collection, index, node }) => {
  //   console.log({ collection, index, node });
  //   return { height: "0px" }
  // }

  // return (
  //   <DragDropContext onDragEnd={onDragEnd}>
  //     <Droppable droppableId="1">
  //       {
  //         provided => (
  //           <div id="sortableContainer" ref={provided.innerRef} {...provided.droppableProps} > {config.sortable ? renderSortableList(items) : renderInfiniteItems(items)}
  //             {provided.placeholder}
  //           </div>
  //         )
  //       }
  //     </Droppable>
  //   </DragDropContext>
  // );
  return (
            <div id="sortableContainer" >  
              {renderItemList({items})}
            </div>
          )
}

List.propTypes = {
  dispatch: PropTypes.func.isRequired,
  config: PropTypes.shape({
    type: PropTypes.string,
    perPage: PropTypes.number,
    API: PropTypes.string,
    initialState: PropTypes.shape({
      topics: PropTypes.array,
      hasMore: PropTypes.bool,
      page: PropTypes.number,
      perPage: PropTypes.number
    }),
    itemComponent: PropTypes.func,
  })
};



function mapStateToProps(state, params) {
  console.log("STATE in Generic LIST mapStateToProps", state, params);
  let obj = {};
  obj[reducerName] = selectTopicsList(state)
  return obj;

}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(List);
