// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';


export const TOPIC_SAVING = 'TOPIC_SAVING';
export const CREATE_TOPIC_REQUEST = 'CREATE_TOPIC_REQUEST';
export const CREATE_TOPIC_SUCCESS = 'CREATE_TOPIC_SUCCESS';
export const CREATE_TOPIC_FAILED = 'CREATE_TOPIC_FAILED';
export const FETCH_TOPICS_REQUEST = 'FETCH_TOPICS_REQUEST';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAILED = 'FETCH_TOPICS_FAILED';
export const PATCH_TOPIC_TITLE_REQUEST = 'PATCH_TOPIC_TITLE_REQUEST';
export const PATCH_TOPIC_TITLE_SUCCESS = 'PATCH_TOPIC_TITLE_SUCCESS';
export const PATCH_TOPIC_TITLE_FAILED = 'PATCH_TOPIC_TITLE_FAILED';
export const FETCH_TOPIC_REQUEST = 'FETCH_TOPIC_REQUEST';
export const FETCH_TOPIC_SUCCESS = 'FETCH_TOPIC_SUCCESS';
export const FETCH_TOPIC_FAILED = 'FETCH_TOPIC_FAILED';
export const GOTO_CREATE_TOPIC = 'GOTO_CREATE_TOPIC';
export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILED = 'FETCH_ITEMS_FAILED';
export const UPLOAD_ITEM_MEDIA_REQUEST = 'UPLOAD_ITEM_MEDIA_REQUEST';
export const UPLOAD_ITEM_MEDIA_SUCCESS = 'UPLOAD_ITEM_MEDIA_SUCCESS';
export const UPLOAD_ITEM_MEDIA_FAILED = 'UPLOAD_ITEM_MEDIA_FAILED';

export const DELETE_ITEM_MEDIA_REQUEST = 'DELETE_ITEM_MEDIA_REQUEST';
export const DELETE_ITEM_MEDIA_SUCCESS = 'DELETE_ITEM_MEDIA_SUCCESS';
export const DELETE_ITEM_MEDIA_FAILED = 'DELETE_ITEM_MEDIA_FAILED';
export const RESET_TOPIC_STATE = 'RESET_TOPIC_STATE';
export const CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAILED = 'CREATE_ITEM_FAILED';
export const EDIT_ITEM_REQUEST = 'EDIT_ITEM_REQUEST';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILED = 'EDIT_ITEM_FAILED';
export const DEL_TOPIC_REQUEST = 'DEL_TOPIC_REQUEST';
export const DEL_TOPIC_SUCCESS = 'DEL_TOPIC_SUCCESS';
export const DEL_TOPIC_FAILED = 'DEL_TOPIC_FAILED';
export const DEL_ITEM_REQUEST = 'DEL_ITEM_REQUEST';
export const DEL_ITEM_SUCCESS = 'DEL_ITEM_SUCCESS';
export const DEL_ITEM_FAILED = 'DEL_ITEM_FAILED';
export const DEL_NEW_ITEM_SUCCESS = 'DEL_NEW_ITEM_SUCCESS';
export const CHANGE_ROUTE = 'CHANGE_ROUTE';
export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';
export const ADD_NEW_TERM = 'ADD_NEW_TERM';
export const ADD_NEW_OPTION = 'ADD_NEW_OPTION';
export const ADD_NEW_DEFINITION = 'ADD_NEW_DEFINITION';
export const UPDATE_OPTION_SUCCESS ='UPDATE_OPTION_SUCCESS';
export const UPDATE_OPTION_FAILED = 'UPDATE_OPTION_FAILED';
export const DELETE_OPTION_SUCCESS = 'DELETE_OPTION_SUCCESS';
export const DELETE_OPTION_FAILED= 'DELETE_OPTION_FAILED';
export const LOGOUT = 'LOGOUT';
export const UPDATE_OUTH='UPDATE_OUTH';

export const SET_ENV_CONFIG_ACTION='SET_ENV_CONFIG_ACTION';
export const SET_ENV_CONFIG='SET_ENV_CONFIG';
export const SUBSCRIBE_TO_APP_CHANNEL_ACTION='SUBSCRIBE_TO_APP_CHANNEL_ACTION';
export const SUBSCRIBE_TO_APP_CHANNEL_SUCCESS='SUBSCRIBE_TO_APP_CHANNEL_SUCCESS';
export const SUBSCRIBE_TO_APP_CHANNEL_FAILURE='SUBSCRIBE_TO_APP_CHANNEL_FAILURE';

export const RECEIVE_APP_CHANNEL_MESSAGE='RECEIVE_APP_CHANNEL_MESSAGE';
export const MARK_CHANNEL_MESSAGE_AS_READ='MARK_CHANNEL_MESSAGE_AS_READ';


// Live beam participants listenr
export const SETUP_ROOM_NODE = 'SETUP_ROOM_NODE';
export const SETUP_ROOM_NODE_SUCCESS = 'SETUP_ROOM_NODE_SUCCESS';
export const SETUP_ROOM_NODE_FAILURE = 'SETUP_ROOM_NODE_FAILURE';

export const EXIT_ROOM_NODE = 'EXIT_ROOM_NODE';

export const DELETE_ROOM_NODE = 'DELETE_ROOM_NODE';
export const DELETE_ROOM_NODE_SUCCESS = 'DELETE_ROOM_NODE_SUCCESS';
export const DELETE_ROOM_NODE_FAILURE = 'DELETE_ROOM_NODE_FAILURE';
export const LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS = 'LISTEN_LIVE_ROOM_PARTICIPANTS_STATUS';

export const UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS = 'UPDATE_LIVE_ROOM_PARTICIPANTS_STATUS';
