/*
 * Learn Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { DEL_ITEM, DEL_ITEM_SUCCESS, DEL_ITEM_FAILURE, ADDED_ITEM, ADD_ITEM, ADD_ITEM_SUCCESS, ADD_ITEM_FAILURE, FETCH_ITEMS , FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE, UPDATE_LIST_ITEM, UPDATE_LIST_ITEM_SUCCESS, UPDATE_LIST_ITEM_FAILURE,IMPORT_LIST_ITEM_SUCCESS,
  DEL_INNER_ITEM_OF_ITEM_SUCCESS,
  ADD_INNER_ITEM_OF_ITEM_SUCCESS,
  UPDATE_INNER_ITEM_OF_ITEM_SUCCESS
} from './constants';

/**
 * Load the Items, this action starts the request saga
 *
 * @return {object} An action object with a type of FETCH_ITEMS
 */
export function fetchItems(page, config) {
  return {
    type: FETCH_ITEMS,
    config,
    page
  };
}

/**
 * Load the Items, this action starts the request saga
 *
 * @return {object} An action object with a type of FETCH_ITEMS_SUCCESS
 */
export function fetchItemsSuccess(items, config) {
  return {
    type: FETCH_ITEMS_SUCCESS,
    items,
    config
  };
}

/**
 * Load the Items, this action starts the request saga
 *
 * @return {object} An action object with a type of FETCH_ITEMS_FAILURE
 */
export function fetchItemsFailure(err, config) {
  return {
    type: FETCH_ITEMS_FAILURE,
    err,
    config,
  };
}

/**
 * addItem creator
 *
 * @return {object} An action object with a type of ADD_ITEM
 */
export function addItemActionCreator(actionInfo,config) {
  console.log("actionInfo, config",actionInfo, config)
  return {
    type: ADD_ITEM,
    actionInfo,
    config,
  };
}

/**
 * addItem success action creator
 *
 * @return {object} An action object with a type of ADD_ITEM_SUCCESS
 */
export function addItemSuccessActionCreator(topicId, itemIndex, item) {
  console.log(topicId, itemIndex)
  return {
    type: ADD_ITEM_SUCCESS,
    topicId,
    itemIndex,
    item
  };
}

/**
 * addItem failure action creator
 *
 * @return {object} An action object with a type of ADD_ITEM_FAILURE
 */
export function addItemFailureActionCreator(topicId, itemIndex, err) {
  console.log(topicId, itemIndex)
  return {
    type: ADD_ITEM_FAILURE,
    topicId,
    itemIndex,
    err
  };
}
/**
 * addedItem action creator
 *
 * @return {object} An action object with a type of ADDED_ITEM
 */
export function addedItemActionCreator(actionInfo, config) {
  return {
    type: ADDED_ITEM,
    actionInfo,
    config
  }
}
/**
 * deleteItem action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function deleteItemActionCreator(item, config) {
  return {
    type: DEL_ITEM,
    item,
    config
  }
}
/**
 * deleteItemSuccess action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function delItemSuccess(item, config) {
  return {
    type: DEL_ITEM_SUCCESS,
    item,
    config
  }
}
/**
 * deleteItem action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function delItemFailure(err, item, config) {
  return {
    type: DEL_ITEM_FAILURE,
    item,
    config,
    err
  }
}
/**
 * updateItem action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function updateListItemActionCreator(item, config) {
  return {
    type: UPDATE_LIST_ITEM,
    item,
    config
  }
}
/**
 * updateItemSuccess action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function updateListItemSuccess(item, config) {
  return {
    type: UPDATE_LIST_ITEM_SUCCESS,
    item,
    config
  }
}
/**
 * updateItemFailure action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function updateListItemFailure(err, item, config) {
  return {
    type: UPDATE_LIST_ITEM_FAILURE,
    item,
    config,
    err
  }
}

/**
 * ImportSuccess action creator
 *
 * @return {object} An action object with a type of DEL_ITEM
 */
export function importListItemSuccess(appChannelMessage, config) {
  return {
    type: IMPORT_LIST_ITEM_SUCCESS,
    appChannelMessage,
    config
  }
}

export const deleteInnerItemFromItemAction = (payload) => ({
  type:DEL_INNER_ITEM_OF_ITEM_SUCCESS,
  payload
});

export const addInnerItemFromItemAction = (payload) => ({
  type: ADD_INNER_ITEM_OF_ITEM_SUCCESS,
  payload
});

export const updateInnerItemOfItemAction = (payload) => {
  return {
    type: UPDATE_INNER_ITEM_OF_ITEM_SUCCESS,
    payload
  }
}