import { FETCH_FB_AUTH_TOKEN, FETCH_FB_AUTH_TOKEN_SUCCESS, FETCH_FB_AUTH_TOKEN_FAILURE, FB_SIGNIN_SUCCESS, FB_SIGNIN_FAILURE  } from "constants/FirebaseConstants";

export const createActionToFetchFBAuthToken = (requestInput) => {
    return {
      type: FETCH_FB_AUTH_TOKEN,
      requestInput,
    };
};


export const fetchFBAuthTokenSuccess = ({requestInput, response}) => {
    return {
      type: FETCH_FB_AUTH_TOKEN_SUCCESS,
      requestInput,
      response
    };
};

export const fetchFBAuthTokenFailure = ({requestInput, err}) => {
    return {
      type: FETCH_FB_AUTH_TOKEN_FAILURE,
      requestInput,
      err
    };
};


export const fbSigninSuccess = ({requestInput, signInResponse}) => {
  return {
    type: FB_SIGNIN_SUCCESS,
    requestInput,
    signInResponse
  };
};

export const fbSigninFailure = ({requestInput, err}) => {
  return {
    type: FB_SIGNIN_FAILURE,
    requestInput,
    err
  };
};
