import {
  DEFAULT_SORTING_ASSESSMENT_CONFIG
} from "../../util/constants";
import { isValidSorting } from "../validators/item/sortingValidator";
const clone = require("rfdc")();

const getSortingItemContent = ({ item }) => {
  const convertedContent = {};
  const itemContent = item.content;
  convertedContent.id = itemContent.id;
  convertedContent.stimulus = itemContent.stimulus;
  const totalWeightOfSortedElements = itemContent.sortedElements.reduce(
    (acc, curr) => acc + curr.weight,
    0
  );
  convertedContent.challenges = itemContent.sortedElements.map(singleEle => ({
    ...singleEle,
    pointPercentage: singleEle.weight / totalWeightOfSortedElements
  }));
  return convertedContent;
};

export const createSortingAssessmentItem = ({
  item,
  topicItemInfo,
  userSignature
}) => {
  const isValid = isValidSorting({ item });
  if (!isValid) {
    return null;
  }

  const finalAssessmentItem = {
    _id: item._id,
    type: "assessment",
    subtype: "sorting",
    content: getSortingItemContent({ item }),
    attachments: item.attachments ? item.attachments : [],
    uuid: item.uuid,
    title: item.title ? item.title : "",
    assessment_config: clone(topicItemInfo.assessment_config) || {
      ...DEFAULT_SORTING_ASSESSMENT_CONFIG
    },
    owner_uuid: userSignature.accountId
  };

  return finalAssessmentItem;
};
