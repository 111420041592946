export const promptSelectableForMatching = (
  data,
  selectableArray,
  topicItemInfo
) => {
  selectableArray.push({
    activityType: "matching",
    title: "Matching"
  });

  if (
    ifOneOrMorePairsHaveMoreAnswerAndNotShuffleLeftToRight(
      data.content.pairs,
      topicItemInfo.assessment_config.shuffleLeftToRight
    )
  ) {
    selectableArray.push({
      activityType: "grouping",
      title: "Grouping"
    });
  }
};

const ifOneOrMorePairsHaveMoreAnswerAndNotShuffleLeftToRight = (
  pairs,
  toShuffleSides
) =>
  !toShuffleSides &&
  pairs.some(
    singlePair =>
      singlePair.correctAnswers && singlePair.correctAnswers.length > 0
  );
