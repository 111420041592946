import { getRandomInteger } from "../../util/topicUtils";
import itemConfigSelectable from "../itemConfigSelectables";
import promptSelectionArray from "../promptSelectionArrayBuilder";

export const changedTopicItemInfoForMatching = (item, topicItemInfo) => {
  let shuffleSide;
  let promptSelectables;

  if (
    !topicItemInfo.assessment_config.challenges_config.display_order &&
    !topicItemInfo.assessment_config.options_config.display_order
  ) {
    const activityModeTypesArray = itemConfigSelectable("matching");
    const randomIndexForArray = getRandomInteger(activityModeTypesArray.length);
    shuffleSide = activityModeTypesArray[randomIndexForArray].value;
  }

  if (topicItemInfo.assessment_config.promptSelection) {
    promptSelectables = promptSelectionArray("matching", item, topicItemInfo);
  }

  const newTopicItemInfo = {
    ...topicItemInfo
  };

  if (shuffleSide) {
    if (shuffleSide === "right_side") {
      newTopicItemInfo.assessment_config.challenges_config.display_order =
        "random";
      newTopicItemInfo.assessment_config.options_config.display_order = "fixed";
    } else {
      newTopicItemInfo.assessment_config.challenges_config.display_order =
        "fixed";
      newTopicItemInfo.assessment_config.options_config.display_order =
        "random";
    }
  }

  if (promptSelectables) {
    newTopicItemInfo.assessment_config.allowed_activities_for_beam = promptSelectables;
  }

  return newTopicItemInfo;
};
