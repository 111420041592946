export function getPatchedBeamMcqItem ({item, patchArray, patchType}){
    if (patchType == 'mcq_addCorrectAnswer'){

    }
}

export function getPatchedBeamFibItem ({item, patchArray, patchType}){
    if (patchType == 'fib_addAcceptableAnswer'){
        patchArray.forEach( (patchObject)=>{
            item = fibPatch({item,patchObject})
        })
    }
    return item;
}

export function fibPatch({item, patchObject}){
    const entity = patchObject.entity;
    const operation = patchObject.operation;
    const acceptableAnswer = patchObject.value;
    switch(entity){
        case 'acceptableAnswer':
            if (operation === 'add'){
                return fib_AddAcceptableAnswer({item, acceptableAnswer})
            }
            break;
        default:
            break;
    }
    return item;
}

export function fib_AddAcceptableAnswer ({item, acceptableAnswer}){
    if (item && item.content && item.content.answers && item.content.answers.length >0){
        if (typeof item.content.answers[0].acceptableAnswers == 'undefined'){
            item.content.answers[0].acceptableAnswers=[];
        }
        item.content.answers[0].acceptableAnswers.push(acceptableAnswer)
    }
    return item;
}