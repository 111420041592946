import React from "react";

export const Trash = ({ onClick,customStyle, displayName, extraClass }) => {

    return (
        <button type="button" onClick={onClick} className={`ant-btn gx-lrnr-general-icon-btn ${(typeof extraClass!=="undefined")?extraClass:""}`} style={customStyle}>
            <i className="icon icon-trash"></i>
            {(typeof displayName!=="undefined")?(<span>{displayName}</span>):(null)}    
        </button>
    )
}