import { isValid } from 'data/validators/valUtils';

export const isValidMcq = ({ item }) => {

    if (!isValid(item) || !isValid(item.content)) {
        return false;
    }
    const noOfAnswers = getValidOptionsCount({ options: item.content.answers })
    const noOfDistractors = getValidOptionsCount({ options: item.content.distractors })
    console.log("noOfAnswers + noOfDistractors", noOfAnswers + noOfDistractors)
    if ((noOfAnswers + noOfDistractors < 2) ) {
        return false;
    }
    return true;
}

const getValidOptionsCount = ({ options }) => {
    console.log("Options", options);
    let noOfValidOptions = 0;
    if (isValid(options)) {
        options.forEach((option) => {
            if (isValid(option)) {
                if (isValid(option.value)){
                    noOfValidOptions++;
                }
            }
        })
    }
    return noOfValidOptions;
}